<template>
  <div>
    <div class="row">
      <div class="col-md">
        <div class="card">
          <div class="card-body position-relative overflow-hidden">
            <div class="card-small-info b">Total de chamados em aberto</div>
            <div class="card-small-info">Todos não finalizados</div>
            <div class="card-value-info">{{ dash.tickets_open }}</div>
            <i class="fas fa-envelope card-icon"></i>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="card">
          <div class="card-body position-relative overflow-hidden">
            <div class="card-small-info b">
              Total de chamados sem atendimento
            </div>
            <div class="card-small-info">Todos em status "aberto"</div>
            <div class="card-value-info">
              {{ dash.tickets_without_attendance }}
            </div>
            <i class="fas fa-envelope-open card-icon"></i>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="card">
          <div class="card-body position-relative overflow-hidden">
            <div class="card-small-info b">
              Total de chamados abertos no mês
            </div>
            <div class="card-small-info">
              {{ dash.period_start }} á {{ dash.period_end }}
            </div>
            <div class="card-value-info">
              {{ dash.tickets_actual_month }}
            </div>
            <i class="fas fa-calendar-times card-icon"></i>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="card">
          <div class="card-body position-relative overflow-hidden">
            <div class="card-small-info b">
              Total de chamados fechados no mês
            </div>
            <div class="card-small-info">
              {{ dash.period_start }} á {{ dash.period_end }}
            </div>
            <div class="card-value-info">
              {{ dash.tickets_closed_actual_month }}
            </div>
            <i class="fas fa-calendar-check card-icon"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <p class="title-graphic">
          Chamados Abertos x Fechados nos últimos 12 meses
        </p>
        <chart-line
          ref="a"
          :chartData="dash.graphics.opensVsCloseds"
          v-if="loaded"
        ></chart-line>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <p class="title-graphic">Quantidade de chamados abertos por contrato</p>
        <chart-bar
          :chartData="dash.graphics.ticketsOpenPerContract"
          v-if="loaded"
        ></chart-bar>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-md-12">
        <p class="title-graphic">Quantidade de chamados abertos por cliente</p>
        <chart-bar
          :chartData="dash.graphics.ticketsOpenPerCustomer"
          v-if="loaded"
        ></chart-bar>
      </div>
    </div>
  </div>
</template>

<script>
import ChartLine from "../Utils/ChartLine";
import ChartBar from "../Utils/ChartBar";
export default {
  data() {
    return {
      loaded: false,
      dash: {
        graphics: {},
      },
    };
  },
  methods: {
    getEntity() {
      let self = this;
      let api = self.$store.state.api + "dashsups";

      self.$http
        .post(api)
        .then((response) => {
          self.dash = response.data;
          self.loaded = true;
        })
        .catch((error) => {
          self.errors = error.reponse.data;
          self.$message(null, self.errors, "error");
        });
    },
  },
  mounted() {
    const self = this;

    self.getEntity();
  },
  components: { ChartLine, ChartBar },
};
</script>

<style scoped>
.card {
  margin-bottom: 1, 5rem;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0, 5rem;
}
.card-body {
  padding: 10px;
}
.card-small-info {
  text-align: justify;
}
.card-value-info {
  font-size: 20px;
  font-weight: 700;
}
.card-value-info span {
  font-size: 20px;
}
.card-icon {
  position: absolute;
  bottom: -10px;
  right: -10px;
  font-size: 90px;
  color: #08b9b3;
  opacity: 0.1;
  z-index: 0;
}
.card-percent.up {
  color: #28a745;
  font-size: 11px;
}
.card-percent.up::before {
  font-family: "Font Awesome 5 Free";
  content: "\f30c";
  font-weight: 700;
  margin-right: 3px;
}
.card-percent.down {
  color: #bb0034;
  font-size: 11px;
}
.card-percent.down::before {
  font-family: "Font Awesome 5 Free";
  content: "\f309";
  font-weight: 700;
  margin-right: 3px;
}
.b {
  font-weight: bold;
}
.title-graphic {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
}
</style>
