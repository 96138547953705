<template>
  <div>
    <div class="panel animated fadeInRightBig">
      <div class="panel-heading">
        <div class="card-header">
          <div class="card-title">Novo Chamado</div>
        </div>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-12">
            <form action>
              <div class="row">
                <div class="col-md-6">
                  <label for="project">
                    <span class="required">*</span> Cliente:
                  </label>
                  <v-select label="customer_name" :options="customers" :reduce="(customers) => customers.id"
                    v-model="newTicket.customer_id" @input="selectedCustomerUsers">
                    <div slot="no-options">Carregando...</div>
                  </v-select>
                </div>
                <div class="col-md-6">
                  <label for="project">
                    <span class="required">*</span> Responsável no Cliente:
                  </label>
                  <v-select label="name" :options="usercustomers" :reduce="(usercustomers) => usercustomers.id"
                    v-model="newTicket.usercustomer_id">
                    <div slot="no-options">
                      Selecione um cliente para listar os responsáveis
                    </div>
                  </v-select>
                </div>
                <div class="col-md-6">
                  <label for="process_names">
                    <span class="required"></span> Nome do Processo:
                  </label>
                  <v-select label="process_name" :options="process_names" :reduce="(process_names) => process_names.id"
                    v-model="newTicket.process_name_id">
                    <div slot="no-options">Carregando...</div>
                  </v-select>
                </div>
                <div class="col-md-6">
                  <label for="process_areas">
                    <span class="required"></span> Área do Processo:
                  </label>
                  <v-select label="process_areas" :options="process_areas" :reduce="(process_areas) => process_areas.id"
                    v-model="newTicket.process_area_id">
                    <div slot="no-options">Carregando...</div>
                  </v-select>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <span class="required">*</span>
                    <label for="subject">Assunto:</label>
                    <input type="text" class="form-control" id="subject" v-model="newTicket.subject"
                      autocomplete="false" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="peopleAttached">Pessoas em anexo:</label>
                    <input type="text" class="form-control" id="peopleAttached" @keydown="handleKeyDown"
                      v-model="attached.mail" autocomplete="false" />
                  </div>
                  <div class="row">
                    <div style="margin-left: 1%;" v-for="(item, index) in this.attached.peopleAttached" :key="index">
                      <div class="itens-mail p-2">
                        {{ item }}
                        <button class="close-button" @click="removeMail(index)" data-bs-toggle="tooltip"
                          data-bs-placement="top" title="Clique para remover">&times;</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <span class="required">*</span>
                  <label for>Descrição:</label>
                  <ckeditor required :editor="editor" v-model="newTicket.description" :config="editorConfig"></ckeditor>
                </div>
              </div>
              <hr />
              <br />
              <div class="d-flex justify-content-between">
               
                <router-link to="/new_tickets" class="btn btn-info">
                  <i class="fa fa-arrow-left"></i> Voltar
                </router-link>
                <a class="btn btn-success" @click="save">
                  Salvar
                  <i class="fa fa-save"></i>
                </a>
              </div>
            </form>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    // Update the loader's progress.
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    // Reject the promise returned from the upload() method.
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    // Note that your request may look different. It is up to you and your editor
    // integration to choose the right communication channel. This example uses
    // a POST request with JSON as a data structure but your configuration
    // could be different.
    xhr.open(
      "POST",
      "https://api.projetos.innovareti.com.br/api/v1/images/upload",
      true
    );
    xhr.responseType = "json";
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      resolve({
        default: response.url,
      });
    });

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    // Prepare the form data.
    const data = new FormData();

    data.append("upload", file);

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, you can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data);
  }
}

function MyUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
    return new MyUploadAdapter(loader);
  };
}

export default {
  components: {
    vSelect,
  },
  name: "imageUpload",
  data() {
    return {
      editor: ClassicEditor,
      editorData: "<p>Hello World ...</p>",
      plugins: ["SimpleUploadAdapter"],
      editorConfig: {
        extraPlugins: [MyUploadAdapterPlugin],
      },
      attached: {
        peopleAttached: [],
        mail: '',
      },
      newTicket: {
        usercustomer_id: "",
        customer_id: "",
        subject: "",
        description: "",
        external_id: "",
        process_name_id: "",
        process_area_id: "",

      },
      process_names: [],
      process_areas: [],
      customers: [],
      usercustomers: [],
      previewImage: null,
      errors: undefined,
    };
  },
  mounted() {
    this.getCustomers();
    this.getProcessNames();
    this.getProcessAreas();
  },
  methods: {
    removeMail(index) {
      if (index >= 0 && index < this.attached.peopleAttached.length) {
        
        this.attached.peopleAttached.splice(index, 1);
        
      }
    },
    handleKeyDown(event) {
     
      if (event.key === "Enter" || event.key === "Tab" || event.key === " ") {
        event.preventDefault();
        this.attached.peopleAttached.push(this.attached.mail);
        this.attached.mail = '';
        
      }
    },

    save: function () {
      const api = this.$store.state.api + "new_ticket/";
      const self = this;

      let form = new FormData();

      form.append("usercustomer_id", self.newTicket.usercustomer_id);
      form.append("customer_id", self.newTicket.customer_id);
      form.append("subject", self.newTicket.subject);
      form.append("description", self.newTicket.description);
      form.append("process_name_id", self.newTicket.process_name_id);
      form.append("process_area_id", self.newTicket.process_area_id);
      form.append("attached", self.attached.peopleAttached);
      form.append("status_entity", 12);

      console.log( self.attached.peopleAttached);
      if(true){
        self.$http
        .post(api, form)
        .then((response) => {
          self.usercustomers = response.data.data;
          self.tableData = self.users;

          self.$message("Sucesso", `Cadastrado com sucesso!`, "success");

          self.usercustomers = [];
          this.$router.push("/new_tickets");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
      }

    
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
    },
    getCustomers: function (id) {
      let self = this;
      let api = self.$store.state.api + "customers?paginated=false";

      self.$http
        .get(api)
        .then((response) => {
          self.customers = response.data.data;
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
    getProcessNames: function (id) {
      const self = this;
      const api = self.$store.state.api + "process_names?paginated=false/";

      self.$http
        .get(api)
        .then((response) => {
          self.process_names = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProcessAreas: function (id) {
     
      const self = this;
      const api = self.$store.state.api + "process_areas?paginated=false/";

      self.$http
        .get(api)
        .then((response) => {
          self.process_areas = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    selectedCustomerUsers(customer) {
      let self = this;
      let api = this.$store.getters.api + "usercustomers?paginated=false";

      self.$http
        .get(api + "&customer_id=" + self.newTicket.customer_id)
        .then((response) => {
          self.usercustomers = response.data.data;
        });
    },
    // selectedProcessTickets(process_names) {
    //   let self = this;
    //   let api = this.$store.getters.api + "process_names?paginated=false";

    //   self.$http
    //     .get(api + "&process_id=" + self.newTicket.process_id)
    //     .then((response) => {
    //       self.process_names = response.data.data;
    //     });
    // },
  },
};
</script>
<style scoped>
.uploading-image {
  display: flex;
}

.required {
  color: red;
}

.itens-mail {
  display: flex;
  align-items: center;
  /* Alinhar verticalmente o texto e o botão */
  padding: 4%;
  background-color: rgb(218, 214, 214);
  cursor: pointer;
}

.close-button {
  margin-left: 8px;
  /* Adicionar um espaço à esquerda do botão */
  border: none;
  background: none;
  font-size: 18px;
  color: red;
  cursor: pointer;
}

.close-button:focus {
  outline: none;
  /* Remover a borda de foco do botão */
}
</style>
