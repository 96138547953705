import Crud from '../components/Wiki/Crud'
import Add from '../components/Wiki/Add'
import View from '../components/Wiki/View'

let routes = {
    path: '/wikis',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/wikis/add/',
            name: 'Adicionar Regitros',
            component: Add,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/wikis/edit/:id',
            name: 'Editar Regitros',
            component: Add,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/wikis/view/:id',
            name: 'Visualizar Regitros',
            component: View,
            meta: {
                permission: 'is_admin'
            }
        },        
        {
            path: '/wikis/',
            name: 'Listar Registros',
            component: Crud,
            meta: {
                permission: 'is_admin'
            }
        },
    ]
};


export default routes