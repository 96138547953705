<template>
    <div>
        <base-crud
            crudName="Categoria de Chamados"
            newText="Nova Categoria"
            :table="table"
            :columns="columns"
            :options="options"
            :endPoint="endPoint"
            :enableAdd="true"
            :enableEdit="true"
            :enableDelete="true"
            :enableView="false"
            :dataForm="dataForm"
            :extra="extra"
        ></base-crud>
    </div>
</template>

<script>
import BaseCrud from "../Base/BaseCrud";


export default {
    data: function() {
        return {
            assigneds: [],
            assignedIds: [],
            table: "ticketCategoryTable",
            pages: [],
            url: "",
            columns: ["id", "name", "actions"],
            tableData: ["id", "name"],
            options: {
                pagination: { chunk: 10, dropdown: false, nav: "scroll" },
                perPage: 10,
                filterByColumn: true,
                filterable: ["id", "name"],
                debounce: 800,
                perPageValues: [10, 25, 50, 100],
                headings: {
                    id: "ID",
                    name: "Nome",
                    actions: "Ações"
                },

                sortable: ["id", "name"],
                templates: {},
                requestFunction: function(data) {
                    let requestData = {};

                    let query = this.$parent.$parent.query(data.query);
                    requestData.params = data;
                    requestData.params.query = "";

                    return this.$http
                        .get(this.url + "?" + query, requestData)
                        .catch(
                            function(e) {
                                this.dispatch("error", e);
                            }.bind(this)
                        );
                }
            },
            extra: [],
            endPoint: "ticketcategories/",
            dataForm: {
                title: {
                    add: "Nova Categoria",
                    edit: "Editar Categoria",
                    view: "Visualizar Categoria",
                    icon: "fa fa-pen"
                },
                button: {
                    back: "/ticketcategories"
                },
                url: {
                    model: "ticketcategories"
                },
                messages: {
                    success: {
                        add: "Categoria cadastrada com sucesso!",
                        edit: "Categoria alterada com sucesso!"
                    }
                },
                fields: [
                    {
                        name: "name",
                        model: "name",
                        label: "Nome",
                        type: "text",
                        isRequired: {
                            add: true,
                            edit: true
                        },
                        placeholder: "",
                        class: "",
                        length: "6|6|6",
                        offset: null
                    }
                ]
            }
        };
    },
    components: {
        BaseCrud
    },
    methods: {
        query: function(query) {
            let columns = {
                id: "id",
                name: "name"
            };
            let filters = "";
            $.each(query, function(index, value) {
                filters += columns[index] + "=" + value + "&";
            });
            return filters;
        }
    },
    mounted: function() {}
};
</script>

<style scoped>
.VuePagination {
    text-align: center;
}
</style>
