<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">Nova Daily</div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <label for="title">O Que fez Ontem?</label>
          <textarea class="form-control" id="title" v-model="dailies.yesterday"  rows="3" disabled="disabled" />
        </div>
      </div><br>
      <div class="row">
        <div class="col-md-12">
          <label for="title">O Que fara Hoje?</label>
          <textarea class="form-control" id="title" v-model="dailies.today"  rows="3" disabled="disabled" />
        </div>
      </div><br> 
      <div class="row">
        <div class="col-md-12">
          <label for="title">Impedimentos ?</label>
          <textarea class="form-control" id="title" v-model="dailies.impediments"  rows="3" disabled="disabled" />
        </div>
      </div>     
    </div>

    <div class="card-body">
      <hr />
      <div class="block text-left">
        <router-link to="/Daily" class="btn btn-info">
          <i class="fa fa-arrow-left"></i> Voltar
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>



export default {
  components: {
 
  },
  data() {
    return {
      dailies: {
        today: '',
        yesterday: '',
        impediments: ''
      }
    };
  },
  computed: {
    url() {
      return (
        this.$store.getters.api + this.$route.params.id
      );
    }
  },

  mounted() {
    let self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getDailies(id);
    }

  },
  methods: {
    getDailies: function(id) {
      let self = this;
      let api = self.$store.state.api + "dailies/" + id;

      self.$http
        .get(api)
        .then(response => {
          self.dailies = response.data.data[0];
        })
        .catch(error => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    }
  }
};
</script>

<style scoped>

</style> 