<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card1">
        <div class="card-body">
          <div class="card mt-3 tab-card">
            <div class="card-header">
              <div class="card-title">
                <h4>
                  Innover Legends<img
                    src="../../assets/images/mlegends.png"
                    alt
                  />
                </h4>
              </div>
            </div>
            <ul class="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="four-tab"
                  data-toggle="tab"
                  href="#four"
                  role="tab"
                  aria-controls="four"
                  aria-selected="true"
                  @click="legendsTab = 1"
                  >Diário</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="five-tab"
                  data-toggle="tab"
                  href="#five"
                  role="tab"
                  aria-controls="five"
                  aria-selected="false"
                  @click="legendsTab = 2"
                  >Semanal</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="six-tab"
                  data-toggle="tab"
                  href="#six"
                  role="tab"
                  aria-controls="six"
                  aria-selected="false"
                  @click="legendsTab = 3"
                  >Mensal</a
                >
              </li>
            </ul>

            <div>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active p-3"
                  id="four"
                  role="tabpanel"
                  aria-labelledby="four-tab"
                  v-if="legendsTab == 1 && legendsTab != 3 && legendsTab != 2"
                >
                  <!-- DIÁRIO -->
                  <div class="row">
                    <table class="table table-striped" style="heigth: 150px">
                      <thead>
                        <tr>
                          <th id="title1">Dev</th>
                          <th id="title1">Qtd Pontos</th>
                          <th id="title1">Tier</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="tPoints in legendsTodaySum"
                          :key="tPoints.id"
                        >
                          <td
                            v-if="tPoints.points > 3"
                            class="table-content"
                            style="width: 32%"
                          >
                            {{ tPoints.name }}
                          </td>
                          <td
                            v-if="tPoints.points > 3"
                            class="table-content"
                            style="width: 32%"
                          >
                            {{ tPoints.points }}
                          </td>
                          <td
                            v-if="tPoints.points > 3"
                            class="table-content"
                            style="width: 32%"
                          >
                            {{ tPoints.tier }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button
                        data-toggle="modal"
                        data-target="#modal-month"
                        class="btn info1 btn-sm"
                      >
                        <small
                          ><img
                            src="https://img.icons8.com/offices/30/000000/rules.png"
                        /></small>
                        Critérios
                      </button>
                    </div>
                    <hr />
                    <br />
                    <div
                      id="modal-month"
                      class="modal fade bs-example-modal-lg"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="classInfo"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h4
                              class="modal-title"
                              style="float: left"
                              id="classModalLabel"
                            >
                              Critérios
                            </h4>
                          </div>
                          <div class="modal-body">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th id="title1">Tier</th>
                                  <th id="title1">Qtd Pontos Necessários</th>
                                  <th id="title1">Meta em %</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Guerreiro
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 4 Até 5
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    50%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Elite
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 6 Até 7
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    75%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Mestre
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Somente 8
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    100%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Grande Mestre
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 9 Até 10
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    120%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Épico
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Somente 11
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    140%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Lenda
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Somente 12
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    160%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Mítico
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 13 Até 15
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    180%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Glória Mítica
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    16 ou Mais
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    200%
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-primary"
                              data-dismiss="modal"
                            >
                              OK
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- SEMANAL -->
                <div
                  class="tab-pane fade show active p-3"
                  id="five"
                  role="tabpanel"
                  aria-labelledby="five-tab"
                  v-if="legendsTab > 1 && legendsTab != 3"
                >
                  <div class="row">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th id="title1">Dev</th>
                          <th id="title1">Qtd Pontos</th>
                          <th id="title1">Tier</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="tPoints in legendsWeekSum" :key="tPoints.id">
                          <td
                            v-if="tPoints.points > 19"
                            class="table-content"
                            style="width: 32%"
                          >
                            {{ tPoints.name }}
                          </td>
                          <td
                            v-if="tPoints.points > 19"
                            class="table-content"
                            style="width: 32%"
                          >
                            {{ tPoints.points }}
                          </td>
                          <td
                            v-if="tPoints.points > 19"
                            class="table-content"
                            style="width: 32%"
                          >
                            {{ tPoints.tier }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button
                        data-toggle="modal"
                        data-target="#modal-month"
                        class="btn info1 btn-sm"
                      >
                        <small
                          ><img
                            src="https://img.icons8.com/offices/30/000000/rules.png"
                        /></small>
                        Critérios
                      </button>
                    </div>
                    <hr />
                    <br />
                    <div
                      id="modal-month"
                      class="modal fade bs-example-modal-lg"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="classInfo"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h4
                              class="modal-title"
                              style="float: left"
                              id="classModalLabel"
                            >
                              Critérios
                            </h4>
                          </div>
                          <div class="modal-body">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th id="title1">Tier</th>
                                  <th id="title1">Qtd Pontos Necessários</th>
                                  <th id="title1">Meta em %</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Guerreiro
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 20 Até 29
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    50%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Elite
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 30 Até 39
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    75%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Mestre
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 40 Até 47
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    100%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Grande Mestre
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 48 Até 55
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    120%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Épico
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 56 Até 63
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    140%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Lenda
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 64 Até 71
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    160%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Mítico
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 72 Até 79
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    180%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Glória Mítica
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    80 ou Mais
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    200%
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-primary"
                              data-dismiss="modal"
                            >
                              OK
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- MENSAL -->
                <div
                  class="tab-pane fade show active p-3"
                  id="six"
                  role="tabpanel"
                  aria-labelledby="six-tab"
                  v-if="legendsTab > 2"
                >
                  <div class="row">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th id="title1">Dev</th>
                          <th id="title1">Qtd Pontos</th>
                          <th id="title1">Tier</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="tPoints in legendsMonthSum"
                          :key="tPoints.id"
                        >
                          <td
                            v-if="tPoints.points > 87"
                            class="table-content"
                            style="width: 32%"
                          >
                            {{ tPoints.name }}
                          </td>
                          <td
                            v-if="tPoints.points > 87"
                            class="table-content"
                            style="width: 32%"
                          >
                            {{ tPoints.points }}
                          </td>
                          <td
                            v-if="tPoints.points > 87"
                            class="table-content"
                            style="width: 32%"
                          >
                            {{ tPoints.tier }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button
                        data-toggle="modal"
                        data-target="#modal-month"
                        class="btn info1 btn-sm"
                      >
                        <small
                          ><img
                            src="https://img.icons8.com/offices/30/000000/rules.png"
                        /></small>
                        Critérios
                      </button>
                    </div>
                    <hr />
                    <br />
                    <div
                      id="modal-month"
                      class="modal fade bs-example-modal-lg"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="classInfo"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h4
                              class="modal-title"
                              style="float: left"
                              id="classModalLabel"
                            >
                              Critérios
                            </h4>
                          </div>
                          <div class="modal-body">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th id="title1">Tier</th>
                                  <th id="title1">Qtd Pontos Necessários</th>
                                  <th id="title1">Meta em %</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Guerreiro
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 88 Até 131
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    50%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Elite
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 132 Até 175
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    75%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Mestre
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 176 Até 210
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    100%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Grande Mestre
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 211 Até 245
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    120%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Épico
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 246 Até 280
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    140%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Lenda
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 281 Até 315
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    160%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Mítico
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    De 316 Até 351
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    180%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    id="tier"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    Glória Mítica
                                  </td>
                                  <td
                                    id="qtdPontos"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    352 ou Mais
                                  </td>
                                  <td
                                    id="metaPorcent"
                                    class="table-content"
                                    style="width: 32%"
                                  >
                                    200%
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-primary"
                              data-dismiss="modal"
                            >
                              OK
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      legendsTab: 1,
      legendsTodaySum: {},
      legendsWeekSum: {},
      legendsMonthSum: {},
    };
  },
  methods: {
    rules() {
      $("#rules").collapse("toggle");
    },
  },
  mounted() {
    let self = this;

    this.$http
      .get(this.$store.getters.api + "innover_legends")
      .then((response) => {
        self.legendsTodaySum = response.data.legendsTodaySum;
        self.legendsWeekSum = response.data.legendsWeekSum;
        self.legendsMonthSum = response.data.legendsMonthSum;
      });
  },
};
</script>

<style scoped>
.table-content {
  font-weight: bold;
  text-align: center;
}

.table-scroll {
  height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}
th {
  background-color: #299bff;
  color: white !important;
  text-align: center;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
}

.info1 {
  background-color: #299bff;
  color: white !important;
  text-align: center;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
}
</style>
