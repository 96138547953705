<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">Visualizar Registro</div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <label for="title">Titulo</label>
          <input
            type="text"
            class="form-control"
            id="title"
            v-model="wikis.title"
            disabled="disabled"
          />
        </div>
      </div>
      <br />

      <div class="row">
        <div class="col-md-12">
          <label for>Detalhes</label>
          <div v-html="wikis.description"></div>
        </div>
      </div>
      <br />

      <div class="row">
        <div class="col-md-12">
          <label for>Tags</label>
          <v-select
            label="title"
            id="title"
            taggable
            multiple
            push-tag
            :options="wikis.get_wiki_tags"
            v-model="wikis.get_wiki_tags"
            disabled="disabled"
          >
            <div slot="no-options">Pressione Enter para inserir múltiplas tags</div>
          </v-select>
        </div>
      </div>
    </div>

    <div class="card-body">
      <hr />
      <div class="block text-right">
        <router-link to="/wikis" class="btn btn-info">
          <i class="fa fa-arrow-left"></i> Voltar
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      wikis: {
        title: "",
        description: "",
        tags: "",
      },
    };
  },
  computed: {
    url() {
      return (
        this.$store.getters.api + "issues/?project_id=" + this.$route.params.id
      );
    },
  },

  mounted() {
    let self = this;

    let id = self.$route.params.id;

    if (id) {
      self.getWiki(id);
    }
  },
  methods: {
    getWiki: function (id) {
      let self = this;
      let api = self.$store.state.api + "wikis/" + id + "?with[]=GetWikiTags";

      self.$http
        .get(api)
        .then((response) => {
          self.wikis = response.data.data[0];
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
  },
};
</script>

<style>
</style> 