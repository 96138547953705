import Process from '../components/Process/Process.vue'
import AddProcess from '../components/Process/AddProcess.vue'
import Solicitation from '../components/Process/Solicitation.vue'

let routes = {
    path: '/process',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/addprocess',
            name: 'Projetos',
            component: AddProcess,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/process/solicitation/:id',
            name: 'Solicitações',
            component: Solicitation,
            meta: {
                permission: ''
            }
        },
        {
            path: '/process/',
            name: 'Processos',
            component: Process,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/process/:operation?/:id?',
            name: 'Projetos',
            component: Process,
            meta: {
                permission: 'is_admin'
            }
        }
    ]
};


export default routes
