<template>
  <div>
    <div v-if="!sprints.id">
      <h5>Adicionar Sprint</h5>
      <hr>
    </div>
    <div v-else>
      <h5>Editar Sprint</h5>
      <hr>
    </div>
    <div class="row">
        <div class="col-md-12">
          <div class="card1">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="name"><span class="required">*</span>Nome</label>
                    <input type="text" class="form-control" placeholder="Digite o nome" v-model="sprints.name"/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="project"><span class="required">*</span>Projeto</label>
                   <v-select :options="projects" label="name" id="project" :reduce="projects => projects.id" v-model="sprints.project_id">
                      <div slot="no-options">Carregando...</div>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="project"><span class="required">*</span>Status</label>
                    <select name="status'" class="form-control" v-model="sprints.status">
                      <option value="">Selecione</option>
                      <option value="ACTIVE">Ativo</option>
                      <option value="CLOSED">Fechado</option>
                      <option value="FUTURE">Futura</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                    <label for="">Início Da Sprint</label>
                    <input type="date" class="form-control" name="start_date" v-model="sprints.start_date">
                </div>
                <div class="col-md-4">
                    <label for="">Fim Da Sprint</label>
                    <input type="date" class="form-control" name="end_date" v-model="sprints.end_date">
                </div>
              </div>
              <br>
                <div class="row">
                <div class="col-md-12">
                <div class="float-l">
                <span class="required">* </span><span>Campos Obrigatórios</span>
                </div>
                <div class="float-r">
                    <router-link to="/projects-sprints" class="btn btn-warning">
                          <i class="fa fa-arrow-left"></i> Voltar
                  </router-link>
                    <button class="btn btn-info" @click="save">Salvar  <i class="fas fa-save"></i></button>
                  </div>
                </div>
              </div>
                <hr class="hr-1">
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
      vSelect
  },
  data() {
    return {
      sprints: {
        name: '',
        project_id: '',
        status: '',
        start_date: '',
        end_date: ''
      },
      projects: [],
    }
  },
  methods: {
    save: function () {
      let api = this.$store.state.api + "sprints";
      const self = this;

      let form = new FormData();

      form.append("name", self.sprints.name);
      form.append("project_id", self.sprints.project_id);
      form.append("status", self.sprints.status);
      form.append("start_date", self.sprints.start_date);
      form.append("end_date", self.sprints.end_date);

      if (self.sprints.id) {
        api += "/" + self.sprints.id;
        form.append("_method", "PUT");
      }

      self.$http
        .post(api, form)
        .then((response) => {
          self.$message("Sucesso", `Cadastrado com Sucesso`, "success");
          this.$router.push("/projects-sprints");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });
            html += "</ul>";
            self.errors = html;
          }
          self.$message(null, self.errors, "error");
        });
    },
    getProjects: function () {

      const self = this;
      const api = self.$store.state.api + 'projects/?paginated=false';

      self.$http.get(api)
          .then((response) => {
              self.projects = response.data.data
          })
          .catch((error) => {
              self.$message(null, error.response.data, 'error');
          });
    },
    getSprints: function (id) {
        const self = this;
        const api = self.$store.state.api + 'sprints/' + id;

        self.$http.get(api)
            .then((response) => {
                self.sprints = response.data.data[0];
                console.log(self.sprints);
            })
            .catch((error) => {
                self.$message(null, error.response.data, 'error');
            });
    }
  },
  mounted() {
  let self = this;
    let id = self.$route.params.id;
    self.getProjects();

    if (id) {
      self.getSprints(id);
    }
  },
}
</script>

<style scoped>
.required {
  color: red;
}
.float-r{
  float:right;
}
.float-l{
  float:left;
}
.hr-1{

  border-color: #299BFF; 
}
</style>
