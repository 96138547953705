<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">Novo Registro</div>
    </div>

    <div class="card-body">
      <form>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label>
                Nome do Contrato
                <span class="require">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                v-model="contracts.name"
                disabled
              />
            </div>
            <div class="form-group">
              <label>
                Tipo do Contrato
                <span class="require">*</span>
              </label>
              <v-select
                label="title"
                :options="typeofcontracts"
                :reduce="(typeofcontracts) => typeofcontracts.id"
                v-model="contracts.contract_id"
                disabled
              >
                <div slot="no-options">Carregando...</div>
              </v-select>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label>
                  Data Inicio
                  <span class="require">*</span>
                </label>
                <input
                  type="date"
                  class="form-control"
                  v-model="contracts.start_date"
                  disabled
                />
              </div>
              <div class="form-group col-md-6">
                <label>
                  Data Final
                  <span class="require">*</span>
                </label>
                <input
                  type="date"
                  class="form-control"
                  v-model="contracts.end_date"
                  disabled
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="form-check col-6 mx-auto" style="padding-left:35px">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="recurrent"
                  name="recurrent"
                  v-model="contracts.recurrent"
                  disabled
                />
                <label class="form-check-label" for="recurrent"
                  >Recorrente</label>
              </div>
              <div class="form-check col-6 mx-auto">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="active"
                  name="active"
                  v-model="contracts.active"
                  disabled
                />
                <label style="padding-left:6px" class="form-check-label" for="active"
                  >Ativo</label>
              </div>
              <input
                style="margin-left:15px"
                type="text"
                v-mask="'###:##:##'"
                id="inputText"
                v-model="contracts.hoursPerProject"
                v-if="contracts.recurrent"
                disabled
              />
            </div>
            <div class="row">
              <div class="col">
                <label>
                  Responsavel Tecnico
                  <span class="require">*</span>
                </label>
                <v-select
                  label="name"
                  :options="users"
                  :reduce="(users) => users.id"
                  v-model="contracts.userTech_id"
                  disabled
                >
                  <div slot="no-options">Carregando...</div>
                </v-select>
              </div>
              <div class="col">
                <label>
                  Responsavel Comercial
                  <span class="require">*</span>
                </label>
                <v-select
                  label="name"
                  :options="users"
                  :reduce="(users) => users.id"
                  v-model="contracts.userComm_id"
                  disabled
                >
                  <div slot="no-options">Carregando...</div>
                </v-select>
              </div>
              <div class="col">
                <label>
                  Responsavel Cliente
                  <span class="require">*</span>
                </label>
                <v-select
                  label="name"
                  :options="usercustomers"
                  :reduce="(usercustomers) => usercustomers.id"
                  v-model="contracts.customerUser_id"
                  disabled
                >
                  <div slot="no-options">Carregando...</div>
                </v-select>
              </div>
            </div>
            <div class="row">
              <div class="card-header col-12">
                <div class="card-title">SLA</div>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="col-6">
                    <h5>Melhorias</h5>
                    <table class="table">
                      <thead>
                        <tr>
                          <td>Prioridade</td>
                          <td>Horas</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(sla, index) in contracts.improvement_slas"
                          :key="index"
                        >
                          <td>{{ sla.priority }}</td>
                          <td>
                            <input
                              type="number"
                              class="form-control"
                              v-model="sla.hours"
                              readonly
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-6">
                    <h5>Problemas/Correções/Dúvidas</h5>
                    <table class="table">
                      <thead>
                        <tr>
                          <td>Prioridade</td>
                          <td>Horas</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(sla, index) in contracts.bug_slas"
                          :key="index"
                        >
                          <td>{{ sla.priority }}</td>
                          <td>
                            <input
                              type="number"
                              class="form-control"
                              v-model="sla.hours"
                              readonly
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!--|-->
          </div>
          <div class="vertical-line" />
          <div class="col">
            <div class="form-group">
              <div class="col-md-12">
                <label>Detalhes</label>
                <ckeditor
                  :editor="editor"
                  v-model="contracts.details"
                  :config="editorConfig"
                  disabled
                ></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="card-body">
      <hr />
      <div class="block text-right">
        <router-link to="/contracts" class="btn btn-info">
          <i class="fa fa-arrow-left"></i> Voltar
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: "<p>Hello World ...</p>",
      editorConfig: {},
      contracts: {
        name: "",
        contract_id: "",
        recurrent: false,
        hoursPerProject: "",
        start_date: "",
        end_date: "",
        userTech_id: "",
        userComm_id: "",
        customerUser_id: "",
        details: "",
        active: "",
      },
      users: [],
      typeofcontracts: [],
      usercustomers: [],
    };
  },
  computed: {
    url() {
      return this.$store.getters.api + this.$route.params.id;
    },
  },
  mounted() {
    let id = this.$route.params.id;
    if (id) {
      this.getContracts(id);
      this.getUsers(id);
      this.getTypeOfContracts(id);
      this.getUserCustomer(id);
    }
  },
  methods: {
    save: function () {
      let api = this.$store.state.api + "contracts";
      const self = this;

      let form = new FormData();

      form.append("name", self.contracts.name);
      form.append("contract_id", self.contracts.contract_id);
      form.append("recurrent", self.contracts.recurrent);
      form.append("hoursPerProject", self.contracts.hoursPerProject);
      form.append("start_date", self.contracts.start_date);
      form.append("end_date", self.contracts.end_date);
      form.append("userTech_id", self.contracts.userTech_id);
      form.append("userComm_id", self.contracts.userComm_id);
      form.append("customerUser_id", self.contracts.customerUser_id);
      form.append("details", self.contracts.details);
      if(self.contracts.active){
        form.append("active", 1);
      } else {
        form.append("active", 0);
      }

      if (self.contracts.id) {
        api += "/" + self.contracts.id;
        form.append("_method", "PUT");
      }

      self.$http
        .post(api, form)
        .then((response) => {
          self.$message("Sucesso", `Cadastrado com Sucesso`, "success");
          this.$router.push("/contracts");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });
            html += "</ul>";
            self.errors = html;
          }
          self.$message(null, self.errors, "error");
        });
    },
    getContracts: function (id) {
      let self = this;
      let api =
        self.$store.state.api +
        "contracts/" +
        id +
        "?with[]=improvement_slas&with[]=bug_slas";

      self.$http
        .get(api)
        .then((response) => {
          self.contracts = response.data.data[0];
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
    getUsers: function (id) {
      let self = this;
      let api = self.$store.state.api + "users/";

      self.$http
        .get(api)
        .then((response) => {
          self.users = response.data.data;
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
    getTypeOfContracts: function (id) {
      let self = this;
      let api = self.$store.state.api + "typeofcontracts/";

      self.$http
        .get(api)
        .then((response) => {
          self.typeofcontracts = response.data.data;
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
    getUserCustomer: function (id) {
      let self = this;
      let api = self.$store.state.api + "usercustomers/";

      self.$http
        .get(api)
        .then((response) => {
          self.usercustomers = response.data.data;
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
  },
};
</script>

<style>
.vertical-line {
  border-right: 1px solid #999;
}
</style>