import Crud from '../components/Customer/Crud'
import Entity from '../components/Customer/Add'
import View from '../components/Customer/View'
// import Edit from '../components/Customer/Edit'

let routes = {
  path: '/Customer',
  component: resolve => require(['../components/layout/App'], resolve),
  children: [{
      path: '/Customers',
      name: 'Listar Clientes',
      component: Crud,
      meta: {
        permission: 'is_admin'
      }
    },
    {
      path: '/customers/Add',
      name: 'Novo Cadastro',
      component: Entity,
    },
    {
      path: '/customers/View/:id',
      name: 'Visualizar',
      component: View,
    },
    {
      path: '/customers/Edit/:id',
      name: 'Editar',
      component: Entity
    }
  ]
};

export default routes;
