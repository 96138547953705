<template>
<div>
  <div class="card mt-3 tab-card">
              <ul
                class="nav nav-tabs card-header-tabs"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="one-tab"
                    data-toggle="tab"
                    href="#one"
                    role="tab"
                    aria-controls="One"
                    aria-selected="true"
                    @click="tab = 1"
                    >Sou Responsável</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="two-tab"
                    data-toggle="tab"
                    href="#two"
                    role="tab"
                    aria-controls="two"
                    aria-selected="false"
                    @click="tab = 2"
                    >Estou Acompanhando</a
                  >
                </li>
              </ul>

              <div>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active p-3"
                    id="one"
                    role="tabpanel"
                    aria-labelledby="one-tab"
                    @click="tab = 1"
                    v-if="tab != 2"
                  >
                    <div class="row">
                       <table class="table table-striped" style="heigth: 150px">
                        <thead>
                          <tr>
                            <th class="code">Numero do Chamado</th>
                            <th class="code">Cliente</th>
                            <th class="code">Assunto</th>
                            <th class="code">Status</th>
                            <th class="code">Acessar Chamado</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="responsible in responsibles" :key="responsible.id">
                            <td class="code" style="width: 32%">
                              {{ responsible.code }}
                            </td>
                            <td class="table-content" style="width: 32%">
                              {{ responsible.sent_by.name }}
                            </td>
                            <td class="table-content" style="width: 32%">
                              {{ responsible.subject }}
                            </td>
                            <td class="table-content" style="width: 32%">
                              {{ responsible.status_entity.title }}
                            </td>
                            <td class="table-content" style="float:right; padding-right:55px;">
                              <a  data-toggle="tooltip" title="Editar" class="btn btn-crud check fa fa-arrow-up"
                              target="_blank" :href="'/new_tickets/view/' + responsible.id"></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade show active p-3"
                    id="two"
                    role="tabpanel"
                    aria-labelledby="two-tab"
                    @click="tab = 2"
                    v-if="tab != 1"
                  >
                    <div class="row">
                      <table class="table table-striped" style="heigth: 150px">
                        <thead>
                          <tr>
                            <th class="code">Numero do Chamado</th>
                            <th class="code">Cliente</th>
                            <th class="code">Assunto</th>
                            <th class="code">Status</th>
                            <th class="code">Acessar Chamado</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="filliable in filliables" :key="filliable.id">
                            <td class="code" style="width: 32%">
                              {{ filliable.code }}
                            </td>
                            <td class="table-content" style="width: 32%">
                              {{ filliable.sent_by.name }}
                            </td>
                            <td class="table-content" style="width: 32%">
                              {{ filliable.subject }}
                            </td>
                            <td class="table-content" style="width: 32%">
                              {{ filliable.status_entity.title}}
                            </td>
                            <td class="table-content">
                              <a  data-toggle="tooltip" title="Editar" class="btn btn-crud check fa fa-arrow-up"
                              target="_blank" :href="'/new_tickets/view/' + filliable.ticket_id"></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
      </div>
</div>

</template>

<script>
export default {
 data() {
   return {
   responsibles: [],
   filliables: [],
   tab: 1,
  }
 },
methods: {
  getMeTickets: function () {
      const self = this;
      const api = self.$store.state.api + 'me-tickets?paginated=false';

      self.$http.get(api)
          .then((response) => {
              self.responsibles = response.data.responsible;
              self.filliables   = response.data.filliable;
          })
          .catch((error) => {
              self.$message(null, error.response.data, 'error');
          });
  },
  getCustomers: function () {
      const self = this;
      const api = self.$store.state.api + 'customers?paginated=false';

      self.$http.get(api)
          .then((response) => {
              self.customers = response.data.data;
          })
          .catch((error) => {
              self.$message(null, error.response.data, 'error');
          });
  },
},
mounted() {
  let self = this;
  this.getMeTickets();
  this.getCustomers();
},
}
</script>

<style scoped>
table {
  border: 1px solid grey;
  border-top: none;
}
thead{
  background-color: #299bff;
}
th {
  color: white !important;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
}
td {
  text-align: center;
}
.info1 {
  background-color: #299bff;
  color: white !important;
  text-align: center;
  font-weight: bold;
  line-height: 150%;
}
.code {
  vertical-align: middle !important;
  width: 35px !important;
  padding: 10px !important;
  white-space: nowrap !important;
}
</style>
