import Profile from '../components/Auth/Profile.vue'

let routes = {
    path: '/profile',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/profile',
            name: 'Profile',
            component: Profile,
            meta: {
              title: "Perfil",
              breadcrumb: `<li><a href="/"><i class="ti-home"></i> Dashboard </a></li><li><a href="/"><i class="ti-user"></i> Profile</a></li>`,
              permission: 'is_admin'
            },
        }
    ]
}


export default routes
