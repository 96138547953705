<template>
  <div>
    <div class="panel animated fadeInRightBig">
      <div class="panel-heading">
        <div class="card-header">
          <div class="card-title">Orçamento - Análise</div>
        </div>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-12">
            <div class="card mt-3 tab-card">
              <div class="card-header2 tab-card-header">
                <ul class="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="one-tab"
                      data-toggle="tab"
                      href="#one"
                      role="tab"
                      aria-controls="One"
                      aria-selected="true"
                    >Detalhes da Solicitação</a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="two-tab"
                      data-toggle="tab"
                      href="#two"
                      role="tab"
                      aria-controls="two"
                      aria-selected="false"
                    >Requisítos para Orçar</a>
                  </li>
                </ul>
              </div>

              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active p-3"
                  id="one"
                  role="tabpanel"
                  aria-labelledby="one-tab"
                >
                  <form>
                    <div class="form-row">
                      <div class="col">
                        <div></div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="userName">
                                <span class="required"></span> Titulo:
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="budgeting.title"
                                placeholder
                                disabled
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="userEmail">
                                <span class="required"></span> Cliente:
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="budgeting.customer"
                                id="userEmail"
                                placeholder
                                disabled
                                autocomplete="off"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="criticality">
                                    <span class="required"></span> Criticidade:
                                  </label>
                                  <select
                                    disabled
                                    class="form-control"
                                    v-model="budgeting.criticism"
                                    required
                                  >
                                    <option
                                      v-for="criticism in criticisms"
                                      :key="criticism.id"
                                      :value="criticisms.id"
                                    >{{ criticism.name }}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group">
                                  <label for="tel">Prazo:</label>
                                  <select
                                    disabled
                                    class="form-control"
                                    v-model="budgeting.deadline"
                                    required
                                  >
                                    <option
                                      v-for="deadline in deadlines"
                                      :key="deadline.id"
                                      :value="deadlines.id"
                                    >{{ deadline.name }}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div id="down-scroll">
                            <div class="col-12">
                              <div class="row">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th style="float:left;">Arquivos</th>
                                      <th>Downloads</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="attachment in budgeting.attachments"
                                      :key="attachment.id"
                                    >
                                      <td>{{attachment.attachment}}</td>
                                      <td>
                                        <a
                                          :href="$store.getters.api + 'budgeting-attachments/download/' + attachment.id"
                                          target="blank"
                                          class="btn btn-primary"
                                        >Download</a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--|-->
                      </div>
                      <div class="vertical-line" />
                      <div class="col">
                        <div class="form-group">
                          <div class="col-md-12">
                            <label>Detalhes</label>
                            <ckeditor
                              :editor="editor"
                              v-model="budgeting.description"
                              :config="editorConfig"
                              disabled
                            ></ckeditor>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="block text-right">
                      <router-link to="/budgetings" class="btn btn-info">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                    </div>
                  </form>
                </div>

                <div class="tab-pane fade p-3" id="two" role="tabpanel" aria-labelledby="two-tab">
                  <h5 class="card-title">Considerações para o Orçamento:</h5>
                  <br />

                  <!--  filho form -->
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Sprint</th>
                        <th>Item</th>
                        <th>Descrição</th>
                        <th>Horas</th>
                        <th>Período</th>
                        <th>*</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in rows" v-bind:key="row.id">
                        <td>
                          <div class="form-group">
                            <label for="userName"></label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Sprint"
                              v-model="row.sprint"
                            />
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label for="userName"></label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="row.item"
                              placeholder="Item"
                            />
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <br />
                            <textarea
                              v-model="row.description"
                              placeholder="Descrição"
                              type="text"
                              class="form-control"
                              rows="1"
                            />
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label for="userName"></label>
                            <input
                              type="number"
                              class="form-control"
                              v-model="row.hours"
                              placeholder="Horas"
                            />
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <label for="userName"></label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="row.period"
                              placeholder="Período"
                            />
                          </div>
                        </td>
                        <td>
                          <button @click="removeChild(index)" class="btn btn-danger btn-sm">
                            <i class="fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div></div>
                  <hr />
                  <button @click="addChild" class="btn btn-primary btn-sm">
                    <i class="fa fa-plus"></i> Novo
                  </button>
                  <hr />
                  <div class="block text-right">
                    <a class="btn btn-success" @click="save">
                      Salvar
                      <i class="fa fa-save"></i>
                    </a>
                    <router-link to="/budgetings" class="btn btn-info">
                      <i class="fa fa-arrow-left"></i> Voltar
                    </router-link>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

//  Ck5 propriedades
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    // Update the loader's progress.
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    // Reject the promise returned from the upload() method.
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    // Note that your request may look different. It is up to you and your editor
    // integration to choose the right communication channel. This example uses
    // a POST request with JSON as a data structure but your configuration
    // could be different.
    xhr.open(
      "POST",
      "https://api.projetos.innovareti.com.br/api/v1/images/upload/",
      true
    );
    xhr.responseType = "json";
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      resolve({
        default: response.url,
      });
    });

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    // Prepare the form data.
    const data = new FormData();

    data.append("upload", file);

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, you can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data);
  }
}

function MyUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
    return new MyUploadAdapter(loader);
  };
}

export default {
  components: {
    vSelect,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      rows: [],
      stepbudgetings: {
        sprint: "",
        item: "",
        hours: "",
        period: "",
        description: "",
      },
      budgeting: {
        customer: "",
        title: "",
        description: "",
        deadline: "",
        criticism: "",
      },
      editorReadOnly: true,
      editor: ClassicEditor,
      editorData: "<p>Hello World ...</p>",
      editorConfig: {
        extraPlugins: [MyUploadAdapterPlugin],
      },
      dropzoneOptions: {
        url: "https://localhost:8000/api/v1/archives/upload",
        thumbnailWidth: 150,
        addRemoveLinks: true,
        dictDefaultMessage:
          "<i class='fas fa-file-upload'></i>  Upload De Documentação   <i class='fas fa-file-upload'></i>",
      },
      criticisms: [
        {
          id: "3",
          name: "Baixa",
        },
        {
          id: "2",
          name: "Média",
        },
        {
          id: "1",
          name: "Alta",
        },
      ],
      deadlines: [
        {
          id: "1",
          name: "15 dias",
        },
        {
          id: "2",
          name: "30 dias",
        },
        {
          id: "3",
          name: "45 dias",
        },
      ],
    };
  },
  methods: {
    save: function () {
      let api = this.$store.state.api + "stepbudgetingsitems";
      const self = this;

      let data = {

        budgeting_id: self.budgeting.id,
        steps: self.rows 

      }

      self.$http
        .post(api, data)
        .then((response) => {
          self.$message("Sucesso", `Orçado com Sucesso`, "success");
          this.$router.push("/budgetings");
          this.aprove();
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });
            html += "</ul>";
            self.errors = html;
          }
          self.$message(null, self.errors, "error");
        });
    },
    addChild() {
      this.rows.push({});
    },
    removeChild(id) {
      this.rows.splice(id, 1);
    },
    aprove: function (props, row, index) {
      const self = this;
      let api = self.$store.state.api + "budgetings/" + props.row.id;
      let fd = new FormData();

      fd.append("_method", "PATCH");
      fd.append("budgeting_status_id", 3);
    },
    getBudgetings(id) {
      let self = this;
      let api =
        self.$store.state.api + "budgetings/" + id + "?with[]=attachments";

      self.$http
        .get(api)
        .then((response) => {
          self.budgeting = response.data.data[0];
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
  },
  mounted() {
    let self = this;
    let id = self.$route.params.id;

    if (id) {
      self.getBudgetings(id);
    }
  },
};
</script>

<style scoped>
/* @import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"); */
.required {
  color: red;
}

tr th {
  text-align: center;
}

#down-scroll {
  height: 183px;
  width: 100%;
  overflow: auto;
}
</style>
