<template>
 <div class="card">
  <div class="card-header animated fadeInRightBig" v-if="$route.params.operation">

    <div class="card-title" v-if="dataForm.title">
      <h3>{{ dataForm.title[$route.params.operation] || '' }}</h3>
      <i v-if="dataForm.title.icon" :class="dataForm.title.icon" style="float:right;margin-top:-32px;font-size:25px;"></i>
    </div>
    <form @submit.prevent>
      <div class="card-body">
          <div class="row">
            <v-col :length="field.length || '12'" :offset="field.offset || null" v-for="(field, index) in dataForm.fields" :key="index">
              <label :for="field.name"><span v-if="isRequired(field)" class="required">*</span> {{ field.label }}:</label>
              <!-- Input do tipo text, number, tel -->
              <input v-if="$route.params.operation != 'view' && field.type == 'text'" :type="field.type" :class="'form-control ' + (field.class || '')" :id="field.name" :name="field.name" v-model="model[field.model]" :placeholder="field.placeholder || ''">
              <!-- Input do tipo textarea -->
              <textarea v-if="$route.params.operation != 'view' && field.type == 'textarea'" :type="field.type" :class="'form-control ' + (field.class || '')" :id="field.name" :name="field.name" v-model="model[field.model]" :placeholder="field.placeholder || ''"></textarea>
              <!-- Input do tipo datetime -->
              <input v-if="$route.params.operation != 'view' && field.type == 'datetime'" :type="'datetime-local'" :class="'form-control ' + (field.class || '')" :id="field.name" :name="field.name" v-model="model[field.model]">
              <!-- Input do tipo date -->
              <input v-if="$route.params.operation != 'view' && field.type == 'date'" :type="'date'" :class="'form-control ' + (field.class || '')" :id="field.name" :name="field.name" v-model="model[field.model]">
              <!-- Input do tipo checkbox -->
              <input v-if="$route.params.operation != 'view' && field.type == 'checkbox'" :type="'checkbox'" :class="' ' + (field.class || '')" :id="field.name" :name="field.name" v-model="model[field.model]">
              <!-- Input do select -->
              <select v-if="$route.params.operation != 'view' && field.type == 'select'" :class="'form-control ' + (field.class || '')" :id="field.name" :name="field.name" v-model="model[field.model]" :placeholder="field.placeholder || ''" >
                <option value="">Selecione...</option>
                <option :value="item.id" v-for="item in field.list" :key="item.id">{{item.name || item.customer_name}}</option>
              </select>
              <!-- Input do tipo file -->
              <input v-if="$route.params.operation != 'view' && field.type == 'file'" :type="field.type" :class="'form-control ' + (field.class || '')" :id="field.name" :name="field.name">
              <!-- Somente visualização -->
              <span v-if="$route.params.operation == 'view' && field.type != 'file'" :class="'block ' + (field.class || '') " >{{ model[field.model] }}</span>
              <!-- Somente visualização para arquivos do tipo password -->
              <span v-if="$route.params.operation == 'view' && field.type == 'password'" :class="'block ' + (field.class || '') " >**********</span>
              <!-- Somente visualização para arquivos do tipo file -->
              <a v-if="$route.params.operation == 'view' && field.type == 'file'" :href="model[field.model]" :class="'block ' + (field.class || '') " target="_blank">Clique para abrir</a>
            </v-col>
          </div>
          <div class="row" v-if="dataChild && dataChild.length > 0">

            <h4>{{dataChild.label}}</h4>
            <div class="col-md-12">
              <button class="btn btn-add pull-left" @click="addChild"><i class="fa fa-plus"></i></button>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>
                      *
                    </th>
                    <th v-for="column in dataChild.fields" :key="column.name">
                      {{column.label}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(child, index) in childs" :key="index">
                    <td>
                      <button class="btn btn-danger" @click="deleteChild(index, child.id)">

                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                    <td v-for="column in dataChild.fields" :key="column.name">

                      <input type="hidden" v-model="child.id" v-if="child.id">

                      <!-- Input do tipo text, number, tel -->
                      <input v-if="$route.params.operation != 'view' && column.type == 'text'" :type="column.type" :class="'form-control ' + (column.class || '')" :id="column.name" :name="column.name" v-model="child[column.name]" :placeholder="column.placeholder || ''">
                      <!-- Input do tipo textarea -->
                      <textarea v-if="$route.params.operation != 'view' && column.type == 'textarea'" :type="column.type" :class="'form-control ' + (column.class || '')" :id="column.name" :name="column.name" v-model="child[column.name]" :placeholder="column.placeholder || ''"></textarea>
                      <!-- Input do tipo datetime -->
                      <input v-if="$route.params.operation != 'view' && column.type == 'datetime'" :type="'datetime-local'" :class="'form-control ' + (column.class || '')" :id="column.name" :name="column.name" v-model="child[column.name]">
                      <!-- Input do tipo date -->
                      <input v-if="$route.params.operation != 'view' && column.type == 'date'" :type="'date'" :class="'form-control ' + (column.class || '')" :id="column.name" :name="column.name" v-model="child[column.name]">
                      <!-- Input do tipo checkbox -->
                      <input v-if="$route.params.operation != 'view' && column.type == 'checkbox'" :type="'checkbox'" :class="' ' + (column.class || '')" :id="column.name" :name="column.name" v-model="child[column.name]">

                      <!-- Input do select -->
                      <select v-if="$route.params.operation != 'view' && column.type == 'select'" :class="'form-control ' + (column.class || '')" :id="column.name" :name="column.name" v-model="child[column.name]" :placeholder="column.placeholder || ''" >
                        <option value="">Selecione...</option>
                        <option :value="item.id" v-for="item in column.list" :key="item.id">{{item.name || item.customer_name}}</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div>
      <div class="">
          <div class="pull-right">
              <router-link :to="dataForm.button.back" class="btn btn-warning">
                  <i class="fa fa-arrow-left"></i> Voltar
              </router-link>
              <button type="submit" class="btn btn-info" v-if="$route.params.operation != 'view'" @click="save">
                  Salvar <i class="fa fa-save"></i>
              </button>
          </div>
          <p v-if="hasRequired"><span class="required">*</span> Campos Obrigatórios</p>
          <div class="clearfix"></div>
      </div>
    </form>
    <!-- <div></div> -->
  </div>
 </div>
</template>

<script>
    import vCol from '../Utils/vCol'

    export default {
        props: {
          dataForm: Object,
          dataChild: Object
        },
        components: {
          vCol
        },
        data() {
            return {
              model: {},
              errors: undefined,
              childs: []
            }
        },
        watch: {
          '$route.params.id': function (val) {
            if (this.$route.params.id) {
              this.getModel()
            }
            else {
              this.model = {}
            }
          }
        },
        mounted() {
          if (this.$route.params.id) {
              this.getModel()
            }
            else {
              this.model = {}
            }
        },
        computed: {
          hasRequired: function () {

            let fields = this.dataForm.fields
            for(let i in fields) {

              if (this.isRequired(fields[i])) {
                return true;
              }
            }
            return false
          }
        },
        methods: {
          deleteChild(index, id){
            this.childs.splice(index,1);
          },
          addChild(){
            this.childs.push({})
          },
          isRequired: function(field) {
            if (this.$route.params.operation == 'view') {
              return false
            }
            return field.isRequired ? field.isRequired[this.$route.params.operation] : true;
          },
          getModel: function() {
            let self = this;
            let id   = self.$route.params.id;
            let api  = self.$store.state.api + self.dataForm.url.model +'/' + id;

            if(self.dataChild && self.dataChild.with != ''){
              api = api + '?with[]=' + self.dataChild.with
            }

            self.$http.get(api)
                .then((response) => {
                    self.model = response.data.data[0];

                    let fields = this.dataForm.fields
                    for(let i in fields) {
                      if (fields[i].type == 'password') {
                        delete self.model[fields[i].model]
                      }
                    }

                    if(self.dataChild && self.dataChild.with != ''){
                      self.childs = response.data.data[0][self.dataChild.with]
                    }
                })
                .catch((error) => {
                    self.errors = error.response.data;
                    self.$message(null, self.errors, 'error');
                });
          },
          save: function () {
            let id = this.$route.params.id ? '/' + this.$route.params.id : '';
            const api = this.$store.state.api + this.dataForm.url.model + id;
            const self = this;

            const operation = self.$route.params.operation;

            let form = new FormData();
            for (var field in self.dataForm.fields) {
              let dataField = self.dataForm.fields[field];
              if (dataField.type == 'file') {
                let fileUpload = document.getElementById(dataField.name);
                form.append(dataField.model, (fileUpload.files[0]) ? fileUpload.files[0] : null);
              }
              else {
                if (self.model[dataField.model]) {
                  form.set(dataField.model, self.model[dataField.model]);
                }
              }
            }

            if(self.childs.length > 0){
              form.append(self.dataChild.with, JSON.stringify(self.childs));
            }

            if (operation == 'edit') {
              form.append('_method', 'PUT');
            }

            self.$http.post(api, form)
                .then((response) => {
                    self.$message('Sucesso', self.dataForm.messages.success[operation], 'success');
                    this.$router.go(-1);
                })
                .catch((error) => {
                    self.errors = error.response.data;

                    if(typeof self.errors === 'object'){
                        let html = '<ul>';
                        $.each(self.errors, function(key, value){
                            html += '<li>' + value[0] + '</li>';
                        });
                        html += '</ul>';
                        self.errors = html;
                    }

                    self.$message(null, self.errors, 'error');
                });
          }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.required {
  color: red;
}
</style>
