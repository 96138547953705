<template>
    <div>
      <div class="row">
      <div>
        <div class="row">
        <div class="col-md-12">
          <div class="card1">
            <div class="card-body">
              <div class="card mt-3 tab-card">
                <ul
                  class="nav nav-tabs card-header-tabs"
                  id="myTab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="one-tab"
                      data-toggle="tab"
                      href="#one"
                      role="tab"
                      aria-controls="One"
                      aria-selected="true"
                      >Projetos</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="two-tab"
                      data-toggle="tab"
                      href="#two"
                      role="tab"
                      aria-controls="two"
                      aria-selected="false"
                      >Sprints</a
                    >
                  </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active p-3"
                    id="one"
                    role="tabpanel"
                    aria-labelledby="one-tab"
                  >
                      <!-- Import de Projeto  -->
                      <crud-projects></crud-projects>
                  </div>

                  <div
                    class="tab-pane fade p-3"
                    id="two"
                    role="tabpanel"
                    aria-labelledby="two-tab"
                  >
                    <!-- Import de Sprints -->
                    <crud-sprint></crud-sprint>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>

    </div>

    </div>
</template>

<script>
 import CrudProjects from './CrudProject'
 import CrudSprint from '../Sprint/CrudSprint'

    export default {
        data: function () {
            let self = this
            return {

            }
        },
        methods: {

        },
        mounted () {


        },
        components: {
          CrudProjects, CrudSprint
        },
        computed: {

        }
    }
</script>

<style scoped>
    .VuePagination {
        text-align: center;
    }
.label1 {
  /* background-color: rgb(214, 207, 207); */
  text-align: center;
  border: 1px solid;
  height: 475px;
  border-radius: 4px;
  border-color: #57b4d7;
}
#title {
  color: grey;
  text-align: center;
  display: block;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 110vh;
  width: 80vw;
}
.card-body {
  padding: 10px;
}
.card-body-text {
  padding-top: 30px;
}
.card-body-text label {
  font-size: 40px;
  margin-bottom: 0px;
  color: grey;
  text-align: center;
  display: block;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
}
.card-body-text h5 {
  overflow: hidden;
  font-weight: bold;
  font-size: 16px;
}
.col-md-2 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.card1 {
  margin-bottom: 1.5rem;
  background: #fff;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px #333;
}
.card-text {
  margin-bottom: 1.5rem;
  height: 150px;
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px #333;
}

.card-text {
  vertical-align: middle;
}
.chart-container {
  position: relative;
  margin: auto;
  height: 110vh;
  width: 80vw;
}
</style>
