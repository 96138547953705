<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card-header">
          <div class="card-title">
            <h3>Painel de SLA dos Chamados</h3>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      v-for="ticket in tickets"
      :key="ticket"
      style="padding: 35px"
    >
      <div
        class="col-md-12"
        style="text-align: center; font-size: 18px; margin-bottom: 15px"
      >
        Chamados com o status
        <span style="font-weight: bold">{{
          ticket.notification.status_entity.title
        }}</span>
        com SLA entre {{ ticket.notification.start }} e
        {{ ticket.notification.end }} hora(s). <br />
        {{
          ticket.notification.customer
            ? "Cliente: " + ticket.notification.customer.customer_name
            : ""
        }}
      </div>
      <div class="col-md-12">
        <table class="table table-striped" style="heigth: 150px">
          <thead>
            <tr>
              <th class="code">Numero do Chamado</th>
              <th class="code">Cliente</th>
              <th class="code">Assunto</th>
              <th class="code">Aberto em</th>
              <th class="code">SLA</th>
              <th class="code">Acessar Chamado</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="tkt in ticket.tickets" :key="tkt.id">
              <td class="code" style="width: 32%">
                {{ tkt.code }}
              </td>
              <td class="table-content" style="width: 32%">
                {{ tkt.sent_by.name }}
              </td>
              <td class="table-content" style="width: 32%">
                {{ tkt.subject }}
              </td>
              <td class="table-content" style="width: 32%">
                {{ tkt.created_at }}
              </td>
              <td class="table-content" style="width: 32%">
                {{ tkt.SLA }}
              </td>
              <td class="table-content">
                <a
                  data-toggle="tooltip"
                  title="Editar"
                  class="btn btn-crud check fa fa-arrow-up"
                  target="_blank"
                  :href="'/new_tickets/view/' + tkt.id"
                ></a>
              </td>
            </tr>
            <tr v-if="ticket.tickets.length == 0">
              <td colspan="6">Nenhum chamado pendente.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    ></loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      tickets: [],
      isLoading: true,
    };
  },
  methods: {
    getSlaNotifications: function () {
      const self = this;
      const api = self.$store.state.api + "tickets_panel";

      self.$http
        .post(api)
        .then((response) => {
          self.tickets = response.data;
          self.isLoading = false;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  components: { Loading },
  mounted() {
    let self = this;
    this.getSlaNotifications();
  },
};
</script>

<style scoped>
table {
  border: 1px solid grey;
  border-top: none;
}
thead {
  background-color: #299bff;
}
th {
  color: white !important;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
}
td {
  text-align: center;
}
.info1 {
  background-color: #299bff;
  color: white !important;
  text-align: center;
  font-weight: bold;
  line-height: 150%;
}
.code {
  vertical-align: middle !important;
  width: 35px !important;
  padding: 10px !important;
  white-space: nowrap !important;
}
</style>
