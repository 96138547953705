  <template>
  <div>
    <div class="panel animated fadeInRightBig">
      <div class="panel-heading">
        <div class="card-header">
          <div class="card-title">Nova Aplicação - Status</div>
        </div>
      </div>
      <div class="panel-body">
        <form id="form" enctype="multipart/form-data">
          <div class="row">
            <div class="col-md-4">
              <label for="name"> <span class="required">*</span>Nome: </label>
              <input
                type="text"
                class="form-control"
                v-model="innoverApplications.name"
              />
            </div>
            <div class="col-md-4">
              <label for="status">
                <span class="required">*</span>Status:
              </label>
              <input
                type="text"
                class="form-control"
                v-model="innoverApplications.status"
              />
            </div>
            <div class="col-md-4">
              <label for="status">
                <span class="required">*</span>Cliente:
              </label>
              <v-select
                :options="customers"
                label="customer_name"
                id="customers"
                :reduce="(customers) => customers.id"
                v-model="innoverApplications.customer_id"
              >
                <div slot="no-options">Carregando...</div>
              </v-select>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-4">
              <label for="server">
                <span class="required">*</span>Servidor:
              </label>
              <input
                type="text"
                class="form-control"
                v-model="innoverApplications.server"
              />
            </div>
            <div class="col-md-4">
              <label for="database">
                <span class="required">*</span>Banco de Dados:
              </label>
              <input
                type="text"
                class="form-control"
                v-model="innoverApplications.database"
              />
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>
                  <span class="required">*</span> Tipo do Projeto:
                </label>
                <v-select
                  style="padding-top: 7px"
                  :options="typeProject"
                  label="name"
                  id="typeProject"
                  :reduce="(typeProject) => typeProject.name"
                  v-model="innoverApplications.application_type"
                >
                  <div slot="no-options">Carregando...</div>
                </v-select>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-4">
              <label for="link"> <span class="required">*</span>Link: </label>
              <input
                type="text"
                class="form-control"
                v-model="innoverApplications.link"
              />
            </div>
            <div class="col-md-4">
              <label for="link">
                <span class="required">*</span>Diretório:
              </label>
              <input
                type="text"
                class="form-control"
                v-model="innoverApplications.directory"
              />
            </div>
            <div class="col-md-4">
              <label for="link">
                <span class="required">*</span>Arquivo Nginx:
              </label>
              <input
                type="text"
                class="form-control"
                v-model="innoverApplications.nginx"
              />
            </div>
            <div
              class="col-md-8"
              style="margin-top: 15px"
              v-if="innoverApplications.application_type == 'API'"
            >
              <label for="link">
                <span class="required">*</span>Alias para localização da API:
              </label>
              <v-select
                taggable
                multiple
                label="title"
                autofocus="true"
                :options="innoverApplications.alias"
                v-model="innoverApplications.alias"
              >
                <div slot="no-options">
                  Pressione Enter para inserir múltiplas tags
                </div>
              </v-select>
            </div>
            <div
              class="col-md-4"
              style="margin-top: 15px"
              v-if="innoverApplications.application_type == 'API'"
            >
              <label for="link">
                <span class="required">*</span>Nome do Pacote APP:
              </label>
              <input
                type="text"
                v-model="innoverApplications.package_name"
                class="form-control"
              />
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-3">
              <div
                class="custom-control custom-checkbox"
                style="padding-top: 15px"
              >
                <input
                  id="backup"
                  name="backup"
                  type="checkbox"
                  class="custom-control-input"
                  v-model="innoverApplications.backup"
                />
                <label
                  class="custom-control-label slt-none"
                  id="backup"
                  for="backup"
                  >Tem Backup?</label
                >
              </div>
            </div>
            <div class="col-md-3">
              <div
                class="custom-control custom-checkbox"
                style="padding-top: 15px"
              >
                <input
                  id="protected_api"
                  name="protected_api"
                  type="checkbox"
                  class="custom-control-input"
                  v-model="innoverApplications.protected_api"
                />
                <label
                  class="custom-control-label slt-none"
                  id="protected_api"
                  for="protected_api"
                  >Monitorar Proteção da API?</label
                >
              </div>
            </div>
            <div class="col-md-3">
              <div
                class="custom-control custom-checkbox"
                style="padding-top: 15px"
              >
                <input
                  id="has_monitor"
                  name="has_monitor"
                  type="checkbox"
                  class="custom-control-input"
                  v-model="innoverApplications.has_monitor"
                />
                <label
                  class="custom-control-label slt-none"
                  id="has_monitor"
                  for="has_monitor"
                  >está no Monitor?</label
                >
              </div>
            </div>
            <div class="col-md-3" style="padding-top: 5px">
              <div>
                <input
                  type="radio"
                  value="1"
                  v-model="innoverApplications.environment"
                />
                <span>Produção</span>
                <br />
                <input
                  type="radio"
                  value="0"
                  v-model="innoverApplications.environment"
                />
                <span>Homologação/Inativo</span>
              </div>
            </div>
          </div>
          <hr />
          <div class="block text-right">
            <a class="btn btn-success" @click="save">
              Salvar
              <i class="fa fa-save"></i>
            </a>
            <router-link to="/innover_applications" class="btn btn-info">
              <i class="fa fa-arrow-left"></i> Voltar
            </router-link>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p><span class="required">*</span> Campos Obrigatórios</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      customers: [],
      innoverApplications: {
        name: "",
        status: "",
        server: "",
        database: "",
        link: "",
        application_type: "",
        directory: "",
        nginx: "",
        environment: 0,
        backup: 0,
        protected_api: 0,
        has_monitor: 0,
        customer_id: "",
        alias: [],
      },
      typeProject: [
        {
          name: "API",
        },
        {
          name: "Web",
        },
        {
          name: "Mobile",
        },
        {
          name: "Outro",
        },
      ],
    };
  },

  methods: {
    save() {
      const api = this.$store.state.api + "innover_applications";
      const self = this;

      let form = new FormData();
      form.append("name", self.innoverApplications.name);
      form.append("status", self.innoverApplications.status);
      form.append("server", self.innoverApplications.server);
      form.append("database", self.innoverApplications.database);
      form.append("link", self.innoverApplications.link);
      form.append(
        "application_type",
        self.innoverApplications.application_type
      );
      form.append("directory", self.innoverApplications.directory);
      form.append("nginx", self.innoverApplications.nginx);
      form.append("customer_id", self.innoverApplications.customer_id);
      form.append("package_name", self.innoverApplications.package_name);
      form.append("environment", self.innoverApplications.environment ? 1 : 0);
      form.append("backup", self.innoverApplications.backup ? 1 : 0);
      form.append(
        "protected_api",
        self.innoverApplications.protected_api ? 1 : 0
      );
      form.append("has_monitor", self.innoverApplications.has_monitor ? 1 : 0);

      self.innoverApplications.alias.forEach((tag) => {
        let content = null;
        if (typeof tag === "object") {
          content = tag.title;
        } else {
          content = tag;
        }
        form.append("alias[]", content);
      });

      self.$http
        .post(api, form)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Aplicação registrada com sucesso`,
            "success"
          );
          this.$router.push("/innover_applications");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
    },
    getCustomers: function () {
      let self = this;
      let api = self.$store.state.api + "customers/?paginated=false";

      self.$http
        .get(api)
        .then((response) => {
          self.customers = response.data.data;
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
  },
  mounted() {
    let self = this;

    self.getCustomers();
  },
};
</script>

<style scoped>
.required {
  color: red;
}
</style>
