<template>
    <div>
        <div class="card">
            <div class="card-header animated fadeInRightBig" >

                <div class="card-title">{{crudName}}
                    <router-link
                        v-if="!$route.params.operation"
                        :to="toadd ? toadd : endPoint + 'add'"
                        class="btn btn-primary"
                        v-show="enableAdd"
                        style="float:right;margin-top:-5px;"
                        >{{newText}}</router-link>
                    <router-link
                        v-if="!$route.params.operation"
                        :to="entityEndpoint"
                        class="btn btn-primary"
                        v-show="enableAddEntity"
                        style="float:right;margin-top:-5px;"
                        >{{newText}}</router-link>
                </div>

            </div>
            <div class="card-body" v-if="$route.params.operation">
                <BaseForm :dataForm="dataForm" :dataChild="dataChild"></BaseForm>
            </div>
            <div class="card-body" v-if="!$route.params.operation">
                <div class="block m-b-md text-right" v-if="enableAddModal">
                    <button type="button" class="btn btn-success" @click="addModal">Adicionar {{ crudName }}</button>
                </div>

                <v-server-table ref="table" :tableData="tableData" :name="table" :url="url" :columns="columns" :options="options">

                    <div
                        :slot="'filter__'+filter.name"
                        v-for="filter in customFilters"
                        :key="filter.name"
                        >

                        <template v-if="filter.type == 'date'">
                            <input type="date" class="form-control" :name='filter.call' :id="filter.name" @change="filterChange(filter.call, filter.name)">
                        </template>

                    </div>

                    <div slot="actions" slot-scope="props">
                        <a v-show="enableDownload" data-toggle="tooltip" title="Download" class="btn btn-info    fa fa-download"
                           @click="download(props.row.file)"></a>
                        <a v-show="enableView" data-toggle="tooltip" title="Consultar" class="btn btn-crud view    fa fa-eye"
                           @click="view(props.row.id)"></a>
                        <a v-show="enableEdit" data-toggle="tooltip" title="Editar" class="btn btn-crud check fa fa-edit"
                           @click="edit(props.row.id)"></a>
                        <a v-show="enableReset" data-toggle="tooltip" title="Alterar Senha" class="btn btn-crud check fa fa-key key"
                           @click="resetpassword(props.row.id)"></a>
                        <a v-show="enableEditModal" @click="editModal(props.row.id)" data-toggle="tooltip" title="Editar" class="btn btn-warning fa fa-edit"
                         ></a>
                        <a v-show="enableDelete" data-toggle="tooltip" title="Deletar"
                           class="btn btn-crud cancel  fa fa-trash" @click="destroy(props, props.row, props.index)"></a>
                        <template v-for="extra in extra">
                          <a data-toggle="tooltip" :title="extra.title" :key="extra.id" v-if="extra.exceptIds ? !extra.exceptIds.includes(props.row.id) : true"
                           :class="extra.class" @click="callExtra(extra.name, props, props.row, props.index)">
                           {{extra.label}}
                          </a>
                        </template>
                    </div>
                    <div slot="photo" slot-scope="props">
                        <img :src="props.row.photo" alt="" class="photo">
                    </div>
                    <div slot="cover_photo" slot-scope="props">
                        <img :src="props.row.cover_photo" alt="" class="photo">
                    </div>
                    <div slot="color" slot-scope="props">
                        <div class="box" v-bind:style="{ 'background-color': props.row.color }"></div>
                        <span class="bold">
                            {{ props.row.color }}
                        </span>
                    </div>
                     <div slot="qrcode" slot-scope="props">
                        <img :src="props.row.qrcode" alt="" class="qrcode">
                        <br>
                        <span class="bold">
                            {{ props.row.qrcode_name }}
                        </span>
                    </div>
                </v-server-table>
            </div>
        </div>


    </div>
</template>

<script>
    import {Pagination} from 'vue-pagination-2'
    import BaseForm from './BaseForm'

    export default {
        data: function () {
            return {
                records: [],
                pages: [],
                tableData: [],
            }
        },
        props: {
            crudName: String,
            newText: String,
            table: String,
            columns: Array,
            options: Object,
            endPoint: String,
            with: String,
            enableAdd: Boolean,
            enableAddEntity: Boolean,
            customFilters: Array,
            enableAddModal:Boolean,
            enableEditModal: Boolean,
            enableEdit: Boolean,
            enableReset: Boolean,
            enableDelete: Boolean,
            enableView: Boolean,
            enableDownload: Boolean,
            extra: Array,
            dataForm: Object,
            toadd: String,
            dataChild: Object
        },
        mounted() {

            this.enableAddModal  = this.enableAddModal || false;
            this.enableEditModal = this.enableEditModal || false;
        },
        computed: {
            url() {
                return this.$store.getters.api + this.endPoint + (this.with ? this.with : '')
            },
            entityEndpoint: function(){
                return this.toadd ? this.toadd : this.endPoint.substring(0, this.endPoint.length - 2);
            }
        },
        methods: {
            callExtra(name, props, row, index) {
              this.$emit(name, {
                props,row,index
              })
            },
            edit(id) {
                this.$router.push({
                    path: (this.toadd ? this.toadd : this.endPoint + 'edit/') + id
                })
            },
             filterChange(call, date) {
              let valor = $("#" + date).val();
              valor = valor.replace("T", " ");

              // console.log(valor)

              this.$emit(call, valor);
              this.$refs.table.refresh();
            },
            view(id) {
                this.$router.push({
                    path: this.endPoint + 'view/' + id
                })
            },
            resetpassword(id) {
              this.$router.push({
                path: this.endPoint.substring(0, this.endPoint.length - 2) + "/reset-password/" + id
              });
            },
            download(file) {
                window.open(file, '_blank');
            },
            destroy(props, row, index) {
                const self = this

                swal({
                    title: "Deseja deletar o registro?",
                    text: "Essa operação não pode ser desfeita",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Sim, deletar!",
                    showLoaderOnConfirm: true,
                    buttons: {
                        catch: {
                            text: "Não",
                            value: "cancel",
                            className: "btn-danger"
                        },
                        confirm: {
                            text: "Sim",
                            value: 'confirm'
                        }
                    },
                })
                    .then((value) => {
                        switch (value) {
                            case 'cancel':
                                swal('Cancelado', 'O registro não foi excluido!', 'info');
                                break;

                            case 'confirm':

                                self.$http.delete(this.url + row.id, {
                                    'id': row.id
                                })
                                    .then((response) => {
                                        swal('Sucesso', 'Registro excluído com sucesso!', 'success');
                                        self.$refs.table.refresh();
                                    });
                                break;
                        }
                   })
            },
            addModal: function(){
                this.$emit('addModal');
            },
            editModal: function(id){
                this.$emit('editModal', id);
            }
        },
        components: {
            Pagination,
            BaseForm
        }
    }
</script>

<style scoped>

    .photo {
      display: flex;
      max-height: 100px;
      width: 100px;
      border-radius: 5px;
    }
    .key {
     border: 2px solid #181716;
     text-align: center;
    }
    .key {
     border: 2px solid grey;
     text-align: center;
    }
    .key1 {
      background-color: grey;
    }

</style>
