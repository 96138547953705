import Crud from '../components/Classification/Crud'
import Add from '../components/Classification/Add'
import Edit from '../components/Classification/Edit'

let routes = {
    path: '/tickets-classifications',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/tickets-classifications/add',
            name: 'Novo Registro',
            component: Add
        },
        {
            path: '/tickets-classifications/add/:id?',
            name: 'Editar Registro',
            component: Edit
        },
        {
            path: '/tickets-classifications',
            name: 'Lista',
            component: Crud
        },
    ]
};


export default routes
