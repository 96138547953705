<template>
  <div>
    <base-crud
      crudName="Innovare TI - Orçamentos"
      newText="Novo Registro"
      :table="projectTable"
      :columns="columns"
      :options="options"
      :endPoint="endPoint"
      :enableAdd="true"
      :enableEdit="true"
      :enableView="true"
      :extra="extra"
      @budgeted="budgeted"
      :enableDelete="true"
    ></base-crud>
  </div>
</template>

<script>
import BaseCrud from "../Base/BaseCrud";


export default {
  data: function() {
    let self = this;
    return {
       extra: [
        {
            id: 1,
            label: '',
            class: 'btn btn-crud check fas fa-wallet',
            title: 'Fazer Orçamento',
            name: 'budgeted',
        },
      ],
      projectstatuses: [],
      dataForm: {},
      projectTable: "projectTable",
      users: [],
      usercustomers: [],
      typeOfContracts: [],
      pages: [],
      url: "",
      columns: [
        "id",
        "title",
        "customer",
        "criticality_id",
        "deadline_id",
        "budgeting_status",
        "actions"
      ],
      tableData: ["id"],
      debounce: 800,
      options: {
        filterByColumn: true,
        filterable: [
          "id",
          "title",
          "customer",
          "criticality_id",
          "deadline_id",
          "budgeting_status",
        ],
        sortable: [
          "id",
          "title",
          "customer",
          "criticality_id",
          "deadline_id",
          "budgeting_status",
        ],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          title: "Titulo",
          customer: "Cliente",
          criticality_id: "Criticidade",
          deadline_id: "Prazo",
          budgeting_status: "Status",
          actions: "Ações"
        },
        templates: {
           budgeting_status: function (h, row, index) {
              return row.budgeting_status ? row.budgeting_status.title : 'Sem Nome';
          },
           criticality_id: function (h, row, index) {
                            if(row.criticality_id == 3){
                                return <h5><span class="badge badge-danger">Alta Criticidade</span></h5>
                            } if(row.criticality_id == 2) {
                                return <h5><span class="badge badge-warning">Média Criticidade</span></h5>
                            } else {
                                return <h5><span class="badge badge-success">Baixa Criticidade</span></h5>
                            }
                        },
            deadline_id: function (h, row, index) {
                if(row.deadline_id == 1){
                    return  <span>15 Dias</span>
                } if(row.deadline_id == 2) {
                    return <span>30 Dias</span>
                } else {
                    return <span>45 Dias</span>
                }
            },
        },
        requestFunction: function(data) {
          let requestData = {};

          let query = this.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = [
            'budgeting_status'
          ];

          return this.$http.get(this.url + "?" + query, requestData).catch(
            function(e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        }
      },
      endPoint: "budgetings/"
    };
  },
  methods: {
    query: function(query) {
      let columns = {

      };
      let filters = "";
      $.each(query, function(index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    budgeted: function (props, row, index){
                const self = this;

                 if(props.row.budgeting_status_id == 3){

                    swal('Erro', 'Tarefa já Orçada', 'info')

                } else {

                let api = self.$store.state.api + 'budgetings/' + props.row.id;
                let fd  = new FormData();

                fd.append('_method', 'PATCH');
                fd.append('budgeting_status_id', 2);

                swal({
                    title: "Deseja assumir este Orçamento?",
                    text: "Essa operação não pode ser desfeita",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Sim",
                    showLoaderOnConfirm: true,
                    buttons: {
                        catch: {
                            text: "Não",
                            value: "cancel",
                            className: "btn-danger"
                        },
                        confirm: {
                            text: "Sim",
                            value: 'confirm'
                        }
                    },
                    })
                    .then((value) => {
                        switch (value) {
                            case 'cancel':
                                swal('Cancelado', 'O Orçamento não foi realizado', 'info');
                                break;

                            case 'confirm':

                                self.$http.post(api, fd)
                                .then((response) => {
                                    this.$router.push({
                                        path: 'budgetings/step_budgeting/' + props.row.id
                                    })
                                })
                                break;
                        }
                    })
                    .catch((error) => {
                        self.$message(null, error.response.data, 'error');
                    });
                }
            },
  },
  mounted() {
    let self = this;
    this.$http
      .get(this.$store.getters.api + "budgetings?paginated=false")
      .then(response => {
        self.budgetings = response.data.data;
      });
  },
  components: {
    BaseCrud
  }
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
</style>
