import CrudProject from '../components/Project/CrudProject'
import View from '../components/Project/View'
import ProjectManager from '../components/Project/ProjectManager'
import ProjectBoard from '../components/Board/ProjectBoard'

let routes = {
    path: '/projects',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/projects/board/:id',
            name: 'Projetos',
            component: ProjectBoard,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/projects/view/:id',
            name: 'Projetos',
            component: View,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/projects/manage/:id',
            name: 'Projetos',
            component: ProjectManager,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/projects/:operation?/:id?',
            name: 'Projetos',
            component: CrudProject,
            meta: {
                permission: 'is_admin'
            }
        },
    ]
};


export default routes
