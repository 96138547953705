<template>
    <div>
      <base-crud
          crudName="Deploy"
          newText="Nova Solicitação"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint='endPoint'
          :enableAdd="false"
          :enableAddEntity="true"
          :enableEdit="false"
          :enableDelete="false"
          :enableView="false"
          :dataForm="dataForm"
          :extra="extra"
          :customFilters="customFilters"
          @filtered="filtered"
          @assign="assign"
          @step_deploy="step_deploy"
          @view="view"
          @releaseDeploy="releaseDeploy"
          toadd="/wfdeploys/deploy/add"
      >
      </base-crud>
    </div>
</template>

<script>
    import BaseCrud from '../Base/BaseCrud'
    import { debug } from 'util';

    export default {
        data: function () {
            return {
              customFilters:[
                {
                  name: "created_at",
                  type: "date",
                  call: "filtered"
                }
              ],
                assigneds: [],
                assignedIds: [],
                table: 'deployTable',
                pages: [],
                url: '',
                columns: ['id', 'user_id', 'deploy_user_id', 'project_id','type_project','deploy_realese_id', 'priority', 'created_at', 'status', 'actions'],
                tableData: [
                  'id',
                  'description'
                ],
                options: {
                    pagination: {chunk: 10, dropdown: false, nav: 'scroll' },
                    perPage: 10,
                    filterByColumn: true,
                    filterable: ['user_id', 'deploy_user_id', 'project_id','type_project','deploy_realese_id', 'priority', 'status'],
                    debounce: 800,
                    perPageValues: [10,25,50,100],
                    headings: {
                        'id': 'ID',
                        'user_id' : 'Solicitante',
                        'deploy_user_id' : 'Responsável Deploy',
                        'project_id' : 'Projeto',
                        'priority' : 'Prioridade',
                        'type_project': 'Segmento',
                        'deploy_realese_id': 'Aut. Deploy',
                        'created_at' : 'Data Solicitação',
                        'status' : 'Status',
                        'actions' : 'Ações'
                    },
                     texts: {
                    filterBy: "Filtrar",
                    defaultOption: "Selecione"
                    },
                    listColumns: {

                      status: [
                        {
                        id: 1,
                        text: "QA Aprovado"
                        },
                      ]
                    },

                    sortable: ['id', 'priority'],
                    templates: {
                        user_id: function (h, row, index) {
                            return row.user ? row.user.name : 'Sem Nome';
                        },
                        deploy_user_id: function (h, row, index) {
                            return row.user_deploy ? row.user_deploy.name : 'Não Atribuido';
                        },
                        deploy_realese_id: function (h, row, index) {
                            return row.deploy_realese ? row.deploy_realese.name : 'Deploy "ainda" não autorizado!';
                        },
                        deploy_user_id: function (h, row, index) {
                            return row.user_deploy ? row.user_deploy.name : 'Não Atribuido';
                        },
                        status: function (h, row, index) {
                            return row.deploy_status ? row.deploy_status.title : 'Sem Nome';
                        },
                        project_id: function (h, row, index) {
                            return row.project ? row.project.name : 'Sem Nome';
                        },
                        priority: function (h, row, index) {
                            if(row.priority == 'Alta Prioridade'){
                                return <h5><span class="badge badge-danger">Alta Prioridade</span></h5>
                            } else if(row.priority == 'Média Prioridade') {
                                return <h5><span class="badge badge-warning">Média Prioridade</span></h5>
                            } else {
                                return <h5><span class="badge badge-success">Baixa Prioridade</span></h5>
                            }
                        },
                    },
                    requestFunction: function (data) {
                      let requestData = {};

                      let query = this.$parent.$parent.query(data.query);

                      requestData.params = data;

                      if (
                        this.$parent.$parent.created_at != null &&
                        this.$parent.$parent.created_at != ""
                      ) {
                        requestData.params.created_at = this.$parent.$parent.created_at;
                      }

                      requestData.params.query = "";
                      requestData.params.with = [
                      'user', 'user_deploy', 'deployStatus', 'project','deploy_realese'
                      ];

                      return this.$http.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                          this.dispatch("error", e);
                        }.bind(this)
                      );
                    },
                },
                extra: [
                    {
                        id: 1,
                        label: '',
                        class: 'btn btn-crud edit fa fa-user-check',
                        title: 'Atribuir a mim',
                        name: 'assign',
                    },
                    {
                        id: 2,
                        label: '',
                        class: 'btn btn-crud check fa fa-list',
                        title: 'Analisar',
                        name: 'step_deploy',
                    },
                    {
                        id: 3,
                        label: '',
                        class: 'btn btn-crud view fa fa-eye',
                        title: 'Visualizar',
                        name: 'view',
                    },
                    {
                        id: 4,
                        label: '',
                        class: 'btn btn-crud cancel fa fa-check',
                        title: 'Liberar Deploy?',
                        name: 'releaseDeploy',
                        meta: {
                        permission: 'is_admin'
                        }
                    },

                ],
                endPoint: 'wfdeploys/',
                dataForm: {}
            }
        },
        components: {
            BaseCrud
        },
        methods: {
          filtered(date) {
              // console.log('aa')
              this.created_at = date;
            },
            query: function (query) {
                let columns = {
                    user_id: 'user[name]',
                    deploy_user_id: 'user_deploy[name]',
                    status: 'deploy_status[title]',
                    project_id: 'project[name]',
                    created_at: 'created_at'
                };
                let filters = 'status_deploy=3';
                $.each(query, function (index, value) {
                    filters += columns[index] + '=' + value + '&';
                });
                return filters;
            },
            assign: function (props, row, index){
                const self = this;

                if(props.row.deploy_user_id != null || !props.row.deploy_realese_id ){

                    swal('Erro', 'Tarefa já atribuída a um usuário ou Deploy não autorizado', 'info');

                } else {

                let api = self.$store.state.api + 'wfdeploys/' + props.row.id;
                let fd  = new FormData();

                fd.append('_method', 'POST');
                fd.append('deploy_user_id', this.user.id);
                fd.append('status', '5');

                swal({
                    title: "Deseja assumir esta tarefa?",
                    text: "Essa operação não pode ser desfeita",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Sim",
                    showLoaderOnConfirm: true,
                    buttons: {
                        catch: {
                            text: "Não",
                            value: "cancel",
                            className: "btn-danger"
                        },
                        confirm: {
                            text: "Sim",
                            value: 'confirm'
                        }
                    },
                    })
                    .then((value) => {
                        switch (value) {
                            case 'cancel':
                                swal('Cancelado', 'A operação não foi realizada', 'info');
                                break;

                            case 'confirm':

                                self.$http.post(api, fd)
                                .then((response) => {
                                    this.$router.push({
                                        path: 'step_deploy/' + props.row.id
                                    })
                                })
                                break;
                        }
                    })
                    .catch((error) => {
                        self.$message(null, error.response.data, 'error');
                    });
                }
            },
            releaseDeploy(props, row, index) {
              const self = this;
              if (!this.$store.state.user.is_admin) {
                swal ('Erro', 'Somente "Admin pode liberar o Deploy!', 'info');
                return
              }
              if ( props.row.deploy_realese_id != null){
              swal('Erro', 'Deploy já autorizado', 'info');
              return
              }
              swal({
                title: "Deseja Liberar esse Deploy?",
                text: "Essa operação não pode ser desfeita",
                type: "warning",
                showCancelButton: true ,
                confimButtonColor: "#DD6B55",
                confirmButtonText: "Sim",
                showLoaderOnConfirm: true,
                buttons: {
                  catch: {
                          text: "Não",
                          value: "cancel",
                          className: "btn-danger"
                      },
                      confirm: {
                          text: "Sim",
                          value: 'confirm'
                      }
                },
              })
              .then((value) => {
                        switch (value) {
                            case 'cancel':
                                swal('Cancelado', 'O Deploy não foi autorizado', 'info');
                                break;

                            case 'confirm':

                            let api =self.$store.state.api + 'wfdeploys/deploy-realese'
                            let fd  = new FormData();
                            fd.append('wfdeploy_id', props.row.id);

                                self.$http.post(api, fd)
                                .then((response) => {
                                  this.$forceUpdate()
                                    // self.$router.push({
                                    //     path: '/wfdeploys/deploys' + props.row.id
                                    // })
                                })
                                break;
                        }
                    })
                    .catch((error) => {
                        self.$message(null, error.response.data, 'error');
                    });

            },
            qa: function (props, row, index){
                const self = this;
            },
            step_deploy: function (props, row, index){
                if (!props.row.deploy_realese_id) {
                  swal('Erro', 'Deploy não foi Autorizado!', 'info')
                  return
                }

                if(props.row.status >= '6'){

                    swal('Erro', 'Deploy já finalizado', 'info');

                } else {
                    this.$router.push({
                        path: 'step_deploy/' + props.row.id
                    })
                }
            },
            view: function (props, row, index){
                const self = this;
                this.$router.push('view/' + props.row.id);
            },
        },
        mounted: function(){
            this.user = this.$store.getters.user;
        },
    }

</script>

<style scoped>
    .VuePagination {
        text-align: center;
    }


</style>
