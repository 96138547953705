<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">Visualizar Registro</div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-md-11">
          <form action>
            <div class="row">
              <div class="col">
                <label for="customer-name">
                  Nome do Cliente
                  <span class="require">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="customers.customer_name"
                  disabled="disabled"
                />
              </div>
              <div class="col">
                <label for="cnpj">CNPJ</label>
                <input
                  type="text"
                  v-mask="'##.###.###/####-##'"
                  class="form-control"
                  v-model="customers.cnpj"
                  disabled
                />
              </div>
            </div>
            <br />

            <div class="row">
              <div class="col">
                <label for="contact-name">Nome do Contato</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="customers.contact_name"
                  disabled="disabled"
                />
              </div>
              <div class="col">
                <label for="email">E-mail</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="customers.email"
                  disabled="disabled"
                />
              </div>
            </div>
            <br />

            <div class="row">
              <div class="col-md-6">
                <label for="domain">
                  Dominio
                  <span class="required">*</span>
                </label>

                <input
                  type="text"
                  class="form-control"
                  v-model="customers.domain"
                  placeholder="mycompany.com.br"
                  disabled="disabled"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="card-body">
      <hr />
      <div class="block text-right">
        <router-link to="/Customers" class="btn btn-info">
          <i class="fa fa-arrow-left"></i> Voltar
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      customers: {
        customer_name: "",
        cnpj: "",
        contact_name: "",
        email: "",
        domain: "",
      },
    };
  },
  computed: {
    url() {
      return this.$store.getters.api + this.$route.params.id;
    },
  },
  mounted() {
    let self = this;
    let id = self.$route.params.id;

    if (id) {
      self.getCustomer(id);
    }
  },
  methods: {
    getCustomer: function (id) {
      let self = this;
      let api = self.$store.state.api + "customers/" + id;

      self.$http
        .get(api)
        .then((response) => {
          self.customers = response.data.data[0];
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
  },
};
</script>

<style>
</style>