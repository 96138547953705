import VideoChat from '../components/Video/VideoChat'
let routes = {
    path: '/video',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/video/',
            name: 'Video Call',
            component: VideoChat,
            meta: {                
            }
        }
    ]
};


export default routes
