import CrudDevs from '../components/WFDeploys/CrudDevs'
import CrudQA from '../components/WFDeploys/CrudQA'
import CrudDeploy from '../components/WFDeploys/CrudDeploy'
import AddDeploy from '../components/WFDeploys/AddDeploy'
import Add from '../components/WFDeploys/Add'
import StepQA from '../components/WFDeploys/StepQA'
import StepDeploy from '../components/WFDeploys/StepDeploy'
import View from '../components/WFDeploys/View'
import History from '../components/WFDeploys/History'

let routes = {
    path: '/wfdeploys',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/wfdeploys/',
            name: 'Solicitações de Deploy',
            component: CrudDevs,
            meta: {
                permission: 'is_dev'
            }
        },
        {
            path: '/wfdeploys/qa',
            name: 'QA - Quality Assurance',
            component: CrudQA,
            meta: {
                permission: 'is_QA'
            }
        },
        {
            path: '/wfdeploys/add',
            name: 'Nova Solicitação',
            component: Add,
            meta: {
                permission: 'is_dev'
            }
        },
        {
            path: '/wfdeploys/step_qa/:id?',
            name: 'QA - Quality Assurance - Análise',
            component: StepQA,
            meta: {
                permission: 'is_QA'
            }
        },
        {
            path: '/wfdeploys/deploys',
            name: 'Deploy',
            component: CrudDeploy,
            meta: {
                permission: 'is_deploy'
            }
        },
        {
            path: '/wfdeploys/deploy/add',
            name: 'Deploy',
            component: AddDeploy,
            meta: {
                permission: 'is_deploy'
            }
        },
        {
            path: '/wfdeploys/step_deploy/:id?',
            name: 'Deploy - Análise',
            component: StepDeploy,
            meta: {
                permission: 'is_deploy'
            }
        },
        {
            path: '/wfdeploys/view/:id?',
            name: 'Visualizar Solicitação',
            component: View,
        },
        {
            path: '/wfdeploys/history/:id?',
            name: 'Histórico de Solicitações',
            component: History,
        },
    ]
};


export default routes
