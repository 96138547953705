<template>
  <div class="card">
    <div class="card-header animated fadeInRightBig">
      <div class="card-title">Relatório de Chamados</div>

      <div class="card-body">
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-4">
              <label for="contract_id"
                ><span class="required">*</span> Contrato:</label
              >
              <v-select
                :options="contracts"
                label="name"
                id="contract_id"
                :reduce="(contract) => contract.id"
                v-model="contract_id"
              >
                <div slot="no-options">Carregando...</div>
              </v-select>
            </div>

            <div class="col-md-4">
              <label for=""><span class="required">*</span> De:</label>
              <input type="date" class="form-control" v-model="initial_date" />
            </div>

            <div class="col-md-4">
              <label for=""><span class="required">*</span> Até:</label>
              <input type="date" class="form-control" v-model="final_date" />
            </div>

            <div class="col-md-4">
              <label for="process_names">
                <span class="required"></span> Nome do Processo:
              </label>
              <v-select
                label="process_name"
                :options="process_names"
                :reduce="(process_names) => process_names.id"
                v-model="contracts.process_name_id"
              >
                <div slot="no-options">Carregando...</div>
              </v-select>
            </div>

            <div class="col-md-4">
              <label for="process_areas">
                <span class="required"></span> Área do Processo:
              </label>
              <v-select
                label="process_areas"
                :options="process_areas"
                :reduce="(process_areas) => process_areas.id"
                v-model="contracts.process_area_id"
              >
                <div slot="no-options">Carregando...</div>
              </v-select>
            </div>
          </div>

          <hr />
          <button class="btn btn-primary" @click="generateReport">Gerar</button>

          <a href="" id="downloadReport" class="hidden" target="blank"></a>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
export default {
  components: { vSelect },
  data() {
    return {
      initial_date: new Date(),
      final_date: new Date(),
      contract_id: "",
      contracts: [
        {
          process_area_id: "",
          process_name_id: "",
        },
      ],
      process_names: [],
      process_areas: [],
    };
  },
  mounted() {
    this.getProcessNames();
    this.getProcessAreas();

    let self = this;
    let api = this.$store.getters.api + "contracts?paginated=false";

    self.$http.get(api).then((response) => {
      self.contracts = response.data.data;
    });
  },
  methods: {
    getProcessNames: function (id) {
      const self = this;
      const api = self.$store.state.api + "process_names/";

      self.$http
        .get(api)
        .then((response) => {
          self.process_names = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProcessAreas: function (id) {
      const self = this;
      const api = self.$store.state.api + "process_areas/";

      self.$http
        .get(api)
        .then((response) => {
          self.process_areas = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    generateReport() {
      $("#downloadReport").attr(
        "href",
        this.$store.getters.api +
          "report/report-sla" +
          "?contract_id=" +
          this.contract_id +
          "&initial_date=" +
          this.initial_date +
          "&final_date=" +
          this.final_date
      );
      document.getElementById("downloadReport").click();
    },
  },
};
</script>

<style>
</style>