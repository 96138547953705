<template>
  <div>
    <base-crud
      crudName="Innovare TI - Empresas"
      newText="Novo Registro"
      :table="projectTable"
      :columns="columns"
      :options="options"
      :endPoint="endPoint"
      :enableAdd="true"
      :enableEdit="true"
      :enableDelete="true"
      :enableView="true"
    >
    </base-crud>
  </div>
</template>

<script>
import BaseCrud from "../Base/BaseCrud";


export default {
  data: function () {
    let self = this;
    return {
      projectstatuses: [],
      dataForm: {},
      projectTable: "projectTable",
      users: [],
      pages: [],
      url: "",
      columns: ["id", "customer_name", "contact_name", "email", "actions"],
      tableData: ["id"],
      debounce: 800,
      options: {
        filterByColumn: true,
        filterable: [
          "id",
          "customer_name",
          "cnpj",
          "contact_name",
          "email",
          "domain",
        ],
        sortable: [
          "id",
          "customer_name",
          "cnpj",
          "contact_name",
          "email",
          "domain",
        ],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          customer_name: "Nome do Cliente",
          contact_name: "Nome do Contato",
          email: "E-mail",
          actions: "Ações",
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = [];

          return this.$http.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "customers/",
    };
  },
  methods: {
    query: function (query) {
      let columns = {
        id: "id",
        customer_name: "customer_name",
        cnpj: "cnpj",
        contact_name: "contact_name",
        domain: "domain",
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
  mounted() {
    let self = this;
    this.$http
      .get(this.$store.getters.api + "projectstatuses?paginated=false")
      .then((response) => {
        self.projectstatuses = response.data.data;
      });
  },
  components: {
    BaseCrud,
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
</style>