<template>
  <div
    class="modal fade"
    id="columnModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Editar Coluna</h5>
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="closeColumnModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <label>Título</label>
          <input
          class="form-control"
          v-model = selectedColumn.title
          />
        <div class="row">
          <label class="col-3">
            Cor:
          </label>
        </div>
        <div class="row">
          <div class="col-3" style="margin-left:50px">
            <sketch v-model="colors" />
          </div>
        </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary p-1 ml-3 float-right"
            @click="save()"
          >
            <i class="fa fa-floppy-o"></i> Salvar
          </button>
          <button
            type="button"
            class="btn btn-warning p-1 ml-3 float-right"
            aria-label="Close"
            @click="closeColumnModal()"
          >
            <i class="fa fa-times"></i> Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Sketch } from 'vue-color'
export default {
  props: ["modalStatus", "closeColumnModal", "column", "refresh"],
  data() {
    return {
      colors: "#FFF",
      selectedColumn: "",
    };
  },
  watch: {
    modalStatus: function (newStatus) {
      if (newStatus == true) {
        this.startModal();
      }
    },
  },
  mounted() {
    let self = this;
  },
  methods: {
    save() {
      let self = this;
      let api = this.$store.getters.api + "user_issue_columns/" + this.selectedColumn.id;
      this.selectedColumn.color = this.colors.hex;

        self.$http
          .put(api, this.selectedColumn
          )
          .then((response) => {
            self.$message("Sucesso", "Coluna editada com sucesso", "success");
          })
          .then(() => {
            this.refresh();
            this.closeColumnModal()
          });
    },
    startModal() {
      this.selectedColumn = {
        "id": this.column.id,
        "title": this.column.title,
        "color": this.column.color,
        "user_id": this.column.user_id
      }
      this.colors = this.column.color
      $("#columnModal").modal("toggle");
    },
  },
  components: {
    Sketch      
  }
};
</script>

<style scoped>
label {
  font-weight: bold;
}

.photo {
  height: 40px;
  width: 60px;
  object-fit: cover;
  border: 1px solid rgb(127, 227, 245);
  border-radius: 5px;
  border-color: rgb(127, 227, 245);
}
.hidden {
  display: none;
}

#message {
  height: 40px !important;
  min-height: 40px !important ;
}

/* Important part */
.modal-dialog {
  overflow-y: initial !important;
}
.modal-body {
  overflow-y: auto;
}

.ipp-alert {
  padding: 5px !important;
  margin-bottom: 0px !important;
}

.coment-date {
  margin-bottom: 10px;
}
</style>
