<template>
    <div>
        <base-crud
                crudName="Status"
                newText="Novo Status"
                :table="projectTable"
                :columns="columns"
                :options="options"
                :endPoint='endPoint'
                :enableAdd="true"
                :enableEdit="true"
                :enableDelete="true"
                :enableView="false"
                :dataForm="dataForm"
        >
          
        </base-crud>
    </div>
</template>

<script>
    import BaseCrud from '../Base/BaseCrud'
import { debug } from 'util';

    export default {
        data: function () {
            let self = this
            return {
                projectTable: 'statusTable',
                users: [],
                pages: [],
                url: '',
                columns: ['id', 'name', 'actions'],                
                options: {
                    sortable: ['id', 'name'],
                    pagination: {chunk: 10, dropdown: false, nav: 'scroll' },
                    perPage: 10,
                    perPageValues: [10,25,50,100],
                    headings: {
                        'id': 'ID',                        
                        'name' : 'Nome',                        
                        'actions' : 'Ações'
                    },
                    templates: {
                                                
                    }
                },
                endPoint: 'projectstatuses/',

                dataForm: {
                  title: {
                    add: 'Novo Status',
                    edit: 'Editar Status',
                    view: 'Visualizar Status',
                    icon: 'fa fa-task'
                  },
                  button: {
                    back: '/projectstatuses'
                  },
                  url: {
                    model: 'projectstatuses'
                  },
                  messages: {
                    success: {
                      add: 'Status cadastrado com sucesso!',
                      edit: 'Status alterado com sucesso!'
                    }
                  },
                  fields: [
                    {
                      name: 'name',
                      model: 'name',
                      label: 'Nome',
                      type: 'text',
                      isRequired: {
                        add: true,
                        edit: false,
                      },
                      placeholder: 'Digite o nome do Status',
                      class: '',
                      length: "12|6|3",
                      offset: null
                    }
                  ]
                }
            }
        },
        mounted () {
                 
        },
        components: {
            BaseCrud
        },
        computed: {
            statusList () {
                // return this.$http.get('/status')
                return [
                    {id: 'e', label: '123'}
                ]
            }
        }
    }
</script>

<style scoped>
    .VuePagination {
        text-align: center;
    }
</style>
