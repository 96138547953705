import {
  Bar,
  mixins
} from 'vue-chartjs'
const {
  reactiveProp
} = mixins
export default {
  extends: Bar,
  mixins: [reactiveProp],
  data: function () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        height: 200,
        legend: {
          display: true,
          labels: {
            fontColor: 'grey',
          },
        }

      }
    }
  },
  mounted() {
    // this.chartData is created in the mixin
    this.renderChart(this.chartData, this.options)
  }
}
