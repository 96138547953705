<template>
  <div>
    <div class="row justify-content-center" v-if="loading">
      <div class="col-md-12" style="font-size: 35px; text-align: center">
        <i class="fas fa-circle-notch fa-spin"></i>
      </div>
    </div>
    <section v-else>
      <div class="row">
        <div class="col-md-12">
          <div class="">
            <div class="card-header">
              <div class="card-title">Filtro para consultar horas</div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-2">
                  <button
                    class="btn btn-filters"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="fas fa-filter"></i> Filtros
                  </button>
                </div>
                <div class="col-md-3">
                  <form
                    :action="
                      $store.state.url +
                      '/export/consults/' +
                      filters.start_date +
                      '/' +
                      filters.end_date
                    "
                    id="export"
                  >
                    <div class="block text-right">
                      <button class="btn btn-block btn-filters" type="submit">
                        <i class="fas fa-file-export"></i> Exportar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="card-body">
              <form action="">
                <div class="row collapse" id="collapseExample">
                  <div class="form-group col-md-2">
                    <label for="inputDateIn"> Data Inicial </label>
                    <input
                      type="date"
                      class="form-control"
                      id="inputDateIn"
                      v-model="filters.start_date"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label for="inputDateOut"> Data Final </label>
                    <input
                      type="date"
                      class="form-control"
                      id="inputDateOut"
                      v-model="filters.end_date"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputDateOut"> Colaborador </label>
                    <v-select
                      :options="users"
                      label="name"
                      :reduce="(users) => users.id"
                      multiple
                      class="style-chooser"
                      v-model="filters.user_id"
                    >
                      <div slot="no-options">Nenhum registro encontrado.</div>
                    </v-select>
                  </div>
                  <div class="form-group col-md-2 mt-3">
                    <a class="btn btn-consult" @click="getEntity">Buscar</a>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="alert alert-success text-center" role="alert">
                      Filtrando dados no período de
                      <strong>{{ startDate }}</strong> à
                      <strong>{{ endDate }}</strong>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="alert alert-info text-center" role="alert">
                      Total de Horas do Período:
                      <strong> {{ totalHours }}</strong>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center" style="padding: 10px">
        <div class="col-md-12" v-if="issues.length > 0">
          <table class="table" border="2" bordercolor="#d3d3d3">
            <thead>
              <tr>
                <th scope="col">ID do Card</th>
                <th scope="col">Nome</th>
                <th scope="col">Data</th>
                <th scope="col">Número de Horas</th>
                <th scope="col">Título do Card</th>
                <th scope="col">Projeto</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="issue in issues" :key="issue.id">
                <td>
                  {{ issue.issue.id }}
                </td>
                <td>
                  {{ issue.issue.user.name }}
                </td>
                <td>
                  {{ issue.date_formatted }}
                </td>
                <td>{{ issue.service_time }} Hora(s)</td>
                <td>
                  {{ issue.issue.summary }}
                </td>
                <td>
                  {{
                    issue.issue.project
                      ? issue.issue.project.name
                      : "Não definido"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-4" v-else>
          <div class="alert alert-primary text-center" role="alert">
            Nenhum registro encontrado no período informado.
          </div>
        </div>
      </div>
      <div
        class="row justify-content-center"
        align="center"
        style="padding: 15px"
      >
        <div class="col-md-12">
          <pagination
            :records="consultTotal"
            :options="options"
            :per-page="15"
            @paginate="pageConsult"
          ></pagination>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { Pagination } from "vue-pagination-2";

export default {
  data() {
    return {
      loading: true,
      issues: [],
      totalHours: 0,
      filters: {},
      hours: [],
      users: [],
      consultPage: 1,
      consultTotal: 15,
      fullPage: true,
      records: [],
      options: {
        texts: {
          count: "Mostrando {from} de {to} até {count} de registros",
        },
        chunk: 4,
        chunksNavigation: "fixed",
      },
    };
  },
  methods: {
    pageConsult(page) {
      this.consultPage = page;
      this.getEntity();
    },
    getEntity: function () {
      const self = this;

      const api =
        self.$store.state.api +
        "dashsups/consult-card/?pageConsult=" +
        self.consultPage;
      // self.loading = true;

      self.$http
        .post(api, self.filters)
        .then((response) => {
          self.issues = response.data.consult.data;
          self.consultTotal = response.data.consult.total;
          self.consultPage = response.data.consult.current_page;
          self.totalHours = response.data.sum;
          self.loading = false;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUsers: function (id) {
      const self = this;
      const api = self.$store.state.api + "users?paginated=false";

      self.$http
        .get(api)
        .then((response) => {
          self.users = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    // getEntity(page) {
    //   let self = this;
    //   if (page != undefined) {
    //     self.consultPage = page;
    //   }
    //   let api =
    //     self.$store.state.api +
    //     "dashsups/consult-card/?pageConsult=" +
    //     self.consultPage;
    //   self.loading = true;

    //   self.$http
    //     .post(api, self.filters)
    //     .then((response) => {
    //       self.issues = response.data.data;
    //       self.consultTotal = response.data.total;
    //       self.consultPage = response.data.current_page;
    //       console.log(self.consultPage);
    //       // self.filters = response.data.filters;
    //       self.loading = false;
    //     })
    //     .catch((error) => {
    //       self.$message(null, error.response.data, "error");
    //     });
    // },
  },
  computed: {
    startDate() {
      if (this.filters.start_date != undefined) {
        return moment(this.filters.start_date).format("ll");
      } else {
        return moment().startOf("month").format("ll");
      }
    },
    endDate() {
      if (this.filters.end_date != undefined) {
        return moment(this.filters.end_date).format("ll");
      } else {
        return moment().endOf("month").format("ll");
      }
    },
  },
  mounted() {
    const self = this;
    self.getEntity();
    self.getUsers();
  },
  components: { vSelect, Pagination },
};
</script>

<style scoped>
.card {
  margin-bottom: 1, 5rem;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0, 5rem;
}

.form-control {
  border: none;
  border-bottom: 2px solid #2ea2cd;
  border-radius: 0;
  color: #040300 !important;
  font-size: 14px;
  padding: 0;
  height: 30px;
}

.btn-consult {
  color: #5b5b5f;
  border: 2px solid #2ea2cd;
}

.btn-consult:hover,
.btn-consult:focus {
  background-color: #2ea2cd !important;
  color: #fff !important;
}

.card-body {
  padding: 10px;
}
.card-small-info {
  text-align: justify;
}
.card-value-info {
  font-size: 20px;
  font-weight: 700;
}
.card-value-info span {
  font-size: 20px;
}
.card-icon {
  position: absolute;
  bottom: -10px;
  right: -10px;
  font-size: 90px;
  color: #08b9b3;
  opacity: 0.1;
  z-index: 0;
}
.card-percent.up {
  color: #28a745;
  font-size: 11px;
}
.card-percent.up::before {
  font-family: "Font Awesome 5 Free";
  content: "\f30c";
  font-weight: 700;
  margin-right: 3px;
}
.card-percent.down {
  color: #bb0034;
  font-size: 11px;
}
.card-percent.down::before {
  font-family: "Font Awesome 5 Free";
  content: "\f309";
  font-weight: 700;
  margin-right: 3px;
}
.b {
  font-weight: bold;
}
.title-graphic {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
}
</style>
