<template>
  <div>
    <div
      class="modal fade"
      id="commentsModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document" :class="'modal-lg'">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Adicionar Comentário(s)
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding-bottom: 0px">
            <div :class="'col-sm-12'">
              <div class="row">
                <div class="col-sm-12">
                  <vue-tribute :options="tributeOptions">
                    <textarea
                      type="text"
                      id="message"
                      v-model="newcomment"
                      placeholder="@NomeDoUsuário seu comentário..."
                      style="width: 100%"
                      class="pb-4"
                    ></textarea>
                  </vue-tribute>
                  <div class="input-group-append mb-2">
                    <button
                      @click="saveComment"
                      class="btn btn-outline-secondary mb-2"
                      type="button"
                      style="width: 100%"
                      id
                    >
                      <i class="fa fa-paper-plane"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="row"
                v-for="comment in comments"
                :key="comment.id + new Date()"
              >
                <template v-if="comment.user.id == $store.getters.user.id">
                  <div class="col-sm-2">
                    <img :src="comment.user.photo" alt="sunil" class="photo" />
                  </div>
                  <div class="col-sm-10">
                    <div class="alert alert-info ipp-alert">
                      {{ comment.comment }}
                    </div>
                    <small style="color: grey" class="pull-right coment-date"
                      >{{ comment.user.name }} -
                      {{ comment.created_at | brDate }}</small
                    >
                  </div>
                </template>
                <template v-else>
                  <div class="col-sm-10">
                    <div class="alert alert-info ipp-alert">
                      {{ comment.comment }}
                    </div>
                    <small style="color: grey" class="pull-left coment-date"
                      >{{ comment.user.name }} -
                      {{ comment.created_at | brDate }}</small
                    >
                  </div>
                  <div class="col-sm-2">
                    <img :src="comment.user.photo" alt="sunil" class="photo" />
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-warning p-1 ml-3 float-right"
              data-dismiss="modal"
              @click="clean"
              aria-label="Close"
            >
              <i class="fa fa-times"></i> Fechar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="issueModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog"
        role="document"
        :class="{ 'modal-lg': isAdd, 'modal-xl': isEdit }"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ modalLabel }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="!editHours">
            <form @submit.prevent class="form-material">
              <div class="row">
                <div :class="'col-sm-12'">
                  <div class="row form-group">
                    <div class="col-sm-12">
                      <label for>Título</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="issue.summary"
                      />
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-sm-4">
                      <label for>Projeto</label>
                      <v-select
                        :options="projects"
                        :filterable="false"
                        v-model="issue.project_id"
                        label="Projeto"
                        @search="searchProject"
                        @input="selectedProject"
                      >
                        <template slot="no-options"
                          >Digite para pesquisar Projetos</template
                        >
                        <template slot="option" slot-scope="option">
                          <div class="d-center">{{ option.name }}</div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">{{ option.name }}</div>
                        </template>
                      </v-select>
                    </div>
                    <div class="col-sm-4">
                      <label for>Sprint</label>
                      <v-select
                        :options="sprints"
                        v-model="issue.sprint_id"
                        label="Sprint"
                      >
                        <template slot="no-options"
                          >Selecione um projeto para listar as sprints</template
                        >
                        <template slot="option" slot-scope="option">
                          <div class="d-center">{{ option.name }}</div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">{{ option.name }}</div>
                        </template>
                      </v-select>
                    </div>
                    <div class="col-sm-4">
                      <label for="">Prazo</label>
                      <input
                        type="date"
                        class="form-control"
                        v-model="issue.due_date"
                      />
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-sm-4">
                      <label for>Criticidade</label>
                      <select v-model="issue.criticality" class="form-control">
                        <option value>Selecione...</option>
                        <option value="Low">Baixa</option>
                        <option value="Normal">Normal</option>
                        <option value="High">Alta</option>
                        <option value="Critical">Crítico</option>
                      </select>
                    </div>
                    <div class="col-sm-4">
                      <label for>Pontos</label>
                      <input
                        type="number"
                        v-model="issue.story_points"
                        class="form-control"
                        min="0"
                      />
                    </div>
                    <div class="col-sm-4">
                      <label for>Responsável</label>
                      <v-select
                        :options="users"
                        :reduce="(users) => users.id"
                        label="name"
                        v-model="issue.user_id"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-sm-12">
                      <label for>Descrição</label>
                      <!-- <textarea name="" id="" rows="3" class="form-control" v-model="issue.description"></textarea> -->
                      <ckeditor
                        :editor="editor"
                        v-model="issue.description"
                        :config="editorConfig"
                      ></ckeditor>
                    </div>
                  </div>
                  <!-- <div class="row form-group">
                  <div class="col-sm-12">
                    <label for>Upload de Arquivos</label>
                    <vue-dropzone
                    @vdropzone-sending="sendingEvent"
                    @vdropzone-complete="completeUpload"
                    ref="myVueDropzone"
                    id="dropzone"
                    :options="dropzoneOptions"
                  ></vue-dropzone>
                  </div>
                </div> -->
                </div>
              </div>
            </form>
          </div>
          <div class="modal-body" v-if="editHours">
            <h4>Horas do Card</h4>
            <div style="padding-bottom: 4%">
              <button
                class="btn btn-sm btn-primary pull-right"
                @click="addHour"
              >
                <i class="fa fa-plus"></i> Adicionar Hora
              </button>
            </div>
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Usuário</th>
                  <th>Data</th>
                  <th>Horas</th>
                  <th>*</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(hour, index) in getHours" :key="hour.id">
                  <template v-if="hour.mode == 'add'">
                    <td style="width: 328px">{{ hour.user_name }}</td>
                    <td style="width: 295px">
                      <div class="center-text">
                        <input
                          type="date"
                          class="form-control"
                          style="
                            width: 160px;
                            display: block;
                            margin-right: auto;
                            margin-left: auto;
                          "
                          v-model="hour.date"
                        />
                      </div>
                    </td>
                    <td style="width: 192px">
                      <input
                        type="text"
                        class="form-control"
                        v-model="hour.service_time"
                      />
                    </td>
                    <td style="width: 272px">
                      <button
                        class="btn btn-sm btn-primary"
                        @click="saveHour(index)"
                        style="margin-right: 5px"
                      >
                        Salvar
                      </button>
                      <button
                        class="btn btn-sm btn-warning"
                        @click="cancelHour(index)"
                      >
                        Cancelar
                      </button>
                    </td>
                  </template>
                  <template v-if="hour.mode == 'edit'">
                    <td>{{ hour.user_name }}</td>
                    <td>{{ hour.date | moment }}</td>
                    <td>{{ hour.service_time }}</td>
                    <td></td>
                  </template>
                  <template v-if="!hour.mode || hour.mode == 'view'">
                    <td>{{ hour.user_name }}</td>
                    <td>{{ hour.date | moment }}</td>
                    <td>{{ hour.service_time }}</td>
                    <td>
                      <button
                        class="btn btn-sm btn-danger"
                        @click="deleteHour(index)"
                      >
                        Deletar
                      </button>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-info p-1 ml-3 float-right"
              @click="changeHours"
              v-if="modalMode == 'edit' && !isTeamBoard"
            >
              <span v-if="!editHours"><i class="fa fa-clock-o"></i> Horas</span>
              <span v-else><i class="fa fa-tasks"></i> Card</span>
            </button>
            <button
              v-if="modalMode == 'edit'"
              type="button"
              class="btn btn-danger p-1 ml-3 float-left"
              data-dismiss="modal"
              @click="deleteIssue"
              aria-label="Close"
            >
              <i class="fa fa-trash"></i> Deletar
            </button>
            <button
              type="button"
              class="btn btn-primary p-1 ml-3 float-right"
              @click="save"
              v-if="modalMode != 'view'"
            >
              <i class="fa fa-floppy-o"></i> Salvar
            </button>
            <button
              type="button"
              class="btn btn-warning p-1 ml-3 float-right"
              data-dismiss="modal"
              @click="clean"
              aria-label="Close"
            >
              <i class="fa fa-times"></i> Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import vue2Dropzone from "vue2-dropzone";
// import "vue2-dropzone/dist/vue2Dropzone.min.css";
import vSelect from "vue-select";
import _ from "lodash";

import VueTribute from "vue-tribute";

//  Ck5 propriedades
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    // Update the loader's progress.
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    // Reject the promise returned from the upload() method.
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    // Note that your request may look different. It is up to you and your editor
    // integration to choose the right communication channel. This example uses
    // a POST request with JSON as a data structure but your configuration
    // could be different.
    xhr.open(
      "POST",
      "https://api.projetos.innovareti.com.br/api/v1/images/upload/",
      true
    );
    xhr.responseType = "json";
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      resolve({
        default: response.url,
      });
    });

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    // Prepare the form data.
    const data = new FormData();

    data.append("upload", file);

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, you can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data);
  }
}

function MyUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
    return new MyUploadAdapter(loader);
  };
}

export default {
  components: { vSelect, ClassicEditor, VueTribute },
  props: ["modalMode", "modalStatus", "selectedIssue", "commentsStatus"],
  filters: {
    brDate(text) {
      var data =
        text.substr(8, 2) +
        "/" +
        text.substr(5, 2) +
        "/" +
        text.substr(0, 4) +
        " " +
        text.substr(11, 10);
      return data;
    },
  },
  data() {
    return {
      isTeamBoard: window.location.pathname.includes("team-board"),
      allUsers: [],
      tributeOptions: {
        // symbol or string that starts the lookup
        trigger: "@",

        // element to target for @mentions
        iframe: null,

        // class added in the flyout menu for active item
        selectClass: "highlight",

        // class added to the menu container
        containerClass: "tribute-container",

        // class added to each list item
        itemClass: "",

        // function called on select that returns the content to insert
        selectTemplate: function (item) {
          console.log(item);
          return "@" + item.original.value;
        },

        // template for displaying item in menu
        menuItemTemplate: function (item) {
          return item.string;
        },

        // template for when no match is found (optional),
        // If no template is provided, menu is hidden.
        noMatchTemplate: null,

        // specify an alternative parent container for the menu
        // container must be a positioned element for the menu to appear correctly ie. `position: relative;`
        // default container is the body
        menuContainer: document.body,

        // column to search against in the object (accepts function or string)
        lookup: "key",

        // column that contains the content to insert by default
        fillAttr: "value",

        // REQUIRED: array of objects to match or a function that returns data (see 'Loading remote data' for an example)
        values: [{ key: "Moises", value: "Moises" }],

        // When your values function is async, an optional loading template to show
        loadingItemTemplate: null,

        // specify whether a space is required before the trigger string
        requireLeadingSpace: true,

        // specify whether a space is allowed in the middle of mentions
        allowSpaces: false,

        // optionally specify a custom suffix for the replace text
        // (defaults to empty space if undefined)
        replaceTextSuffix: "  ",

        // specify whether the menu should be positioned.  Set to false and use in conjuction with menuContainer to create an inline menu
        // (defaults to true)
        positionMenu: true,

        // when the spacebar is hit, select the current match
        spaceSelectsMatch: false,

        // turn tribute into an autocomplete
        autocompleteMode: false,

        // Customize the elements used to wrap matched strings within the results list
        // defaults to <span></span> if undefined
        searchOpts: {
          pre: "<span>",
          post: "</span>",
          skip: false, // true will skip local search, useful if doing server-side search
        },

        // Limits the number of items in the menu
        menuItemLimit: 25,

        // specify the minimum number of characters that must be typed before menu appears
        menuShowMinLength: 0,
      },
      newcomment: "",
      editor: ClassicEditor,
      editorData: "",
      plugins: ["SimpleUploadAdapter"],
      editorConfig: {
        extraPlugins: [MyUploadAdapterPlugin],
      },
      issue: {
        id: "",
        summary: "",
        project_id: 0,
        sprint_id: 0,
        user_id: "",
      },
      projects: [],
      sprints: [],
      users: [],
      comments: [],
      editHours: false,
      hours: [],
    };
  },
  watch: {
    modalStatus: function (newStatus) {
      this.clean();
      this.editHours = false;
      if (newStatus == true) {
        this.startModal();
      }
    },
    commentsStatus: function (newStatus) {
      this.clean();
      if (newStatus == true) {
        this.startModalComments();
      }
    },
  },
  computed: {
    isEdit() {
      return this.modalMode == "edit";
    },
    isAdd() {
      return this.modalMode == "add";
    },
    modalLabel() {
      if (this.modalMode == "add") {
        return "Adicionar";
      } else if (this.modalMode == "edit") {
        return "Editar";
      } else if (this.modalMode == "view") {
        return "Consultar";
      }
      return "";
    },
    getHours() {
      return this.hours;
    },
  },
  mounted() {
    let self = this;

    self.getUsers();

    self.getIssues(self.issue.id);

    $("#issueModal").on("hidden.bs.modal", function (e) {
      self.clean();
    });
    window.addEventListener("keyup", function (id) {
      if (event.altKey && event.keyCode === 46) {
        self.deleteIssue(id);
      }
    });
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  methods: {
    deleteHour(index) {
      let self = this;
      let api =
        self.$store.getters.api + "issue-service-hour/" + this.hours[index].id;

      self.$http
        .delete(api)
        .then((response) => {
          self.$message("Sucesso", "Hora deletada com sucesso", "success");
          this.hours.splice(index, 1);
          self.$emit("refresh");
        })
        .catch((error) => {
          self.$errorMessage(error);
        });
    },
    cancelHour(index) {
      this.hours.splice(index, 1);
    },
    saveHour(index) {
      let self = this;
      let api = self.$store.getters.api + "issue-service-hour";

      let data = self.hours[index];

      self.$http
        .post(api, data)
        .then((response) => {
          self.$message("Sucesso", "Hora Adicionada com sucesso", "success");

          self.hours.splice(index, 1);

          var data = response.data;
          data.mode = "view";
          self.hours.push(data);
          self.$emit("refresh");
        })
        .catch((error) => {
          self.$errorMessage(error);
        });
    },
    addHour() {
      this.hours.push({
        issue_id: this.selectedIssue.id,
        user_id: this.$store.getters.user.id,
        user_name: this.$store.getters.user.name,
        date: moment().format("YYYY-MM-DD"),
        service_time: "",
        mode: "add",
      });
    },
    changeHours() {
      this.editHours = !this.editHours;
    },
    sendingEvent(file, xhr, formData) {
      formData.append("issue_id", this.$route.params.id);
    },
    completeUpload(response) {
      this.getIssues(self.issue.id);
    },
    getIssues(id) {
      let self = this;
      let api = this.$store.getters.api + "issues/" + id;

      this.$http.get(api).then((response) => {
        this.selectedIssue = response.data.data[0];
      });
    },
    getComments(id) {
      let self = this;
      let api =
        this.$store.getters.api + "issues/" + id + "?with[]=comments.user";

      this.$http.get(api).then((response) => {
        this.comments = response.data.data[0].comments;
      });
    },
    saveComment() {
      let self = this;
      let api = this.$store.getters.api + "issue-comments";

      if (self.newcomment != "") {
        let user_linked = self.newcomment.substring(
          self.newcomment.indexOf("@") + 1,
          self.newcomment.indexOf("  ")
        )
          ? self.newcomment.substring(
              self.newcomment.indexOf("@") + 1,
              self.newcomment.indexOf("  ")
            )
          : "";
        self.$http
          .post(api, {
            issue_id: self.issue.id,
            comment: self.newcomment,
            user_linked: user_linked,
          })
          .then((response) => {
            self.$message("Sucesso", "Comentário Adicionado", "success");
          })
          .then(() => {
            this.getComments(self.issue.id);

            self.newcomment = "";
          });
      }
    },
    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "users?paginated=false";
      self.$http
        .get(api)
        .then((response) => {
          self.users = response.data.data;
          response.data.data.forEach((item) => {
            self.tributeOptions.values.push({
              key: item.name,
              value: item.name,
            });
          });
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    selectedProject(project) {
      let self = this;
      let api = this.$store.getters.api + "sprints";

      self.$http.get(api + "?project_id=" + project.id).then((response) => {
        self.sprints = response.data.data;
      });
    },
    searchUser: _.debounce(function (search) {
      let self = this;
      let api = this.$store.getters.api + "users";
      if (search.length >= 3) {
        self.$http.get(api + "?name=" + search).then((response) => {
          self.users = response.data.data;
        });
      }
    }, 300),
    searchProject: _.debounce(function (search) {
      let self = this;
      let api = this.$store.getters.api + "projects";
      if (search.length >= 3) {
        self.$http.get(api + "?name=" + search).then((response) => {
          self.projects = response.data.data;
        });
      }
    }, 300),
    startModalComments() {
      this.issue.summary = this.selectedIssue.summary;
      this.issue.description = this.selectedIssue.description;
      this.issue.story_points = this.selectedIssue.story_points;
      this.issue.criticality = this.selectedIssue.criticality;
      this.issue.id = this.selectedIssue.id;
      this.issue.status = this.selectedIssue.status;
      this.issue.comments = this.selectedIssue.comments;

      this.issue.project_id = this.selectedIssue.project;
      this.issue.sprint_id = this.selectedIssue.sprint;
      this.issue.user_id = this.selectedIssue.user;

      this.comments = [];
      this.getComments(this.issue.id);

      $("#commentsModal").modal("toggle");
    },
    startModal() {
      if (this.modalMode == "add") {
        this.issue.summary = "";
        this.issue.description = "";
        this.issue.story_points = 0;
        this.issue.criticality = "Normal";
        this.issue.id = 0;
        this.issue.status = "todo";
        this.issue.project_id = "";
        this.issue.sprint_id = "";
        this.issue.user_id = this.$store.getters.user.id
          ? this.$store.getters.user.id
          : "";
        this.issue.due_date = moment().format("YYYY-MM-DD");
        this.issue.comments = [];

        this.clean();
      } else if (this.modalMode == "view") {
      } else if (this.modalMode == "edit") {
        this.issue.summary = this.selectedIssue.summary;
        this.issue.description = this.selectedIssue.description;
        this.issue.story_points = this.selectedIssue.story_points;
        this.issue.criticality = this.selectedIssue.criticality;
        this.issue.id = this.selectedIssue.id;
        this.issue.status = this.selectedIssue.status;
        this.issue.comments = this.selectedIssue.comments;

        this.issue.project_id = this.selectedIssue.project;
        this.issue.sprint_id = this.selectedIssue.sprint;
        this.issue.user_id = this.selectedIssue.user;

        this.comments = [];
        this.hours = this.selectedIssue.service_hours;
        this.getComments(this.issue.id);
      }
      $("#issueModal").modal("toggle");
    },
    clean() {
      this.$emit("changeModal", false);
    },
    deleteIssue() {
      let self = this;
      let api = this.$store.getters.api + "issues/" + self.issue.id;

      if (this.modalMode == "edit") {
        self.$http
          .delete(api)
          .then((response) => {
            self.$message("Sucesso", "Card Excluído com Sucesso", "success");
          })
          .then(() => {
            self.$emit("refresh");
          })
          .catch((response) => {
            self.$errorMessage(response);
          });
      }
    },
    save() {
      let self = this;
      let api = this.$store.getters.api + "issues";

      let newIssue = {};

      newIssue.project_id =
        self.issue.project_id != null ? self.issue.project_id.id : "";
      newIssue.project_name =
        self.issue.project_id != null ? self.issue.project_id.name : "";
      newIssue.sprint_id =
        self.issue.sprint_id != null ? self.issue.sprint_id.id : "";

      newIssue.original_estimate = 0;
      newIssue.summary = self.issue.summary;
      newIssue.description = self.issue.description;
      newIssue.total_spent = 0;
      newIssue.status = "todo";
      newIssue.story_points = self.issue.story_points;
      newIssue.criticality = self.issue.criticality;
      newIssue.due_date = self.issue.due_date;

      if (typeof self.issue.user_id == "object") {
        newIssue.user_id = self.issue.user_id.id;
      } else {
        newIssue.user_id = self.issue.user_id;
      }

      if (this.modalMode == "add") {
        self.$http
          .post(api, newIssue)
          .then((response) => {
            self.$message("Sucesso", "Card cadastrado com sucesso!", "success");
          })
          .then(() => {
            self.$emit("refresh");
            self.clean();
            // $("#issueModal").modal("toggle");
          })
          .catch((response) => {
            self.$errorMessage(response);
          });
      } else {
        newIssue.status = self.issue.status;
        newIssue.id = self.issue.id;

        self.$http
          .put(api + "/" + newIssue.id, newIssue)
          .then((response) => {
            self.$message("Sucesso", "Card atualizado com sucesso!", "success");
          })
          .then(() => {
            self.$emit("refresh");
            self.clean();
            $("#issueModal").modal("toggle");
          })
          .catch((response) => {
            self.$errorMessage(response);
          });
      }
    },
  },
};
</script>

<style scoped>
label {
  font-weight: bold;
}
.photo {
  height: 40px;
  width: 60px;
  object-fit: cover;
  border: 1px solid rgb(127, 227, 245);
  border-radius: 5px;
  border-color: rgb(127, 227, 245);
}
.hidden {
  display: none;
}

/* Important part */

.modal-body {
  overflow-y: auto;
  height: 75vh;
}

.ipp-alert {
  padding: 5px !important;
  margin-bottom: 0px !important;
}

.coment-date {
  margin-bottom: 10px;
}
.modal {
  text-align: center;
  padding-right: 0px !important;
  height: auto !important;
}
/* .modal-dialog {
  display: inline-block;
  vertical-align: middle;
  min-width: 80vw;
} */
.modal-dialog {
  height: 100%; /* = 90% of the .modal-backdrop block = %90 of the screen */
}
.modal-content {
  height: 95%; /* = 100% of the .modal-dialog block */
}
.ck-editor__editable_inline {
  min-height: 170px;
  max-height: 170px;
}
</style>
