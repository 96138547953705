<template>
  <div>
    <base-crud
      crudName="Innovare TI - Feriados"
      newText="Novo Registro"
      :table="projectTable"
      :columns="columns"
      :options="options"
      :endPoint="endPoint"
      :enableAdd="true"
      :enableEdit="true"
      :enableView="true"
      :enableDelete="true"

    ></base-crud>
  </div>
</template>

<script>
import BaseCrud from "../Base/BaseCrud";


export default {
  data: function () {
    let self = this;
    return {
      extra: [],
      projectstatuses: [],
      dataForm: {},
      projectTable: "projectTable",
      users: [],
      customers: [],
      typeOfContracts: [],
      pages: [],
      url: "",
      columns: [
        "id",
        "name",
        "type_holiday",
        "holiday_date_br",
        "actions"
      ],
      tableData: ["id"],
      debounce: 800,
      options: {
        filterByColumn: true,
        filterable: [
        "id",
        "name",
        "type_holiday",
        "holiday_date_br"
        ],
        sortable: ["id"],
        listColumns: {},
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          name: "Nome",
          type_holiday: 'Tipo Do Feriado',
          holiday_date_br: 'Dia do Feriado',
          actions: "Ações",
        },
        templates: {},

        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = [];

          return this.$http.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "holidays/",
      toadd: "holiday/add"
    };
  },
  methods: {
    query: function (query) {
      let columns = {
        id: "id",
        name: "name",

      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
  mounted() {

  },
  components: {
    BaseCrud,
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.inative {
  text-decoration: line-through;
}
</style>
