<template>
    <div>
        <base-crud
                crudName="Projetos"
                newText="Novo Projeto"
                :table="projectTable"
                :columns="columns"
                :options="options"
                :endPoint='endPoint'
                :enableAdd="true"
                :enableEdit="true"
                :enableDelete="true"
                :enableView="true"
                :dataForm="dataForm"
                :customFilters="customFilters"
                :extra="extra"
        >

        </base-crud>
    </div>
</template>

<script>
    import BaseCrud from '../Base/BaseCrud'
import { debug } from 'util';

    export default {
        data: function () {
            let self = this
            return {
              customFilters:[ ],
                extra: [],
                projectTable: 'wikiclients',
                users: [],
                pages: [],
                url: '',
                columns: ['id', 'title', 'customer_id', 'typeOfRegister', 'actions'],
                tableData: ['id', 'title', 'customer_id', 'typeOfRegister'],
                debounce: 800,
                options: {
                  filterByColumn: true,
                  filterable: ['id', 'title', 'customer_id', 'typeOfRegister'],
                  listColumns: {},
                    sortable: ['id', 'title', 'customer_id', 'typeOfRegister'],
                    pagination: {chunk: 10, dropdown: false, nav: 'scroll' },
                    perPage: 10,
                    perPageValues: [10,25,50,100],
                    headings: {
                        'id': 'ID',
                        'title' : 'Titulo',
                        'customer_id' : 'Cliente',
                        'typeOfRegister' : 'Tipo do Registro',
                        'actions' : 'Ações'
                    },
                    templates: {
                        customer_id: function (h, row, index) {
                          return row.customers ? row.customers.customer_name : "Sem Cliente Atribuído";
                        },
                    },
                    requestFunction: function (data) {
                      let requestData = {};

                      let query = this.$parent.$parent.query(data.query);

                      requestData.params = data;
                      requestData.params.query = "";
                      requestData.params.with = [
                      "customers"
                      ];

                      return this.$http.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                          this.dispatch("error", e);
                        }.bind(this)
                      );
                    },
                },
                endPoint: 'wikiclients/',

                dataForm: {}
            }
        },
        methods: {
             query: function (query) {
                  let columns = {
                      id: 'id',
                      title: 'title',
                      customer_id: "customers[customer_name]",
                      typeOfRegister: 'typeOfRegister'
                  };
                  let filters = '&orderByDesc=id&';
                  $.each(query, function (index, value) {
                      filters += columns[index] + '=' + value + '&';
                  });
                  return filters;
              },

        },
        mounted () {

            let self = this

        },
        components: {
            BaseCrud
        },
        computed: {}
    }
</script>

<style scoped>
    .VuePagination {
        text-align: center;
    }
</style>
