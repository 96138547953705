<template>
  <div>
    <base-crud
      crudName="Notificações de Chamados"
      newText="Nova Notificação"
      :table="table"
      :columns="columns"
      :options="options"
      :endPoint="endPoint"
      :enableAdd="true"
      :enableEdit="true"
      :enableDelete="true"
      :enableView="false"
      :dataForm="dataForm"
      :extra="extra"
    ></base-crud>
  </div>
</template>

<script>
import BaseCrud from "../Base/BaseCrud";


export default {
  data: function () {
    return {
      assigneds: [],
      assignedIds: [],
      table: "ticketNotificationsTable",
      pages: [],
      url: "",
      columns: ["id", "start", "status", "customer_id", "notified", "actions"],
      tableData: ["id", "start", "status", "customer_id", "notified"],
      options: {
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        filterByColumn: true,
        filterable: ["id", "start", "status", "customer_id", "notified"],
        debounce: 800,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          start: "Horas",
          status: "Status",
          customer_id: "Cliente",
          notified: "Notifica os usuários",
          actions: "Ações",
        },

        sortable: ["id", "start"],
        templates: {
          status: function (h, row, index) {
            return row.status_entity
              ? row.status_entity.title
              : "Não identificado";
          },
          customer_id: function (h, row, index) {
            return row.customer
              ? row.customer.customer_name
              : "Nenhum cliente específico, considera o geral";
          },
          start: function (h, row, index) {
            return row.start
              ? "Entre " + row.start + " e " + row.end + " horas"
              : "";
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ["statusEntity", "customer"];

          return this.$http.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      extra: [],
      endPoint: "ticket_notifications/",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    query: function (query) {
      let columns = {
        id: "id",
        start: "start",
        status: "statusEntity[title]",
        customer_id: "customer[customer_name]",
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
  mounted: function () {},
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
</style>
