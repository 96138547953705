import Crud from '../components/Budgetings/Crud'
import Entity from '../components/Budgetings/Add'
import StepBudgeting from '../components/Budgetings/StepBudgeting'
import View from '../components/Budgetings/View'

let routes = {
    path: '/Budgetings',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/budgetings',
            name: 'Listar Orçamentação',
            component: Crud,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/budgetings/add',
            name: 'Novo Orçamentação',
            component: Entity
        },
        {
            path: '/budgetings/view/:id',
            name: 'Vizualizar Orçamentação',
            component: View
        },
        {
            path: '/budgetings/step_budgeting/:id',
            name: 'Orçamento',
            component: StepBudgeting
        },
        {
            path: '/budgetings/edit/:id',
            name: 'Editar Orçamentação',
            component: Entity
        },
    ]
};

export default routes;
