<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              Indicadores de Meta
              <i class="fas fa-chart-line"></i>
            </div>
          </div>
          <div class="card-body text-center"></div>
        </div>
      </div>
    </div>

    <!-- Charts -->
    <div class="row">
      <div class="col-md-12">
        <div class="card1">
          <div class="card-body">
            <div class="card mt-3 tab-card">
              <ul
                class="nav nav-tabs card-header-tabs"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="one-tab"
                    data-toggle="tab"
                    href="#one"
                    role="tab"
                    aria-controls="One"
                    aria-selected="true"
                    @click="tab = 1"
                    >Semanal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="two-tab"
                    data-toggle="tab"
                    href="#two"
                    role="tab"
                    aria-controls="two"
                    aria-selected="false"
                    @click="tab = 2"
                    >Mês/Dia</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="three-tab"
                    data-toggle="tab"
                    href="#three"
                    role="tab"
                    aria-controls="three"
                    aria-selected="false"
                    @click="tab = 3"
                    >To-do/Doing</a
                  >
                </li>
              </ul>

              <div>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active p-3"
                    id="one"
                    role="tabpanel"
                    aria-labelledby="one-tab"
                    v-if="tab == 1 && tab != 3 && tab != 2"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <!-- <h4>
                          <label id="title">Pontos da Semana Passada</label>
                        </h4> -->
                        <chart-bar :chartData="pointsDaySum"></chart-bar>
                        <label class="msg-bottom">Dev</label>
                      </div>
                      <div class="col-md-6">
                        <!-- <h4>
                          <label id="title">Pontos da Semana</label>
                        </h4> -->
                        <chart-bar :chartData="pointsWeekSum"></chart-bar>
                        <label class="msg-bottom">Devs</label>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade show active p-3"
                    id="two"
                    role="tabpanel"
                    aria-labelledby="two-tab"
                    v-if="tab > 1 && tab != 3"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <!-- <h4>
                          <label id="title">Cards No Ano Por Mês</label>
                        </h4> -->
                        <chart-line
                          ref="a"
                          :chartData="pointsPerWeekInYearSum"
                        ></chart-line>
                        <label class="msg-bottom">Semana do Ano</label>
                      </div>
                      <div class="col-md-6">
                        <!-- <h4>
                          <label id="title">Pontos Somados Hoje por Dev</label>
                        </h4> -->
                        <chart-bar :chartData="pointsTodaySum"></chart-bar>
                        <label class="msg-bottom">Devs</label>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade show active p-3"
                    id="three"
                    role="tabpanel"
                    aria-labelledby="three-tab"
                    v-if="tab > 2"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <!-- <h4>
                          <label id="title">Card's em "To-Do" por Dev</label>
                        </h4> -->
                        <chart-bar :chartData="cardsInTodoCount"></chart-bar>
                        <label class="msg-bottom">Dev</label>
                      </div>
                      <div class="col-md-6">
                        <!-- <h4>
                          <label id="title">Card's em "Doing" por Dev</label>
                        </h4> -->
                        <chart-bar :chartData="cardsInDoingCount"></chart-bar>
                        <label class="msg-bottom">Devs</label>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <innover-legends></innover-legends>
  </div>
</template>

<script>
import ChartBar from "../Utils/ChartBar";
import ChartLine from "../Utils/ChartLine";
import InnoverLegends from "../Utils/InnoverLegends";

export default {
  data() {
    return {
      activeProjects: 0,
      openIssues: 0,
      openTickets: 0,
      cardsInTodoCount: {},
      cardsInDoingCount: {},
      datacollection: [],
      loaded: false,
      tab: 1,
      pointsDaySum: {},
      pointsWeekSum: {},
      pagination: {},
      pointsTodaySum: {},
      pointsPerWeekInYearSum: {},
    };
  },
  mounted() {
    this.user = this.$store.state.user;
    let self = this;
    this.$http
      .get(this.$store.getters.api + "analytics/summary")
      .then((response) => {
        self.activeProjects = response.data.activeProjects;
        self.openIssues = response.data.openIssues;
        self.openTickets = response.data.openTickets;

        let metaWeek = [];
        for (
          let index = 0;
          index < response.data.pointsPerWeekInYearSum.data.length;
          index++
        ) {
          metaWeek.push(500);
        }
        (self.pointsDaySum = {
          labels: response.data.pointsLastWeekSum.labels,
          datasets: [
            {
              label: "Pontos da Semana Passada",
              backgroundColor: "#f1f968",
              borderColor: "#f1f968",
              borderWidth: 3,
              radius: 1,
              data: response.data.pointsLastWeekSum.data,
            },
          ],
        }),
          (self.pointsWeekSum = {
            labels: response.data.pointsWeekSum.labels,
            datasets: [
              {
                label: "Pontos da Semana",
                backgroundColor: "#299BFF",
                borderColor: "#299BFF",
                borderWidth: 3,
                radius: 1,
                data: response.data.pointsWeekSum.data,
              },
            ],
          }),
          (self.pointsTodaySum = {
            labels: response.data.pointsTodaySum.labels,
            datasets: [
              {
                label: "Pontos do Dia",
                backgroundColor: "#299BFF",
                borderColor: "#299BFF",
                borderWidth: 3,
                radius: 1,
                data: response.data.pointsTodaySum.data,
              },
            ],
          }),
          (self.pointsPerWeekInYearSum = {
            labels: response.data.pointsPerWeekInYearSum.labels,
            datasets: [
              {
                label: "Pontos na Semana do Ano",
                backgroundColor: "rgba(0, 900, 500, 0.1)",
                borderColor: "#299BFF",
                pointBackgroundColor: "#299BFF",
                borderWidth: 4,
                radius: 2,
                pointBorderColor: "#299BFF",
                data: response.data.pointsPerWeekInYearSum.data,
              },
              {
                label: "Meta",
                backgroundColor: "transparent",
                borderColor: "#ff7973",
                pointBackgroundColor: "#ff7973",
                borderWidth: 0,
                radius: 0,
                pointBorderColor: "#ff7973",
                data: metaWeek,
              },
            ],
          }),
          (self.cardsInTodoCount = {
            labels: response.data.cardsInTodoCount.labels,
            datasets: [
              {
                label: "Cards em Todo",
                backgroundColor: "#299BFF",
                borderColor: "#299BFF",
                borderWidth: 3,
                radius: 5,
                data: response.data.cardsInTodoCount.data,
              },
            ],
          }),
          (self.cardsInDoingCount = {
            labels: response.data.cardsInDoingCount.labels,
            datasets: [
              {
                label: "Cards em Doing",
                backgroundColor: "#299BFF",
                borderColor: "#299BFF",
                borderWidth: 3,
                radius: 5,
                data: response.data.cardsInDoingCount.data,
              },
            ],
          });
      });
  },
  methods: {
    getIssues() {
      let self = this;
      let api = self.store.state.api + "issues/?paginated=false";

      self.$$http
        .get(api)
        .then((response) => {
          self.issues = response.data.data;
        })
        .catch((error) => {
          self.errors = error.reponse.data;
          self.$message(null, self.errors, "error");
        });
    },
  },
  components: {
    ChartBar,
    ChartLine,
    InnoverLegends,
  },
};
</script>
<style>
.msg-bottom {
  color: grey;
  text-align: center;
  display: block;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
  font-size: 22px;
}
.label1 {
  /* background-color: rgb(214, 207, 207); */
  text-align: center;
  border: 1px solid;
  height: 475px;
  border-radius: 4px;
  border-color: #57b4d7;
}
#title {
  color: grey;
  text-align: center;
  display: block;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
}
#title1 {
  color: grey;
  text-align: center;
  display: relative;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 110vh;
  width: 80vw;
}
.card-body {
  padding: 10px;
}
.card1 {
  margin-bottom: 1.5rem;
  background: #fff;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px #333;
}

.chartjs-render-monitor {
  min-height: 300px;
}
</style>
