<template>
    <div>
        <base-crud
                crudName="Innovare TI - Base de Conhecimento"
                newText="Novo Registro"
                :table="projectTable"
                :columns="columns"
                :options="options"
                :endPoint='endPoint'
                :enableAdd="true"
                :enableEdit="true"
                :enableDelete="true"
                :enableView="true"
        >
        </base-crud>
    </div>
</template>

<script>
    import BaseCrud from '../Base/BaseCrud'
    import { debug } from 'util';
    import vSelect from 'vue-select';

    export default {
        data: function () {
            let self = this
            return {
                projectstatuses: [],
                dataForm: {},
                projectTable: 'projectTable',
                users: [],
                pages: [],
                url: '',
                columns: ['id', 'title', 'user_id','category', 'actions'],
                tableData: ['id', 'title', 'user_id','category'],
                debounce: 800,
                options: {
                    filterByColumn: true, 
                    filterable: ['title', 'user_id','category'],
                    sortable: ['id', 'title', 'user_id',],
                    pagination: {chunk: 10, dropdown: false, nav: 'scroll' },
                    perPage: 10,
                    perPageValues: [10,25,50,100],
                    headings: {
                        'id': 'ID',
                        'title' : 'Titulo',
                        'user_id': 'Criado por:',
                        'category':'Categoria',
                        'actions' : 'Ações'
                    },
                    templates: {

                        project_status_id: function (h, row, index) {
                            return row.status != undefined ? row.status.name : '';
                        },
                        user_id: function (h, row, index) {
                            return row.user ? row.user.name : 'Sem Pessoa atribuída';
                        },
                    },
                    requestFunction: function (data) {
                        let requestData = {};

                        let query = this.$parent.$parent.query(data.query);
                        requestData.params = data;
                        requestData.params.query = '';
                        requestData.params.with = [
                        'user'
                        ];

                        return this.$http.get(this.url + '?' + query, requestData)
                            .catch(function (e) {
                                    this.dispatch("error", e);
                                }.bind(this)
                            );
                    }
                },
                endPoint: 'wikis/',

            }
        },
        methods: {
          query: function (query) {
                let columns = {
                    title: 'title',
                    category:'category',
                    user_id: 'user[name]'
                };
                let filters = 'orderByDesc=id&';
                $.each(query, function (index, value) {
                    filters += columns[index] + '=' + value + '&';
                });
                return filters;
            },
        },
        mounted () {

            let self = this
            this.$http.get( this.$store.getters.api + 'projectstatuses?paginated=false')
            .then( response => {
                self.projectstatuses = response.data.data
            })
        },
        components: {
            BaseCrud
        },
        computed: {
            statusList () {
                // return this.$http.get('/status')
                return [
                    {id: 'e', label: '123'}
                ]
            }
        }
    }
</script>

<style scoped>
    .VuePagination {
        text-align: center;
    }
</style>
