import Crud from '../components/TicketStatus/Crud'
import TicketStatus from '../components/TicketStatus/TicketStatus'

let routes = {
    path: '/tickets_status',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
      {
          path: '/tickets_status/',
          name: 'Status',
          component: Crud
      },
      {
        path: '/ticket_status/:id?',
        name: 'Status',
        component: TicketStatus
    },
    ]
}

export default routes