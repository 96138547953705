import Crud from '../components/User/Crud'
import Add from '../components/User/Add'
import Edit from '../components/User/Edit'
import View from '../components/User/View'
import ChangePassword from '../components/User/ChangePassword'
import ResetPassword from '../components/User/ResetPassword'

let routes = {
    path: '/users',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/users/',
            name: 'Usuários',
            component: Crud,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/users/add',
            name: 'Usuários',
            component: Add,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/users/edit/:id?',
            name: 'Usuários',
            component: Edit,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/user/change-password',
            name: 'Usuários',
            component: ChangePassword,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/user/reset-password/:id?',
            name: 'Usuários',
            component: ResetPassword,
            meta: {
                permission: 'is_admin'
            }
        }

    ]
};


export default routes
