import ReportSLA from '../components/Report/ReportSLA'

let routes = {
    path: '/report',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/report/report-sla',
            name: 'Tickets',
            component: ReportSLA,
            meta: {
                permission: ''
            }
        }
    ]
}
export default routes

