import Crud from '../components/ProjectStatus/Crud'

let routes = {
    path: '/projectstatuses',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/projectstatuses/:operation?/:id?',
            name: 'Status',
            component: Crud,
            meta: {
                permission: 'is_admin'
            }
        },
    ]
};


export default routes
