<template>
<div>
  <div class="col-md-12 container">
    <h1>IPP - Innovare Project Planning</h1>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              Indicadores de Meta
              <i class="fas fa-chart-line"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Charts -->

    <div style="width:99%;">
      <div class="row">
      <div class="col-md-2">
        <div class="card-text">
          <div class="card-body-text">
            <label>{{ kpi.kpiTotalChamados }}</label>
            <h5 id="title">Total de Chamados</h5>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="card-text">
          <div class="card-body-text">
            <label>{{ kpi.kpiTotalAberto }}</label>
            <h5 id="title">Chamados Abertos</h5>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="card-text">
          <div class="card-body-text">
            <label>{{ kpi.kpiTotalPendenteCliente }}</label>
            <h5 id="title">Pendentes Cliente</h5>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="card-text">
          <div class="card-body-text">
            <label>{{ kpi.kpiTotalPendenteInnovare }}</label>
            <h5 id="title">Pendentes Innovare</h5>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="card-text">
          <div class="card-body-text">
            <label>{{ kpi.kpiTotalFechados }}</label>
            <h5 id="title">Chamados Fechados</h5>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="card-text">
          <div class="card-body-text">
            <label>{{ kpi.kpiTempoGasto }}</label>
            <h5 id="title">Tempo Gasto</h5>
          </div>
        </div>
      </div>
    </div>
    </div>

    <br />
    <br />
    <div style="width:99%;">
    <div class="row">
      <div class="col-md-12">
        <div class="card1">
          <div class="card-body">
            <div class="card mt-3 tab-card">
              <ul
                class="nav nav-tabs card-header-tabs"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="one-tab"
                    data-toggle="tab"
                    href="#one"
                    role="tab"
                    aria-controls="One"
                    aria-selected="true"
                    @click="tab = 1"
                    >Abertos/fechados</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="two-tab"
                    data-toggle="tab"
                    href="#two"
                    role="tab"
                    aria-controls="two"
                    aria-selected="false"
                    @click="tab = 2"
                    >Mês/Categoria</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="three-tab"
                    data-toggle="tab"
                    href="#three"
                    role="tab"
                    aria-controls="three"
                    aria-selected="false"
                    @click="tab = 3"
                    >Mês/Prioridade</a
                  >
                </li>
              </ul>

              <div>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active p-3"
                    id="one"
                    role="tabpanel"
                    aria-labelledby="one-tab"
                    v-if="tab == 1 && tab != 3 && tab != 2"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <!-- <h4>
                          <label id="title">Pontos da Semana Passada</label>
                        </h4> -->
                        <chart-line
                          :chartData="abertosFechadosNoMes"
                          :options="options"
                        ></chart-line>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade show active p-3"
                    id="two"
                    role="tabpanel"
                    aria-labelledby="two-tab"
                    v-if="tab > 1 && tab != 3"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <chart-bar :chartData="ticketsPerCategories"></chart-bar>
                        <label class="msg-bottom">Semana do Ano</label>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade show active p-3"
                    id="three"
                    role="tabpanel"
                    aria-labelledby="three-tab"
                    v-if="tab > 2"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <chart-bar></chart-bar>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>

    <div class="row">
      <div style="width:99%;">
        <div class="row">
        <div class="col-md-12">
          <div class="card1">
            <div class="card-body">
              <div class="card mt-3 tab-card">
                <ul
                  class="nav nav-tabs card-header-tabs"
                  id="myTab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="one-tab"
                      data-toggle="tab"
                      href="#one"
                      role="tab"
                      aria-controls="One"
                      aria-selected="true"
                      >Opens</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="two-tab"
                      data-toggle="tab"
                      href="#two"
                      role="tab"
                      aria-controls="two"
                      aria-selected="false"
                      >Closeds</a
                    >
                  </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active p-3"
                    id="one"
                    role="tabpanel"
                    aria-labelledby="one-tab"
                  >
                    <form>
                      <base-crud
                        crudName="Chamados Abertos"
                        newText="Novo Chamado"
                        :table="table"
                        :columns="columns"
                        :options="options"
                        :endPoint="endPoint"
                        :enableAdd="false"
                        :enableEdit="false"
                        :enableDelete="true"
                        :enableView="true"
                        :dataForm="dataForm"
                        :extra="extra"
                        @open="openTap"
                      ></base-crud>
                    </form>
                  </div>

                  <div
                    class="tab-pane fade p-3"
                    id="two"
                    role="tabpanel"
                    aria-labelledby="two-tab"
                  >
                    <closeds></closeds>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>

    </div>
  </div>
</div>

</template>

<script>
// import chart from './Dashboard/ProjectChartLine'
import ChartLine from "../Utils/ChartLine";
import ChartBar from "../Utils/ChartBar";
import BaseCrud from "../Base/BaseCrud";
import Closeds from "./Closeds";

export default {
  data() {
    return {
      activeProjects: 0,
      openIssues: 0,
      openTickets: 0,
       tab: 1,
      datacollection: [],
      loaded: false,
      options: {
        legend: {
          display: true
        }
      },
      pointsDaySum: {},
      ticketsPerCategories: {},
      pointsTodaySum: {},
      pointsPerWeekInYearSum: {},
      assigneds: [],
      assignedIds: [],
      table: "deployTable",
      pages: [],
      url: "",
      columns: [
        "client_id",
        "sent_by",
        "code",
        "subject",
        "status_entity",
        "created_at",
        "origin",
        "contract",
        "SLA",
        "last_interaction",
        "actions"
      ],
      tableData: ["id", "description"],
      options: {
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        filterByColumn: true,
        filterable: [
          "client_id",
          "sent_by",
          "code",
          "subject",
          "status_entity",
          "created_at",
          "origin",
          "contract"
        ],
        sortable: [
          "client_id",
          "sent_by",
          "code",
          "subject",
          "status_entity",
          "created_at",
          "origin"
        ],
        listColumns: {
          status_entity: [
            {
              id: 1,
              text: "Aberto"
            },
            {
              id: 2,
              text: "Em triagem"
            },
            {
              id: 12,
              text: "Na fila"
            },
            {
              id: 3,
              text: "Atendimento N1"
            },
            {
              id: 4,
              text: "Atendimento N2"
            },
            {
              id: 5,
              text: "Em QA - (Testes Internos)"
            },
            {
              id: 6,
              text: "Homologação Cliente"
            },
            {
              id: 7,
              text: "Aguardando GMUD"
            },
            {
              id: 8,
              text: "Em Acompanhamento (Produção)"
            },
            {
              id: 9,
              text: "Aguardando resposta do cliente"
            },
            {
              id: 10,
              text: "Em análise"
            },
            {
              id: 11,
              text: "Finalizado"
            },
            {
              id: 12,
              text: "Na Fila"
            },
            {
              id: 13,
              text: "Reaberto"
            },
            {
              id: 14,
              text: "Aguardando retorno TI Cliente"
            },
            {
              id: 15,
              text: "Aguardando retorno Área de negócio Cliente"
            },
            {
              id: 16,
              text: "Aguardando Chamado Terceiros"
            }
          ]
        },
        debounce: 800,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          client_id: "Cliente",
          sent_by: "Enviado por",
          code: "N. Chamado",
          subject: "Título",
          status_entity: "Status",
          created_at: "Aberto em",
          origin: "Origem",
          contract: "Contrato",
          last_interaction: "Última Interação",
          actions: "Ações"
        },
        sortable: ["id", "priority"],
        templates: {
          created_at: function(h, row, index) {
            return moment(row.created_at).format("DD/MM/YYYY HH:mm:ss");
          },
          client_id: function(h, row, index) {
            return row.sent_by
              ? row.sent_by.customers.customer_name
              : "Nenhum Registro";
          },
          status_entity: function(h, row, index) {
            if (row.status == "1") {
              return (
                <h5>
                  <span
                    style="background-color:#ce3a49; color:#fff;"
                    class="badge badge"
                  >
                    Aberto
                  </span>
                </h5>
              );
            }

            if (row.status == "2") {
              return (
                <h5>
                  <span
                    style="background-color:#429550cc; color:#fff;"
                    class="badge badge"
                  >
                    Em triagem
                  </span>
                </h5>
              );
            }
            if (row.status == "12") {
              return (
                <h5>
                  <span
                    style="background-color:#c5c73c; color:#fff;"
                    class="badge badge"
                  >
                    Na Fila
                  </span>
                </h5>
              );
            }
            if (row.status == "3") {
              return (
                <h5>
                  <span
                    style="background-color:#c2289bb4; color:#fff;"
                    class="badge badge"
                  >
                    Atendimento N1
                  </span>
                </h5>
              );
            }
            if (row.status == "4") {
              return (
                <h5>
                  <span
                    style="background-color:#b634d6b4  ; color:#fff;"
                    class="badge badge"
                  >
                    Atendimento N2
                  </span>
                </h5>
              );
            }
            if (row.status == "5") {
              return (
                <h5>
                  <span
                    style="background-color:#429550cc  ; color:#fff;"
                    class="badge badge"
                  >
                    Em QA (testes internos)
                  </span>
                </h5>
              );
            }
            if (row.status == "6") {
              return (
                <h5>
                  <span
                    style="background-color:#35be4ccc  ; color:#fff;"
                    class="badge badge"
                  >
                    Homologação Cliente
                  </span>
                </h5>
              );
            }
            if (row.status == "7") {
              return (
                <h5>
                  <span
                    style="background-color:#0aeaf1cc  ; color:#fff;"
                    class="badge badge"
                  >
                    Aguardando GMUD
                  </span>
                </h5>
              );
            }
            if (row.status == "8") {
              return (
                <h5>
                  <span
                    style="background-color:#00ff6244  ; color:#fff;"
                    class="badge badge"
                  >
                    Em Acompanhamento (Produção)
                  </span>
                </h5>
              );
            }
            if (row.status == "9") {
              return (
                <h5>
                  <span
                    style="background-color:#e2c543cc  ; color:#fff;"
                    class="badge badge"
                  >
                    Aguardando resposta do cliente
                  </span>
                </h5>
              );
            }
            if (row.status == "10") {
              return (
                <h5>
                  <span
                    style="background-color:#f0f0f0  ; color:#000;"
                    class="badge badge"
                  >
                    Em análise
                  </span>
                </h5>
              );
            }
            if (row.status == "13") {
              return (
                <h5>
                  <span
                    style="background-color:#ff00009b  ; color:#fff;"
                    class="badge badge"
                  >
                    Reaberto
                  </span>
                </h5>
              );
            }
            if (row.status == "14") {
              return (
                <h5>
                  <span
                    style="background-color:#ffa07a  ; color:#fff;"
                    class="badge badge"
                  >
                    Aguardando retorno TI Cliente
                  </span>
                </h5>
              );
            }
            if (row.status == "15") {
              return (
                <h5>
                  <span
                    style="background-color:#cd5c5c  ; color:#fff;"
                    class="badge badge"
                  >
                    Aguardando retorno Área de negócio Cliente
                  </span>
                </h5>
              );
            }
            if (row.status == "16") {
              return (
                <h5>
                  <span
                    style="background-color:#cd9c5c  ; color:#fff;"
                    class="badge badge"
                  >
                    Aguardando Chamado Terceiros
                  </span>
                </h5>
              );
            }
          },
          sent_by: function(h, row, index) {
            return row.sent_by ? row.sent_by.name : "Nenhum Registro";
          },
          contract: function(h, row, index) {
            return row.contract ? row.contract.name : "Sem Informação";
          }
        },
        requestFunction: function(data) {
          let requestData = {};

          let query = this.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = [
            "sent_by",
            "status_entity",
            "sent.customers",
            "contract"
          ];

          return this.$http.get(this.url + "?" + query, requestData).catch(
            function(e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        }
      },
      extra: [
        {
          id: 1,
          title: "Abrir em outra aba",
          class: "btn btn-crud check fa fa-arrow-up",
          name: "open",
          label: ""
        }
      ],
      endPoint: "new_tickets/",
      dataForm: {},
      kpi: {
        kpiTotalChamados: 0,
        kpiTotalAberto: 0,
        kpiTotalPendenteCliente: 0,
        kpiTotalPendenteInnovare: 0,
        kpiTotalFechados: 0,
        kpiTempoGasto: 0
      },
      abertosFechadosNoMes: {}
    };
  },
  mounted() {
    this.setupDash();
  },
  methods: {
    setupDash() {
      this.user = this.$store.state.user;
      let self = this;
      this.$http
        .get(this.$store.getters.api + "analytics/dash-tickets")
        .then(response => {
          console.log(response.data);

          this.kpi = response.data.kpi;

          self.abertosFechadosNoMes = {
            labels: response.data.charts.abertosNoMes.labels,
            datasets: [
              {
                label: "Chamados Abertos no últimos 6 meses",
                backgroundColor: "rgba(500, 900, 500, 0.1)",
                borderColor: "#60A7E6",
                pointBackgroundColor: "#60A7E6",
                borderWidth: 2,
                radius: 2,
                pointBorderColor: "#60A7E6",
                data: response.data.charts.abertosNoMes.data
              },
              {
                label: "Chamados Fechados no últimos 6 meses",
                backgroundColor: "rgba(500, 400, 700, 0.1)",
                borderColor: "#ff7973",
                pointBackgroundColor: "#ff7973",
                borderWidth: 2,
                radius: 2,
                pointBorderColor: "#ff7973",
                data: response.data.charts.fechadosNoMes.data
              }
            ]
          };
          /*
          self.activeProjects = response.data.activeProjects;
          self.openIssues = response.data.openIssues;
          self.openTickets = response.data.openTickets;

          let metaWeek = [];
          for (
            let index = 0;
            index < response.data.pointsPerWeekInYearSum.data.length;
            index++
          ) {
            metaWeek.push(500);
          }


          (self.ticketsPerCategories = {
            labels: response.data.ticketsPerCategories.labels,
            datasets: [
              {
                label: "Pontos da Semana Passada",
                backgroundColor: "#f1f968",
                borderColor: "#f1f968",
                borderWidth: 3,
                radius: 5,
                data: response.data.ticketsPerCategories.data,
              },
            ],
          }),
            (self.pointsWeekSum = {
              labels: response.data.pointsWeekSum.labels,
              datasets: [
                {
                  label: "Pontos da Semana",
                  backgroundColor: "#62A8E6",
                  borderColor: "#62A8E6",
                  borderWidth: 3,
                  radius: 5,
                  data: response.data.pointsWeekSum.data,
                },
              ],
            }),
            (self.pointsTodaySum = {
              labels: response.data.pointsTodaySum.labels,
              datasets: [
                {
                  label: "Pontos do Dia",
                  backgroundColor: "#62A8E6",
                  borderColor: "#62A8E6",
                  borderWidth: 3,
                  radius: 5,
                  data: response.data.pointsTodaySum.data,
                }
              ],
            }),
            (self.pointsPerWeekInYearSum = {
              labels: response.data.pointsPerWeekInYearSum.labels,
              datasets: [
                {
                  label: "Pontos na Semana do Ano",
                  backgroundColor: "rgba(0, 900, 500, 0.1)",
                  borderColor: "#60A7E6",
                  pointBackgroundColor: "#60A7E6",
                  borderWidth: 4,
                  radius: 5,
                  pointBorderColor: "#60A7E6",
                  data: response.data.pointsPerWeekInYearSum.data,
                },
                {
                  label: "Meta",
                  backgroundColor: "transparent",
                  borderColor: "#ff7973",
                  pointBackgroundColor: "#ff7973",
                  borderWidth: 0,
                  radius: 0,
                  pointBorderColor: "#ff7973",
                  data: metaWeek,
                },
              ],
            });

            */
        });
    },
    getIssues() {
      let self = this;
      let api = self.store.state.api + "issues/?paginated=false";

      self.$$http
        .get(api)
        .then(response => {
          self.issues = response.data.data;
        })
        .catch(error => {
          self.errors = error.reponse.data;
          self.$message(null, self.errors, "error");
        });
    },
    openTap(props) {
      open("/new_tickets/view/" + props.row.id);
    },
    query: function(query) {
      let columns = {
        id: "id",
        client_id: "sent.customers[customer_name]",
        sent_by: "sent_by[name]",
        code: "code",
        subject: "subject",
        status_entity: "status",
        origin: "origin",
        created_at: "created_at",
        contract: "contract[name]"
      };
      let filters = "orderByDesc=id&closed=0&";
      $.each(query, function(index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    }
  },
  components: {
    ChartLine,
    ChartBar,
    BaseCrud,
    Closeds
  }
};
</script>
<style scoped>
.msg-bottom {
  color: grey;
  text-align: center;
  display: block;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
  font-size: 22px;
}
.label1 {
  /* background-color: rgb(214, 207, 207); */
  text-align: center;
  border: 1px solid;
  height: 475px;
  border-radius: 4px;
  border-color: #57b4d7;
}
#title {
  color: grey;
  text-align: center;
  display: block;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 110vh;
  width: 80vw;
}
.card-body {
  padding: 10px;
}
.card-body-text {
  padding-top: 30px;
}
.card-body-text label {
  font-size: 40px;
  margin-bottom: 0px;
  color: grey;
  text-align: center;
  display: block;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
}
.card-body-text h5 {
  overflow: hidden;
  font-weight: bold;
  font-size: 16px;
}
.col-md-2 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.card1 {
  margin-bottom: 1.5rem;
  background: #fff;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px #333;
}
.card-text {
  margin-bottom: 1.5rem;
  height: 150px;
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px #333;
}

.card-text {
  vertical-align: middle;
}
.chart-container {
  position: relative;
  margin: auto;
  height: 110vh;
  width: 80vw;
}
</style>
