import Crud from '../components/UsersCustomer/Crud'
import Add from '../components/UsersCustomer/Add'
import Edit from '../components/UsersCustomer/Edit'
import View from '../components/UsersCustomer/View'

let routes = {
    path: '/UserCustomer',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/usercustomers',
            name: 'Listar Registros',
            component: Crud,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/usercustomers/Add',
            name: 'Novo Registro',
            component: Add
        },
        {
            path: '/usercustomers/View/:id',
            name: 'Visualizar Registro',
            component: View,            
        },
        {
            path: '/usercustomers/Edit/:id',
            name: 'Editar Registro',
            component: Edit
        }
    ]

};

export default routes;