<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">Detalhes do Projeto: {{project.name}}</div>
    </div>

    <!--tab contents-->
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" href="#one" role="tab" data-toggle="tab">Indicadores</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#two" role="tab" data-toggle="tab">Cards</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#three" role="tab" data-toggle="tab">QA's</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#four" role="tab" data-toggle="tab">Historico</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#five" role="tab" data-toggle="tab">Anexos</a>
      </li>
    </ul>
    <!-- Tab panes -->
    <div class="tab-content">
      <br />
      <!--Grafico-->
      <div role="tabpanel" class="tab-pane fade show active" id="one">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <issues-per-status v-if="activeChart" :chartData="chartDataStatus"></issues-per-status>
            </div>
            <div class="col-md-6">
              <issues-per-status-percent v-if="activeChart" :chartData="chartDataPercent"></issues-per-status-percent>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <!--Tabela-->
      <div role="tabpanel" class="tab-pane fade show" id="two">
        <div class="card-body">
          <v-server-table
            ref="table"
            :name="table"
            :url="url"
            :columns="columns"
            :options="options"
          ></v-server-table>
        </div>
      </div>

      <div role="tabpanel" class="tab-pane fade show" id="three">
        <div class="card-body" v-if="project.qas.length > 0">
          <div class="card" v-for="qas in project.qas" :key="qas.id">
            <div class="row">
              <div class="col-md-3">
                <h5 class="card-title">{{ project.name }}</h5>
                <p class="card-text" v-if="qas.sprint_id != null">{{qas.sprint.name }}</p>
              </div>
              <div class="col-md-3">
                <h5 class="card-title">Origem da Solicitação</h5>
                <p class="card-text" v-if=" qas.origin == 1">Projeto</p>
                <p class="card-text" v-if=" qas.origin == 2">Chamado</p>
              </div>
              <div class="col-md-5">
                <h5 class="card-title">Prioridade</h5>
                <h6 class="card-text">
                  <span
                    v-bind:class="[qas.priority == 'Alta Prioridade' ? 'badge badge-danger' : [qas.priority == 'Média Prioridade' ? 'badge badge-warning' : 'badge badge-success']]"
                  >{{ qas.priority }}</span>
                </h6>
                <p class="card-text" v-if="qas.priority == 'Alta Prioridade'">{{ qas.justify }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <br />
                <h5 class="card-title">Tipo do Projeto</h5>
                <p class="card-text">{{ qas.type_project }}</p>
              </div>
              <div class="col-md-3">
                <br />
                <h5 class="card-title">Solicitante</h5>
                <p class="card-text">{{ qas.user_qa.name }}</p>
              </div>
              <div class="col-md-3">
                <br />
                <h5 class="card-title">Criado em</h5>
                <p class="card-text">{{ qas.created_at }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3">
                <br />
                <h5 class="card-title">Responsáevl pelo QA</h5>
                <p class="card-text">{{ qas.user_qa.name }}</p>
              </div>
              <div class="col-md-3">
                <br />
                <h5 class="card-title">Data de Análise</h5>
                <p class="card-text">{{ qas.moderated_at }}</p>
              </div>
              <div class="col-md-3">
                <br />
                <h5 class="card-title">Status</h5>
                <p class="card-text">{{ qas.deploy_status.title }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <br />
                <br />
                <h5 class="card-title">Descrição</h5>
                <p class="card-text">{{ qas.description }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-10">
                <h5 class="card-title">Documentos de QA</h5>
                <br />
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Nome do Arquivo</th>
                      <th scope="col">Etapa</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="attach in qas.wf_attachments" :key="attach.id">
                      <td>{{ attach.attachment }}</td>
                      <td>{{ attach.step }}</td>
                      <td>
                        <button
                          type="button"
                          @click="downloadLink(attach.id)"
                          class="btn btn-crud download"
                          data-tooltip="tooltip"
                          title="Download"
                        >
                          <i class="fas fa-download"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" v-else>Nenhum QA Encontrado</div>
        <hr />
      </div>

      <!-- Historico -->
      <div role="tabpanel" class="tab-pane fade show" id="four">
        <div class="card" v-if="project.history.length > 0">
          <div class="card" v-for="history in project.history" :key="history.id">
            <div class="card-header">
              <h5 class="card-title">{{ history.title }}</h5>
              <br />
              <span class="card-text" v-html="history.description"></span>
              <div class="card-body">
                <i>{{ history.user.name }} {{ history.created_at }}</i>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" v-else>Nenhum Historico Encontrado</div>

        <!-- Button trigger modal -->
        <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#ModalComents"
        >Adicionar Comentario</button>
      </div>

      <!-- Modal Comentarios -->
      <div
        class="modal fade show bd-example-modal-lg"
        id="ModalComents"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="ModalLongTitle">Comentarios</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <label for="title">Titulo</label>
                  <input type="text" v-model="comments.title" class="form-control" id="title" />
                </div>
              </div>
              <br />
              <div class="col-md-12">
                <ckeditor :editor="editor" v-model="comments.description" :config="editorConfig"></ckeditor>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              <button type="button" class="btn btn-primary" @click="saveComments()" data-dismiss="modal" >Concluir</button>
            </div>
          </div>
        </div>
      </div>

      <!-- </div> -->

      <!-- Anexos -->
      <div role="tabpanel" class="tab-pane fade show" id="five">
        <div class="card-body">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Nome do Arquivo</th>
                <th scope="col">Download</th>
                <th scope="col">Anexo por</th>
                <th scope="col">Criado em</th>
              </tr>
            </thead>
            <tbody v-if="project.attachment.length > 0">
              <tr v-for="attachm in project.attachment" :key="attachm.id">
                <td>{{ attachm.title }}</td>
                <td>
                  <button
                    type="button"
                    @click="downloadAttachment(attachm.id)"
                    class="btn btn-link"
                    data-tooltip="tooltip"
                    title="Download"
                  >{{ attachm.attachment }}</button>
                </td>
                <td>{{ attachm.user.name }}</td>
                <td scope="col">{{ attachm.created_at }}</td>
              </tr>
            </tbody>
          </table>
          <button
            type="button"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#ModalAttachments"
          >Anexos</button>
        </div>
        <hr />

        <!-- Modal Anexos -->
        <div
          class="modal fade bd-example-modal-lg"
          id="ModalAttachments"
          tabindex="-1"
          role="dialog"
          aria-labelledby="ModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="ModalLongTitle">Anexos</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <label for="title">Titulo</label>
                    <input type="text" v-model="comments.title" class="form-control" id="title" />
                  </div>
                </div>
                <br />
                <label for="formControlFile">Adicionar Anexos</label>
                <input type="file" class="form-control-file" id="attachment" />
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" @click="saveAttachments()" data-dismiss="modal" >Concluir</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---->
    <div class="block text-right">
      <router-link to="/projects" class="btn btn-info">
        <i class="fa fa-arrow-left"></i> Voltar
      </router-link>
    </div>
  </div>
</template>

<script>

import IssuesPerStatus from "../Charts/IssuesPerStatus";
import IssuesPerStatusPercent from "../Charts/IssuesPerStatusPercent";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: {
    IssuesPerStatus,
    IssuesPerStatusPercent
  },
  computed: {
    url() {
      return (
        this.$store.getters.api + "issues/?project_id=" + this.$route.params.id
      );
    }
  },
  data() {
    let self = this;
    return {
      editor: ClassicEditor,
      editorData: "<p>Hello World ...</p>",
      editorConfig: {},
      activeChart: false,
      chartDataStatus: {
        labels: [],
        series: []
      },
      chartDataPercent: {
        labels: [],
        series: []
      },
      project: {
        name: "",
        qas: [
          {
            user_qa: {
              id: 0,
              name: ""
            },
            deploy_status: ""
          }
        ],
        history: [
          {
            user: {
              id: 0,
              name: ""
              // created_at: ""
            }
          }
        ],
        attachment: [
          {
            user: {
              id: 0,
              name: ""
            }
          }
        ],
        history_deploy: [
          {
            description: ""
          }
        ],
        user: {
          name: ""
        },
        sprint: {
          name: ""
        },
        user_qa: {
          name: ""
        },
        tickets: [],
        sprint_id: "",
        description: "",
        origin: [
          {
            id: "1",
            name: "Projeto"
          },
          {
            id: "2",
            name: "Chamado"
          }
        ],
        observation: ""
      },
      comments: {
        title: "",
        description: ""
      },

      table: "IssuesTable",
      columns: ["id", "summary", "story_points", "status", "assignee"],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filable: ["id", "summary", "project_status_id"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        // headings: {
        //     'id': 'ID',
        //     'project_status_id' : 'Status',
        //     'name' : 'Nome',
        //     'email' : 'E-mail',
        //     'actions' : 'Ações'
        // },
        // templates: {

        //     project_status_id: function (h, row, index) {
        //         return row.status != undefined ? row.status.name : '';
        //     }
        // },
        requestFunction: function(data) {
          let requestData = {};

          let query = "";

          let url = this.url;
          if (query != "") {
            url = url + "&" + query;
          }
          requestData.params = data;
          requestData.params.query = "";

          return this.$http.get(url, requestData).catch(
            function(e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        }
      }
    };
  },

  mounted() {
    const self = this;
    self.getProjectsContent();
  },
  methods: {

    query: function(query) {
      let columns = {
        id: "id",
        summary: "summary"
      };
      let filters = "";
      $.each(query, function(index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    downloadLink(id) {
      window.open(
        this.$store.state.api + "attachments/download/" + id,
        "_blank"
      );
    },
    downloadAttachment(id) {
      window.open(
        this.$store.state.api + "attachment/download/" + id,
        "_blank"
      );
    },
    saveComments: function() {
      let api = this.$store.state.api + "projecthistories";
      const self = this;

      let form = new FormData();

      form.append("user_id", self.$store.getters.user.id);
      form.append("project_id", self.project.id);
      form.append("title", self.comments.title);
      form.append("description", self.comments.description);

      self.$http
        .post(api, form)
        .then(response => {
          self.$message("Sucesso", `Cadastrado com sucesso`, "success");
          $("#ModalComents").hide();
          self.getProjectsContent();
        })
        .catch(error => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function(key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
    },
    saveAttachments: function() {
      let api = this.$store.state.api + "projectattachemnts";
      const self = this;

      let form = new FormData();

      form.append("user_id", self.$store.getters.user.id);
      form.append("project_id", self.project.id);
      form.append("title", self.comments.title);

      let attachment = document.getElementById("attachment").files[0];
      if (attachment) {
        form.append("attachment", attachment);
      }

      self.$http
        .post(api, form)
        .then(response => {
          self.$message("Sucesso", `Cadastrado com sucesso`, "success");
          $("#ModalAttachments").hide();
          self.getProjectsContent();
        })
        .catch(error => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function(key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
    },
  getProjectsContent: function () {
    let self = this;

    self.$http
      .get(self.$store.getters.api + "projects/" + self.$route.params.id, {
        params: {
          // with: "issues"
          with: ["qas", "history", "attachment"]
        }
      })
      .then(response => {
        self.project = response.data.data[0];
      });

    self.$http
      .get(
        self.$store.getters.api +
          "analytics/issues-per-status/" +
          self.$route.params.id
      )
      .then(response => {
        self.chartDataStatus.labels = response.data.labels;
        self.chartDataStatus.series = [response.data.series];
        self.chartDataPercent = response.data;
      })
      .then(() => {
        self.activeChart = true;
      });
  }
  }
};
</script>

<style scoped >
.thumbnail {
  padding: 0px;
}
.panel {
  position: relative;
}
.panel > .panel-heading:after,
.panel > .panel-heading:before {
  position: absolute;
  top: 11px;
  left: -16px;
  right: 100%;
  width: 0;
  height: 0;
  display: block;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
  pointer-events: none;
}
.panel > .panel-heading:after {
  border-width: 7px;
  border-right-color: #f7f7f7;
  margin-top: 1px;
  margin-left: 2px;
}
.panel > .panel-heading:before {
  border-right-color: #ddd;
  border-width: 8px;
}
</style>
