<template>
  <div>
    <base-crud
      crudName="Innovare TI - Contratos"
      newText="Novo Registro"
      :table="projectTable"
      :columns="columns"
      :options="options"
      :endPoint="endPoint"
      :enableAdd="true"
      :enableEdit="true"
      :enableDelete="true"
      :enableView="true"
      :extra="extra"
      @manage="manage"
    ></base-crud>
  </div>
</template>

<script>
import BaseCrud from "../Base/BaseCrud";


export default {
  data: function() {
    let self = this;
    return {
      extra: [
        {
          id: 1,
          label: "",
          class: "btn btn-crud edit fa fa-cog",
          title: "Gerenciar",
          name: "manage"
        }
      ],
      projectstatuses: [],
      dataForm: {},
      projectTable: "projectTable",
      users: [],
      usercustomers: [],
      typeOfContracts: [],
      pages: [],
      url: "",
      columns: [
        "id",
        "name",
        "contract_id",
        "start_date",
        "end_date",
        "userTech_id",
        "userComm_id",
        "active",
        "actions",
      ],
      tableData: ["id"],
      debounce: 800,
      options: {
        filterByColumn: true,
        filterable: [
          "id",
          "name",
          "contract_id",
          "start_date",
          "end_date",
          "userTech_id",
          "userComm_id",
          "active",
        ],
        sortable: [
          "id",
          "name",
          "contract_id",
          "start_date",
          "end_date",
          "userTech_id",
          "userComm_id",
          "active",
        ],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          name: "Nome do Usuario",
          contract_id: "Tipo de Contrato",
          start_date: "Data Inicio",
          end_date: "Data Final",
          userTech_id: "Resp. Tecnico",
          userComm_id: "Resp. Comercial",
          active: "Status",
          actions: "Ações"
        },
        templates: {
          contract_id: function(h, row, index) {
            return row.typeofcontracts
              ? row.typeofcontracts.title
              : "Nenhum Registro";
          },
          userTech_id: function(h, row, index) {
            return row.userstech ? row.userstech.name : "Nenhum Registro";
          },
          userComm_id: function(h, row, index) {
            return row.userscomm ? row.userscomm.name : "Nenhum Registro";
          },
          active: function(h, row, index) {
            return row.active ? "Ativo" : "Não Ativo"
          },
        },
        requestFunction: function(data) {
          let requestData = {};

          let query = this.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = [
            "userstech",
            "userscomm",
            "typeofcontracts"
          ];

          return this.$http.get(this.url + "?" + query, requestData).catch(
            function(e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        }
      },
      endPoint: "contracts/"
    };
  },
  methods: {
    manage(data) {
      this.$router.push({ path: "/contracts/manage/" + data.row.id });
    },
    query: function(query) {
      let columns = {
        id: "id",
        contract_id: "typeofcontracts[title]",
        userTech_id: "user[name]",
        userComm_id: "user[name]",
        active: "active"
      };
      let filters = "";
      $.each(query, function(index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    }
  },
  mounted() {
    let self = this;
    this.$http
      .get(this.$store.getters.api + "projectstatuses?paginated=false")
      .then(response => {
        self.projectstatuses = response.data.data;
      });
  },
  components: {
    BaseCrud
  }
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
</style>
