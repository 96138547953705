<template>
  <chartist
    class="ct-chart"
    ratio=""
    type="Pie"
    :data="chartData"
    :options="chartOptions"
  ></chartist>
</template>

<script>
export default {
  props: {
    chartData: {
      type: Object,
      default: null
    }    
  },
  data() {
    return {      
      chartOptions: {
        fullWidth: true,
        height: 250
      }
    };
  }
};
</script>
