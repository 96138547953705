<template>
  <div>
    <base-crud
      crudName="Chamados"
      newText="Novo Chamado"
      :table="table"
      :columns="columns"
      :options="options"
      :endPoint="endPoint"
      :enableAdd="true"
      :enableEdit="false"
      :enableDelete="true"
      :enableView="true"
      :dataForm="dataForm"
      :extra="extra"
      :ref="'crudTickets'"
      @open="openTap"
      @squad="squad"
    ></base-crud>

    <div>
      <div
        class="modal fade"
        id="squadModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="attachModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-sm" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="squadModalLabel">
                Squad Reponsável - {{ idModal.code }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div style="padding-left: 70px" class="col-md-8">
                <div class="form-group">
                  <label for="sprint_id">Squad Atuante:</label>
                  <v-select
                    :options="squads"
                    label="name"
                    id="squads"
                    :value="ticket.squad"
                    :reduce="(squads) => squads.name"
                    v-model="ticket.squad"
                  >
                    <div slot="no-options">Carregando...</div>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                @click="assignSquad()"
              >
                Salvar
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                @click="clean()"
              >
                <i class="fa fa-times"></i> Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../Base/BaseCrud";
import vSelect from "vue-select";


export default {
  data: function () {
    return {
      ticket: {
        squad: "",
      },
      idModal: {},
      squads: [
        {
          id: 1,
          name: "Time Fullstack",
        },
        {
          id: 2,
          name: "Time Fluig",
        },
        {
          id: 3,
          name: "Comercial",
        },
      ],
      assigneds: [],
      assignedIds: [],
      table: "newTicketsTable",
      pages: [],
      url: "",
      columns: [
        "client_id",
        "sent_by",
        "code",
        "subject",
        "status_entity",
        "created_at",
        "squad",
        "contract",
        "SLA",
        "last_interaction",
        "actions",
      ],
      tableData: ["id", "description"],
      options: {
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        filterByColumn: true,
        filterable: [
          "client_id",
          "sent_by",
          "code",
          "subject",
          "status_entity",
          "created_at",
          "squad",
          "contract",
        ],
        sortable: [
          "client_id",
          "sent_by",
          "code",
          "subject",
          "status_entity",
          "created_at",
          "squad",
        ],
        listColumns: {
          squads: [
            {
              id: 1,
              text: "Time Fullstack",
            },
            {
              id: 2,
              text: "Time Fluig",
            },
            {
              id: 3,
              text: "Comercial",
            },
          ],
          status_entity: [
            {
              id: 1,
              text: "Aberto",
            },
            {
              id: 2,
              text: "Em triagem",
            },
            {
              id: 12,
              text: "Na fila",
            },
            {
              id: 3,
              text: "Atendimento N1",
            },
            {
              id: 4,
              text: "Atendimento N2",
            },
            {
              id: 5,
              text: "Em QA - (Testes Internos)",
            },
            {
              id: 6,
              text: "Homologação Cliente",
            },
            {
              id: 7,
              text: "Aguardando GMUD",
            },
            {
              id: 8,
              text: "Em Acompanhamento (Produção)",
            },
            {
              id: 9,
              text: "Aguardando resposta do cliente",
            },
            {
              id: 10,
              text: "Em análise",
            },
            {
              id: 11,
              text: "Finalizado",
            },
            {
              id: 12,
              text: "Na Fila",
            },
            {
              id: 13,
              text: "Reaberto",
            },
            {
              id: 14,
              text: "Aguardando retorno TI Cliente",
            },
            {
              id: 15,
              text: "Aguardando retorno Área de negócio Cliente",
            },
            {
              id: 16,
              text: "Aguardando Chamado Terceiros",
            },
            {
              id: 17,
              text: "Apto à Finalizar",
            },
            {
              id: 20,
              text: "Atendimento N3",
            },
          ],
        },
        debounce: 800,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          client_id: "Cliente",
          sent_by: "Enviado por",
          code: "N. Chamado",
          subject: "Título",
          status_entity: "Status",
          created_at: "Aberto em",
          squad: "Time Responsável",
          contract: "Contrato",
          last_interaction: "Última Interação",
          actions: "Ações",
        },
        sortable: ["id", "priority"],
        templates: {
          created_at: function (h, row, index) {
            return moment(row.created_at).format("DD/MM/YYYY HH:mm:ss");
          },
          client_id: function (h, row, index) {
            return row.sent_by
              ? row.sent_by.customers.customer_name
              : "Nenhum Registro";
          },
          squad: function (h, row, index) {
            if (row.squad == "1") {
              return (
                <h5>
                  <span>Time Fullstack</span>
                </h5>
              );
            }

            if (row.squad == "2") {
              return (
                <h5>
                  <span>Time Fluig</span>
                </h5>
              );
            }

            if (row.squad == "3") {
              return (
                <h5>
                  <span>Comercial</span>
                </h5>
              );
            }
          },
          status_entity: function (h, row, index) {
            if (row.status == "1") {
              return (
                <h5>
                  <span
                    style="background-color:#ce3a49; color:#fff;"
                    class="badge badge"
                  >
                    Aberto
                  </span>
                </h5>
              );
            }

            if (row.status == "2") {
              return (
                <h5>
                  <span
                    style="background-color:#429550cc; color:#fff;"
                    class="badge badge"
                  >
                    Em triagem
                  </span>
                </h5>
              );
            }
            if (row.status == "12") {
              return (
                <h5>
                  <span
                    style="background-color:#c5c73c; color:#fff;"
                    class="badge badge"
                  >
                    Na Fila
                  </span>
                </h5>
              );
            }
            if (row.status == "3") {
              return (
                <h5>
                  <span
                    style="background-color:#c2289bb4; color:#fff;"
                    class="badge badge"
                  >
                    Atendimento N1
                  </span>
                </h5>
              );
            }
            if (row.status == "4") {
              return (
                <h5>
                  <span
                    style="background-color:#b634d6b4  ; color:#fff;"
                    class="badge badge"
                  >
                    Atendimento N2
                  </span>
                </h5>
              );
            }
            if (row.status == "5") {
              return (
                <h5>
                  <span
                    style="background-color:#429550cc  ; color:#fff;"
                    class="badge badge"
                  >
                    Em QA (testes internos)
                  </span>
                </h5>
              );
            }
            if (row.status == "6") {
              return (
                <h5>
                  <span
                    style="background-color:#35be4ccc  ; color:#fff;"
                    class="badge badge"
                  >
                    Homologação Cliente
                  </span>
                </h5>
              );
            }
            if (row.status == "7") {
              return (
                <h5>
                  <span
                    style="background-color:#0aeaf1cc  ; color:#fff;"
                    class="badge badge"
                  >
                    Aguardando GMUD
                  </span>
                </h5>
              );
            }
            if (row.status == "8") {
              return (
                <h5>
                  <span
                    style="background-color:#00ff6244  ; color:#fff;"
                    class="badge badge"
                  >
                    Em Acompanhamento (Produção)
                  </span>
                </h5>
              );
            }
            if (row.status == "9") {
              return (
                <h5>
                  <span
                    style="background-color:#e2c543cc  ; color:#fff;"
                    class="badge badge"
                  >
                    Aguardando resposta do cliente
                  </span>
                </h5>
              );
            }
            if (row.status == "10") {
              return (
                <h5>
                  <span
                    style="background-color:#f0f0f0  ; color:#000;"
                    class="badge badge"
                  >
                    Em análise
                  </span>
                </h5>
              );
            }
            if (row.status == "13") {
              return (
                <h5>
                  <span
                    style="background-color:#ff00009b  ; color:#fff;"
                    class="badge badge"
                  >
                    Reaberto
                  </span>
                </h5>
              );
            }
            if (row.status == "14") {
              return (
                <h5>
                  <span
                    style="background-color:#ffa07a  ; color:#fff;"
                    class="badge badge"
                  >
                    Aguardando retorno TI Cliente
                  </span>
                </h5>
              );
            }
            if (row.status == "15") {
              return (
                <h5>
                  <span
                    style="background-color:#cd5c5c  ; color:#fff;"
                    class="badge badge"
                  >
                    Aguardando retorno Área de negócio Cliente
                  </span>
                </h5>
              );
            }
            if (row.status == "16") {
              return (
                <h5>
                  <span
                    style="background-color:#cd9c5c  ; color:#fff;"
                    class="badge badge"
                  >
                    Aguardando Chamado Terceiros
                  </span>
                </h5>
              );
            }
            if (row.status == "17") {
              return (
                <h5>
                  <span
                    style="background-color:#bb2c5c  ; color:#fff;"
                    class="badge badge"
                  >
                    Apto à Finalizar
                  </span>
                </h5>
              );
            }
          },
          sent_by: function (h, row, index) {
            return row.sent_by ? row.sent_by.name : "Nenhum Registro";
          },
          contract: function (h, row, index) {
            return row.contract ? row.contract.name : "Sem Informação";
          },
          squad: function (h, row, index) {
            return row.squad ? row.squad : "Não Atribuido";
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = [
            "sent_by",
            "status_entity",
            "sent.customers",
            "contract",
            "squad",
          ];
         
          return this.$http.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      extra: [
        {
          id: 1,
          title: "Abrir em outra aba",
          class: "btn btn-crud check fa fa-arrow-up",
          name: "open",
          label: "",
        },
        {
          id: 2,
          label: "",
          class: "btn btn-crud edit fa fa-users",
          title: "Setar Squad?",
          name: "squad",
          meta: {},
        },
      ],
      endPoint: "new_tickets/",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
    vSelect,
  },
  methods: {
    squad(props, row, index) {
      this.idModal = props.row;
      $("#squadModal").modal("show");
      const self = this;
      self.$http
        .get(self.$store.getters.api + "new_tickets/" + props.row.id)
        .then((response) => {
          self.ticket = response.data.data;
          console.log((self.ticket = response.data.data));
        });
    },
    // getTicket() {
    //   const self = this;
    //   self.$http
    //     .get(self.$store.getters.api + "ticket_assign_squad/")
    //     .then((response) => {
    //       self.ticket = response.data.data;
    //       console.log((self.ticket = response.data.data));
    //     });
    // },
    assignSquad: function () {
      const self = this;

      if (this.ticket.squad == "" || this.ticket.squad == null) {
        swal("Erro", "Selecione um Squad Responsável para Salvar!", "info");
      } else {
        let api = self.$store.state.api + "ticket_assign_squad/";

        swal({
          title: "Deseja Alterar o Squad reponsável?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Sim",
          showLoaderOnConfirm: true,
          buttons: {
            catch: {
              text: "Não",
              value: "cancel",
              className: "btn-danger",
            },
            confirm: {
              text: "Sim",
              value: "confirm",
            },
          },
        })
          .then((value) => {
            switch (value) {
              case "cancel":
                swal("Cancelado", "A operação não foi realizada", "info");
                break;

              case "confirm":
                this.idModal.squad = this.ticket.squad;

                self.$http.post(api, this.idModal).then((response) => {
                  self.$refs.crudTickets.$refs.table.refresh();
                });
                break;
            }
          })
          .catch((error) => {
            self.$message(null, error.response.data, "error");
          });
      }
      $("#squadModal").modal("hide");
    },
    openTap(props) {
      open("/new_tickets/view/" + props.row.id);
    },
    clean() {
      this.ticket.squad = "";
    },
    query: function (query) {
      let columns = {
        id: "id",
        client_id: "sent.customers[customer_name]",
        sent_by: "sent.customers[name]",
        code: "code",
        subject: "subject",
        status_entity: "status",
        squad: "squad",
        created_at: "created_at",
        contract: "contract[name]",
      };
      let filters = "orderByDesc=id&closed=0&";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
  mounted: function () {
    let self = this;
    this.user = this.$store.getters.user;

    $("#squadModal").modal("hide", function (e) {
      self.clean();
    });
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.center {
  text-align: center;
}
.badge-warning {
  color: #fff;
  background-color: #e2c543cc !important;
}
</style>
