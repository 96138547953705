<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">Novo Registro</div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <label for="title">Titulo</label>
          <input type="text" class="form-control" id="title" v-model="wikis.title" />
        </div>
        <div class="col-md-3">
          <label for="title">Imagens</label>
          <input type="file" class="form-control" id="photo_" v-on="wikis.imagem" />
        </div>
        <div class="col-md-3">
          <label for="title">Categoria</label>
          <v-select
            id="category"
            v-model="wikis.category"
            :options="['','Correção de Bugs', 'Configurações', 'Códigos Reutilizáveis', 'Melhorias em Código']"
            placeholder="Selecione uma categoria"
          ></v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label for>Detalhes</label>
          <ckeditor :editor="editor" v-model="wikis.description" :config="editorConfig"></ckeditor>
        </div>
      </div>
      <br />

      <div class="row">
        <div class="col-md-12">
          <label for>Tags</label>
          <v-select taggable multiple label="title" autofocus="true" :options="wikis.get_wiki_tags"
            v-model="wikis.get_wiki_tags">
            <div slot="no-options">Pressione Enter para inserir múltiplas tags</div>
          </v-select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <template>
              <button @click="$router.go(-1)" class="btn btn-info">
                <i class="fa fa-arrow-left"></i> Voltar
              </button>
            </template>
            <a class="btn btn-success" @click="save">
              Salvar
              <i class="fa fa-save"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import vSelect from "vue-select";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


// import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    // Update the loader's progress.
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    // Reject the promise returned from the upload() method.
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    // Note that your request may look different. It is up to you and your editor
    // integration to choose the right communication channel. This example uses
    // a POST request with JSON as a data structure but your configuration
    // could be different.
    xhr.open(
      "POST",
      "https://api.projetos.innovareti.com.br/api/v1/images/upload/",
      true
    );
    xhr.responseType = "json";
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      resolve({
        default: response.url
      });
    });

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener("progress", evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    // Prepare the form data.
    const data = new FormData();

    data.append("upload", file);

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, you can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data);
  }
}

function MyUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
    return new MyUploadAdapter(loader);
  };
}

export default {
  components: {
    vSelect
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: "<p>Hello World ...</p>",
      editorConfig: {
        extraPlugins: [MyUploadAdapterPlugin]
      },
      wikis: {
        title: "",
        description: "",
        category:"",
        imagem:"",
        tags: ""
      }
    };
  },
  computed: {
    url() {
      return (
        this.$store.getters.api + "issues/?project_id=" + this.$route.params.id
      );
    }
  },

  mounted() {
    let self = this;
    // this.user = this.$store.getters.user;
    let id = self.$route.params.id;

    if (id) {
      self.getWiki(id);
    }
  },
  methods: {
    save: function () {
      let api = this.$store.state.api + "wikis";
      const self = this;

      let form = new FormData();
      let photo = document.getElementById("photo_");

      // form.append("user_id", this.user.id);
      form.append("title", self.wikis.title);
      form.append("description", self.wikis.description);
      form.append("category", self.wikis.category);
      form.append("imagem", photo);

      self.wikis.get_wiki_tags.forEach(tag => {
        let content = null;
        if (typeof tag === "object") {
          content = tag.title;
        } else {
          content = tag;
        }
        form.append("tags[]", content);
      });

      if (self.wikis.id) {
        api += "/" + self.wikis.id;
        form.append("_method", "PUT");
      }

      self.$http
        .post(api, form)
        .then(response => {
          self.$message("Sucesso", `Cadastrado com sucesso`, "success");

          this.$router.push("/wikis");
        })
        .catch(error => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
    },

    getWiki: function (id) {
      let self = this;
      let api = self.$store.state.api + "wikis/" + id + "?with[]=GetWikiTags";

      self.$http
        .get(api)
        .then(response => {
          self.wikis = response.data.data[0];
        })
        .catch(error => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    }
  }
};
</script>

<style scoped>
.ck-content .image-style-side {
  max-width: 50%;
  float: right;
  margin-left: var(--ck-image-style-spacing);
}

.ck-content .image.image_resized {
  max-width: 100%;
}

.ck-content .image.image_resized {
  display: block;
  box-sizing: border-box;
}

.ck-content .image.image_resized img {
  width: 100%;
}

.ck-content .image.image_resized>figcaption {
  display: block;
}
</style>
