<template>
    <div>
        <base-crud
                crudName="Histórico de Solicitações"
                newText="Nova Solicitação"
                :table="table"
                :columns="columns"
                :options="options"
                :endPoint='endPoint'
                :enableAdd="false"
                :enableEdit="false"
                :enableDelete="false"
                :enableView="false"
                :dataForm="dataForm"
                :customFilters="customFilters"
                @filtered="filtered"
                :extra="extra"
                @view="view"
        >
        </base-crud>
    </div>
</template>

<script>
    import BaseCrud from '../Base/BaseCrud'
    import { debug } from 'util';

    export default {
        data: function () {
            return {
              customFilters: [
                {
                  name: "created_at",
                  type: "date",
                  call: "filtered"
                }
              ],
                table: 'developersTable',
                pages: [],
                url: '',
                columns: ['id', 'user_id', 'project_id', 'priority', 'created_at', 'status', 'actions'],
                tableData: [
                  'id',
                  'description'
                ],
                options: {
                    sortable: ['id', 'name'],
                    pagination: {chunk: 10, dropdown: false, nav: 'scroll' },
                    perPage: 10,
                    perPageValues: [10,25,50,100],
                    filterByColumn: true,
                    filterable: ['id', 'user_id', 'project_id', 'priority', 'created', 'status'],
                    headings: {
                        'id': 'ID',
                        'user_id' : 'Responsável',
                        'project_id' : 'Projeto',
                        'priority' : 'Prioridade',
                        'created_at' : 'Data Solicitação',
                        'status' : 'Status',
                        'actions' : 'Ações'
                    },
                     texts: {
                    filterBy: "Filtrar",
                    defaultOption: "Selecione"
                    },
                    listColumns: {

                      status: [
                        {
                        id: 1,
                        text: "Aguardando Análise"
                        },
                        {
                        id: 2,
                        text: "QA Rejeitado"
                        },
                        {
                        id: 3,
                        text: "QA Aprovado"
                        },
                        {
                        id: 4,
                        text: "Deploy Realizado"
                        },
                      ]
                    },
                    templates: {
                        user_id: function (h, row, index) {
                            return row.user ? row.user.name : 'Sem Nome';
                        },
                        status: function (h, row, index) {
                            return row.deploy_status ? row.deploy_status.title : 'Sem Nome';
                        },
                        project_id: function (h, row, index) {
                            return row.project ? row.project.name : 'Sem Nome';
                        },
                        priority: function (h, row, index) {
                            if(row.priority == 'Alta Prioridade'){
                                return <h5><span class="badge badge-danger">Alta Prioridade</span></h5>
                            } else if(row.priority == 'Média Prioridade') {
                                return <h5><span class="badge badge-warning">Média Prioridade</span></h5>
                            } else {
                                return <h5><span class="badge badge-success">Baixa Prioridade</span></h5>
                            }
                        },
                    },
                    requestFunction: function (data) {
                      let requestData = {};

                      let query = this.$parent.$parent.query(data.query);

                      requestData.params = data;

                      if (
                        this.$parent.$parent.created_at != null &&
                        this.$parent.$parent.created_at != ""
                      ) {
                        requestData.params.created_at = this.$parent.$parent.created_at;
                      }

                      requestData.params.query = "";
                      requestData.params.with = [
                      'user', 'deployStatus', 'project'
                      ];

                      return this.$http.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                          this.dispatch("error", e);
                        }.bind(this)
                      );
                    },
                },
                extra: [
                    {
                        id: 1,
                        label: '',
                        class: 'btn btn-crud view fa fa-eye',
                        title: 'Visualizar',
                        name: 'view',
                    },
                ],

                endPoint: 'wfdeploys/',
                dataForm: {}
            }
        },
        components: {
            BaseCrud
        },
        methods: {
          filtered(date) {
              // console.log('aa')
              this.created_at = date;
            },
            query: function (query) {
                let columns = {
                    user_id: 'user[name]',
                    status: 'deploy_status[title]',
                    project_id: 'project[name]',
                };

                let filters = '';
                $.each(query, function (index, value) {
                    filters += columns[index] + '=' + value + '&';
                });
                return filters;
            },
            view: function (props, row, index){
                const self = this;
                this.$router.push('view/' + props.row.id);
            },
        }
    }

</script>

<style scoped>
    .VuePagination {
        text-align: center;
    }
</style>
