import {
  Line,
  mixins
} from 'vue-chartjs'
import annotation from 'chartjs-plugin-annotation'
const {
  reactiveProp
} = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  annotation: annotation,
  data: function () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        height: 200,
        legend: {
          display: true,
          labels: {
            fontColor: 'grey',
          },
          tooltips: {
            mode: 'index',
            intersect: true
          },
          annotation: {
            annotations: [{
              drawTime: 'afterDraw', // overrides annotation.drawTime if set
              id: 'a-line-1', // optional
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y-axis-0',
              value: '500',
              borderColor: 'black',
              borderWidth: 5,
            }]
          }

        }
      },
    }
  },
  mounted() {
    // this.chartData is created in the mixin
    this.renderChart(this.chartData, this.options)
  }
}
