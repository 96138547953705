import Index from '../components/Schedule/Index'

let routes = {
    path: '/schedule',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/schedule/',
            name: 'Agenda',
            component: Index,
        },
    ]
};


export default routes
