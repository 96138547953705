import Crud from '../components/Holiday/Crud'
import Entity from '../components/Holiday/Add'
// import Edit from '../components/Holiday/Edit'

let routes = {
    path: '/Daily',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/holidays/',
            name: 'Listar Registros',
            component: Crud,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/holidays/add',
            name: 'Novo Feriado',
            component: Entity,
        },
        {
            path: '/holidays/edit/:id',
            name: 'Visualizar Feriado',
            component: Entity,
            meta: {
                permission: 'is_admin'
            }
        }

    ]
};

export default routes;
