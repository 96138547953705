<template>
  <div>
    <div v-if="loaded">
      <!-- cards content -->
      <div class="row">
        <div class="col-md-12">
          <h5 class="header text-center">
            Contrato: {{ contract.name }} - ID: {{ contract.id }}
          </h5>
        </div>
      </div>
      <div class="card-group">
        <div class="card">
          <div class="card-body text-white bg-primary mb-3 text-center">
            <h5 class="header">Horas do Contrato</h5>
            <hr />
            <h3 class="card-text" v-if="contract.hoursPerProject">
              {{ contract.hoursPerProject }}
              <span v-if="contract.recurrent">*</span>
            </h3>
            <h3 class="card-text" v-else>
              00:00<span v-if="contract.recurrent">*</span>
            </h3>
          </div>
        </div>
        <div class="card">
          <div class="card-body text-white bg-success mb-3 text-center">
            <h5 class="header">Débito de Horas</h5>
            <hr />
            <h3 class="card-text" v-if="balance.debit">{{ balance.debit }}</h3>
            <h3 class="card-text" v-else>00:00</h3>
          </div>
        </div>
        <div class="card">
          <div class="card-body text-white bg-warning mb-3 text-center">
            <h5 class="header">Crédito de Horas</h5>
            <hr />
            <h3 class="card-text" v-if="balance.credit">
              {{ balance.credit }}
            </h3>
            <h3 class="card-text" v-else>00:00</h3>
          </div>
        </div>
        <div class="card">
          <div class="card-body text-white bg-info mb-3 text-center">
            <h5 class="header">Banco de horas</h5>
            <hr />
            <h3 class="card-text" v-if="balance.balance">
              {{ balance.balance }}
            </h3>
            <h3 class="card-text" v-else>00:00</h3>
          </div>
        </div>
      </div>
      <small>* Recorrente</small>
      <br />
      <!-- modal content-->
      <!--button-->
      <button
        type="button"
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#ModalCenter"
      >
        Adicionar Registro
      </button>

      <button type="button" class="btn btn-warning" @click="monthLaunch">
        Lançamento Mensal
      </button>

      <button type="button" class="btn btn-danger" @click="monthEnd">
        Fechamento o Mês
      </button>

      <button type="button" class="btn btn-info" @click="report">
        Relatório Mensal
      </button>

      <!--modal-->
      <div
        class="modal fade"
        id="ModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="ModalLongTitle">Novo Registro</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-row">
                  <div class="col">
                    <label>Origem</label>
                    <v-select
                      label="name"
                      v-model="contractmanagers.origin"
                      :options="['Chamado', 'Projetos', 'Contrato']"
                    ></v-select>
                  </div>
                  <div class="col">
                    <label>Tipo</label>
                    <v-select
                      label="name"
                      v-model="contractmanagers.operation"
                      :options="['Creditar', 'Debitar']"
                    ></v-select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col">
                    <label>Data Hora Inicio</label>
                    <date-time
                      v-model="contractmanagers.start_date"
                      format="YYYY-MM-DD HH:mm:ss"
                      formatted="DD/MM/YYYY HH:mm:ss"
                      output-format="YYYY-MM-DD HH:mm:ss"
                      :no-button-now="true"
                    >
                      <input
                        type="text"
                        class="form-control"
                        :value="contractmanagers.start_date"
                      />
                    </date-time>
                  </div>
                  <div class="col">
                    <label>Data Hora Final</label>
                    <date-time
                      v-model="contractmanagers.end_date"
                      format="YYYY-MM-DD HH:mm:ss"
                      formatted="DD/MM/YYYY HH:mm:ss"
                      output-format="YYYY-MM-DD HH:mm:ss"
                      :no-button-now="true"
                    >
                      <input
                        type="text"
                        class="form-control"
                        :value="contractmanagers.end_date"
                      />
                    </date-time>
                  </div>
                </div>
                <br />
                <div class="form-row">
                  <label>Total de horas</label>
                  <input
                    type="text"
                    v-mask="'###:##:##'"
                    class="form-control"
                    id="total-hours"
                    v-model="contractmanagers.total_hours"
                    placeholder="000:00:00 ou Vazio para automatico"
                  />
                  <span style="color: red; font-size: 10px"
                    >Calculo de horas considerando jornada de trabalho esta em
                    desenvolvimento.</span
                  >
                </div>
                <div class="form-row">
                  <label>Descrição</label>
                  <textarea
                    class="form-control"
                    v-model="contractmanagers.description"
                    rows="3"
                  ></textarea>
                </div>
                <br />
                <div class="form-row">
                  <label>N° de Controle</label>
                  <input
                    class="form-control"
                    v-model="contractmanagers.entity_id"
                    type="text"
                    placeholder="N° de Chamado, Cod. do Projeto, n° do Contrato, etc."
                  />
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Voltar
              </button>
              <button type="button" class="btn btn-primary" @click="save">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- base crud -->
      <base-crud
        :table="projectTable"
        :columns="columns"
        :options="options"
        :endPoint="endPoint"
        :enableAdd="false"
        :enableEdit="true"
        :enableDelete="true"
        :enableView="false"
      ></base-crud>
    </div>
    <div v-if="!loaded">
      <div class="row">
        <div class="col-md-12 text-center">
          <i class="fa fa-spinner fa-spin fa-5x"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../Base/BaseCrud";
import vSelect from "vue-select";

export default {
  components: {
    BaseCrud,
    vSelect,
  },
  data() {
    let self = this;
    return {
      loaded: false,
      contract: {},
      projectstatuses: [],
      dataForm: {},
      projectTable: "projectTable",
      users: [],
      contractmanagers: [],
      contracts: [],
      hoursContract: null,
      pages: [],
      url: "",
      balance: {},
      columns: [
        "id",
        "origin",
        "operation",
        "start_date",
        "end_date",
        "total_hours",
        "entity_id",
        "actions",
      ],
      tableData: ["id"],
      debounce: 800,
      options: {
        filterByColumn: true,
        filterable: [
          "id",
          "origin",
          "operation",
          "start_date",
          "end_date",
          "total_hours",
          "entity_id",
        ],
        sortable: [
          "id",
          "origin",
          "operation",
          "start_date",
          "end_date",
          "total_hours",
          "entity_id",
        ],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          origin: "Origem",
          operation: "Tipo",
          start_date: "Data Inicio",
          end_date: "Data Fim",
          total_hours: "Total horas",
          entity_id: "Controle",
          actions: "Ações",
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ["contracts"];

          return this.$http.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "contractmanagers/",
    };
  },
  mounted() {
    this.setup();
  },
  methods: {
    report() {
      this.$router.push("/contracts/report");
    },
    setup() {
      // const self = this;
      // let id = self.$route.params.id;
      // console.log(id);
      // const api = self.$store.state.api + "contractmanagers/consolidated/" + id;
      // console.log(api);
      // self.$http
      //   .get(api)
      //   .then(response => {
      //     self.contractmanagers = response.data.data;
      //   })
      //   .catch(error => {
      //     self.errors = error.response.data;
      //     self.$message(null, self.errors, "error");
      //   });

      this.getContract();
      this.getCurrentBalance();
    },
    getContract() {
      const self = this;
      let id = self.$route.params.id;

      const api = self.$store.state.api + "contracts/" + id;

      self.$http
        .get(api)
        .then((response) => {
          self.contract = response.data.data[0];
        })
        .then(() => {
          self.loaded = true;
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
    getCurrentBalance() {
      const self = this;
      let id = self.$route.params.id;

      const api =
        self.$store.state.api + "contractmanagers/current-balance/" + id;

      self.$http
        .get(api)
        .then((response) => {
          self.balance = response.data;
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
    monthEnd() {
      this.$router.push("/contracts/manage/month-end/" + this.$route.params.id);
    },
    monthLaunch() {
      this.$router.push(
        "/contracts/manage/month-launch/" + this.$route.params.id
      );
    },
    save: function () {
      const self = this;
      const api = self.$store.state.api + "contractmanagers";

      let form = new FormData();

      form.append("contract_id", self.$route.params.id);
      form.append("operation", self.contractmanagers.operation);
      form.append("origin", self.contractmanagers.origin);
      form.append("start_date", self.contractmanagers.start_date);
      form.append("end_date", self.contractmanagers.end_date);
      form.append("total_hours", self.contractmanagers.total_hours);
      form.append("description", self.contractmanagers.description);
      form.append("entity_id", self.contractmanagers.entity_id);

      self.$http
        .post(api, form)
        .then((response) => {
          self.$message("Sucesso", `Cadastrado com Sucesso`, "success");
          $("#ModalCenter").hide();
          // this.$router.push({ path: "/contracts/manage/" + data.row.id });
          location.reload();
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });
            html += "</ul>";
            self.errors = html;
          }
          self.$message(null, self.errors, "error");
        });
    },
    query: function (query) {
      let columns = {};
      let filters = "contract_id=" + this.$route.params.id;
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
  getUsers: function (id) {
    let self = this;
    let api = self.$store.state.api + "contractmanagers/consolidated/" + id;

    self.$http
      .get(api)
      .then((response) => {
        self.contractmanagers = response.data.data;
      })
      .catch((error) => {
        self.errors = error.response.data;
        self.$message(null, self.errors, "error");
      });
  },
  getConsolidated: function (id) {
    const self = this;
    const api = self.$store.state.api + "contractmanagers/consolidated/" + id;
    debugger;
    self.$http
      .get(api)
      .then((response) => {
        self.contractmanagers = response.data.data;
      })
      .catch((error) => {
        self.errors = error.response.data;
        self.$message(null, self.errors, "error");
      });
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.card-body {
  border-radius: 4px;
  max-width: 18rem;
}
</style>
