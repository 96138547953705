<template>
    <div>
        <div class="panel animated fadeInRightBig">
            <div class="panel-heading">
                <div class="card-header">
                    <div class="card-title">
                        Deploy - Análise
                    </div>
                </div>
            </div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-12">
                    <div class="card mt-3 tab-card">
                        <div class="card-header2 tab-card-header">
                        <ul class="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="One" aria-selected="true">Detalhes da Solicitação</a>
                            </li>
                            <li class="nav-item" v-show="wfdeploy.origin == 2">
                                <a class="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="Two" aria-selected="false">Chamado</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="Three" aria-selected="false">Arquivos</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="four-tab" data-toggle="tab" href="#four" role="tab" aria-controls="Four" aria-selected="false">Finalizar Deploy</a>
                            </li>
                        </ul>
                        </div>

                        <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="one-tab">
                            <div class="row">
                                <div class="col-md-3">
                                    <h5 class="card-title">{{ wfdeploy.project.name }}</h5>
                                    <p class="card-text" v-if="wfdeploy.sprint_id != null">{{ wfdeploy.sprint.name }}</p>
                                </div>
                                <div class="col-md-3">
                                    <h5 class="card-title">Origem da Solicitação</h5>
                                    <p class="card-text" v-if="wfdeploy.origin == 1">Projeto</p>
                                    <p class="card-text" v-if="wfdeploy.origin == 2">Chamado</p>
                                </div>
                                <div class="col-md-5">
                                    <h5 class="card-title">Prioridade</h5>
                                    <h6 class="card-text"><span v-bind:class="[wfdeploy.priority == 'Alta Prioridade' ? 'badge badge-danger' : [wfdeploy.priority == 'Média Prioridade' ? 'badge badge-warning' : 'badge badge-success']]">{{ wfdeploy.priority }}</span></h6>
                                    <p class="card-text" v-if="wfdeploy.priority == 'Alta Prioridade'">{{ wfdeploy.justify }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <br>
                                    <h5 class="card-title">Tipo do Projeto</h5>
                                    <p class="card-text">{{ wfdeploy.type_project }}</p>
                                </div>
                                <div class="col-md-3">
                                    <br>
                                    <h5 class="card-title">Solicitante</h5>
                                    <p class="card-text">{{ wfdeploy.user.name }}</p>
                                </div>
                                <div class="col-md-3">
                                    <br>
                                    <h5 class="card-title">Criado em</h5>
                                    <p class="card-text">{{ wfdeploy.created_at }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div v-if="wfdeploy.user_qa" class="col-md-3">
                                    <br>
                                    <h5 class="card-title">Responsáevl pelo QA</h5>
                                    <p class="card-text">{{ wfdeploy.user_qa.name }}</p>
                                </div>
                                <div class="col-md-3" v-if="wfdeploy.status != 8 && wfdeploy.status != 7">
                                    <br>
                                    <h5 class="card-title">Data de Aprovação</h5>
                                    <p class="card-text">{{ wfdeploy.moderated_at }}</p>
                                </div>
                                <div class="col-md-3">
                                    <br>
                                    <h5 class="card-title">Deploy autorizado por:</h5>
                                    <p class="card-text">{{ wfdeploy.deploy_realese.name}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <br><br>
                                    <h5 class="card-title">Descrição</h5>
                                    <p class="card-text">{{ wfdeploy.description }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade p-3" id="two" role="tabpanel" aria-labelledby="two-tab" v-if="wfdeploy.origin == 2">
                            <h5 class="card-title">Chamados relacionados a esta solicitação:</h5><br>
                            <h5><span v-for="ticket in wfdeploy.tickets" :key="ticket.id" class="badge badge-info spacing">{{ticket.ticket_id }}</span></h5>
                        </div>
                        <div class="tab-pane fade p-3" id="three" role="tabpanel" aria-labelledby="three-tab">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5 class="card-title">Documentos de QA</h5><br>
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nome do Arquivo</th>
                                                <th scope="col">Etapa</th>
                                                <th scope="col">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="attach in filteredQA" :key="attach.id">
                                                <td>{{ attach.attachment }}</td>
                                                <td>{{ attach.step }}</td>
                                                <td>
                                                    <button type="button"  @click="downloadLink(attach.id)" class="btn btn-crud download" data-tooltip="tooltip" title="Download"><i class="fas fa-download"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-6">
                                    <h5 class="card-title">Anexos</h5><br>
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nome do Arquivo</th>
                                                <th scope="col">Etapa</th>
                                                <th scope="col">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="attach in filteredAttachs" :key="attach.id">
                                                <td>{{ attach.filename }}</td>
                                                <td>{{ attach.step }}</td>
                                                <td>
                                                    <button type="button"  @click="downloadLink(attach.id)" class="btn btn-crud download" data-tooltip="tooltip" title="Download"><i class="fas fa-download"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            </div>

                            <div class="tab-pane fade p-3" id="four" role="tabpanel" aria-labelledby="four-tab">
                                <h5 class="card-title">Considerações sobre o deploy:</h5><br>

                                <div class="row">
                                    <div class="col-md-12">
                                        <label for="priority"><span class="required">*</span> Descritivo:</label>
                                        <textarea class="form-control" v-model="wfdeploy.observation"> </textarea><br>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="attachments"><span class="required">*</span> Anexos:</label>
                                        <input type="file" name="attachments" id="attachments" multiple>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2"><br>
                                        <div class="buttons">
                                            <a  class="btn btn-alt float-left" @click="save(3)">Finalizar Deploy</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import vSelect from 'vue-select'

    export default {
        data() {
            return {
                wfdeploy: {
                    project: {
                        name:''
                    },
                    user: {
                        name:''
                    },
                    sprint: {
                        name:''
                    },
                    tickets: [],
                    sprint_id: '',
                    description: '',
                    origin:[
                        {
                            id: '1',
                            name: 'Projeto'
                        },
                        {
                            id: '2',
                            name: 'Chamado'
                        }
                    ],
                    observation:'',
                },

            }
        },
        computed: {
            filteredSprints: function(){
                return this.sprints.filter(x => x.project_id == this.wfdeploy.project_id);
            },
            filteredQA: function(){
                return this.wfdeploy.wf_attachments.filter(x => x.type == 'QADoc');
            },
            filteredAttachs: function(){
                return this.wfdeploy.wf_attachments.filter(x => x.type == 'Anexo');
            }
        },
        methods: {

            save: function (status) {

                const api = this.$store.state.api + 'wfdeploys/' + this.$route.params.id;
                const self = this;

                let form  = new FormData();

                let attachments = document.getElementById('attachments');

                console.log(this.user.id)

                form.append('_method', 'patch');

                form.append('observation', self.wfdeploy.observation);
                form.append('status', '6');
                form.append('status_deploy', '6');
                form.append('step', '3');


                form.append('attachments', attachments.files.length);
                for(var i=0; attachments.files.length > i; i++){
                    form.append('attachments'+i, (attachments.files[i]) ? attachments.files[i] : '');
                }

                self.$http.post(api, form)
                    .then((response) => {

                        self.$message('Sucesso', `Solicitação registrada com sucesso`, 'success');
                        this.$router.push('/wfdeploys/deploys');

                    })
                    .catch((error) => {
                        self.errors = error.response.data;

                        if(typeof self.errors === 'object'){
                            let html = '<ul>';

                            $.each(self.errors, function(key, value){
                                html += '<li>' + value[0] + '</li>';
                            });

                            html += '</ul>';

                            self.errors = html;
                        }

                        self.$message(null, self.errors, 'error');
                    });

            },

            getWFDeploy: function (id) {

                const self = this;
                const api = self.$store.state.api + 'wfdeploys/'+ id + '?with[]=project&with[]=sprint&with[]=user&with[]=tickets&with[]=wfAttachments&with[]=user_qa&with[]=deploy_realese';

                self.$http.get(api)
                    .then((response) => {
                        self.wfdeploy = response.data.data[0]
                    })
                    .catch((error) => {
                        self.$message(null, error.response.data, 'error');
                    });
            },

            downloadLink(id) {

                window.open(this.$store.state.api +"attachments/download/" + id, '_blank');

            },
        },
        mounted: function(){
            const self = this;

            let id = self.$route.params.id;
            if(id){
                self.getWFDeploy(id);
            }

            this.user = this.$store.getters.user;
        },
        components: {
            vSelect
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
