<template>
  <div>
    <issue-modal
      :modalMode="issueModal"
      :modalStatus="issueModalStatus"
      :selectedIssue="selectedIssue"
      @refresh="refresh"
    ></issue-modal>
    <div class="col-md-12">
      <div>
        <div class="card-header">
          <div class="card-title">Protótipo - Tasks diárias</div>
          <hr />
        </div>
        <div class="card-body">
          <div class="row">
            <div class="container">
              <div @click.prevent="">
                <h5 style="text-align: left;">
                  Hoje
                  <i class="fas fa-angle-down"></i>
                </h5>
              </div>
              <hr />
              <div>
                <div class>
                  <div class="col-md-12">
                    <div class="pointer" v-for="issue in issues" :key="issue.id">
                      <div class="inline letter">
                        <div>
                          <div v-if="issue.status != 'done'" @dblclick="cardDoubleClick(issue)">
                            <input
                              type="checkbox"
                              class="margin_right"
                              v-model="issue.checked"
                              @dblclick.stop
                              @change="checkCard(issue,$event)"
                            />
                            {{issue.summary}}
                            <br>
                            <small v-if="issue.project">{{issue.project ? issue.project.name : ''}} / {{issue.sprint ? issue.sprint.name : ''}} </small>
                          </div>
                          <div v-else @dblclick="cardDoubleClick(issue)" class="done">
                            <input
                              checked
                              type="checkbox"
                              class="margin_right"
                              v-model="issue.checked"
                              @dblclick.stop
                              @change="checkCard(issue,$event)"
                            />                            
                            {{issue.summary}} 
                            <br>
                            <small v-if="issue.project">{{issue.project ? issue.project.name : ''}} / {{issue.sprint ? issue.sprint.name : ''}} </small>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IssueModal from "./IssueModal";
export default {
  data() {
    return {
      selectedIssue: {},
      issueModal: "edit",
      issueModalStatus: false,
      issues: "",
      card: ""
    };
  },
  components: { IssueModal },
  methods: {
    getMyIssues() {
      let self = this;
      let api = this.$store.getters.api + "my-issues-day";
      this.$http
        .get(api)
        .then(response => {
          self.issues = response.data;
        })
        .catch(error => {
          self.$message(null, error.response.data, "error");
        });
    },
    refresh() {
      $("#issueModal").on("hidden.issueModal", function() {
        location.reload();
      });
    },
    checkCard(issue, $event) {
      if (issue.status != "done") {
        issue.status = "done";
        issue.checked = true;
      } else {
        issue.status = "todo";
        issue.checked = false;
      }
      let self = this;
      let api = this.$store.getters.api + "issues/" + issue.id;
      this.$http.put(api, { status: issue.status }).catch(error => {
        self.$message(null, error.response.data, "error");
      });
    },
    cardDoubleClick(card) {
      // card.cancel = true;
      this.selectedIssue = card;
      this.issueModal = "edit";
      this.issueModalStatus = true;
      $("#issueModal").modal("show");
    },
    refresh() {
      if ($("#issueModal").modal("hide")) {
        document.location.reload();
      }
    }
  },
  filters: {
    brDate(text) {
      if (text != "" && text != "null" && text != null) {
        // aaaa-mm-dd

        let date = text.split("-");

        return date[2] + "/" + date[1] + "/" + date[0];
      }

      return "";
    }
  },
  computed: {},
  mounted() {
    let self = this;

    self.getMyIssues();
  }
};
</script>

<style>
.pointer {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.margin_right {
  margin-right: 10px;
}
.letter {
  font-style: italic;
  font-weight: bold;
  font-size: 16px;
  font-family: arial, sans-serif;
}
.card-header {
  border-bottom: none;
}
.done {
  text-decoration: line-through;
}
@keyframes strike {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.strike {
  position: relative;
}
.strike::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: black;
  animation-name: strike;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid #555555;
  border-radius: 3px;
  background-color: white;
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
</style>
