<template>
   <div>
        <div class="panel animated fadeInRightBig">
            <div class="panel-heading">
                <div class="card-header">
                    <div class="card-title">
                        Novo Feriado
                    </div>
                </div>
            </div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                          <div class="col-md-4">
                              <div class="form-group">
                                  <label for="userName"><span class="required">*</span> Nome do Feriado:</label>
                                  <input type="text" class="form-control" v-model="holiday.name" placeholder=""/>
                              </div>
                          </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                  <label for="sprint_id"><span class="required">*</span> Tipo de Projeto:</label>
                                  <v-select :options="typeHolidays" label="name" id="typeHolidays" :reduce="typeHolidays => typeHolidays.name" v-model="holiday.type_holiday">
                                    <div slot="no-options">Carregando...</div>
                                  </v-select>
                                </div>
                            </div>
                          <div class="col-md-4">
                              <div class="form-group">
                                  <label><span class="required">*</span> Data do Feriado:</label>
                                  <input type="date" class="form-control" v-model="holiday.holiday_date"/>
                              </div>
                          </div>
                          </div>
                      </div>
                    <hr>
                    </div>
                    <hr>
                      <div class="block text-right">
                          <a class="btn btn-success" @click="save">
                              Salvar <i class="fa fa-save"></i>
                          </a>
                          <router-link to="/holidays" class="btn btn-info">
                              <i class="fa fa-arrow-left"></i>Voltar
                          </router-link>
                      </div>
                </div>
                <br>
            </div>
        </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
 components: {
    vSelect
},
data() {
  return {
    holiday: {
      name: '',
      type_holiday: '',
      holiday_date:''
    },
     typeHolidays:[
        {
            id: '1',
            name: 'Municipal'
        },
        {
            id: '2',
            name: 'Estadual'
        },
        {
            id: '3',
            name: 'Nacional'
        }
    ],
  }
},
methods: {
  save: function () {
      let api = this.$store.state.api + "holidays";
      const self = this;

      let form = new FormData();

      form.append('name', self.holiday.name);
      form.append('type_holiday', self.holiday.type_holiday);
      form.append('holiday_date', self.holiday.holiday_date);

      if (self.holiday.id) {
        api += "/" + self.holiday.id;
        form.append("_method", "PUT");
      }

      self.$http
        .post(api, form)
        .then((response) => {
          self.$message("Sucesso", `Cadastrado com Sucesso`, "success");
          this.$router.push("/holidays");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });
            html += "</ul>";
            self.errors = html;
          }
          self.$message(null, self.errors, "error");
        });
    },
    getHoliday() {
      let self = this;
      let id = self.$route.params.id;
      let api =
        self.$store.state.api + "holidays/" + id;

      self.$http
        .get(api)
        .then((response) => {
          self.holiday = response.data.data[0];
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
},
mounted() {
  let self = this;

   let id = self.$route.params.id;

    if (id) {
      self.getHoliday();
    }
},
}
</script>

<style scoped>
.required{
color: red;
}
</style>
