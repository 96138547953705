<template>
  <div>
    <div class="panel animated fadeInRightBig">
      <div class="panel-heading">
        <div class="panel-title">
          <span>
            <b>Editar Classificação</b>
            <i
              class="fa fa-user-circle"
              style="float: right; margin-top: 6px; font-size: 21px"
            ></i>
            <hr />
          </span>
        </div>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <span class="required">*</span>
              <label for="problem">Problema:</label>
              <input
                type="text"
                class="form-control"
                id="problem"
                v-model="classification.problem"
                autocomplete="false"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <span class="required">*</span>
              <label for="classification">Classificação:</label>
              <input
                type="text"
                class="form-control"
                id="classification"
                v-model="classification.classification"
                autocomplete="false"
              />
            </div>
          </div>
        </div>

        <div class="block text-right">
          <a class="btn btn-success" @click="save">
            Salvar
            <i class="fa fa-save"></i>
          </a>
          <router-link to="/tickets-classifications" class="btn btn-info">
            <i class="fa fa-arrow-left"></i> Voltar
          </router-link>
        </div>
        <div class="row">
          <div class="col-md-4">
            <b><span class="required">*</span> Campos Obrigatórios</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      classification: {
        problem: "",
        classification: "",
      },

      errors: undefined,
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;

      self.classification.id = self.$route.params.id;

      const api =
        this.$store.state.api + "classifications/" + self.classification.id;

      self.classification._method = "put";

      self.$http
        .post(api, self.classification)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Registro atualizado com sucesso!`,
            "success"
          );
          this.$router.push("/tickets-classifications");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
    },
    getEntity(id) {
      const api = this.$store.state.api + "classifications/" + id;
      const self = this;

      self.$http
        .get(api)
        .then((response) => {
          self.classification = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, "Não foi possível encontrar o produto", "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.getEntity(id);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
