import Index from '../components/Dashsup/Index'
import Consult from '../components/Dashsup/Consult'

let routes = {
    path: '/dashsup',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/dashsup',
            name: 'Chamados em Aberto',
            component: Index,
        },
        {
            path: '/consult-card',
            name: 'Gestão de Horas',
            component: Consult,
        },

    ]
}

export default routes
