import Vue from 'vue'
import Router from 'vue-router'

import { store } from '../main.js'

import Home from './home'
import Login from './login'
import User from './user'
import Profile from './profile'
import Project from './project'
import Sprint from './sprint'
import Status from './status'
import Ticket from './tickets'
import WFDeploys from './wfdeploys'
import Wiki from './wiki'
import Classifications from './classification'
import WikiClients from './wikiClients'
import Board from './board'
import Daily from './daily'
import Customer from './customer'
import UsersCustomer from './usersCustomer'
import typeOfContract from './typeOfContract'
import Contracts from './contracts'
import NewTickets from './new_ticket'
import TicketCategories from './ticketCategories'
import TicketStatus from './ticketStatus'
import Budgetings from './budgetings'
import InnoverApplications from './innoverApplications'
import Report from './report'
import Video from './video'
import ProjectsSprints from './project_sprints'
import Holiday from './holiday'
import TicketNotifications from './ticketNotifications'
import Schedule from './schedule'
import Backlog from './backlog'
import Dashsup from './dashsup'
import Process from './process'
import ProcessName from './process_names'
import ProcessArea from './process_areas'
import ReportUserCustomer from './reportUserCustomer'

Vue.use(Router);

const router = new Router({
    mode: 'history',
    linkActiveClass: "active",
    hashbang: false,
    routes: [
        Login,
        Home,
        User,
        Profile,
        Project,
        Sprint,
        Status,
        Ticket,
        WFDeploys,
        Wiki,
        WikiClients,
        Board,
        Daily,
        Customer,
        UsersCustomer,
        typeOfContract,
        Contracts,
        NewTickets,
        TicketCategories,
        TicketStatus,
        Budgetings,
        InnoverApplications,
        Report,
        Video,
        ProjectsSprints,
        Holiday,
        TicketNotifications,
        Schedule,
        Backlog,
        Dashsup,
        Process,
        ProcessName,
        ProcessArea,
        Classifications,
        ReportUserCustomer
    ]
});

router.beforeEach((to, from, next) => {
    const authenticated = localStorage.getItem('token');

    if (!authenticated && to.path.toString() != '/login') {
        next('/login');

        return true;
    }

    if (authenticated && to.path.toString() == '/login') {
        next('/');

        return true;
    }

    switch (to.meta.permission) {
        case 'is_dev':
            if (authenticated) next();
            else next('/login');
            break;

        case 'is_QA':
            if (localStorage.getItem("is_QA") == '1') next();
            else next('/login');
            break;

        case 'is_deploy':
            if (localStorage.getItem("is_deploy") == '1') next();
            else next('/login');
            break;

        default:
            next()
    }
});

router.afterEach((to, from) => {
    let _name = to.name;
    let _route = to.fullPath;

    if (_name === 'Home') {
        _name = '';
        _route = '';
    }

    store.commit("updateBreadCrumbs", { name: _name, route: _route })
});

export default router
