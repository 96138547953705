<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card" @click="$emit('click')">
        <div class="card-body">
          <div :class="class_creator(task)" v-if="task.details != true" key="front">
            <div v-if="count_issue_comments(task) != 0" class="position-comments" @click="cardComments">
              <span class="count-comments">
                <span>{{ count_issue_comments(task) }}</span>
              </span>
              <span class="icon-comments">
                <i class="fas fa-comment-dots" title="Visualizar comentário(s)"></i>
              </span>
            </div>
            <div v-else class="position-comments" @click="cardComments">
              <span class="icon-comments">
                <i class="fas fa-comment-medical" style="color: #2ea2cd;" title="Adicionar comentário(s)"></i>
              </span>
            </div>
            <div class="card-title" @click="cardEdit(task)">
               {{ "#" + task.id + "-" + task.summary }}
            </div>
            <div v-if="task.newticket_id" >
                <span class="badge badge-warning">
                  <i class="fas fa-ticket-alt" aria-hidden="true"></i>
                </span>
                  {{ task.newticket.sent_by.customers.customer_name }} - {{ task.newticket.sent_by.name }}
            </div> 
            <div class="project" v-if="task.project">
              <span>Projeto</span>{{ task.project.name }}
            </div>
            <div class="sprint" v-if="task.sprint">
              <span>Sprint</span>{{ task.sprint.name }}
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <img
                  v-if="task.user"
                  :src="task.user.photo"
                  alt="Profile Pic"
                  class="profile"
                />
                <div class="name" v-if="task.user">{{ task.user.name }}</div>
                <div class="name badge badge-danger" v-else>Sem responsável</div>
                <div class="date" v-if="task.due_date">
                  <i class="far fa-calendar-alt"></i
                  >{{ task.due_date | brDate }} - {{ task.criticality }}
                </div>
              </div>
              <div class="col-6">
                <div class="priority">
                  Horas:
                  <span class="points">{{ task.total_hours }}</span>
                </div>
                <div class="priority">
                  Pontos:
                  <span class="hours">{{ task.story_points }}</span>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-3">
                <button
                  @click="deleteIssue(task.id)"
                  class="btn btn-block btn-card delete"
                  data-toggle="popover"
                  data-content="Deletar"
                  data-original-title=""
                  title=""
                >
                  <i class="far fa-trash-alt"></i>
                </button>
              </div>
              <div class="col-3">
                <button
                  @click="cardEdit(task)"
                  class="btn btn-block btn-card edit"
                  data-toggle="popover"
                  data-content="Editar"
                  data-original-title=""
                  title=""
                >
                  <i class="far fa-edit"></i>
                </button>
              </div>
              <!-- <div class="col-3">
                  <button class="btn btn-block btn-card transfer" data-toggle="popover" data-content="Transferir" data-original-title="" title=""><i class="fas fa-exchange-alt"></i></button>
                </div> -->
              <div class="col-3">
                <button
                  @click="showTaskDetail(task)"
                  class="btn btn-block btn-card"
                  data-toggle="popover"
                  data-content="Virar"
                  data-original-title=""
                  title=""
                >
                  <i class="fas fa-info"></i>
                </button>
              </div>
              <div class="col-3">
                <button
                  @click="openTap(task.id)"
                  target="_blank"
                  class="btn btn-block btn-card btn-warning"
                  data-toggle="popover"
                  data-content="Fullscreen"
                  data-original-title=""
                  title=""
                >
                  <i class="fas fa-tv"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="back" v-if="task.details == true">
            <div class="card-title" @click="cardEdit(task)">
              {{ "#" + task.id + "-" + task.summary }}
            </div>
            <div class="descriptions" v-if="task.created_by">
              <span>Criado por</span>{{ task.created_by.name }}
            </div>
            <div class="descriptions" v-if="task.transfered_by">
              <span>Transferido por</span>{{ task.transfered_by.name }}
            </div>
            <div class="descriptions" v-if="task.created_at">
              <span>Criado em</span>{{ task.created_at | brDate }}
            </div>
            <div class="descriptions" v-if="task.updated_at">
              <span>Alterado em</span>{{ task.updated_at | brDate }}
            </div>
            <div class="descriptions" v-if="task.closed_at">
              <span>Finalizado em</span>{{ task.closed_at | brDate }}
            </div>
            <div class="row mt-2 justify-content-end">
              <div class="col-3">
                <button
                  @click="hideTaskDetail(task)"
                  class="btn btn-block btn-card"
                  data-toggle="popover"
                  data-content="Virar"
                  data-original-title=""
                  title=""
                >
                  <i class="fas fa-info"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Badge from "./Badge.vue";
import issueModal from "./IssueModal"
export default {
  components: {
    Badge,
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  methods: {
    openTap(id){
      let routeData = this.$router.resolve('/new-board/issue/' + id, this.index, this.column);
      window.open(routeData.href, '_blank');
    },
    count_issue_comments: function (task) {
      return task.count_issue_comments ? task.count_issue_comments : 0;
    },
    deleteIssue(id) {
      this.$emit("deleteIssue", id, this.index, this.column);
    },
    showTaskDetail(task) {
      task.details = true;
      this.$forceUpdate();
    },
    hideTaskDetail(task) {
      task.details = false;
      this.$forceUpdate();
    },
    cardEdit(task) {
      this.$emit("cardEdit", task);
    },
    class_creator: function (task) {
      return this.$store.state.user.id != task.user_id ? 'front-creator' : 'front';
    },
    cardComments() {
      this.$emit("cardComments", true);
    },
    getProjectSprint(task) {
      let text = "";

      text = task.project ? task.project.name : "";
      text += task.sprint ? " - " + task.sprint.name : "";

      return text;
    },
    badgeColor(criticality) {
      const mappings = {
        Low: "yellow",
        Normal: "green",
        High: "purple",
        Critical: "red",
        Design: "purple",
        "Feature Request": "teal",
        Backend: "blue",
        QA: "green",
        default: "teal",
      };
      return mappings[criticality] || mappings.default;
    },
  },
  filters: {
    brDate(text) {
      var data =
        text.substr(8, 2) + "/" + text.substr(5, 2) + "/" + text.substr(2, 2);
      return data;
    },
  },
};
</script>
<style scoped>
/* Style.css - Card IPP
---------------------------------------------------------
* Theme Name: Card IPP
* Author: Marcos Lima
* Version: 1.0.0
---------------------------------------------------------
*/

@charset "UTF-8";

.flip-enter-active,
.flip-leave-active {
  transform: rotateX(360deg);
  transition: transform 0.8s;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.flip-leave-to {
}

/* Main */
body {
  background: #fff;
  display: block;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  width: 100%;
  color: #333;
}

a {
  color: #2ea2cd;
}

a:hover {
  color: #2ea2cd;
  text-decoration: none;
}

.card {
  border-radius: 0.5rem;
  box-shadow: none;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  width: 100%;
  /* height: 170px; */
}

.card .card-body {
  padding: 0;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
}

/* .card:hover .card-body {
  transform: rotateX(180deg);
} */

/* #cardFullFlip .card .card-body.flipped {
  transform: rotateX(180deg);
} */

#cardFull .card {
  height: auto;
}

#cardFull .card .card-body {
  padding: 10px;
  transition: none;
}

#cardFull .card:hover .card-body,
#cardFullFlip .card:hover .card-body,
#cardFullFlip .card:hover .card-body.flipped {
  transform: none;
}

#cardFullFlip .card {
  height: 161px;
}

.front,
.front-creator,
.back {
  /* position: absolute; */
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  padding: 10px;
  cursor: pointer;
  user-select: none;
}

.front {
  background-color: #fff;
  border-radius: 0.5rem;
}

.front-creator {
  background-color: whitesmoke;
  border-radius: 0.5rem;
}

.back {
  background-color: #fff;
  border-radius: 0.5rem;
  /* transform: rotateX(180deg); */
}

.title {
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.profile {
  width: 33px;
  height: 33px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px dashed #333;
  float: left;
  margin-right: 10px;
}

.name {
  font-size: 12px;
  font-weight: 700;
}

.date {
  font-size: 10px;
}

.date i {
  margin-right: 5px;
}

.priority {
  font-size: 12px;
  float: right;
  margin-top: 5px;
}

.points {
  font-size: 20px;
  font-weight: 700;
  margin-left: 2px;
}

.hours {
  font-size: 20px;
  font-weight: 700;
  margin-right: 10px;
  margin-left: 2px;
}

.project,
.sprint {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
}

.project span,
.sprint span {
  font-size: 12px;
  font-weight: 700;
  width: 60px;
  display: inline-block;
}

.descriptions {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
}

.descriptions span {
  font-size: 12px;
  font-weight: 700;
  width: 90px;
  display: inline-block;
}

.btn-card {
  border: 2px solid;
  background-color: #fff;
  border-radius: 0.25rem;
  font-size: 12px;
  padding: 0;
  color: #333;
}

.btn-card:focus {
  outline: none;
  box-shadow: none;
}

.btn-card.edit {
  border-color: #337545;
}

.btn-card.delete {
  border-color: #de2218;
}

.btn-card.transfer {
  border-color: #f7cd34;
}

.btn-card.flip {
  border-color: #2ea2cd;
}

.btn-card.edit:hover {
  background-color: #337545;
  color: #fff;
}

.btn-card.delete:hover {
  background-color: #de2218;
  color: #fff;
}

.btn-card.transfer:hover {
  background-color: #f7cd34;
}

.btn-card.flip:hover {
  background-color: #2ea2cd;
  color: #fff;
}

.popover {
  font-size: 10px;
}

.card-title {
  user-select: none;
  cursor: pointer;
}

.count-comments {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.icon-comments {
  font-size: 18px;
  color: #28a745;
}

.position-comments {
  float: right;
  padding-left: 2px;
}

.count-comments {
  font-size: 18px; font-weight: bold; color: #333;
}

.icon-comments {
  font-size: 18px; color: #28a745;
}

.position-comments {
  float: right; padding-left: 2px;
}
</style>
