<template>
  <div>
      <div class="row">
          <div class="col-md-4">
              <label for="">Pesquisar</label>
              <input type="text" class="form-control" v-model="search">
              <button class="btn btn-primary" @click="searchContact">Pesquisar</button>
          </div>
      </div>
      <table class="table table-bordered">
          <thead>
              <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Telefone</th>
                  <th>E-mail</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="contact in contacts" :key="contact.id">
                  <td>{{contact.id}}</td>
                  <td>{{contact.name}}</td>
                  <td>{{contact.celphone}}</td>
                  <td>{{contact.email}}</td>
              </tr>
          </tbody>
      </table>
  </div>
</template>

<script>
export default {
    data () {
        return {
            contacts: [],
            search: ''
        }
    },
    mounted() {

        let self = this
        let api = this.$store.getters.api + 'contacts'

        self.$http.get(api)
        .then( response => {
            self.contacts = response.data
        })

    },
    methods: {
        searchContact(){
            
            let self = this
            let api = this.$store.getters.api + 'contacts?search=' + self.search

            self.$http.get(api)
            .then( response => {
                self.contacts = response.data
            })
        }
    }
}
</script>

<style>

</style>