<template>
  <div class="card">
    <div class="card-header animated fadeInRightBig" >
    
        <div class="card-title">Relatório de Chamados</div>      

        <div class="card-body">  
            
            <form @submit.prevent>

                <div class="row">
                    <div class="col-md-4">
                        <label for="customer_name"><span class="required">*</span> Cliente:</label>
                        <v-select :options="customers" label="name" id="customer_name" :reduce="customers => customers.id" v-model="customer_name">
                            <div slot="no-options">Carregando...</div>
                        </v-select>                            
                    </div>

                    <div class="col-md-4">
                        <label for="month"><span class="required">*</span> Mês:</label>
                        <v-select :options="months" label="name" id="month" :reduce="month => month.id" v-model="month">
                            <div slot="no-options">Carregando...</div>
                        </v-select>                            
                    </div>

                    <div class="col-md-4">
                        <label for="month"><span class="required">*</span> Ano:</label>
                        <input type="number" class="form-control" v-model="year">  
                    </div>
                </div>

                <hr>
                <button class="btn btn-primary" @click="generateReport">Gerar</button>

                <a href="" id="downloadReport" class="hidden" target="blank"></a>
            </form>

        </div>
    </div>
  </div>
    
</template>

<script>
import vSelect from 'vue-select'
export default {
    components: {vSelect},
    data() {
        return {
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            months: [
                { id: 1, name: 'Janeiro'},
                { id: 2, name: 'Fevereiro'},
                { id: 3, name: 'Março'},
                { id: 4, name: 'Abril'},
                { id: 5, name: 'Maio'},
                { id: 6, name: 'Junho'},
                { id: 7, name: 'Julho'},
                { id: 8, name: 'Agosto'},
                { id: 9, name: 'Setembro'},
                { id: 10, name: 'Outubro'},
                { id: 11, name: 'Novembro'},
                { id: 12, name: 'Dezembro'},
            ],
            customer_name: 'AFIP',
            customers: [
                { id: 'AFIP', name: 'AFIP' },
                { id: 'Coopercredi', name: 'Coopercredi' },
                { id: 'Emulzint', name: 'Emulzint' },
                { id: 'Enesa', name: 'Enesa' },
                { id: 'Estapar', name: 'Estapar' },
                { id: 'Innovare', name: 'Innovare' },
                { id: 'Keyence', name: 'Keyence' },
                { id: 'Leal', name: 'Leal' },
                { id: 'MetaFrio', name: 'MetaFrio' },
                { id: 'Orient', name: 'Orient' },
                { id: 'Saint-Gobain Sekurit', name: 'Saint-Gobain Sekurit' },
                { id: 'Serramar', name: 'Serramar' },
                { id: 'Sonepar', name: 'Sonepar' },
                { id: 'SoulPay', name: 'SoulPay' },
                { id: 'SPR', name: 'SPR' },
                { id: 'SwissRe', name: 'SwissRe' },
                { id: 'UpTime Robot', name: 'UpTime Robot' },
                { id: 'Wurth', name: 'Wurth' },

            ]
        }
    },
    methods: {
        generateReport(){
            $('#downloadReport').attr('href', 
                this.$store.getters.api + 'tickets/report' +
                    '?customer_name=' + this.customer_name + 
                    '&month=' + this.month + 
                    '&year=' + this.year
            );
            document.getElementById('downloadReport').click();
        }
    }
}
</script>

<style>

</style>