import Crud from '../components/TypeOfContract/Crud'
import Add from '../components/TypeOfContract/Add'
import Edit from '../components/TypeOfContract/Edit'
import View from '../components/TypeOfContract/View'

let routes = {
    path: '/typeofcontract',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/typeofcontracts',
            name: 'Listar Registros',
            component: Crud,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/typeofcontracts/Add',
            name: 'Novo Registro',
            component: Add
        },
        {
            path: '/typeofcontracts/View/:id',
            name: 'Visualizar Registro',
            component: View
        },
        {
            path: '/typeofcontracts/Edit/:id',
            name: 'Editar Registro',
            component: Edit
        }
    ]   
};

export default routes;