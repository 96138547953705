<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <button id="back" @click="$router.go(-1)" class="btn btn-info btn-sm">
          <i class="fa fa-arrow-left"></i>
        </button>
        <button id="addCard" @click="addCard" class="btn btn-primary btn-sm">
          <i class="fa fa-plus"></i>
        </button>
        <button
          id="addMultipleCard"
          @click="addMultipleCard"
          class="btn btn-success btn-sm"
        >
          <i class="fa fa-clone"></i>
        </button>
        <button @click="filters" class="btn btn-warning btn-sm">
          <i class="fa fa-filter"></i>
        </button>
        <button @click="refresh" class="btn btn-info btn-sm">
          <i class="fa fa-sync"></i>
        </button>
        <button @click="help" class="btn btn-danger btn-sm">
          <i class="fa fa-question"></i>
        </button>
      </div>
    </div>

    <div class="collapse" id="help">
      <div class="card card-body">
        <div class="row">
          <h6 style="margin-left: 17px; margin-top: 14px">Atalhos:</h6>
          <div class="col-md-2">
            Card =
            <div class="keyboard__row keyboard__row--h3">
              <div class="key--letter key--word key--w1" data-key="18">
                <span>Alt</span>
              </div>
              <div class="key--letter key--word key--w1" data-key="187">
                <span>+</span>
              </div>
              <div
                class="key--letter key--word key--w1"
                data-char="C"
                data-key="67"
              >
                <span>C</span>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            Pesquisar =
            <div class="keyboard__row keyboard__row--h3">
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>Alt</span>
              </div>
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>+</span>
              </div>
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>P</span>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            Refresh =
            <div class="keyboard__row keyboard__row--h3">
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>Alt</span>
              </div>
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>+</span>
              </div>
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>R</span>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            Info =
            <div class="keyboard__row keyboard__row--h3">
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>Alt</span>
              </div>
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>+</span>
              </div>
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>I</span>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>

    <div class="collapse" id="filters">
      <div class="card card-body">
        <div class="row">
          <div class="col-md-4">
            <label for>Data Fechados</label>
            <input
              type="date"
              class="form-control"
              v-model="ultimaAtualizacao"
            />
          </div>
          <div class="col-md-4">
            <label for>Usuários</label>
            <v-select
              :options="users"
              :filterable="false"
              v-model="user"
              label="User"
            >
              <template slot="no-options"
                >Digite para pesquisar Usuários</template
              >
              <template slot="option" slot-scope="option">
                <div class="d-center">{{ option.name }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </v-select>
          </div>
          <div class="col-md-4">
            <label for>Sprints</label>
            <v-select
              :options="sprints"
              :filterable="false"
              :reduce="(sprints) => sprints.id"
              v-model="sprint_id"
              @input="loadIssues"
              label="User"
            >
              <template slot="no-options"
                >Digite para pesquisar Sprints</template
              >
              <template slot="option" slot-scope="option">
                <div class="d-center">{{ option.name }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
    <div class="col-md-12">
      <issue-modal
        :modalMode="issueModal"
        :modalStatus="issueModalStatus"
        :selectedIssue="selectedIssue"
        @changeModal="changeIssueModal"
        @refresh="refresh"
      ></issue-modal>
      <br />
      <issue-multiple-modal
        :modalMode="issueMultipleModal"
        :modalStatus="issueMultipleModalStatus"
        :selectedIssue="selectedIssue"
        @changeModal="changeIssueMultipleModal"
        @refresh="refresh"
      ></issue-multiple-modal>
      <hr />
    </div>

    <div class="row">
      <div class="col-md-12 row">
        <div v-for="column in columns" :key="column.title" class="col-md-4">
          <h4
            class="text-gray-700 font-semibold font-sans tracking-wide text-sm"
            :class="{
              'bg-blue': column.title == 'todo',
              'bg-ylw': column.title == 'doing',
              'bg-green': column.title == 'done',
            }"
          >
            {{ column.title }}
          </h4>
          <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
          <draggable
            :list="column.tasks"
            :animation="200"
            ghost-class="ghost-card"
            group="tasks"
            class="dragg-list"
            @end="changed"
            @change="changed"
          >
            <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
            <task-card
              v-for="(task, index) in column.tasks"
              :key="task.id"
              :task="task"
              :column="column"
              :index="index"
              class="mt-3 cursor-move"
              @cardEdit="cardEdit(task)"
              @deleteIssue="deleteIssue"
            ></task-card>
            <!-- </transition-group> -->
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import TaskCard from "./TaskCard";
import IssueModal from "./IssueModal";
import IssueMultipleModal from "./IssueMultipleModal";
import vSelect from "vue-select";
import _ from "lodash";

export default {
  components: {
    TaskCard,
    draggable,
    IssueModal,
    IssueMultipleModal,
    vSelect,
  },
  data() {
    return {
      ultimaAtualizacao: "",
      sprint_id: 0,
      projects: [],
      project: 0,
      selectedIssue: {},
      columns: [],
      issueModal: "add",
      issueModalStatus: false,
      issueMultipleModal: "add",
      issueMultipleModalStatus: false,
      lastElementAdded: null,
      users: [],
      user: 0,
    };
  },
  mounted() {
    let self = this;

    this.getSprints();
    this.loadIssues();
    this.loadUsers();
    // atalho para novo card ALT+C
    window.addEventListener("keyup", function (event) {
      if (event.altKey && event.keyCode === 67) {
        self.addCard(event);
      }
    });
    // atalho para info ALT+I
    window.addEventListener("keyup", function (event) {
      if (event.altKey && event.keyCode === 73) {
        self.help(event);
      }
    });
    // atalho para reload dos cards ALT+R
    window.addEventListener("keyup", function (event) {
      if (event.altKey && event.keyCode === 82) {
        self.refresh(event);
      }
    });
    // atalho para pesquisa data + projeto ALT+P
    window.addEventListener("keyup", function (event) {
      if (event.altKey && event.keyCode === 80) {
        self.filters(event);
      }
    });
  },
  methods: {
    changeIssueMultipleModal(status) {
      this.issueMultipleModalStatus = status;
    },
    loadUsers() {
      let self = this;
      let api = this.$store.getters.api + "users/?paginated=false";

      self.$http.get(api).then((response) => {
        self.users = response.data.data;
      });
    },
    changed(event) {
      const self = this;
      if (event.added) {
        self.lastElementAdded = event.added.element;
      }

      if (event instanceof CustomEvent && self.lastElementAdded) {
        let from = $(event.from).siblings("h4").text();
        let to = $(event.to).siblings("h4").text();

        self.lastElementAdded.status = to;

        const api = self.$store.state.api + "move-issues";
        self.$http
          .post(api, {
            changed: [self.lastElementAdded],
            action: "moveIssue",
            key: "id",
          })
          .then((response) => {
            self.lastElementAdded = null;
          })
          .catch((error) => {
            self.$message("Erro", error.response.data, "error");
          });
      }
    },

    loadIssues() {
      let self = this;
      let api = this.$store.getters.api + "team-issues";
      let issues = this.$http
        .get(api, {
          params: {
            closed_at: self.ultimaAtualizacao,
            project_id: self.$route.params.id,
            user_id: self.user ? self.user.id : 0,
            sprint_id: self.sprint_id,
          },
        })
        .then((response) => {
          this.columns = response.data;
        });
    },
    getSprints: function () {
      const self = this;
      const api = self.$store.state.api + "sprints/?paginated=false";

      self.$http
        .get(api, {
          params: {
            project_id: self.$route.params.id,
          },
        })
        .then((response) => {
          self.sprints = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    searchProject: _.debounce(function (search) {
      let self = this;
      let api = this.$store.getters.api + "projects";
      if (search.length >= 3) {
        self.$http.get(api + "?name=" + search).then((response) => {
          self.projects = response.data.data;
        });
      }
    }, 300),
    selectedProject(project) {
      let self = this;
      let api = this.$store.getters.api + "sprints";

      self.$http.get(api + "?project_id=" + project.id).then((response) => {
        self.sprints = response.data.data;
      });
    },
    deleteIssue(id, index, column) {
      let self = this;
      let api = this.$store.getters.api + "issues/";

      self.$http
        .delete(api + id)
        .then((response) => {
          column.tasks.splice(index, 1);
        })
        .then(() => {
          self.$message(
            "Sucesso",
            "Card(s) Deletado(s) com sucesso",
            "success"
          );
          this.refresh();
        });
    },
    filters() {
      $("#filters").collapse("toggle");
    },
    help() {
      $("#help").collapse("toggle");
    },
    refresh() {
      this.loadIssues();
    },
    changeIssueModal(status) {
      this.issueModalStatus = status;
    },
    cardEdit(card) {
      console.log("dblclick");
      card.cancel = true;
      this.selectedIssue = card;
      this.issueModal = "edit";
      this.issueModalStatus = true;
    },
    addMultipleCard() {
      this.issueMultipleModal = "add";
      this.issueMultipleModalStatus = true;
    },
    addCard() {
      this.issueModal = "add";
      this.issueModalStatus = true;
    },
    getIssues: function (id) {
      const self = this;
      const api = self.$store.state.api + "team-issues";

      self.$http
        .get(api)
        .then((response) => {
          self.issues = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
};
</script>

<style scoped>
.dragg-list {
  height: 100%;
  min-height: 300px;
  border: 1px solid grey;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f7fafc;
}

.column-width {
  min-width: 320px;
  width: 320px;
}
/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}
.text-gray-700 {
  padding-left: 15px;
}
button:focus,
input:focus,
select:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
}
.column-width {
  /* min-width: 320px; */
  width: 380px;
}
.bg-gray-100 {
  --bg-opacity: 1;
  background-color: rgb(251, 253, 255);
}
.border-white {
  border-color: rgba(53, 53, 53, 0.16) !important;
}
.bg-blue {
  border-radius: 3px;
  background-color: #fd0019c5;
}
.bg-ylw {
  border-radius: 3px;
  background-color: #0099ffb2;
}
.bg-green {
  border-radius: 3px;
  background-color: #18b43abc;
}
.text-title {
  padding-left: 4px;
}
.keyboard {
  text-align: center;
  font-size: 14px;
  font-family: sans-serif;
}
.keyboard__row {
  display: inline-block;
  height: 1em;
  margin: 0.2em;
}
.keyboard__row--h1 {
  height: 1.7em;
  line-height: 1.4em;
}
.keyboard__row--h3 {
  height: 1.6em;
}
.keyboard__row > * {
  position: relative;
  background: rgb(138, 135, 135);
  text-align: center;
  color: #eee;
  float: left;
  border-radius: 0.3em;
  margin: 0.2em;
  padding: 0.2em;
  width: 1.8em;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  border: 1px solid #444;
  box-shadow: 0 0.2em 0 0.05em rgb(75, 75, 75);
  border-bottom-color: #555;
}
.keyboard__row > div[data-pressed],
.keyboard__row > div:active {
  background: #2a2a2a;
  color: #aaa;
  position: relative;
  top: 0.2em;
  box-shadow: 0 0 0 0.05em black;
}
.key--letter {
  line-height: 1.2em;
}
.key--word > * {
  font-size: 0.8em;
}
</style>
