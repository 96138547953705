import Crud from '../components/InnoverApplications/Crud'
import Add from '../components/InnoverApplications/Add'
import Edit from '../components/InnoverApplications/Edit'
import View from '../components/InnoverApplications/View'

let routes = {
    path: '/innover_applications',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
      {
          path: '/innover_applications',
          name: 'Aplicações',
          component: Crud,
          permission: 'is_admin'
      },
      {
          path: '/innover_applications/add',
          name: 'Aplicações',
          component: Add,
          permission: 'is_admin'
      },
      {
          path: '/innover_applications/edit/:id?',
          name: 'Aplicações',
          component: Edit,
          permission: 'is_admin'
      },
      {
          path: '/innover_applications/view/:id?',
          name: 'Aplicações',
          component: View,
          permission: 'is_admin'
      },
    ]
}

export default routes
