<template>
  <div>
    <base-crud
      crudName="Developer - Solicitação de QA"
      newText="Nova Solicitação"
      :table="table"
      :columns="columns"
      :options="options"
      :endPoint="endPoint"
      :customFilters="customFilters"
      @filtered="filtered"
      :enableAdd="true"
      :enableEdit="false"
      :enableDelete="false"
      :enableView="true"
      :dataForm="dataForm"
      :extra="extra"
      @del="del"
    >
    </base-crud>
  </div>
</template>

<script>
import BaseCrud from "../Base/BaseCrud";


export default {
  data: function() {
    return {
      customFilters:[
        {
          name: "created_at",
          type: "date",
          call: "filtered"
        }
      ],
      created_at: "",

      table: "developersTable",
      pages: [],
      url: "",
      columns: [
        "id",
        "user_id",
        "project_id",
        "priority",
        "created_at",
        "status",
        "actions"
      ],
      tableData: ["id", "description"],
      options: {
        sortable: ["id", "name"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        filterByColumn: true,
        filterable: [
          "id",
          "user_id",
          "project_id",
          "priority",
          "status",
          "actions"
        ],
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          user_id: "Responsável",
          project_id: "Projeto",
          priority: "Prioridade",
          created_at: "Data Solicitação",
          status: "Status",
          actions: "Ações"
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione"
        },
        listColumns: {
          status: [
            {
              id: 1,
              text: "Aguardando Análise"
            },
            {
              id: 2,
              text: "QA Rejeitado"
            },
            {
              id: 3,
              text: "QA Aprovado"
            },
            {
              id: 4,
              text: "Deploy Realizado"
            }
          ]
        },
        templates: {
          user_id: function(h, row, index) {
            return row.user ? row.user.name : "Sem Nome";
          },
          status: function(h, row, index) {
            return row.deploy_status ? row.deploy_status.title : "Sem Nome";
          },
          project_id: function(h, row, index) {
            return row.project ? row.project.name : "Sem Nome";
          },
          priority: function(h, row, index) {
            if (row.priority == "Alta Prioridade") {
              return (
                <h5>
                  <span class="badge badge-danger">Alta Prioridade</span>
                </h5>
              );
            } else if (row.priority == "Média Prioridade") {
              return (
                <h5>
                  <span class="badge badge-warning">Média Prioridade</span>
                </h5>
              );
            } else {
              return (
                <h5>
                  <span class="badge badge-success">Baixa Prioridade</span>
                </h5>
              );
            }
          }
        },
      requestFunction: function (data) {
        let requestData = {};

        let query = this.$parent.$parent.query(data.query);

        requestData.params = data;

        if (
          this.$parent.$parent.created_at != null &&
          this.$parent.$parent.created_at != ""
        ) {
          requestData.params.created_at = this.$parent.$parent.created_at;
        }

        requestData.params.query = "";
        requestData.params.with = [
        "user", "deployStatus", "project"
        ];

        return this.$http.get(this.url + "?" + query, requestData).catch(
          function (e) {
            this.dispatch("error", e);
          }.bind(this)
        );
      },
      },
      extra: [
        {
          id: 1,
          label: "",
          class: "btn btn-crud cancel fa fa-trash",
          title: "Atribuir a mim",
          name: "del",
          icon: ""
        }
      ],

      endPoint: "wfdeploys/",
      dataForm: {}
    };
  },
  components: {
    BaseCrud
  },
  methods: {
     filtered(date) {
      // console.log('aa')
      this.created_at = date;
    },
    query: function(query) {
      let columns = {
        user_id: "user[name]",
        status: "deploy_status[title]",
        project_id: "project[name]"
      };

      let filters = "";
      $.each(query, function(index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    del: function(props, row, index) {
      const self = this;

      if (props.row.status != "1") {
        swal(
          "Erro",
          "Esta solicitação já está em andamento e não pode ser excluída",
          "info"
        );
      } else {
        const self = this;

        swal({
          title: "Deseja deletar o registro?",
          text: "Essa operação não pode ser desfeita",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Sim, deletar!",
          showLoaderOnConfirm: true,
          buttons: {
            catch: {
              text: "Não",
              value: "cancel",
              className: "btn-danger"
            },
            confirm: {
              text: "Sim",
              value: "confirm"
            }
          }
        }).then(value => {
          switch (value) {
            case "cancel":
              swal("Cancelado", "O registro não foi excluido!", "info");
              break;

            case "confirm":
              self.$http
                .delete(this.$store.state.api + "wfdeploys/" + props.row.id, {
                  id: props.row.id
                })
                .then(response => {
                  swal("Sucesso", "Registro excluído com sucesso!", "success");

                  $(function() {
                    setTimeout(function() {
                      location.reload();
                    }, 1000);
                  });
                });
              break;
          }
        });
      }
    }
    // destroy(props, row, index) {
    //     const self = this

    //     swal({
    //         title: "Deseja deletar o registro?",
    //         text: "Essa operação não pode ser desfeita",
    //         type: "warning",
    //         showCancelButton: true,
    //         confirmButtonColor: "#DD6B55",
    //         confirmButtonText: "Sim, deletar!",
    //         showLoaderOnConfirm: true,
    //         buttons: {
    //             catch: {
    //                 text: "Não",
    //                 value: "cancel",
    //                 className: "btn-danger"
    //             },
    //             confirm: {
    //                 text: "Sim",
    //                 value: 'confirm'
    //             }
    //         },
    //     })
    //         .then((value) => {
    //             switch (value) {
    //                 case 'cancel':
    //                     swal('Cancelado', 'O registro não foi excluido!', 'info');
    //                     break;

    //                 case 'confirm':

    //                     self.$http.delete(this.$store.state.api + 'wfdeploys/' + props.row.id, {
    //                         'id': props.row.id
    //                     })
    //                         .then((response) => {
    //                             console.log(index)
    //                             this.table.splice(index, 1);
    //                             swal('Sucesso', 'Registro excluído com sucesso!', 'success');
    //                         });
    //                     break;
    //             }
    //        })
    // },
  }
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
</style>
