<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">
        {{ customers.id ? "Editar Cliente " : "Cadastrar Cliente" }}
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-md-11">
          <form action>
            <div class="row">
              <div class="col">
                <label for="customer-name">
                  Nome do Cliente
                  <span class="require">*</span>
                </label>
                <input type="text" class="form-control" v-model="customers.customer_name" />
              </div>
              <div class="col">
                <label for="cnpj">CNPJ</label>
                <input type="text" v-mask="'##.###.###/####-##'" class="form-control" v-model="customers.cnpj"
                  placeholder="Somente Numeros. Ex: 00.000.000/0000-00" />
              </div>
            </div>
            <br />

            <div class="row">
              <div class="col">
                <label for="contact-name">Nome do Contato</label>
                <input type="text" class="form-control" v-model="customers.contact_name" />
              </div>
              <div class="col">
                <label for="email">E-mail</label>
                <input type="text" class="form-control" v-model="customers.email" />
              </div>
            </div>
            <br />

            <div class="row">
              <div class="col-md-6">
                <label for="domain">
                  Dominio
                  <span class="required">*</span>
                </label>

                <input type="text" class="form-control" v-model="customers.domain" placeholder="mycompany.com.br" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="card-body">
      <hr />
      <div class="row ">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <template>
              <button @click="$router.go(-1)" class="btn btn-info">
                <i class="fa fa-arrow-left"></i> Voltar
              </button>
            </template>
            <a class="btn btn-success" @click="save">
              Salvar
              <i class="fa fa-save"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      customers: {
        customer_name: "",
        cnpj: "",
        contact_name: "",
        email: "",
        domain: "",
        switch: 0,
      },
    };
  },
  computed: {
    url() {
      return this.$store.getters.api + this.$route.params.id;
    },
  },
  mounted() {
    let self = this;
    let id = self.$route.params.id;

    if (id) {
      self.getCustomer(id);
    }
  },
  methods: {
    save: function () {
      let api = this.$store.state.api + "customers";
      const self = this;

      let form = new FormData();

      form.append("customer_name", self.customers.customer_name);
      form.append("cnpj", self.customers.cnpj);
      form.append("contact_name", self.customers.contact_name);
      form.append("email", self.customers.email);
      form.append("domain", self.customers.domain);
      form.append("select_all", self.customers.switch);

      if (self.customers.id) {
        api += "/" + self.customers.id;
        form.append("_method", "PUT");
      }

      self.$http
        .post(api, form)
        .then((response) => {
          self.$message("Sucesso", `Cadastrado com Sucesso`, "success");
          this.$router.push("/customers");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });
            html += "</ul>";
            self.errors = html;
          }
          self.$message(null, self.errors, "error");
        });
    },
    getCustomer: function (id) {
      let self = this;
      let api = self.$store.state.api + "customers/" + id;

      self.$http
        .get(api)
        .then((response) => {
          self.customers = response.data.data[0];
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
  },
};
</script>

<style></style>