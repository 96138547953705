<template>
  <div>
    <section>
      <!-- Main Content -->
      <div class="main-content">
        <!-- <div class="row" v-if="!fullscreen">
          <div class="col-md-12">
            <button
              class="btn btn-filters"
              type="button"
              data-toggle="collapse"
              data-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <i class="fas fa-filter"></i> Filtros
            </button>
          </div>
        </div> -->
        <!-- <div class="row collapse" id="collapseExample">
          <div class="col-md-3">
            <label>Filial</label>
            <v-select
              :options="branches"
              label="title"
              :reduce="(branches) => branches.id"
              multiple
              class="style-chooser"
              v-model="filters.branches"
            >
              <div slot="no-options">Nenhum registro encontrado.</div>
            </v-select>
          </div>
          <div class="col-md-3">
            <label>Departamento</label>
            <v-select
              :options="departments"
              label="title"
              :reduce="(departments) => departments.id"
              multiple
              class="style-chooser"
              v-model="filters.departments"
            >
              <div slot="no-options">Nenhum registro encontrado.</div>
            </v-select>
          </div>

          <div class="col-md-3">
            <label>Cliente</label>
            <v-select
              :options="customers"
              label="name"
              :reduce="(customers) => customers.id"
              multiple
              class="style-chooser"
              v-model="filters.customers"
            >
              <div slot="no-options">Nenhum registro encontrado.</div>
            </v-select>
          </div>

          <div class="col-md-3">
            <label>Tipo de Atendimento</label>
            <v-select
              :options="order_types"
              label="type_name"
              :reduce="(order_types) => order_types.id"
              multiple
              class="style-chooser"
              v-model="filters.order_types"
            >
              <div slot="no-options">Nenhum registro encontrado.</div>
            </v-select>
          </div>
          <div class="col-md-4" style="margin-top: 15px">
            <label>Responsável</label>
            <v-select
              :options="users"
              label="name"
              :reduce="(users) => users.id"
              multiple
              class="style-chooser"
              v-model="filters.assignee"
            >
              <div slot="no-options">Nenhum registro encontrado.</div>
            </v-select>
          </div>
          <div class="col-md-4" style="margin-top: 15px">
            <label>Status</label>
            <v-select
              :options="status"
              label="name"
              :reduce="(status) => status.id"
              multiple
              class="style-chooser"
              v-model="filters.status"
            >
              <div slot="no-options">Nenhum registro encontrado.</div>
            </v-select>
          </div>
          <div class="col-md-4" style="margin-top: 15px">
            <label>Solicitante</label>
            <v-select
              :options="users"
              label="name"
              :reduce="(users) => users.id"
              multiple
              class="style-chooser"
              v-model="filters.technical"
            >
              <div slot="no-options">Nenhum registro encontrado.</div>
            </v-select>
          </div>
          <div
            align="center"
            style="margin-top: 15px; margin-bottom: 15px"
            class="col-md-12"
          >
            <button class="btn btn-add" @click="getEntity()">Filtrar</button>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-4" v-if="!fullscreen">
            <div class="card">
              <div class="card-header">
                <div class="card-title">Colaboradores</div>
              </div>
              <div class="card-body">
                <div
                  class="alert alert-secondary"
                  style="text-align: center"
                  v-if="users.length > 0"
                  role="alert"
                >
                  Clique sobre um colaborador para editar a agenda.
                </div>
                <div
                  class="alert alert-secondary"
                  style="text-align: center"
                  v-else
                  role="alert"
                >
                  Nenhum colaborador cadastrado.
                </div>
                <div v-for="user in users" :key="user.id">
                  <div
                    :class="
                      user.id == resource_selected
                        ? 'chat message r_selected'
                        : 'chat message'
                    "
                    @click="selectResource(user.id)"
                    style="cursor: pointer"
                  >
                    <img
                      class="chat-pic ml-2"
                      :src="user.photo"
                      alt="Chat Profile"
                    />
                    <div class="chat-message">
                      <div class="chat-who">{{ user.name }}</div>
                      <div class="chat-text">
                        {{ user.open_events }} evento(s).
                      </div>
                    </div>

                    <div class="clearfix mt-2">
                      <i
                        v-if="user.id == resource_selected"
                        class="fas fa-chevron-right"
                        style="font-size: 25px"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body mt-3" align="center">
                <button
                  class="btn btn-sm btn-filters"
                  v-if="users.length > 0 && resource_selected"
                  @click="unSelectResource"
                >
                  Limpar Filtros
                </button>
              </div>
            </div>
          </div>
          <div :class="fullscreen ? 'col-md-12' : 'col-md-8'">
            <div class="card">
              <div class="card-header">
                <div class="card-title">
                  <div class="row">
                    <div class="col-md-4">Agenda</div>
                    <div class="col-md-8 fs14 mt-1" align="right">
                      <span @click="fullscreenMode()" style="cursor: pointer">
                        <i
                          :class="
                            !fullscreen
                              ? 'fas fa-expand-arrows-alt'
                              : 'fas fa-compress-arrows-alt'
                          "
                          style="color: #08b9b3"
                        ></i>
                        {{
                          !fullscreen
                            ? "Exibir tela cheia"
                            : "Reduzir tela cheia"
                        }}
                      </span>
                      <span
                        @click="getEntity(), getUsers()"
                        class="ml-3"
                        style="cursor: pointer"
                      >
                        <i class="fas fa-sync-alt" style="color: #08b9b3"></i>
                        Atualizar
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <FullCalendar :options="calendarOptions" v-if="loaded" />
              </div>
              <div class="card-body" v-if="calendarOptions.editable">
                <button
                  class="btn btn-add"
                  style="
                    position: fixed;
                    bottom: 40px;
                    right: 28%;
                    box-shadow: 4px 4px 5px #888;
                    z-index: 1000;
                  "
                  @click="saveEvents"
                >
                  <i class="fas fa-save"></i> Salvar Alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    ></loading>

    <!-- Modal Info -->
    <div
      class="modal fade"
      id="modalInfo"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      v-if="infoModal"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              <i class="fas fa-circle" :style="'color: ' + infoModal.color"></i>
              {{ infoModal.title }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" style="font-size: 16px !important">
              <div class="col-md-12">
                <strong>ID:</strong> {{ infoModal.id }}
              </div>
              <div class="col-md-12 mt-1">
                <strong>Nome do Colaborador:</strong>
                {{ infoModal.user_id.name }}
              </div>
              <div class="col-md-12 mt-1">
                <strong>Título do Evento:</strong> {{ infoModal.pure_title }}
              </div>
              <div class="col-md-12 mt-1" v-if="infoModal.project_id">
                <strong>Projeto:</strong> {{ infoModal.project_id.name }}
              </div>
              <div class="col-md-12 mt-1" v-if="infoModal.sprint_id">
                <strong>Sprint:</strong> {{ infoModal.sprint_id.name }}
              </div>
              <div class="col-md-12 mt-1">
                <strong>Início do Evento:</strong>
                {{ infoModal.start_event }}
              </div>
              <div class="col-md-12 mt-1">
                <strong>Previsão de Término:</strong>
                {{ infoModal.end_event }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="editEvent(infoModal)"
            >
              <i class="fas fa-pencil-alt"></i> Editar
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="deleteEvent(infoModal)"
            >
              <i class="fas fa-trash-alt"></i> Deletar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal New -->
    <div
      class="modal fade"
      id="modalNew"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      v-if="projects.length > 0"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{ new_demand.edit ? "Editar Evento" : "Novo Evento" }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" style="margin-top: 10px">
              <div class="col-md-12">
                <label for>Título do Evento</label>
                <input
                  type="text"
                  v-model="new_demand.title"
                  class="form-control"
                />
              </div>
              <div class="col-md-12 mt-2">
                <label for>Selecione o Colaborador</label>
                <v-select
                  :options="users"
                  :multiple="true"
                  label="name"
                  :reduce="(users) => users.id"
                  class="style-chooser"
                  v-model="new_demand.user_id"
                >
                  <div slot="no-options">Nenhum registro encontrado.</div>
                </v-select>
              </div>
              <div class="col-md-12 mt-2">
                <label for>Selecione o Projeto</label>
                <v-select
                  :options="projects"
                  label="name"
                  :reduce="(projects) => projects"
                  class="style-chooser"
                  v-model="new_demand.project_id"
                >
                  <div slot="no-options">Nenhum registro encontrado.</div>
                </v-select>
              </div>
              <template v-if="new_demand.project_id">
                <div
                  class="col-md-12 mt-2"
                  v-if="new_demand.project_id.sprints"
                >
                  <label for>Selecione a Sprint</label>
                  <v-select
                    :options="new_demand.project_id.sprints"
                    label="name"
                    :reduce="(sprints) => sprints.id"
                    class="style-chooser"
                    v-model="new_demand.sprint_id"
                  >
                    <div slot="no-options">Nenhum registro encontrado.</div>
                  </v-select>
                </div>
              </template>
              <div class="col-md-12" style="margin-top: 15px">
                <label for>Cor do Evento</label>
                <input
                  type="color"
                  v-model="new_demand.color"
                  class="form-control"
                />
              </div>
              <div
                class="col-md-12"
                style="margin-top: 15px"
                v-if="!new_demand.edit"
              >
                <label for>Evento Privado?</label><br />
                <input type="checkbox" v-model="new_demand.private" class="" />
                <br />
                <small
                  >Ao criar um evento privativo, somente você irá
                  visualizá-lo</small
                >
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="!new_demand.edit"
              @click="newEvent"
            >
              Criar Evento
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-else
              @click="saveChanges"
            >
              Editar Evento
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from "@fullcalendar/core/locales/pt-br";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      fullscreen: false,
      loaded: true,
      changes: false,
      fullPage: true,
      isLoading: false,
      resource_selected: "",
      originalEvents: [],
      infoModal: "",
      tes: {},
      new_demand: "",
      date_formated: "",

      currentEvents: [],
      calendarOptions: {
        locale: ptLocale,
        eventTimeFormat: {
          hour: "numeric",
          minute: "2-digit",
          meridiem: false,
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        height: "700px",
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        select: this.handleDateSelect,
        events: [],
      },
      filters: {
        show_finished: 0,
      },
      customers: [],
      order_types: [],
      users: [],
      projects: [],
      calendarApi: "",
      status: [
        {
          id: 1,
          name: "Aguardando",
        },
        {
          id: 2,
          name: "Visita Confirmada",
        },
        {
          id: 4,
          name: "Em atendimento",
        },
        {
          id: 5,
          name: "Finalizado",
        },
      ],
    };
  },
  computed: {},
  methods: {
    fullscreenMode() {
      const self = this;
      self.loaded = false;

      if (self.fullscreen == true) {
        self.fullscreen = false;
      } else {
        self.fullscreen = true;
      }
      self.getEntity();
      self.getUsers();
      self.loaded = true;
    },
    viewDemand(infoModal) {
      const self = this;
      $("#modalInfo").modal("toggle");

      var URL = self.$store.state.portalUrl + "demands/view/" + infoModal.id;

      window.open(URL, "_blank");
    },
    selectResource(id) {
      this.filters.user_id = id;
      this.resource_selected = id;
      this.calendarOptions.editable = true;
      this.getEntity();
    },
    unSelectResource() {
      this.filters = {};
      this.resource_selected = "";
      this.calendarOptions.editable = false;
      this.getEntity();
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleEventClick(clickInfo) {
      this.infoModal = this.calendarOptions.events.find(
        (element) => element.id == clickInfo.event.id
      );

      $("#modalInfo").modal("toggle");
    },
    handleDateSelect(selectInfo) {
      const self = this;

      self.new_demand = {};

     
        if (moment(selectInfo.startStr) < moment().subtract(1, "days")) {
          swal(
            "Ops",
            "Você não pode selecionar uma data anterior a data atual!",
            "info"
          );
        } else {
          let calendarApi = selectInfo.view.calendar;

          self.new_demand.start = selectInfo.startStr;
          self.new_demand.end = selectInfo.endStr;
          self.new_demand.date_hour = moment(self.new_demand.date).format("LT");
          self.new_demand.cf_hour = moment(
            self.new_demand.completion_forecast
          ).format("LT");
          self.new_demand.color = "#4287f5";

          self.date_formated = moment(self.new_demand.date).format("LL");

          $("#modalNew").modal("toggle");
        }
         if ( selectInfo.view.type == "dayGridMonth") {
        
        const hourStart = '08:00:00';
        const hourEnd = '18:00:00';

        const startDate = self.new_demand.start;

        self.new_demand.start = self.new_demand.start + ' ' + hourStart;
        self.new_demand.end = startDate + ' ' + hourEnd;
       
      }
    },
    editEvent() {
      const self = this;
      self.new_demand = self.infoModal;
      self.new_demand.title = self.infoModal.pure_title;
      self.new_demand.edit = true;
      $("#modalNew").modal("toggle");
    },
    deleteEvent(infoModal) {
      const self = this;
      const api = self.$store.state.api + "schedules/" + self.infoModal.id;
      self.isLoading = true;

      self.$http
        .delete(api)
        .then((response) => {
          self.getEntity();
          self.isLoading = false;
          self.$message("Sucesso", `Agenda atualizada!`, "success");
        })
        .catch((error) => {
          self.$message("Erro", `Não foi possível deletar o evento.`, "error");
          self.isLoading = false;
        });
    },
    saveChanges() {
      const self = this;
      const api = self.$store.state.api + "schedule-save-event";
      self.isLoading = true;

      self.$http
        .post(api, self.new_demand)
        .then((response) => {
          self.getEntity();
          self.isLoading = false;
          self.$message("Sucesso", `Evento atualizado!`, "success");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });
            html += "</ul>";
            self.errors = html;
          }
          self.$message(null, self.errors, "error");
          self.isLoading = false;
        });
    },
    saveEvents() {
      const self = this;
      const api = self.$store.state.api + "schedule-save";
      self.isLoading = true;

      self.$http
        .post(api, self.currentEvents)
        .then((response) => {
          self.getEntity();
          self.isLoading = false;
          self.$message("Sucesso", `Agenda atualizada!`, "success");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });
            html += "</ul>";
            self.errors = html;
          }
          self.$message(null, self.errors, "error");
          self.isLoading = false;
        });
    },
    newEvent() {
      const self = this;
      const api = self.$store.state.api + "schedule-new";
      self.isLoading = true;

      self.$http
        .post(api, self.new_demand)
        .then((response) => {
          self.getEntity();
          self.getUsers();
          self.isLoading = false;
          self.calendarApi.removeEvent({
            id: "11",
          });
          $("#modalNew").modal("hide");
          self.$message("Sucesso", `Evento Criado`, "success");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });
            html += "</ul>";
            self.errors = html;
          }
          self.$message(null, self.errors, "error");
          self.isLoading = false;
        });
    },
    getEntity: function (id) {
      const self = this;
      const api = self.$store.state.api + "schedule-events";
      self.isLoading = true;

      self.$http
        .post(api, self.filters)
        .then((response) => {
          self.calendarOptions.events = response.data;
          self.isLoading = false;
          self.changes = false;
          self.originalEvents = self.calendarOptions.events;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.isLoading = false;
        });
    },
    getUsers: function (id) {
      const self = this;
      const api = self.$store.state.api + "schedule-users";

      self.$http
        .post(api)
        .then((response) => {
          self.users = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProjects: function (id) {
      const self = this;
      const api =
        self.$store.state.api + "projects?paginated=false&with[]=sprints";

      self.$http
        .get(api)
        .then((response) => {
          self.projects = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  components: { vSelect, FullCalendar, Loading },
  props: {},
  mounted: function () {
    this.getUsers();
    this.getProjects();
    this.getEntity();
  },
};
</script>
<style scoped>
/* min-width */
@media screen and (min-width: 900px) {
  .margin-home {
    margin-top: -20px;
  }
}
.r_selected {
  background-color: #ffeb86;
}
</style>