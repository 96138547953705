<template>
    <div>
        <base-crud
                crudName="Sprint"
                newText="Nova Sprint"
                :table="sprintTable"
                :columns="columns"
                :options="options"
                :endPoint='endPoint'
                :enableAdd="true"
                :enableEdit="true"
                :enableDelete="true"
                :enableView="false"
                :dataForm="dataForm"
                :customFilters="customFilters"
                @startDate="startDate"
                @endDate="endDate"
                :extra="extra"
        >

        </base-crud>
    </div>
</template>

<script>
    import BaseCrud from '../Base/BaseCrud'
import { debug } from 'util';

    export default {
        data: function () {
            let self = this
            return {
              customFilters:[
                {
                  name: "start_date",
                  type: "date",
                  call: "startDate"
                },
                {
                  name: "end_date",
                  type: "date",
                  call: "endDate"
                },
              ],
                extra: [],
                sprintTable: 'sprintTable',
                users: [],
                pages: [],
                url: '',
                columns: ['id', 'name', 'project_id', 'status', 'start_date', 'end_date', 'actions'],
                tableData: ['id', 'name', 'project_id', 'status', 'start_date', 'end_date', 'actions'],
                debounce: 800,
                options: {
                  filterByColumn: true,
                  filterable: ['id', 'name', 'project_id', 'status'],
                  listColumns: {
                   status: [
                      {
                        id: 'ACTIVE',
                        text: 'Ativo',
                      },
                      {
                        id: 'CLOSED',
                        text: 'Fechado',
                      },
                      {
                        id: 'FUTURE',
                        text: 'Futura',
                      },
                    ]
                  },
                    sortable: ['id', 'name', 'project_id', 'status'],
                    pagination: {chunk: 10, dropdown: false, nav: 'scroll' },
                    perPage: 10,
                    perPageValues: [10,25,50,100],
                    headings: {
                        'id': 'ID',
                        'name' : 'Nome',
                        'project_id' : 'Projeto',
                        'status' : 'Status',
                        'start_date' : 'Iniciado Em:',
                        'end_date' : 'Finalizado Em:',
                    },
                    templates: {
                      project_id: function (h, row, index) {
                        return row.project ? row.project.name : 'Sem Nome';
                      },

                      start_date: function (h, row, index) {
                        if(row.start_date == ''){
                            return <h6><span>n/a</span></h6>
                        } else {
                          return row.start_date;
                        }
                      },

                      end_date: function (h, row, index) {
                        if(row.end_date == ''){
                            return <h6><span>n/a</span></h6>
                        } else {
                          return row.end_date;
                        }
                      },
                    },
                    requestFunction: function (data) {
                      let requestData = {};

                      let query = this.$parent.$parent.query(data.query);

                      requestData.params = data;

                      if (
                        this.$parent.$parent.start_date != null &&
                        this.$parent.$parent.start_date != ""
                      ) {
                        requestData.params.start_date = this.$parent.$parent.start_date;
                      }
                      if (
                        this.$parent.$parent.end_date != null &&
                        this.$parent.$parent.end_date != ""
                      ) {
                        requestData.params.end_date = this.$parent.$parent.end_date;
                      }

                      requestData.params.query = "";
                      requestData.params.with = [
                      "project"
                      ];

                      return this.$http.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                          this.dispatch("error", e);
                        }.bind(this)
                      );
                    },
                },
                endPoint: 'sprints/',

                dataForm: {

                }
            }
        },
        methods: {
          startDate(date) {
            this.start_date = date;
          },
          endDate(date) {
            this.end_date = date;
          },
          query: function (query) {
                let columns = {
                    id: 'id',
                    name: 'name',
                    project_id: 'project[name]',
                    status: 'Status',
                    start_date: 'start_date',
                    end_date: 'end_date'

                };
                let filters = '&orderByDesc=id&';
                $.each(query, function (index, value) {
                    filters += columns[index] + '=' + value + '&';
                });
                return filters;
            },
            getProjects: function () {

                const self = this;
                const api = self.$store.state.api + 'projects/?paginated=false';

                self.$http.get(api)
                    .then((response) => {
                        self.projects = response.data.data
                    })
                    .catch((error) => {
                        self.$message(null, error.response.data, 'error');
                    });
            },
            getSprints: function () {
                const self = this;
                const api = self.$store.state.api + 'sprints/?paginated=false';

                self.$http.get(api)
                    .then((response) => {
                        self.sprints = response.data.data
                    })
                    .catch((error) => {
                        self.$message(null, error.response.data, 'error');
                    });
            }
        },
        mounted () {
          this.getProjects();
          this.getSprints();
        },
        components: {
            BaseCrud
        },
        computed: {
            statusList () {
                // return this.$http.get('/status')
                return [
                    {id: 'e', label: '123'}
                ]
            }
        }
    }
</script>

<style scoped>
    .VuePagination {
        text-align: center;
    }
</style>
