import NewBoard from '../components/Board/NewBoard'
// import MyBoard from '../components/Board/MyBoard'
// import MyBoard2 from '../components/Board/MyBoard2'
// import MyBoard3 from '../components/Board/MyBoard3'
import TeamBoard from '../components/Board/TeamBoard'
import TasksDay from '../components/Board/TasksDay'
import TapIssue from '../components/Board/TapIssue'
import Contact from '../components/Board/Contact'

let routes = {
    path: '/projects',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        // {
        //     path: '/my-board',
        //     name: 'Meu Quadro',
        //     component: MyBoard,
        //     meta: {
        //         permission: ''
        //     }
        // },
        {
            path: '/contacts',
            name: 'Contatos',
            component: Contact,
            meta: {
                permission: ''
            }
        },
        {
            path: '/team-board',
            name: 'Quadro do Time',
            component: TeamBoard,
            meta: {
                permission: 'is_admin'
            }
        },
        // {
        //     path: '/my-board2',
        //     name: 'Quardro do Time',
        //     component: MyBoard2,
        //     meta: {
        //         permission: 'is_admin'
        //     }
        // },
        // {
        //     path: '/my-board3',
        //     name: 'Quardro do Time',
        //     component: MyBoard3,
        //     meta: {
        //         permission: 'is_admin'
        //     }
        // },
        {
            path: '/new-board',
            name: 'Quardro do Time',
            component: NewBoard,
            meta: {
                permission: ''
            }
        },
        {
            path: '/tasks-day',
            name: 'Quardro do Dia',
            component: TasksDay,
            meta: {
                permission: ''
            }
        },
        {
            path: '/new-board/issue/:id',
            name: 'Issue Full',
            component: TapIssue,
            meta: {
                permission: ''
            }
        }
    ]
};


export default routes
