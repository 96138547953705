<template>
    <div>
        <div class="panel animated fadeInRightBig">
            <div class="panel-heading">
                <div class="card-header">
                    <div class="card-title">
                        Visualização
                    </div>
                </div>
            </div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-12">
                    <div class="card mt-3 tab-card">
                        <div class="card-header2 tab-card-header">
                        <ul class="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="One" aria-selected="true">Detalhes da Solicitação</a>
                            </li>
                            <li class="nav-item" v-show="wfdeploy.origin == 2">
                                <a class="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="Two" aria-selected="false">Chamado</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="Three" aria-selected="false">Arquivos</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="four-tab" data-toggle="tab" href="#four" role="tab" aria-controls="Four" aria-selected="false">Histórico</a>
                            </li>
                        </ul>
                        </div>

                        <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="one-tab">
                            <div class="row">
                                <div class="col-md-3">
                                    <h5 class="card-title">{{ wfdeploy.project.name }}</h5>
                                    <p class="card-text" v-if="wfdeploy.sprint_id != null">{{ wfdeploy.sprint.name }}</p>
                                </div>
                                <div class="col-md-3">
                                    <h5 class="card-title">Origem da Solicitação</h5>
                                    <p class="card-text" v-if="wfdeploy.origin == 1">Projeto</p>
                                    <p class="card-text" v-if="wfdeploy.origin == 2">Chamado</p>
                                </div>
                                <div class="col-md-5">
                                    <h5 class="card-title">Prioridade</h5>
                                    <h6 class="card-text"><span v-bind:class="[wfdeploy.priority == 'Alta Prioridade' ? 'badge badge-danger' : [wfdeploy.priority == 'Média Prioridade' ? 'badge badge-warning' : 'badge badge-success']]">{{ wfdeploy.priority }}</span></h6>
                                    <p class="card-text" v-if="wfdeploy.priority == 'Alta Prioridade'">{{ wfdeploy.justify }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <br>
                                    <h5 class="card-title">Tipo do Projeto</h5>
                                    <p class="card-text">{{ wfdeploy.type_project }}</p>
                                </div>
                                <div class="col-md-3">
                                    <br>
                                    <h5 class="card-title">Solicitante</h5>
                                    <p class="card-text">{{ wfdeploy.user.name }}</p>
                                </div>
                                <div class="col-md-3">
                                    <br>
                                    <h5 class="card-title">Criado em</h5>
                                    <p class="card-text">{{ wfdeploy.created_at }}</p>
                                </div>
                            </div>
                            <div>
                              <div class="row" v-if="wfdeploy.status > 2">
                                  <div class="col-md-3" v-if="wfdeploy.user_qa">
                                      <br>
                                      <h5 class="card-title">Responsável pelo QA</h5>
                                      <p class="card-text" v-if="wfdeploy.status > 2 ">{{ wfdeploy.user_qa.name }}</p>
                                  </div>
                                  <div class="col-md-3" v-if="wfdeploy.status != 8 && wfdeploy.status != 7">
                                      <br>
                                      <h5 class="card-title">Data de Análise</h5>
                                      <p class="card-text" >{{ wfdeploy.moderated_at }}</p>
                                  </div>
                                  <div class="col-md-3">
                                      <br>
                                      <h5 class="card-title">Status</h5>
                                      <p class="card-text">{{ wfdeploy.deploy_status.title }}</p>
                                  </div>
                              </div>
                              <div class="row" v-if="wfdeploy.status == 6">
                                  <div class="col-md-3">
                                      <br>
                                      <h5 class="card-title">Responsável pelo Deploy</h5>
                                      <p class="card-text">{{ wfdeploy.user_deploy.name }}</p>
                                  </div>
                                  <div class="col-md-3">
                                      <br>
                                      <h5 class="card-title" v-if="wfdeploy.status == 6">Data de Análise</h5>
                                      <p class="card-text" >{{ wfdeploy.deploy_at }}</p>
                                  </div>
                              </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <br><br>
                                    <h5 class="card-title">Descrição</h5>
                                    <p class="card-text">{{ wfdeploy.description }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade p-3" id="two" role="tabpanel" aria-labelledby="two-tab" v-if="wfdeploy.origin == 2">
                            <h5 class="card-title">Chamados relacionados a esta solicitação:</h5><br>
                            <h5><span v-for="ticket in wfdeploy.tickets" :key="ticket.id" class="badge badge-info spacing">{{ticket.ticket_id }}</span></h5>
                        </div>
                        <div class="tab-pane fade p-3" id="three" role="tabpanel" aria-labelledby="three-tab">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5 class="card-title">Documentos de QA</h5><br>
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nome do Arquivo</th>
                                                <th scope="col">Etapa</th>
                                                <th scope="col">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="attach in filteredQA" :key="attach.id">
                                                <td>{{ attach.attachment }}</td>
                                                <td>{{ attach.step }}</td>
                                                <td>
                                                    <button type="button"  @click="downloadLink(attach.id)" class="btn btn-crud download" data-tooltip="tooltip" title="Download"><i class="fas fa-download"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-6">
                                    <h5 class="card-title">Anexos</h5><br>
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nome do Arquivo</th>
                                                <th scope="col">Etapa</th>
                                                <th scope="col">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="attach in filteredAttachs" :key="attach.id">
                                                <td>{{ attach.filename }}</td>
                                                <td>{{ attach.step }}</td>
                                                <td>
                                                    <button type="button"  @click="downloadLink(attach.id)" class="btn btn-crud download" data-tooltip="tooltip" title="Download"><i class="fas fa-download"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            </div>

                            <div class="tab-pane fade p-3" id="four" role="tabpanel" aria-labelledby="four-tab">

                                <div class="container mt-0 mb-5">
                                    <div class="row">
                                        <div class="col-md-12 offset-md-0">
                                            <h4>Histórico</h4>
                                            <ul class="timeline">
                                                <li v-for="history in wfdeploy.history_deploy" :key="history.id">
                                                    <a target="_blank" href="#">Considerações/Informações do {{ history.step == 2 ? 'QA' : 'Deploy' }}</a>
                                                    <a href="#" class="float-right">{{ history.created_at }}</a>
                                                    <p>{{ history.observation }}</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import vSelect from 'vue-select'

    export default {
        data() {
            return {
                wfdeploy: {
                    history_deploy: [{
                        description: ''
                    }],
                    project: {
                        name:''
                    },
                    user: {
                        name:''
                    },
                    sprint: {
                        name:''
                    },
                    user_qa: {
                        name:''
                    },
                    tickets: [],
                    sprint_id: '',
                    description: '',
                    origin:[
                        {
                            id: '1',
                            name: 'Projeto'
                        },
                        {
                            id: '2',
                            name: 'Chamado'
                        }
                    ],
                    observation:'',
                },

            }
        },
        computed: {
            filteredSprints: function(){
                return this.sprints.filter(x => x.project_id == this.wfdeploy.project_id);
            },
            filteredQA: function(){
                return this.wfdeploy.wf_attachments.filter(x => x.type == 'QADoc');
            },
            filteredAttachs: function(){
                return this.wfdeploy.wf_attachments.filter(x => x.type == 'Anexo');
            }
        },
        methods: {

            save: function (status) {

                const api = this.$store.state.api + 'wfdeploys/' + this.$route.params.id;
                const self = this;

                let form  = new FormData();

				let qaDoc = document.getElementById('qaDoc');
                let attachments = document.getElementById('attachments');

                console.log(this.user.id)

                form.append('_method', 'put');

                form.append('observation', self.wfdeploy.observation);
                form.append('status', status);
                form.append('status_deploy', status);
                form.append('step', '2');

                form.append('qaDoc', (qaDoc.files[0]) ? qaDoc.files[0] : '');

                form.append('attachments', attachments.files.length);
                for(var i=0; attachments.files.length > i; i++){
                    form.append('attachments'+i, (attachments.files[i]) ? attachments.files[i] : '');
                }

                self.$http.post(api, form)
                    .then((response) => {

                        self.$message('Sucesso', `Solicitação registrada com sucesso`, 'success');
                        this.$router.push('/wfdeploys/qa');

                    })
                    .catch((error) => {
                        self.errors = error.response.data;

                        if(typeof self.errors === 'object'){
                            let html = '<ul>';

                            $.each(self.errors, function(key, value){
                                html += '<li>' + value[0] + '</li>';
                            });

                            html += '</ul>';

                            self.errors = html;
                        }

                        self.$message(null, self.errors, 'error');
                    });

            },

            getWFDeploy: function (id) {

                const self = this;
                const api = self.$store.state.api + 'wfdeploys/'+ id + '?with[]=project&with[]=sprint&with[]=user&with[]=tickets&with[]=wfAttachments&with[]=user_qa&with[]=user_deploy&with[]=deployStatus&with[]=historyDeploy';

                self.$http.get(api)
                    .then((response) => {
                        self.wfdeploy = response.data.data[0]
                    })
                    .catch((error) => {
                        self.$message(null, error.response.data, 'error');
                    });
            },

            downloadLink(id) {

                window.open(this.$store.state.api +"attachments/download/" + id, '_blank');

            },
        },
        mounted: function(){
            const self = this;

            let id = self.$route.params.id;
            if(id){
                self.getWFDeploy(id);
            }

            this.user = this.$store.getters.user;
        },
        components: {
            vSelect
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}
</style>
