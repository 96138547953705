<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <button id="addCard" @click="addCard" class="btn btn-primary btn-sm">
          <i class="fa fa-plus"></i>
        </button>
        <button
          id="addMultipleCard"
          @click="addMultipleCard"
          class="btn btn-success btn-sm"
        >
          <i class="fa fa-clone"></i>
        </button>
        <button @click="filters" class="btn btn-warning btn-sm">
          <i class="fa fa-filter"></i>
        </button>
        <button @click="refresh" class="btn btn-info btn-sm">
          <i class="fa fa-sync"></i>
        </button>
        <button @click="help" class="btn btn-danger btn-sm">
          <i class="fa fa-question"></i>
        </button>
        <button @click="addColumnDiv" class="btn btn-purple btn-sm">
          <i class="fas fa-plus-square"></i>
        </button>
        <div>
          <h6 v-if="pointsWeekSum">Pontos na semana: {{ pointsWeekSum }}</h6>
          <h6 v-else>Pontos na semana: 0</h6>
          <h6 v-if="pointsTodaySum">Pontos do dia: {{ pointsTodaySum }}</h6>
          <h6 v-else>Pontos do dia: 0</h6>
        </div>
      </div>
    </div>

    <div class="collapse" id="addColumnDiv">
      <div class="card card-body">
        <div class="row">
          <div class="col-3">
          Nome da Coluna:
          <input
            class="form-control"
            v-model="addColumnName"
          />
          </div>
        </div>
        <button @click="addColumn()" class="btn btn-primary btn-sm col-2" style="margin-left:45px; margin-top:10px">
          Adicionar coluna
        </button>
      </div>
    </div>

    <div class="collapse" id="help">
      <div class="card card-body">
        <div class="row"></div>
        <div class="row">
          <h6 style="margin-left: 17px">Legenda Criticidades:</h6>
          <div class="col-md-2">
            <div
              class="
                px-3
                h-6
                rounded-full
                text-xs
                font-semibold
                flex
                items-center
                bg-yellow-100
                text-yellow-700
              "
            >
              <span class="w-2 h-2 rounded-full mr-1 bg-yellow-400"></span>
              <span>Baixa</span>
            </div>
          </div>
          <div class="col-md-2">
            <div
              class="
                px-3
                h-6
                rounded-full
                text-xs
                font-semibold
                flex
                items-center
                bg-green-100
                text-green-700
              "
            >
              <span class="w-2 h-2 rounded-full mr-1 bg-green-400"></span>
              <span>Normal</span>
            </div>
          </div>
          <div class="col-md-2">
            <div
              class="
                px-3
                h-6
                rounded-full
                text-xs
                font-semibold
                flex
                items-center
                bg-purple-100
                text-purple-700
              "
            >
              <span class="w-2 h-2 rounded-full mr-1 bg-purple-400"></span>
              <span>Alta</span>
            </div>
          </div>
          <div class="col-md-2">
            <div
              class="
                px-3
                h-6
                rounded-full
                text-xs
                font-semibold
                flex
                items-center
                bg-red-100
                text-red-700
              "
            >
              <span class="w-2 h-2 rounded-full mr-1 bg-red-400"></span>
              <span>Crítico</span>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div class="row">
          <h6 style="margin-left: 17px; margin-top: 14px">Atalhos:</h6>
          <div class="col-md-2">
            Card =
            <div class="keyboard__row keyboard__row--h3">
              <div class="key--letter key--word key--w1" data-key="18">
                <span>Alt</span>
              </div>
              <div class="key--letter key--word key--w1" data-key="187">
                <span>+</span>
              </div>
              <div
                class="key--letter key--word key--w1"
                data-char="C"
                data-key="67"
              >
                <span>C</span>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            Pesquisar =
            <div class="keyboard__row keyboard__row--h3">
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>Alt</span>
              </div>
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>+</span>
              </div>
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>P</span>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            Refresh =
            <div class="keyboard__row keyboard__row--h3">
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>Alt</span>
              </div>
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>+</span>
              </div>
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>R</span>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            Info =
            <div class="keyboard__row keyboard__row--h3">
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>Alt</span>
              </div>
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>+</span>
              </div>
              <div class="key--letter key--word key--w1" data-key="18-R">
                <span>I</span>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>

    <div class="collapse" id="help">
      <div class="card card-body">
        <div class="row">
          <div class="col-md-4">
            Legenda Criticidades:
            <span class="badge badge-Low">Baixa</span>
            <span class="badge badge-Normal">Normal</span>
            <span class="badge badge-High">Alta</span>
            <span class="badge badge-Critical">Critica</span>
          </div>
        </div>
      </div>
    </div>

    <div class="collapse" id="filters">
      <div class="card card-body">
        <div class="row">
          <div class="col-md-4">
            <label for>Data Fechados</label>
            <input
              type="date"
              class="form-control"
              @change="refresh"
              v-model="ultimaAtualizacao"
            />
          </div>
          <div class="col-md-4">
            <label for>Projetos</label>
            <v-select
              :options="projects"
              :filterable="false"
              v-model="project"
              label="Projeto"
              @refreshT="refreshTable"
              @search="searchProject"
              @input="selectedProject"
            >
              <template slot="no-options"
                >Digite para pesquisar Projetos</template
              >
              <template slot="option" slot-scope="option">
                <div class="d-center">{{ option.name }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </v-select>
          </div>
          <div class="col-md-4">
            <label for>Sprint</label>
            <v-select
              :options="sprints"
              :reduce="(sprints) => sprints.id"
              v-model="sprint_id"
              label="Sprint"
              @input="loadIssues"
            >
              <template slot="no-options"
                >Selecione um projeto para listar as sprints</template
              >
              <template slot="option" slot-scope="option">
                <div class="d-center">{{ option.name }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
    <div class="col-md-12">
      <column-modal
        :modalStatus = "columnModalStatus"
        :column = "selectedColumn"
        :closeColumnModal = "closeColumnModal"
        :refresh = "refresh"
      >
      </column-modal>
      <issue-modal
        :modalMode="issueModal"
        :modalStatus="issueModalStatus"
        :commentsStatus="commentsModalStatus"
        :selectedIssue="selectedIssue"
        @changeModal="changeIssueModal"
        @refresh="refresh"
      ></issue-modal>
      <br />
      <issue-multiple-modal
        :modalMode="issueMultipleModal"
        :modalStatus="issueMultipleModalStatus"
        :selectedIssue="selectedIssue"
        @changeModal="changeIssueMultipleModal"
        @refresh="refresh"
      ></issue-multiple-modal>

      <hr />
    </div>
    <div class="side-scroller">
      <div style="transform: rotateX(180deg)">
        <draggable
          :list="columns" 
          :animation="200"
          group="columns"
          class="col-md-12 row all-flex "
          @end="changedColumn"
          @change="changedColumn"
        >
        <div v-for="(column, index) in columns" :key="column.id" class="col-md-4" style="padding-bottom:15px">
          <h4
            style="cursor:pointer"
            class="text-gray-700 font-semibold font-sans tracking-wide text-sm"
            :style="{
              'background-color': column.color,
            }"
          >
            {{ column.title }}
            <i 
            v-if="column.title != 'done' && column.title != 'todo'" 
            class="fas fa-times float-right"
            @click="deleteColumn(index, column.id)"
            style="margin-top:2px; margin-right:3px"
            ></i>
            <i 
            v-if="column.title != 'done' && column.title != 'todo'" 
            class="fas fa-edit float-right"
            @click="editColumn(column)"
            style="margin-top:2px; margin-right:3px"
            ></i>
          </h4>
          <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
          <draggable
            :list="column.tasks"
            :animation="200"
            ghost-class="ghost-card"
            group="tasks"
            class="dragg-list"
            @end="changed"
            @change="changed"
          >
            <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
            <task-card
              v-for="(task, index) in column.tasks"
              :key="task.id"
              :task="task"
              :column="column"
              :index="index"
              class="mt-3 cursor-move"
              @cardEdit="cardEdit(task)"
              @cardComments="cardComments(task)"
              @deleteIssue="deleteIssue"
            ></task-card>
            <!-- </transition-group> -->
          </draggable>
        </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import TaskCard from "./TaskCard";
import IssueModal from "./IssueModal";
import ColumnModal from "./ColumnModal";
import IssueMultipleModal from "./IssueMultipleModal";
import vSelect from "vue-select";

import _ from "lodash";

export default {
  components: {
    TaskCard,
    draggable,
    ColumnModal,
    IssueModal,
    IssueMultipleModal,
    vSelect,
  },
  data() {
    return {
      selectedColumn: "",
      addColumnName: "",
      ultimaAtualizacao: "",
      sprint_id: 0,
      projects: [],
      project: 0,
      sprints: [],
      sprint: 0,
      selectedIssue: {},
      columns: [],
      columnModalStatus: false,
      issueModal: "add",
      commentsModalStatus: false,
      issueModalStatus: false,
      issueMultipleModal: "add",
      issueMultipleModalStatus: false,
      lastElementAdded: null,
      pointsWeekSum: {},
      pointsTodaySum: {},
    };
  },
  mounted() {
    let self = this;

    self.getAnalytcs();

    this.loadIssues();
    // atalho para novo card ALT+C
    window.addEventListener("keyup", function (event) {
      if (event.altKey && event.keyCode === 67) {
        self.addCard(event);
      }
    });
    // atalho para info ALT+I
    window.addEventListener("keyup", function (event) {
      if (event.altKey && event.keyCode === 73) {
        self.help(event);
      }
    });
    // atalho para reload dos cards ALT+R
    window.addEventListener("keyup", function (event) {
      if (event.altKey && event.keyCode === 82) {
        self.refresh(event);
      }
    });
    // atalho para pesquisa data + projeto ALT+P
    window.addEventListener("keyup", function (event) {
      if (event.altKey && event.keyCode === 80) {
        self.filters(event);
      }
    });
  },
  methods: {
    changedColumn(event) {
      let self = this;
      let api = this.$store.getters.api + "user_issue_columns/moveColumns";
      self.$http
        .post(api, 
        self.columns)
        .then((response) => {
          
        });
    },
    closeColumnModal() {
      this.columnModalStatus = false;
      $("#columnModal").modal("toggle");
    },
    addColumn(){
      const self = this;
      const api = self.$store.state.api + "user_issue_columns";

      self.$http
        .post(api, {
          "title" : self.addColumnName,
          "userId" : self.user.id       
        })
        .then((response) => {
          self.$message("Sucesso!", response.data, "success");
          self.loadIssues();
          self.addColumnName = '';
        })
        .catch((error) => {
          self.$message("Erro", error.response.data, "error");
        });
    },
    set(value) {
      if (this.$store.commit("updateList", value)) {
        self.changend(event);
      }
    },
    changed(event) {
      const self = this;
      if (event.added) {
        self.lastElementAdded = event.added.element;
      }

      if (event instanceof CustomEvent && self.lastElementAdded) {
        let from = $(event.from).siblings("h4").text();
        let to = $(event.to).siblings("h4").text();
        self.lastElementAdded.status = to;

        const api = self.$store.state.api + "move-issues";
        self.$http
          .post(api, {
            changed: [self.lastElementAdded],
            action: "moveIssue",
            key: "id",
          })
          .then((response) => {
            self.lastElementAdded = null;
          })
          .catch((error) => {
            self.$message("Erro", error.response.data, "error");
          });
      } else {
        const api = self.$store.state.api + "issues-save-index";
        self.$http.post(api, self.columns).catch((error) => {
          self.$message("Erro", error.response.data, "error");
        });
      }
    },

    loadIssues() {
      let self = this;
      let api = this.$store.getters.api + "my-issues";
      let issues = this.$http
        .get(api, {
          params: {
            closed_at: self.ultimaAtualizacao,
            project_id: self.project ? self.project.id : 0,
            sprint_id: self.sprint_id,
          },
        })
        .then((response) => {
          this.columns = response.data;
        });
    },
    searchProject: _.debounce(function (search) {
      let self = this;
      let api = this.$store.getters.api + "projects";
      if (search.length >= 3) {
        self.$http.get(api + "?name=" + search).then((response) => {
          self.projects = response.data.data;
        });
      }
    }, 300),
    selectedProject(project) {
      let self = this;
      let api = this.$store.getters.api + "sprints";

      self.$http.get(api + "?project_id=" + project.id).then((response) => {
        self.sprints = response.data.data;
      });
    },
    refreshTable: _.debounce(function (refreshT) {
      let self = this;
      this.refresh();
    }, 300),
    deleteIssue(id, index, column) {
      let self = this;
      let api = this.$store.getters.api + "issues/";

      swal({
        title: "Voce está certo disso?",
        text: "a exclusão do card não pode ser desfeita!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          confirm: {
            text: "Sim, deletar!",
            value: "confirm",
            className: "btn-danger",
          },
          catch: {
            text: "Cancelar",
            value: "cancel",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O card não foi excluido!", "info");
            break;

          case "confirm":
            self.$http
              .delete(api + id)
              .then((response) => {
                column.tasks.splice(index, 1);
              })
              .then(() => {
                self.$message(
                  "Sucesso",
                  "Card(s) Deletado(s) com sucesso",
                  "success"
                );
                this.refresh();
              });
            break;
        }
      });
    },
    deleteColumn(index, columnId) {
      let self = this;
      let api = this.$store.getters.api + "user_issue_columns/";

      swal({
        title: "Voce está certo disso?",
        text: "A exclusão da coluna também ira remover todos os cards contidos nela!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          confirm: {
            text: "Sim, deletar!",
            value: "confirm",
            className: "btn-danger",
          },
          catch: {
            text: "Cancelar",
            value: "cancel",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "A coluna não foi excluida!", "info");
            break;

          case "confirm":
            self.$http
              .delete(api + columnId)
              .then((response) => {
                self.columns.splice(index, 1);
              })
              .then(() => {
                self.$message(
                  "Sucesso",
                  "Coluna deletada com sucesso",
                  "success"
                );
                this.refresh();
              });
            break;
        }
      });
    },
    filters() {
      $("#filters").collapse("toggle");
    },
    help() {
      $("#help").collapse("toggle");
    },
    addColumnDiv() {
      $("#addColumnDiv").collapse("toggle");
    },
    refresh() {
      this.loadIssues();
      this.getAnalytcs();
    },
    changeIssueModal(status) {
      this.issueModalStatus = status;
      this.commentsModalStatus = status;
      // this.refresh();
    },
    changeIssueMultipleModal(status) {
      this.issueMultipleModalStatus = status;
    },
    cardEdit(card) {
      console.log("dblclick");
      card.cancel = true;
      this.selectedIssue = card;
      this.issueModal = "edit";
      this.issueModalStatus = true;
    },
    cardComments(card) {
      this.selectedIssue = card;
      this.issueModalStatus = false;
      this.commentsModalStatus = true;
    },
    addMultipleCard() {
      this.issueMultipleModal = "add";
      this.issueMultipleModalStatus = true;
    },
    addCard() {
      this.issueModal = "add";
      this.issueModalStatus = true;
    },
    editColumn(column) {
      this.selectedColumn = column;
      this.columnModalStatus = true;
    },
    getIssues: function () {
      const self = this;
      const api = self.$store.state.api + "my-issues/?paginated=false";

      self.$http
        .get(api)
        .then((response) => {
          self.issues = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getAnalytcs: function () {
      this.user = this.$store.state.user;
      let self = this;
      this.$http
        .get(this.$store.getters.api + "analytics/summary")
        .then((response) => {
          self.pointsWeekSum = response.data.pointsWeekSum.data[0];
          self.pointsTodaySum = response.data.pointsTodaySum.data[0];
        });
    },
  },
};
</script>

<style scoped>
.dragg-list {
  min-height: 503px;
  border: 1px solid grey;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f7fafc;
}

.column-width {
  min-width: 320px;
  width: 320px;
}
/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}
.text-gray-700 {
  padding-left: 15px;
}
button:focus,
input:focus,
select:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
}
.column-width {
  /* min-width: 320px; */
  width: 380px;
}
.bg-gray-100 {
  --bg-opacity: 1;
  background-color: rgb(251, 253, 255);
}
.border-white {
  border-color: rgba(53, 53, 53, 0.16) !important;
}
.text-title {
  padding-left: 4px;
}
.keyboard {
  text-align: center;
  font-size: 14px;
  font-family: sans-serif;
}
.keyboard__row {
  display: inline-block;
  height: 1em;
  margin: 0.2em;
}
.keyboard__row--h1 {
  height: 1.7em;
  line-height: 1.4em;
}
.keyboard__row--h3 {
  height: 1.6em;
}
.keyboard__row > * {
  position: relative;
  background: rgb(138, 135, 135);
  text-align: center;
  color: #eee;
  float: left;
  border-radius: 0.3em;
  margin: 0.2em;
  padding: 0.2em;
  width: 1.8em;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  border: 1px solid #444;
  box-shadow: 0 0.2em 0 0.05em rgb(75, 75, 75);
  border-bottom-color: #555;
}
.keyboard__row > div[data-pressed],
.keyboard__row > div:active {
  background: #2a2a2a;
  color: #aaa;
  position: relative;
  top: 0.2em;
  box-shadow: 0 0 0 0.05em black;
}
.key--letter {
  line-height: 1.2em;
}
.key--word > * {
  font-size: 0.8em;
}

.btn-purple {
  background-color:#6600CC;
  color:white;
  border-color:#6600CC
}

.btn-purple:hover {
  color:#fff
}
.side-scroller{
  overflow: auto;
  overflow-y: hidden;
  transform: rotateX(180deg); 
  padding-bottom:10px
}

.all-flex{
  display:flex; 
  flex-wrap:nowrap; 
  flex:0 0 auto
}
</style>

