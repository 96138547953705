<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">Nova Daily</div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <label for="title">O Que fez Ontem?</label>
          <textarea class="form-control text-left" id="title" v-model="dailies.yesterday" rows="3" />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-12">
          <label for="title">O Que fara Hoje?</label>
          <textarea class="form-control text-left" id="title" v-model="dailies.today" rows="3" />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-12">
          <label for="title">Impedimentos ?</label>
          <textarea class="form-control text-left" id="title" v-model="dailies.impediments" rows="3" />
        </div>
      </div>
    </div>

    <div class="card-body">
      <hr />
      <div class="row mt-3">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <template>
              <button @click="$router.go(-1)" class="btn btn-info">
                <i class="fa fa-arrow-left"></i> Voltar
              </button>
            </template>
            <a class="btn btn-success" @click="save">
              Salvar
              <i class="fa fa-save"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  components: {},
  data() {
    return {
      dailies: {
        today: "",
        yesterday: "",
        impediments: "",
      },
    };
  },
  computed: {
    url() {
      return this.$store.getters.api + this.$route.params.id;
    },
  },

  mounted() {
    let self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getDailies(id);
    }
  },
  methods: {
    save: function () {
      let api = this.$store.state.api + "dailies";
      const self = this;

      let form = new FormData();

      form.append("today", self.dailies.today);
      form.append("yesterday", self.dailies.yesterday);
      form.append("impediments", self.dailies.impediments);

      if (self.dailies.id) {
        api += "/" + self.dailies.id;
        form.append("_method", "PUT");
      }

      self.$http
        .post(api, form)
        .then((response) => {
          self.$message("Sucesso", `Cadastrado com sucesso`, "success");

          this.$router.push("/daily");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
    },
    returnDaily() {
      swal({
        title: "Deseja mesmo VOLTAR?",
        text: "Você pode perder os dados inseridos!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Permanecer",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "Você não foi redirecionado", "info");
            break;

          case "confirm":
            this.$router.push({
              path: "/daily",
            });
            break;
        }
      });
    },
  },

  getDailies: function (id) {
    let self = this;
    let api = self.$store.state.api + "dailies/" + id;

    self.$http
      .get(api)
      .then((response) => {
        self.dailies = response.data.data[0];
      })
      .catch((error) => {
        self.errors = error.response.data;
        self.$message(null, self.errors, "error");
      });
  },
};
</script>

<style scoped></style>
