<template>
  <div>
    <!-- cards content -->
    <div class="card-group">
      <div class="card">
        <div class="card-body">
          <h5 class="header">Fechamento Mensal do Contrato: {{contract.name}} - ID: {{contract.id}}</h5>
          <div class="row">
            <div class="col-md-4">
              <label for>Data Inicial</label>
              <input type="month" class="form-control" v-model="month" />            
            </div>           
            <div class="col-md-12">
              <br>
              <button @click="endMonth" class="btn btn-primary">Fechar Mês</button>              
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h5 class="header">Resumo Saldo</h5>
        <div class="row">
          <div class="col-md-12">
            <table class="table table-striped">
              <thead>
                <tr>                                    
                  <th>Mês/Ano</th>
                  <th>Crédito</th>
                  <th>Débito</th>
                  <th>Saldo</th>                  
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="balance in balances" :key="balance.id">                                    
                  <td>{{balance.month + '/' + balance.year}}</td>
                  <td>{{balance.credit}}</td>
                  <td>{{balance.debit}}</td>
                  <td>{{balance.balance}}</td>
                  <th>
                    <button class="btn btn-danger" @click=deleteMonthEnd(balance.id)>
                      <i class="fa fa-trash"></i> Deletar
                    </button>
                  </th>
                </tr>
              </tbody>
              <tfoot v-if="balances.length > 0">
                <tr>
                  <td colspan="6">Total</td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    let self = this;
    return {
      contract: {},
      month: "",      
      balances: [],
    };
  },
  mounted() {
    this.getContract();
    this.getBalances();
  },
  methods: {    

    deleteMonthEnd(id){
      const self = this;
      
      const api = self.$store.state.api + "contractmanagers/balances/" + id;

      self.$http
        .delete(api)
        .then((response) => {
          self.$message('Sucesso', 'Deletado com sucesso','success');
        })
        .then( () => {
          self.getBalances();
        })
        .catch((error) => {          
          self.$errorMessage(error);
        });
    },
    getContract() {
      const self = this;
      let id = self.$route.params.id;

      const api = self.$store.state.api + "contracts/" + id;

      self.$http
        .get(api)
        .then((response) => {
          self.contract = response.data.data[0];
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
    getBalances(){
      const self = this;
      let id = self.$route.params.id;

      const api = self.$store.state.api + "contractmanagers/balances/" + id;

      self.$http
        .get(api)
        .then((response) => {
          self.balances = response.data;
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
    endMonth(){
      const self = this;
      let id = self.$route.params.id;

      const api = self.$store.state.api + "contractmanager/end-month";

      self.$http
        .post(api,{
          contract_id: id,
          month: self.month
        })
        .then((response) => {
          self.contract = response.data
        })
        .then(() => {
          self.getBalances()
        })
        .catch((error) => {          
          self.$errorMessage(error);
        });
    }
  },
};
</script>

<style scoped>
</style>