import Crud from '../components/ProcessArea/Crud'
import Add from '../components/ProcessArea/Add'
import Edit from '../components/ProcessArea/Edit'

let routes = {
    path: '/process_areas',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/process_areas/add',
            name: 'Novo Registro',
            component: Add
        },
        {
            path: '/process_areas/edit/:id?',
            name: 'Editar Registro',
            component: Edit
        },
        {
            path: '/process_areas',
            name: 'Registros',
            component: Crud
        },
    ]
};


export default routes
