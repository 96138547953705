<template>
   <div class="card" v-cloak>
        <div class="card-header animated fadeInRightBig">
            <div class="card-title">Gerenciar Projeto {{project.name}}

            </div>
        </div>
        <div class="card-body">
            <h4>Nova Sprint</h4>
            <form @submit.prevent>
                <div class="row">
                    <div class="col-md-3">
                        <label for="">Nome da Sprint</label>
                        <input type="text" class="form-control" name="name" v-model="newSprint.name">
                    </div>
                    <div class="col-md-3">
                        <label for="">Início</label>
                        <input type="date" class="form-control" name="start_date" v-model="newSprint.start_date">
                    </div>
                    <div class="col-md-3">
                        <label for="">Fim</label>
                        <input type="date" class="form-control" name="end_date" v-model="newSprint.end_date">
                    </div>
                    <div class="col-md-3">
                        <label for="">Status</label>
                        <select name="status'" class="form-control" v-model="newSprint.status">>
                            <option value="">Selecione</option>
                            <option value="ACTIVE">Ativo</option>
                            <option value="CLOSED">Fechado</option>
                            <option value="FUTURE">Futura</option>
                        </select>
                    </div>
                </div>
                <br>
                    <div class="row">
                      <div class="col-md-2">
                        <button class="btn btn-info" @click="saveAddSprint">Salvar</button>
                        <router-link to="/projects" class="btn btn-warning">
                              <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      </div>
                    </div>
                     <br>
                    <br>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="col-md-6">
                           <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>Sprints do(a) {{project.name}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="sprint in project.sprints" :key="sprint.id"
                          >
                            <td>{{ sprint.name }}</td>
                          </tr>
                        </tbody>
                      </table>
                        </div>
                      </div>
                    </div>
            </form>


        </div>
   </div>
</template>

<script>
export default {
    data(){
        return {
            project: {
                sprints: [
                    {
                        name: "Sprint 1",
                        jira_id: 0,
                        start_date: new Date().toLocaleString(),
                        end_date: new Date().toLocaleString(),
                        status: 'Active',
                        project_id: 1,
                        issues: []
                    }
                ],
            },

            sprint: {
                active: false
            },
            newSprint: {
                name: '',
                start_date: '',
                end_date: '',
                status: '',
                project_id: ''
            }
        }
    },
    mounted () {

        this.loadProject()

    },
    computed: {

    },
    methods: {
        saveAddSprint(){
            let self = this

            let api = this.$store.getters.api + 'projects/create-sprint'
            this.newSprint.project_id = this.project.id
            self.$http.post(api,this.newSprint)
            .then( response => {
                self.$message('Sucesso', 'Sprint criada com sucesso', 'success');
                this.loadProject()
            })
            .catch( response => {

            })
        },
        loadProject(){
            let self = this

            self.$http.get(self.$store.getters.api + 'projects/' + self.$route.params.id,{
                params: {
                    with: ['sprints', 'sprints.issues','backlog']
                }
            })
            .then(response => {
                self.project = response.data.data[0]
            })
            .catch(response => {

            })
        },
    }
}
</script>

<style scoped>
table{
  margin-top: 15px;
  border: 4px solid  #299bff;
}
td {
  font-weight: bold;
  text-align: center;
}
th {

  background-color: #299bff;
  color: white !important;
  text-align: center;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
}

.pull-right {
    float: right;
}

.list-group-item{
    margin-bottom: 5px;
}

.hidden {
    display:none;
}

</style>
