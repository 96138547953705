<template >
  <div class="card-body">
    <div>
      <div v-show="showLoad" class="load">
        <div class="spin"></div>
        <div class="loading">CARREGANDO</div>
      </div>
      <!-- <h2>Vizualizar Ticket</h2> -->
      <div class="row">
        <div class="col-md-1">
          <h5 class="card-title">N°</h5>
          <p class="card-text">#{{ ticket.code }}</p>
        </div>
        <div class="col-md-2">
          <h5 class="card-title">Assunto</h5>
          <p class="card-text">
            {{ ticket.subject }}
            <span data-toggle="modal" data-target="#editarModal" style="cursor: pointer; color: blue"><i
                class="fas fa-pen-square"></i> Editar</span>
          </p>
        </div>
        <div class="modal fade" id="editarModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Alterar Assunto
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <input type="text" class="form-control" v-model="ticket.subject" />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary p-1 ml-3 float-right" data-dismiss="modal" aria-label="Close"
                  @click="saveSubject()">
                  <i class="fa fa-floppy-o"></i> Salvar
                </button>
                <button type="button" class="btn btn-secondary p-1 ml-3 float-right" data-dismiss="modal"
                  aria-label="Close">
                  <i class="fa fa-times"></i> Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <h5 class="card-title">Responsável no Cliente</h5>
          <p class="card-text">
            {{ ticket.sent_by ? ticket.sent_by.name : "" }}
          </p>
        </div>
        <div class="col-md-2">
          <h5 class="card-title">Cliente</h5>
          <p class="card-text">
            {{
              ticket.sent_by && ticket.sent_by.customers
              ? ticket.sent_by.customers.customer_name
              : ""
            }}
          </p>
        </div>
        <div class="col-md-2">
          <h5 class="card-title">Prioridade</h5>
          <p class="card-text">
            {{ ticket.priority_description }}
          </p>
        </div>
        <div class="col-md-2">
          <h5 class="card-title">SLA</h5>
          <p class="card-text">
            {{ ticket.SLA }}
          </p>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-12" v-if="ticket.people_attached">
          <h5 class="card-title">Pessoas em Cópia</h5>
          <div class="people-attached">
            {{ ticket.people_attached }}
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="peopleAttached">Pessoas em Cópia</label>
            <input type="text" class="form-control" id="peopleAttached" @keydown="handleKeyDown" v-model="attached.mail"
              autocomplete="false" />
          </div>
          <div class="row">
            <div style="margin-left: 1%;" v-for="(item, index) in this.attached.peopleAttached" :key="index">
              <div class="itens-mail p-2">
                {{ item }}
                <button class="close-button" @click="removeMail(index)" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Clique para remover">&times;</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <hr />
      <!-- Historico de Chamados -->
      <div class="row">
        <div class="col-md-8">
          <div role="tabpanel" class="card-header">
            <div class="card-title">
              <h3>Histórico do Chamado</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label for>Responder Chamado</label>
              <ckeditor v-bind:disabled="this.saveEnable == false" :editor="editor" v-model="interactionNew.description"
                :config="editorConfig"></ckeditor>
            </div>
            <div class="card-body">
              <div class="col-md-12 d-flex justify-content-between">
                <div class="form-check form-check-inline d-flex align-items-start">
                  <input class="form-check-input" type="checkbox" id="inputCheck" value="checked"
                    v-model="interactionNew.checkIteraction" />
                  <label class="form-check-label" for="inputCheck">Enviar notificação</label>
                </div>


              </div>


            </div>

          </div>
          <div class="row ">
            <div class="col-md-12">
              <div class="d-flex justify-content-between">
                <button class="btn btn-info" v-bind:disabled="this.saveEnable == false" @click="returnDaily">
                  <i class="fa fa-arrow-left"></i> Voltar
                </button>
                <button v-bind:disabled="this.saveEnable == false" class="btn btn-success ml-auto" @click="save">
                  Responder Chamado <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="card history-tickets" v-for="(history, index) in ticket.interactions" :key="index">
            <div class="row">
              <div class="col-md-12">
                <div class="container" style="background-color: #fff" v-if="history.order == '1'">
                  <h4 class="card-title text-left">Mensagem do Cliente</h4>
                  <span class="customer-right" style="margin-top: -20px">
                    <i>Cliente</i>
                  </span>
                  <br />
                  <span class="time-right" style="margin-top: -20px">
                    <i>{{ history.created }}</i>
                  </span>
                  <br />
                  <span class="ticket-history" v-html="history.body"></span>
                  <br />
                </div>

                <div class="container" style="background-color: #deebff" v-else-if="history.order == '3'">
                  <h4 class="card-title text-left">Alteração de Status</h4>
                  <span class="time-right" style="margin-top: -30px">
                    <button class="btn" @click="updateInteraction(history)">
                      <i>{{ history.created }}</i>
                    </button>
                  </span>
                  <br />
                  <span v-html="history.body"></span>
                  <br />
                </div>

                <div class="container darker text-left" v-else>
                  <h4 class="card-title text-right">Resposta InnovareTI</h4>
                  <span class="innover-left" style="margin-top: -20px">
                    <i>{{
                      history.user_interaction_id
                      ? history.user_interaction.name
                      : "Não Identificado"
                    }}</i>
                  </span>
                  <br />
                  <span class="time-left" style="margin-top: -20px">
                    <i>{{ history.created }}</i>
                  </span>
                  <br />
                  <span v-html="history.body"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div role="tabpanel" class="card-header">
            <div class="card-title">
              <h3>Mais Informações</h3>
            </div>
          </div>

          <div class="row">
            <div class="container card-title" style="background-color: #fff">
              <div role="tabpanel" class="card-header">
                <div class="card-title">
                  <h6>Responsável pelo chamado</h6>
                </div>
              </div>
              <select v-bind:disabled="this.saveEnable == false" v-if="!ticket.responsible" class="form-control"
                v-model="ticket.responsible_id" @change="setResponsible()">
                <option v-for="user in users" :key="user.id" :value="user.id">
                  {{ user.name }}
                </option>
              </select>
              <div class="row" v-if="ticket.responsible">
                <div class="col-md-3">
                  <img class="photo" :src="ticket.responsible.photo" alt="sunil" width="70%" />
                </div>
                <div class="col-md-9">
                  <div class="alert alert-info ipp-alert inline w-100" v-if="ticket.responsible">
                    {{ ticket.responsible.name }}
                    <div class="text-right inline float-right">
                      <button class="btn btn-danger" @click="removeResponsible()">
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="container card-title" style="background-color: #fff">
              <div role="tabpanel" class="card-header">
                <div class="card-title">
                  <h6>Observacao</h6>
                </div>
              </div>
              <input type="text" class="form-control" v-model="ticket.classification" @blur="updateFields()" />
            </div>


          </div>

          <div class="row">

            <div class="border border-primary card-title col-md-12" style="background-color: #fff">
              <div role="tabpanel" class="card-header">
                <div class="card-title">
                  <h6>Classificação</h6>
                </div>
              </div>
              <v-select :options="classifications" v-model="ticket.classification_id" label="classification"
                :reduce="classification => classification.id" @option:selected="classificationSelected">
              </v-select>

            </div>
          </div>

          <div class="row">
            <div class="container card-title" style="background-color: #fff">
              <div role="tabpanel" class="card-header">
                <div class="card-title">
                  <h6>Contrato</h6>
                </div>
              </div>
              <select v-bind:disabled="this.saveEnable == false" v-if="!ticket.contract" class="form-control"
                v-model="ticket.contract_id" @change="updateFields()">
                <option v-for="contract in contracts" :key="contract.id" :value="contract.id">
                  {{ contract.id + "-" + contract.name }}
                </option>
              </select>
              <div class="row" v-if="ticket.contract">
                <div class="col-md-9">
                  <div class="alert alert-info ipp-alert inline w-100">
                    {{ ticket.ticket.contract ? contract.name : "" }}
                    <div class="text-right inline float-right">
                      <button class="btn btn-danger" @click="removeContract()">
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="container card-title" style="background-color: #fff">
              <div role="tabpanel" class="card-header">
                <div class="card-title">
                  <h6>Categoria do chamado</h6>
                </div>
              </div>
              <select v-bind:disabled="this.saveEnable == false" class="form-control" v-model="ticket.category_id"
                @change="updateFields()">
                <option v-for="category in ticketCategories" :key="category.id" :value="category.id">
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="container card-title" style="background-color: #fff">
              <div role="tabpanel" class="card-header">
                <div class="card-title">
                  <h6>Nome do Processo</h6>
                </div>
              </div>
              <select v-bind:disabled="this.saveEnable == false" class="form-control" v-model="ticket.process_name_id"
                @change="updateFields()">
                <option v-for="process_name in process_names" :key="process_name.id" :value="process_name.id">
                  {{ process_name.process_name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="container card-title" style="background-color: #fff">
              <div role="tabpanel" class="card-header">
                <div class="card-title">
                  <h6>Área do Processo</h6>
                </div>
              </div>
              <select v-bind:disabled="this.saveEnable == false" class="form-control" v-model="ticket.process_area_id"
                @change="updateFields()">
                <option v-for="process_area in process_areas" :key="process_area.id" :value="process_area.id">
                  {{ process_area.process_areas }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="container card-title" style="background-color: #fff">
              <div role="tabpanel" class="card-header">
                <div class="card-title">
                  <h6>Status do Chamado</h6>
                </div>
              </div>
              <div align="center">
                {{ ticket.status_entity ? ticket.status_entity.title : "" }}
              </div>
              <div align="center" style="margin-top: 15px">
                <button v-bind:disabled="this.saveEnable == false" class="btn btn-primary btn-sm" data-toggle="modal"
                  data-target="#status">
                  <i class="fas fa-exchange-alt"></i> Alterar Status
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="container" style="background-color: #fff">
              <div role="tabpanel" class="card-header">
                <div class="card-title">
                  <h6>Horas de Atendimento</h6>
                </div>
              </div>
              <div v-if="ticket.service_hours && ticket.service_hours.length">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Início</th>
                      <th>Fim</th>
                      <th>Total</th>
                      <th>Deletar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="service_hour in ticket.service_hours" :key="service_hour.id" data-toggle="tooltip"
                      data-placement="top" :title="service_hour.comment">
                      <td>
                        <img :src="service_hour.responsible.photo" class="responsible-hours-photo" />
                      </td>
                      <td>
                        {{ formatDate(service_hour.start) }}
                      </td>
                      <td>{{ formatDate(service_hour.end) }}</td>
                      <td>
                        {{ service_hour.total_general }}
                      </td>
                      <td>
                        <button style="background: none; border: none" @click="deleteHour(service_hour.id)">
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <hr />

                <div class="row">
                  <div class="col-md-12 text-center">
                    <b>Total:</b>
                    {{ calculateTotalTimeServiceHours() }}
                  </div>
                </div>
              </div>

              <div align="center" style="margin-top: 15px">
                <button v-bind:disabled="this.saveEnable == false" class="btn btn-primary btn-sm" data-toggle="modal"
                  data-target="#serviceHours">
                  <i class="far fa-clock"></i> Lançar Horas
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="container card-title" style="background-color: #fff">
              <div role="tabpanel" class="card-header">
                <div class="card-title">
                  <h6>Prioridade</h6>
                </div>
              </div>
              <select class="form-control" v-model="ticket.priority" @change="updateFields()">
                <option value="">Selecione...</option>
                <option value="1">Baixa</option>
                <option value="2">Normal</option>
                <option value="3">Alta</option>
                <option value="4">Urgente</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="container card-title" style="background-color: #fff">
              <div role="tabpanel" class="card-header">
                <div class="card-title">
                  <h6>Devs acompanhando este chamado</h6>
            
                </div>
              </div>
              <div class="row" v-if="selectedUser">
                <div class="col-md-3">
                  <img class="photo" :src="selectedUser.photo" alt="sunil" width="70%" />
                </div>
                <div class="col-md-9">
                  <div class="alert alert-info ipp-alert inline w-100">
                    {{ selectedUser.name}}
                  </div>
                </div>
              </div>
              <div class="row" v-for="dev in ticket.devs" :key="dev.id">
                <div class="col-md-3">
                  <img class="photo" :src="dev.user.photo" alt="sunil" width="70%" />
                </div>
                <div class="col-md-9">
                  <div class="alert alert-info ipp-alert" v-if="dev.user">
                    {{ dev.user.name }}
                  </div>
                </div>
              </div>
              <div class="row" v-if="ticket.dev">
                <div class="col-md-3">
                  <img class="photo" :src="dev.user.photo" alt="sunil" width="70%" />
                </div>
                <div class="col-md-9">
                  <div class="alert alert-info ipp-alert" v-if="ticket.user">
                    {{ dev.user.name }} nome do dev
                  </div>
                </div>
              </div>
              <div align="center" style="margin-top: 15px">
                <button v-bind:disabled="this.saveEnable == false" class="btn btn-primary btn-sm" data-toggle="modal"
                  data-target="#users">
                  <i class="fas fa-users"></i> Vincular Dev
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="container card-title" style="background-color: #fff">
              <div role="tabpanel" class="card-header">
                <div class="card-title">
                  <h6>Nº do Chamado</h6>
                </div>
              </div>

              <div class="row">
                <div style="padding-left: 50px"></div>
                <div class="col-md-9">
                  <div class="alert alert-info ipp-alert">
                    #{{ ticket.code }}
                  </div>
                </div>
              </div>

              <div align="center" style="margin-top: 15px">
                <button v-bind:disabled="this.saveEnable == false" class="btn btn-primary btn-sm" data-toggle="modal"
                  data-target="#ticketCode">
                  <i class="fas fa-sync-alt"></i> Alterar Nº Chamado
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="container card-title" style="background-color: #fff">
              <div role="tabpanel" class="card-header">
                <div class="card-title">
                  <h6>Responsável/Cliente</h6>
                </div>
              </div>

              <div class="row">
                <div style="padding-left: 50px"></div>
                <div class="col-md-9">
                  <div class="alert alert-info ipp-alert" v-if="ticket.sent_by">
                    {{ ticket.sent_by.name }}/{{
                      ticket.sent_by.customers
                      ? ticket.sent_by.customers.customer_name
                      : ""
                    }}
                  </div>
                </div>
              </div>

              <div align="center" style="margin-top: 15px">
                <button v-bind:disabled="this.saveEnable == false" class="btn btn-primary btn-sm" data-toggle="modal"
                  data-target="#responsible">
                  <i class="fas fa-user-tie"></i> Alterar Responsável
                </button>
              </div>
            </div>
          </div>

          <div class="row" v-if="ticket.attachments && ticket.attachments.length > 0">
            <div class="container card-title" style="background-color: #fff">
              <div role="tabpanel" class="card-header">
                <div class="card-title">
                  <h6>Anexos do Chamado</h6>
                </div>
              </div>

              <div class="row" v-for="attachment in ticket.attachments" :key="attachment.id">
                <div class="col-md-10">
                  <div class="limit-text-long-card" style="font-size: 12px; font-weight: normal" v-if="attachment">
                    <a :href="attachment.path" target="_blank">{{
                      attachment.name
                    }}</a>
                  </div>
                </div>
                <div class="col-md-2">
                  <a :href="attachment.path" target="_blank">
                    <i class="fas fa-download"></i>
                  </a>
                </div>
              </div>
              <!-- Botão Modal Adicionar Anexos -->
              <div align="center" style="margin-top: 15px">
                <button v-bind:disabled="this.saveEnable == false" class="btn btn-primary btn-sm" data-toggle="modal"
                  data-target="#attachModal">
                  <i class="fas fa-paperclip"></i> Adicionar Anexo
                </button>
              </div>
              <!-- Modal Adicionar Anexos -->
              <div class="modal fade" id="attachModal" tabindex="-1" role="dialog" aria-labelledby="attachModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-lg" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="attachModalLabel">
                        Novo Anexo
                      </h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <input type="file" name="file" class="form-control-file" id="attachment" />
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-primary" @click="saveAttachments()" data-dismiss="modal">
                        Salvar
                      </button>
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">
                        <i class="fa fa-times"></i>Fechar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="container card-title" style="background-color: #fff">
              <div role="tabpanel" class="card-header">
                <div class="card-title">
                  <h6>Anexos do Chamado</h6>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="limit-text-long-card" style="font-size: 12px; font-weight: normal">
                    <p>Nenhum Registro</p>
                  </div>
                </div>
              </div>
              <!-- Botão Modal Adicionar Anexos -->
              <div align="center" style="margin-top: 15px">
                <button class="btn btn-primary btn-sm" data-toggle="modal" data-target="#attachModal">
                  <i class="fas fa-paperclip"></i> Adicionar Anexo
                </button>
              </div>
              <!-- Modal Adicionar Anexos -->
              <div class="modal fade" id="attachModal" tabindex="-1" role="dialog" aria-labelledby="attachModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-lg" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="attachModalLabel">
                        Novo Anexo
                      </h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <input type="file" name="file" class="form-control-file" id="attachment" />
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-primary" @click="saveAttachments()" data-dismiss="modal">
                        Salvar
                      </button>
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">
                        <i class="fa fa-times"></i>Fechar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal de Lançamento de Horas -->
    <div class="modal fade" id="serviceHours" tabindex="-1" role="dialog" aria-labelledby="serviceHours"
      aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="hoursModalLabel">
              Lançamento de horas de atendimento
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" align="center">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Data e hora início</label>
                  <ctk-picker v-model="additionallHour.start" :output-format="'YYYY-MM-DD HH:mm'"
                    :format="'YYYY-MM-DD HH:mm'" :formated="'YYYY-MM-DD HH:mm'" :button-now-translation="'Hoje'"
                    :label="''"></ctk-picker>
                  <!-- <VueCtkDateTimePicker v-model="additionallHour.start" /> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Data e hora fim</label>
                  <ctk-picker v-model="additionallHour.end" :output-format="'YYYY-MM-DD HH:mm'"
                    :format="'YYYY-MM-DD HH:mm'" :formated="'YYYY-MM-DD HH:mm'" :button-now-translation="'Hoje'"
                    :label="''"></ctk-picker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Comentário</label>
                  <textarea v-model="additionallHour.comment" class="form-control" rows="7"></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Responsável</label>
                  <select v-bind:disabled="this.saveEnable == false" v-model="additionallHour.responsible_id"
                    class="form-control">
                    <option v-for="user in users" :key="user.id" :value="user.id">
                      {{ user.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary p-1 ml-3 float-right" @click="serviceHours">
              <i class="fa fa-floppy-o"></i> Lançar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Vinculo de Usuários -->
    <div class="modal fade" id="users" tabindex="-1" role="dialog" aria-labelledby="users" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Vinculo de devs</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" align="center">
            <label for="lancamento">Selecione um desenvolvedor para vincular a este chamado:</label>
            <select v-model="interactionNew.user_id" class="form-control">
              <option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.name }}
              </option>
            </select>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary p-1 ml-3 float-right" data-dismiss="modal" aria-label="Close"
              @click="saveUser">
              <i class="fa fa-floppy-o"></i> Salvar
            </button>
            <button type="button" class="btn btn-secondary p-1 ml-3 float-right" data-dismiss="modal" aria-label="Close">
              <i class="fa fa-times"></i> Fechar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Alteração de Status -->
    <div class="modal fade" id="status" tabindex="-1" role="dialog" aria-labelledby="status" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Alteração de Status
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" align="center">
            <label for="lancamento">Selecione um novo status para este chamado:</label>
            <select v-model="ticket.status" class="form-control">
              <option v-for="stat in status" :key="stat.id" :value="stat.id">
                {{ stat.title }}
              </option>
            </select>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary p-1 ml-3 float-right" data-dismiss="modal" aria-label="Close"
              @click="updateFields()">
              <i class="fa fa-floppy-o"></i> Salvar
            </button>
            <button type="button" class="btn btn-secondary p-1 ml-3 float-right" data-dismiss="modal" aria-label="Close">
              <i class="fa fa-times"></i> Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal de Alteração de Responsável-->
    <div class="modal fade" id="responsible" tabindex="-1" role="dialog" aria-labelledby="responsible" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Novo Responsável</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="col-md-12 mt-2">
            <label for>Selecione novo Responsável para este chamado:</label>
            <v-select :options="usercustomers" label="name" :reduce="(usercustomers) => usercustomers.id"
              class="style-chooser" v-model="interactionNew.usercustomers_id">
              <div slot="no-options">Nenhum registro encontrado.</div>
            </v-select>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary p-1 ml-3 float-right" data-dismiss="modal" aria-label="Close"
              @click="updateTicketResponsible">
              <i class="fa fa-floppy-o"></i> Salvar
            </button>
            <button type="button" class="btn btn-secondary p-1 ml-3 float-right" data-dismiss="modal" aria-label="Close">
              <i class="fa fa-times"></i> Fechar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Alteração do nº de chamado-->
    <div class="modal fade" id="ticketCode" tabindex="-1" role="dialog" aria-labelledby="ticketCode" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Alterar Nº de Chamado
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <label for="lancamento">De:</label>
                <br />
                <input type="text" v-mask="'#####'" class="form-control" disabled :placeholder="ticket.code" />
              </div>
              <div class="col-md-6">
                <label for="lancamento">Para:</label>
                <input type="text" class="form-control" v-model="lastTicket" v-mask="'#####'" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary p-1 ml-3 float-right" data-dismiss="modal" aria-label="Close"
              @click="updateTicketCode">
              <i class="fa fa-floppy-o"></i> Salvar
            </button>
            <button type="button" class="btn btn-secondary p-1 ml-3 float-right" data-dismiss="modal" aria-label="Close">
              <i class="fa fa-times"></i> Fechar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Alteração de Data e Hora de Iteração -->
    <div class="modal fade" id="created_at" tabindex="-1" role="dialog" aria-labelledby="created_at" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Alterar Data e Hora de Iteração
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-8">
                <label for="lancamento">Data e Hora de Criação:</label>
                <ctk-picker v-model="interaction_created_at" :output-format="'YYYY-MM-DD HH:mm'"
                  :format="'YYYY-MM-DD HH:mm'" :formated="'YYYY-MM-DD HH:mm'" :button-now-translation="'Hoje'"
                  :label="''"></ctk-picker>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary p-1 ml-3 float-right" data-dismiss="modal" aria-label="Close"
              @click="
                updateTicketCreatedAt(interaction_id, interaction_created_at)
              ">
              <i class="fa fa-floppy-o"></i> Salvar
            </button>
            <button type="button" class="btn btn-secondary p-1 ml-3 float-right" data-dismiss="modal" aria-label="Close">
              <i class="fa fa-times"></i> Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import vSelect from "vue-select";

class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    // Update the loader's progress.
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    // Reject the promise returned from the upload() method.
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    // Note that your request may look different. It is up to you and your editor
    // integration to choose the right communication channel. This example uses
    // a POST request with JSON as a data structure but your configuration
    // could be different.
    xhr.open(
      "POST",
      "https://api.projetos.innovareti.com.br/api/v1/images/upload/",
      true
    );
    xhr.responseType = "json";
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    // const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      resolve({
        default: response.url,
      });
    });

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    // Prepare the form data.
    const data = new FormData();

    data.append("upload", file);

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, you can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data);
  }
}

function MyUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
    return new MyUploadAdapter(loader);
  };
}
export default {
  data() {
    return {
      interactionNew: {
        user_id: null,
      },
      attached: {
        mail: "",
        peopleAttached: [],
      },
      contracts: [],
      contract_id: 0,
      lastTicket: "",
      additionallHour: {
        start: "",
        end: "",
        comment: "",
        responsible_id: "",
      },
      ticketCategories: [],
      process_names: [],
      process_areas: [],
      ticket: [],
      customers: [],
      usercustomers: [],
      classifications: [],
      interactionNew: {
        usercustomer_id: "",
        description: "",
        hours: "",
        user_id: "",
        userCustomer_id: "",
        Customer_id: "",
        status_id: "",
        checkIteraction: true,
      },
      editor: ClassicEditor,
      editorData: "<p>Hello World ...</p>",
      plugins: ["SimpleUploadAdapter"],
      editorConfig: {
        extraPlugins: [MyUploadAdapterPlugin],
      },
      users: [],
      status: [],
      interaction_id: 0,
      interaction_created_at: "",
      showLoad: false,
      saveEnable: true,
    };
  },

  components: { ClassicEditor, vSelect },

  computed: {
    selectedUser() {

      return this.users.find(user => user.id === this.interactionNew.user_id) || {};
    },
  },

  created() {
    window.addEventListener("beforeunload", function (e) {
      window.alert("");
      e.preventDefault();
      e.returnValue = "";
    });
  },

  methods: {
    removeMail(index) {
      if (index >= 0 && index < this.attached.peopleAttached.length) {

        this.attached.peopleAttached.splice(index, 1);

      }
    },
    handleKeyDown(event) {

      if (event.key === "Enter" || event.key === "Tab" || event.key === " ") {
        event.preventDefault();
        this.attached.peopleAttached.push(this.attached.mail);
        this.attached.mail = '';

      }
    },
    classificationSelected(selectedOption) {

      this.updateFields()
    },
    formatDate(date) {
      return moment(date).format("L") + " " + moment(date).format("LT");
    },
    diffMoment(ini, end) {
      var ini = moment(ini);
      var end = moment(end);
      var diff = moment.duration(ini.diff(end)).format("LT");
      return diff;
    },
    returnDaily() {
      swal({
        title: "Deseja mesmo VOLTAR?",
        text: "Você pode perder os dados inseridos!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim",
        showLoaderOnConfirm: true,
        buttons: {
          confirm: {
            text: "Sim",
            value: "confirm",
            className: "btn btn-success",
          },
          catch: {
            text: "Permanecer",
            value: "cancel",
            className: "btn btn-info",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "Você não foi redirecionado", "info");
            break;

          case "confirm":
            this.$router.push({
              path: "/new_tickets",
            });
            break;
        }
      });
    },

    loading: async function () {
      try {
        this.showLoad = true;
        this.saveEnable = false;
        const response = await axios.post(
          this.$store.state.api + "tickets_interactions"
        );

        console.log(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        this.showLoad = false;
        this.saveEnable = true;
      }
    },

    calculateTotalTimeServiceHours() {
      let total = 0;

      this.ticket.service_hours.forEach((service_hour) => {
        let startMoment = moment(service_hour.start);
        let endMoment = moment(service_hour.end);

        let duration = moment.duration(endMoment.diff(startMoment));

        total += duration.asSeconds();
      });

      let duration = total;
      let hours = duration / 3600;
      duration = duration % 3600;

      let min = parseInt(duration / 60);
      duration = duration % 60;

      let sec = parseInt(duration);

      if (sec < 10) {
        sec = `0${sec}`;
      }
      if (min < 10) {
        min = `0${min}`;
      }
      if (parseInt(hours, 10) > 0) {
        return ` ${parseInt(hours, 10)}:${min} horas`;
      }
      return ` ${min} minutos`;
    },
    getCustomers: function (id) {
      let self = this;
      let api = self.$store.state.api + "customers?paginated=false";

      self.$http
        .get(api)
        .then((response) => {
          self.customers = response.data.data;
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },

    // selectedUsercustomer(usercustomers) {
    //       let self = this;
    //       let api = this.$store.getters.api + "customers?paginated=false";

    //       self.$http.get(api + "?customer_id=" + usercustomers.id).then(response => {
    //         self.customers = response.data.data;
    //       });
    // },
    getuserCustomers: function (id) {
      let self = this;
      let api = self.$store.state.api + "usercustomers?paginated=false";

      self.$http
        .get(api)
        .then((response) => {
          self.usercustomers = response.data.data;
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
    removeResponsible() {
      this.ticket.responsible_id = null;
      this.updateFields();
      this.getTicket();
    },
    removeContract() {
      this.ticket.contract_id = null;
      this.updateFields();
      this.getTicket();
    },
    setResponsible() {
      swal({
        title: "Deseja mesmo atribruir um Responsável?",
        type: "warning",
        confirmButtonText: "Sim",
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonColor: "",
        buttons: {
          catch: {
            text: "cancelar",
            value: "cancel",
            className: "btn btn-info",
            reverseButtons: true,
          },
          confirm: {
            text: "Sim",
            value: "confirm",
            className: "btn btn-success",
            reverseButtons: true,
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "Responsável não atribuido!", "info");
            this.ticket.responsible_id = "";
            break;

          case "confirm":
            this.updateFields();
            break;
        }
      });
    },
    updateFields() {
      const self = this;
      const api = self.$store.state.api + "new_tickets/" + self.ticket.id;
      self.$http
        .put(api, {
          category_id: self.ticket.category_id,
          status: self.ticket.status,
          responsible_id: self.ticket.responsible_id,
          contract_id: self.ticket.contract_id,
          code: self.ticket.code,
          priority: self.ticket.priority,
          classification: self.ticket.classification,
          process_name_id: self.ticket.process_name_id,
          process_area_id: self.ticket.process_area_id,
          classification_id: self.ticket.classification_id
        })
        .then((response) => {
          self.getTicket();
          self.$message("Sucesso", "Atualizado com sucesso", "success");
        })
        .catch((error) => {
          self.$message("Erro", error.response.data, "error");
        });
    },
    getProcessNames: function (id) {
      const self = this;
      const api = self.$store.state.api + "process_names?paginated=false";

      self.$http
        .get(api)
        .then((response) => {
          self.process_names = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getClassifications: function (id) {
      const self = this;
      const api = self.$store.state.api + "classifications?paginated=false";

      self.$http
        .get(api)
        .then((response) => {
          self.classifications = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProcessAreas: function (id) {
      const self = this;
      const api = self.$store.state.api + "process_areas/";

      self.$http
        .get(api)
        .then((response) => {
          self.process_areas = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    save: function () {
      let api = this.$store.state.api + "tickets_interactions";
      const self = this;

      this.attached.peopleAttached.push(this.ticket.people_attached);

      let form = new FormData();

      form.append("ticket_id", self.$route.params.id);
      form.append("body", self.interactionNew.description);
      form.append("order", "2");
      form.append("status", "1");
      form.append("people_attached", this.attached.peopleAttached);
      form.append("checkIteraction", self.interactionNew.checkIteraction);


      self.$http
        .post(api, form)
        .then((response) => {
          self.$message("Sucesso", `Cadastrado com sucesso`, "success");

          this.interactionNew.description = "";
          this.getTicket();
          this.attached.peopleAttached = [];

        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
      this.loading();
      this.attached.peopleAttached = [];


    },
    makeFormData() {


      this.attached.peopleAttached.push(this.ticket.people_attached);

      let fd = new FormData();

      fd.append("subject", this.ticket.subject);
      fd.append("ticket_id", this.$route.params.id);
      fd.append("body", this.ticket.description);
      fd.append("order", 1);
      fd.append("status", 1);
      fd.append("people_attached", this.attached.peopleAttached);
      fd.append("_method", "PUT");

      return fd;
    },
    saveSubject: function () {
      const self = this;
      const api = self.$store.state.api + "new_tickets/" + self.ticket.id;


      const fd = self.makeFormData();

      self.$http
        .post(api, fd)
        .then((response) => {
          self.$message("Sucesso", `Assunto atualizado com sucesso`, "success");
          this.attached.peopleAttached = [];
        })
        .catch((error) => {
          self.$message("Erro", error.response.data, "error");
        });


    },
    saveAttachments: function () {
      let api = this.$store.state.api + "new_ticket-attach";
      const self = this;

      let form = new FormData();

      form.append("user_id", self.$store.getters.user.id);
      form.append("ticket_id", self.ticket.id);

      let attachment = document.getElementById("attachment").files[0];
      if (attachment) {
        form.append("attachment", attachment);
      }

      self.$http
        .post(api, form)
        .then((response) => {
          self.$message("Sucesso", `Anexo Guardado com sucesso`, "success");
          $("#attachModal").hide();
          self.getTicket();
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
    },
    deleteHour(id) {
      console.log(id);
      const self = this;
      let api = self.$store.state.api + "tickets_service_hours/" + id;

      self.$http
        .delete(api)
        .then((response) => {
          self.$message("Sucesso", "Registro Excluído com Sucesso", "success");
          self.getTicket();
        })
        .catch((response) => {
          self.$errorMessage(response);
        });
    },

    serviceHours() {
      const self = this;
      let api = self.$store.state.api + "tickets_service_hours";

      let form = new FormData();
      form.append("ticket_id", self.ticket.id);
      form.append("start", self.additionallHour.start);
      form.append("end", self.additionallHour.end);
      form.append("comment", self.additionallHour.comment);
      form.append("responsible_id", self.additionallHour.responsible_id);

      self.$http
        .post(api, form)
        .then((response) => {
          $("#serviceHours").modal("hide");
          self.additionallHour = {
            start: "",
            end: "",
            comment: "",
            responsible_id: "",
          };
          self.getTicket();
          self.$message("Sucesso", `Horas lançadas com sucesso!`, "success");

          self.getTicket();
        })
        .catch((error) => {
          self.$message("Erro", error.response.data, "error");
        });
    },
    updateTicketResponsible() {
      const self = this;
      const api = self.$store.state.api + "change_ticket_responsible";
      self.$http
        .post(api, {
          ticket_id: self.ticket.id,
          responsible_id: self.interactionNew.usercustomers_id,
        })
        .then((response) => {
          self.getTicket();
          self.getLastTicket();
          self.$message("Sucesso", "Dados atualizado com sucesso!", "success");
        });
    },
    updateInteraction(interaction_id) {
      const self = this;
      self.interaction_id = interaction_id.id;
      self.interaction_created_at = interaction_id.created_at;
      $("#created_at").modal("show");
    },
    updateTicketCreatedAt(interaction_id, created_at) {
      const self = this;
      const api = self.$store.state.api + "change_ticket_created_at";
      self.$http
        .post(api, {
          interaction_id: interaction_id,
          created_at: created_at + ":00",
        })
        .then((response) => {
          self.getTicket();
          self.getLastTicket();
          self.$message(
            "Sucesso",
            "Data de criação atualizado com sucesso!",
            "success"
          );
        })
        .catch((error) => {
          self.$message("Erro", error.response.data, "error");
        });
    },
    updateTicketCode() {
      const self = this;
      const api = self.$store.state.api + "change_ticket_code";
      self.$http
        .post(api, {
          ticket_id: self.ticket.id,
          newTicketCode: self.lastTicket,
        })
        .then((response) => {
          self.getTicket();
          self.getLastTicket();
          self.$message(
            "Sucesso",
            "Número do ticket atualizado com sucesso!",
            "success"
          );
        })
        .catch((error) => {
          self.$message("Erro", error.response.data, "error");
        });
    },
    saveUser() {
      let api = this.$store.state.api + "tickets_devs";
      const self = this;

      let form = new FormData();

      form.append("ticket_id", self.$route.params.id);
      form.append("user_id", self.interactionNew.user_id);
      self.$http
        .post(api, form)
        .then((response) => {
          self.$message("Sucesso", `Dev vinculado com sucesso!`, "success");

          this.interactionNew.hours = "";
          this.getTicket();
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
    },
    getTicket() {
      const self = this;
      self.$http
        .get(self.$store.getters.api + "new_tickets/" + self.$route.params.id, {
          params: {
            // Adiciona Relacionamento.
            with: [
              "interactions",
              "attachments",
              "sent_by",
              "devs",
              "responsible",
              "service_hours.responsible",
              "status_entity",
              "process_name_id",
              "process_area_id",
            ],
          },
        })
        .then((response) => {
          self.ticket = response.data.data[0];
          console.log((self.ticket = response.data.data[0]));
          //Initializing tooltips
          self.$nextTick(() => {
            $('[data-toggle="tooltip"]').tooltip();
          });
        });
    },
    getUsers() {
      const self = this;
      self.$http
        .get(self.$store.getters.api + "users?paginated=false", {
          params: {
            // Adiciona Relacionamento.
            with: [],
          },
        })
        .then((response) => {
          self.users = response.data.data;
        });
    },
    getStatus() {
      const self = this;
      self.$http
        .get(self.$store.getters.api + "tickets_status?paginated=false", {
          params: {
            // Adiciona Relacionamento.
            with: [],
          },
        })
        .then((response) => {
          self.status = response.data.data;
        });
    },
    getTicketCategories() {
      const self = this;
      self.$http
        .get(self.$store.getters.api + "ticketcategories?paginated=false")
        .then((response) => {
          self.ticketCategories = response.data.data;
        });
    },
    getLastTicket() {
      const self = this;
      self.$http
        .get(self.$store.getters.api + "new_tickets?orderByDesc=code")
        .then((response) => {
          self.lastTicket = response.data.data[0].code;
          self.lastTicket = self.lastTicket + 1;
        });
    },
    getContracts() {
      const self = this;
      self.$http
        .get(self.$store.getters.api + "contracts?paginated=false")
        .then((response) => {
          self.contracts = response.data.data;
        });
    },
  },
  mounted() {
    let self = this;

    this.getTicket();
    this.getUsers();
    this.getStatus();
    this.getTicketCategories();
    this.getCustomers();
    this.getuserCustomers();
    this.getLastTicket();
    this.getContracts();
    this.getProcessNames();
    this.getProcessAreas();
    this.getClassifications();
  },
};
</script>
<style scoped>
.ticket-history {
  overflow-x: auto;
}

.hidden {
  display: none;
}

#message {
  height: 40px !important;
  min-height: 40px !important;
}

.ipp-alert {
  padding: 5px !important;
  margin-left: -25px !important;
  margin-bottom: 0px !important;
  margin-top: 15px;
}

.photo {
  margin-top: 15px;
}

.coment-date {
  margin-bottom: 10px;
}

.container {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  overflow-x: auto;
  float: none;
}

.container-1 {
  height: 150px !important;
}

.darker {
  border-color: #ccc;
  background-color: #ddd;
}

.container::after {
  content: "";
  clear: both;
  display: table;
}

.time-right {
  float: right;
  color: #aaa;
  font-weight: bold;
}

.innover-left {
  float: left;
  color: #999;
  margin-left: 5px;
  font-weight: bold;
}

.customer-right {
  float: right;
  color: #999;
  margin-right: 35px;
  font-weight: bold;
}

.time-left {
  float: left;
  color: #999;
  font-weight: bold;
}

.limit-text-long-card {
  max-width: 40ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.load {
  top: 30%;
  left: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  position: fixed;
  overflow: hidden;
}

.spin {
  background: #00000054;
  height: 130px;
  width: 130px;
  border-radius: 50vh;
  border: 5px solid #00000000;
  border-top: 5px solid black;
  animation: loading 3s linear infinite;
}

.loading {
  position: absolute;
  color: black;
}

.uploading-image {
  display: flex;
}

.required {
  color: red;
}

.itens-mail {
  display: flex;
  align-items: center;
  /* Alinhar verticalmente o texto e o botão */
  padding: 4%;
  background-color: rgb(218, 214, 214);
  cursor: pointer;
}

.close-button {
  margin-left: 8px;
  /* Adicionar um espaço à esquerda do botão */
  border: none;
  background: none;
  font-size: 18px;
  color: red;
  cursor: pointer;
}

.close-button:focus {
  outline: none;
  /* Remover a borda de foco do botão */
}

.people-attached {
  font-size: 12px;
}

@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}
</style>

