<template>
  <div class="card-body animated fadeInRightBig">
    <div role="tabpanel" class="card-header animated fadeInRightBig">
      <div class="card-title">
        <h3>Tickets</h3>
      </div>
    </div>
    <div class="card card-body">
      <h2>Vizualizar Ticket</h2>
      <div class="row">
        <div class="col-md-3">
          <h5 class="card-title">Numero do Ticket</h5>
          <p class="card-text">{{ ticket.ticket }}</p>
        </div>
        <div class="col-md-3">
          <h5 class="card-title">Titulo</h5>
          <p class="card-text">{{ ticket.title }}</p>
        </div>
        <div class="col-md-3">
          <h5 class="card-title">Responsavel no Cliente</h5>
          <p class="card-text">{{ ticket.user_name }}</p>
        </div>
        <div class="col-md-3">
          <h5 class="card-title">Cliente</h5>
          <p class="card-text">{{ ticket.customer_name }}</p>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-3">
          <h5 class="card-title">Status</h5>
          <p class="card-text">{{ ticket.status }}</p>
        </div>
        <div class="col-md-3">
          <h5 class="card-title">Categoria</h5>
          <p class="card-text">{{ ticket.category}}</p>
        </div>
        <div class="col-md-3">
          <h5 class="card-title">Ultima Alteração</h5>
          <p class="card-text">{{ ticket.last_iteration }}</p>
        </div>
        <div class="col-md-3">
          <h5 class="card-title">Data Fechamento</h5>
          <p class="card-text">{{ ticket.closed_at }}</p>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-3">
          <h5 class="card-title">Prioridade</h5>
          <p class="card-text">{{ ticket.priority }}</p>
        </div>
        <div class="col-md-3">
          <h5 class="card-title">Tempo Trabalhado</h5>
          <p class="card-text">{{ ticket.worked_time }}</p>
        </div>
      </div>
      <hr />
      <!-- Historico de Chamados -->
      <div role="tabpanel" class="card-header animated fadeInRightBig">
        <div class="card-title">
          <h3>Historico de Chamados</h3>
        </div>
      </div>
      <div class="card history-tickets" v-for="history in ticket.history" :key="history.id">
        <div class="row">
          <div class="container text-right" v-if=" history.origin == 'C'">
            <h4 class="card-title text-left">{{ ticket.customer_name }}</h4>
            <br />
            <span v-html="history.message"></span>
            <br />
            <span class="time-right">
              <i>{{ history.history_date }}</i>
            </span>
          </div>

          <div class="container darker text-left" v-if=" history.origin == 'A'">
            <h4 class="card-title text-right">Innovare TI</h4>
            <br />
            <span v-html="history.message"></span>

            <span class="time-left">
              <i>{{ history.history_date }}</i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      ticket: []
    };
  },

  methods: {
    getTicket() {
      const self = this;
      self.$http
        .get(self.$store.getters.api + "tickets/" + self.$route.params.id, {
          params: {
            // Adiciona Relacionamento.
            with: ["history"]
          }
        })
        .then(response => {
          self.ticket = response.data.data[0];
        });
    }
  },
  mounted() {
    this.getTicket();
  }
};
</script>
<style scoped>
.container {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.darker {
  border-color: #ccc;
  background-color: #ddd;
}

.container::after {
  content: "";
  clear: both;
  display: table;
}

.time-right {
  float: right;
  color: #aaa;
}

.time-left {
  float: left;
  color: #999;
}
</style>