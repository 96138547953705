<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">#</th>
          <th scope="col">Título</th>
          <th scope="col">Solicitante</th>
          <th scope="col">Data Solicitação</th>
          <th scope="col">Status</th>
          <th scope="col">Previsão</th>
          <th scope="col">Responsável</th>
          <th scope="col">Tipo</th>
          <th scope="col">Votos</th>
          <th scope="col">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="backlog in backlogs" :key="backlog.id">
          <td>
            <button @click="viewBacklog(backlog)" class="btn btn-view view">
              <i class="fas fa-eye"></i>
            </button>
          </td>
          <th scope="row">{{ backlog.id }}</th>
          <td
            class="overflow"
            data-toggle="tooltip"
            data-placement="top"
            :title="backlog.title"
            style="cursor: pointer"
            @click="viewBacklog(backlog)"
          >
            {{ backlog.title }}
          </td>
          <td>{{ backlog.created_user.name }}</td>
          <td>{{ backlog.created_at }}</td>
          <td>
            <i
              :class="badgeStatus(backlog)"
              data-toggle="tooltip"
              data-placement="top"
              :title="backlogStatus(backlog)"
              style="cursor: pointer; font-size: 18px"
              @click="changeStatus(backlog)"
            ></i>
          </td>
          <td>
            {{ backlog.forecast_date ? backlog.forecast_date : "Não iniciado" }}
          </td>
          <td v-if="backlog.responsible">
            <span
              v-if="backlog.admin"
              @click="openModalAttribution(backlog.id)"
              class="link"
              data-toggle="modal"
              data-target="#modalAttribution"
            >
              {{
                backlog.responsible
                  ? backlog.responsible_user.name
                  : "Não atribuído"
              }}
              <i class="fas fa-user-edit"></i>
            </span>
            <span v-else>
              {{
                backlog.responsible
                  ? backlog.responsible_user.name
                  : "Não atribuído"
              }}
            </span>
          </td>
          <td v-else>
            <span
              v-if="backlog.admin"
              class="link"
              @click="openModalAttribution(backlog.id)"
              data-toggle="modal"
              data-target="#modalAttribution"
              >Atribuir <i class="fas fa-plus-circle"></i
            ></span>
            <span v-else>Não atribuído</span>
          </td>
          <td>{{ backlog.type == 1 ? "Melhoria" : "BUG" }}</td>
          <th>
            {{ backlog.total_votings }}
            <i
              :class="
                backlog.total_votings > backlog.last_votings
                  ? 'ml-2 fas fa-arrow-up green'
                  : backlog.total_votings == backlog.last_votings
                  ? 'ml-2 fas fa-minus-circle blue'
                  : 'ml-2 fas fa-arrow-down red'
              "
            ></i>
          </th>
          <td v-if="backlog.status == 1">
            <button
              v-if="backlog.vote"
              @click="toggleVote(backlog.id)"
              class="btn btn-voting vote"
            >
              <i class="fas fa-check"></i> Votar
            </button>
            <button
              v-else
              @click="toggleVote(backlog.id)"
              class="btn btn-voting unvote"
            >
              <i class="fas fa-times"></i> Remover Voto
            </button>
          </td>
          <td v-else>Votação Finalizada</td>
        </tr>
        <tr v-if="total == 0">
          <td colspan="10" align="center">Nenhum registro encontrado.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["backlogs", "total"],
  data() {
    return {};
  },
  methods: {
    openModalAttribution(id) {
      this.$emit("openModalAttribution", id);
    },
    viewBacklog(obj) {
      this.$emit("viewBacklog", obj);
    },
    toggleVote(id) {
      this.$emit("toggleVote", id);
    },
    changeStatus(backlog) {
      if (backlog.admin) {
        $("#modalStatus").modal("toggle");
        this.$emit("changeStatusModal", backlog.id);
      }
    },
    badgeStatus(backlog) {
      if (backlog.status == 1) {
        return "fas fa-circle blue";
      }
      if (backlog.status == 2) {
        return "fas fa-circle yellow";
      }
      if (backlog.status == 3) {
        return "fas fa-circle green";
      }
    },
    backlogStatus(backlog) {
      if (backlog.status == 1) {
        return "Aguardando";
      }
      if (backlog.status == 2) {
        return "Em desenvolvimento";
      }
      if (backlog.status == 3) {
        return "Finalizado";
      }
    },
  },
  mounted() {
    setTimeout(function () {
      $(function () {
        $('[data-toggle="tooltip"]').tooltip();
      });
    }, 2000);
  },
};
</script>

<style scoped>
.green {
  color: rgb(57, 172, 0);
}

.red {
  color: rgb(172, 23, 0);
}

.blue {
  color: rgb(0, 113, 199);
}

.yellow {
  color: rgb(254, 211, 38);
}

.link {
  color: rgb(0, 98, 172);
  cursor: pointer;
  text-decoration: none;
}

.link:hover {
  color: rgb(0, 50, 89);
  cursor: pointer;
  text-decoration: underline;
}
td.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 450px;
}
.tooltip .tooltip-inner {
  width: 450px !important;
}
</style>