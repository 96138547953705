<template>
  <div>
    <div class="panel animated fadeInRightBig">
      <div class="panel-heading">
        <div class="card-header">
          <div class="card-title">Novo Orçamento</div>
        </div>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-12">
            <form action>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="userName">
                      <span class="required">*</span> Titulo:
                    </label>
                    <input type="text" class="form-control" v-model="budgeting.title" placeholder />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="userEmail">
                      <span class="required">*</span> Cliente:
                    </label>
                    <input type="text" class="form-control" v-model="budgeting.customer" id="userEmail" placeholder
                      autocomplete="off" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="criticality">
                      <span class="required">*</span> Criticidade:
                    </label>
                    <v-select :options="criticisms" label="name" id="criticism" :reduce="criticisms => criticisms.id"
                      v-model="budgeting.criticality_id">
                      <div slot="no-options">Carregando...</div>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="tel">Prazo:</label>
                    <v-select :options="deadlines" label="name" id="deadline" :reduce="deadlines => deadlines.id"
                      v-model="budgeting.deadline_id">
                      <div slot="no-options">Carregando...</div>
                    </v-select>
                  </div>
                </div>
              </div>
              <hr v-if="budgeting.id" />
              <div v-if="budgeting.id" class="row">
                <div class="col-md-12">
                  <label for="project">
                    Documentação:
                  </label>
                  <br>
                  <div id="down-scroll">
                    <div class="row">
                      <div class="col-md-12">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <!-- <th>Arquivo</th>
                              <th>Download</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="attachment in budgeting.attachments" :key="attachment.id">
                              <td>{{ attachment.attachment }}</td>
                              <td>
                                <a :href="$store.getters.api + 'budgeting-attachments/download/' + attachment.id"
                                  target="blank" class="btn btn-primary">Download</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <br>
                  <hr>
                  <br>
                  <vue-dropzone @vdropzone-sending="sendingEvent" @vdropzone-complete="completeUpload" ref="myVueDropzone"
                    id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                </div>
              </div>
              <br>
              <hr />
              <div class="row">
                <div class="col-md-12 ck-editor__editable_inline">
                  <label for="project">
                    <span class="required">*</span> Descrição:
                  </label>
                  <ckeditor :editor="editor" v-model="budgeting.description" :config="editorConfig"></ckeditor>
                </div>
              </div>
              <hr />
              <div class="row mt-3">
                <div class="col-md-12">
                  <div class="d-flex justify-content-between">
                    <router-link to="/usercustomers" class="btn btn-info">
                      <i class="fa fa-arrow-left"></i> Voltar
                    </router-link>

                    <a class="btn btn-success" @click="save">
                      Salvar
                      <i class="fa fa-save"></i>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

//  Ck5 propriedades
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    // Update the loader's progress.
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    // Reject the promise returned from the upload() method.
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    // Note that your request may look different. It is up to you and your editor
    // integration to choose the right communication channel. This example uses
    // a POST request with JSON as a data structure but your configuration
    // could be different.
    xhr.open(
      "POST",
      "https://api.projetos.innovareti.com.br/api/v1/images/upload/",
      true
    );
    xhr.responseType = "json";
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      resolve({
        default: response.url,
      });
    });

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    // Prepare the form data.
    const data = new FormData();

    data.append("upload", file);

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, you can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data);
  }
}

function MyUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
    return new MyUploadAdapter(loader);
  };
}

export default {
  components: {
    vSelect,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      budgeting: {
        customer: "",
        title: "",
        description: "",
        criticality_id: "",
        deadline_id: "",
      },
      editor: ClassicEditor,
      editorData: "<p>Hello World ...</p>",
      editorConfig: {
        extraPlugins: [MyUploadAdapterPlugin],
      },
      dropzoneOptions: {
        url: this.$store.getters.api + "budgeting-attachments/upload",
        thumbnailWidth: 150,
        addRemoveLinks: true,
        dictDefaultMessage:
          "<i class='fas fa-file-upload'></i>  Upload De Documentação   <i class='fas fa-file-upload'></i>",
      },
      criticisms: [
        {
          id: 1,
          name: "Baixa",
        },
        {
          id: 2,
          name: "Média",
        },
        {
          id: 3,
          name: "Alta",
        },
      ],
      deadlines: [
        {
          id: 1,
          name: "15 dias",
        },
        {
          id: 2,
          name: "30 dias",
        },
        {
          id: 3,
          name: "45 dias",
        },
      ],
    };
  },
  methods: {
    sendingEvent(file, xhr, formData) {
      formData.append("budgeting_id", this.$route.params.id);
    },
    completeUpload(response) {
      this.getBudgetings();
    },
    save: function () {
      let api = this.$store.state.api + "budgetings";
      const self = this;

      let form = new FormData();

      form.append("title", self.budgeting.title);
      form.append("customer", self.budgeting.customer);
      form.append("deadline_id", self.budgeting.deadline_id);
      form.append("criticality_id", self.budgeting.criticality_id);
      form.append("description", self.budgeting.description);
      form.append("budgeting_status_id", 1);

      if (self.budgeting.id) {
        api += "/" + self.budgeting.id;
        form.append("_method", "PUT");
      }

      self.$http
        .post(api, form)
        .then((response) => {
          self.$message("Sucesso", `Cadastrado com Sucesso`, "success");
          this.$router.push("/budgetings");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });
            html += "</ul>";
            self.errors = html;
          }
          self.$message(null, self.errors, "error");
        });
    },

    getBudgetings() {
      let self = this;
      let id = self.$route.params.id;
      let api =
        self.$store.state.api + "budgetings/" + id + "?with[]=attachments";

      self.$http
        .get(api)
        .then((response) => {
          self.budgeting = response.data.data[0];
          console.log(self.budgeting);
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
  },
  mounted() {
    let self = this;
    let id = self.$route.params.id;

    if (id) {
      self.getBudgetings();
    }
  },
};
</script>

<style scoped>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");


#down-scroll {
  height: 185px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.required {
  color: red;
}
</style>
