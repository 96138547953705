<template>
    <div>
        <div class="panel animated fadeInRightBig">
            <div class="panel-heading">
                <div class="card-header">
                    <div class="card-title">
                        Novo Resgistro de Deploy
                    </div>
                </div>
            </div>
            <div class="panel-body">
                <form id="form" enctype='multipart/form-data'>
                    <div class="row">
                        <div class="col-md-5">
                            <label for="project"><span class="required">*</span> Projeto:</label>
                            <v-select :options="projects" label="name" id="project" :reduce="projects => projects.id"
                                v-model="wfdeploy.project_id">
                                <div slot="no-options">Carregando...</div>
                            </v-select>
                        </div>
                        <div class="col-md-5">
                            <label for="sprint_id"><span class="required">*</span> Sprint:</label>
                            <v-select :options="filteredSprints" label="name" id="sprint_id"
                                :reduce="filteredSprints => filteredSprints.id" v-model="wfdeploy.sprint_id">
                                <div slot="no-options">Selecione primeiro a Sprint...</div>
                            </v-select>
                        </div>
                        <div class="col-md-2 float-right">
                            <label for="sprint_id"><span class="required">*</span> Solicitante:</label>
                            <div>{{ user.name }}</div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-5">
                            <label for="sprint_id"><span class="required">*</span> Prioridade:</label>
                            <v-select :options="priority" label="name" id="priority" :reduce="priority => priority.name"
                                v-model="wfdeploy.priority">
                                <div slot="no-options">Selecione a Prioridade...</div>
                            </v-select>
                        </div>
                        <div class="col-md-5">
                            <label for="sprint_id"><span class="required">*</span> Tipo de Projeto:</label>
                            <v-select :options="typeProjects" label="name" id="sprint_id"
                                :reduce="typeProjects => typeProjects.name" v-model="wfdeploy.type_project">
                                <div slot="no-options">Carregando...</div>
                            </v-select>
                        </div>
                        <div class="col-md-2">
                            <div class="custom-control custom-checkbox" style="padding-top: 32px;">
                                <input id="gmud" name="gmud" type="checkbox" class="custom-control-input" @click.stop
                                    v-model="wfdeploy.gmud">
                                <label class="custom-control-label slt-none" id="pass" for="gmud">Necessita de GMUD?<small
                                        id="most">Lembrando que são necessários em projetos que o cliente exige
                                        GMUD</small></label>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-5">
                            <label for="origin"><span class="required">*</span> Origem:</label>
                            <v-select :options="origin" label="name" id="project" :reduce="origin => origin.id"
                                v-model="wfdeploy.origin_id">
                                <div slot="no-options">Carregando...</div>
                            </v-select>
                        </div>
                        <div class="col-md-5" v-if="wfdeploy.origin == 2">
                            <label for="tickets_id"><span class="required">*</span> Número do Chamado:</label>
                            <v-select label="ticket" id="tickets_id" :options="tickets" :reduce="tickets => tickets.ticket"
                                taggable multiple push-tag v-model="wfdeploy.tickets_id">
                                <div slot="no-options">Digite e pressione enter para inserir múltiplos chamados</div>
                            </v-select>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="description"><span class="required">*</span> Motivo do Deploy:</label>
                                <textarea class="form-control" id="description" rows="5"
                                    v-model="wfdeploy.description"></textarea>
                            </div>
                        </div>

                    </div>
                    <hr>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <div class="d-flex justify-content-between">
                                <router-link to="/usercustomers" class="btn btn-info">
                                    <i class="fa fa-arrow-left"></i> Voltar
                                </router-link>

                                <a class="btn btn-success" @click="save">
                                    Salvar
                                    <i class="fa fa-save"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <p><span class="required">*</span> Campos Obrigatórios</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
    data() {
        return {
            wfdeploy: {
                project_id: '',
                sprint_id: '',
                description: '',
                origin_id: '',
                type_project: '',
                tickets_id: '',
                justify: '',
                gmud: 0,
                priority: '',
            },
            origin: [
                {
                    id: '1',
                    name: 'Projeto'
                },
                {
                    id: '2',
                    name: 'Chamado'
                }
            ],
            typeProjects: [
                {
                    id: '1',
                    name: 'APP'
                },
                {
                    id: '2',
                    name: 'WEB'
                },
                {
                    id: '2',
                    name: 'Fluig'
                }
            ],
            priority: [
                {
                    id: '1',
                    name: 'Baixa Prioridade'
                },
                {
                    id: '2',
                    name: 'Média Prioridade'
                },
                {
                    id: '3',
                    name: 'Alta Prioridade'
                }
            ],
            tickets: [],
            projects: [],
            sprints: [],
            errors: undefined,
            user: {
                id: '',
                name: ''
            }
        }
    },
    computed: {
        filteredSprints: function () {
            return this.sprints.filter(x => x.project_id == this.wfdeploy.project_id);
        }
    },
    methods: {

        getProjects: function () {

            const self = this;
            const api = self.$store.state.api + 'projects/?paginated=false';

            self.$http.get(api)
                .then((response) => {
                    self.projects = response.data.data
                })
                .catch((error) => {
                    self.$message(null, error.response.data, 'error');
                });
        },
        getSprints: function () {
            const self = this;
            const api = self.$store.state.api + 'sprints/?paginated=false';

            self.$http.get(api)
                .then((response) => {
                    self.sprints = response.data.data
                })
                .catch((error) => {
                    self.$message(null, error.response.data, 'error');
                });
        },
        getTickets: function () {
            const self = this;
            const api = self.$store.state.api + 'tickets/?paginated=false';

            self.$http.get(api)
                .then((response) => {
                    self.tickets = response.data.data
                })
                .catch((error) => {
                    self.$message(null, error.response.data, 'error');
                });
        },

        save: function () {

            const api = this.$store.state.api + 'wfdeploys?small=1';
            const self = this;

            let form = new FormData();
            form.append('user_id', this.user.id);
            form.append('project_id', self.wfdeploy.project_id);
            form.append('sprint_id', self.wfdeploy.sprint_id);
            form.append('priority', self.wfdeploy.priority);
            form.append('origin', self.wfdeploy.origin);
            form.append('ticket_id', self.wfdeploy.ticket_id);
            form.append('type_project', self.wfdeploy.type_project);
            form.append('gmud', self.wfdeploy.gmud ? 1 : 0);
            form.append('description', self.wfdeploy.description);
            form.append('status_deploy', 3);

            if (self.wfdeploy.gmud) {
                form.append('status', 7);
            } else {
                form.append('status', 8);
            }

            self.$http.post(api, form)
                .then((response) => {

                    self.$message('Sucesso', `Solicitação de Deploy registrada com sucesso`, 'success');
                    this.$router.push('/wfdeploys/deploys');

                })
                .catch((error) => {
                    self.errors = error.response.data;

                    if (typeof self.errors === 'object') {
                        let html = '<ul>';

                        $.each(self.errors, function (key, value) {
                            html += '<li>' + value[0] + '</li>';
                        });

                        html += '</ul>';

                        self.errors = html;
                    }

                    self.$message(null, self.errors, 'error');
                });

        },
    },
    mounted: function () {

        this.getProjects();
        this.getSprints();
        this.getTickets();


        this.user = this.$store.state.user;
        console.log(this.user.id);

    },
    components: {
        vSelect
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.required {
    color: red;
}

.float-right {
    width: 30%;
    float: right;
}

#most {
    display: none;
}

#pass:hover #most {
    display: block;
}

small {
    color: grey;
    border: 1px;
    border-color: black;
    user-select: none;
}

.slt-none {
    user-select: none;
}</style>
