import Index from '../components/Backlog/Index'

let routes = {
    path: '/backlog',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/backlog/',
            name: 'Backlog',
            component: Index,
        },
    ]
};


export default routes
