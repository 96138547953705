import Opens from '../components/NewTickets/Opens'
import Closeds from '../components/NewTickets/Closeds'
import Add from '../components/NewTickets/Add'
import View from '../components/NewTickets/View'
import DashTickets from '../components/NewTickets/DashTickets'
import MyTickets from '../components/NewTickets/MyTickets'
import Panel from '../components/NewTickets/Panel'
import TicketManagement from '../components/NewTickets/TicketManagement'

let routes = {
  path: '/wfdeploys',
  component: resolve => require(['../components/layout/App'], resolve),
  children: [{
    path: '/new_tickets/',
    name: 'Chamados Abertos',
    component: Opens,
  },
  {
    path: '/tickets-management/',
    name: 'Tickets',
    component: TicketManagement,
    meta: {
        permission: 'is_admin'
    }
  },
  {
    path: '/closed_tickets/',
    name: 'Chamados Finalizados',
    component: Closeds,
  },
  {
    path: '/new_tickets/add',
    name: 'Adicionar Chamado',
    component: Add,
    meta: {
      permission: 'is_admin'
    }
  },
  {
    path: '/new_tickets/view/:id?',
    name: 'Visualizar Chamado',
    component: View,
  },
  {
    path: '/dash_tickets',
    name: 'Visualizar Chamado',
    component: DashTickets
  },
  {
    path: '/my_tickets',
    name: 'Visualizar Chamado',
    component: MyTickets
  },
  {
    path: '/tickets_panel',
    name: 'Painel de Chamados',
    component: Panel
  },
  ]
};


export default routes
