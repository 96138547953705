import Crud from '../components/Ticket/Crud'
import View from '../components/Ticket/View'
import Edit from '../components/Ticket/Edit'
import TicketReport from '../components/Ticket/TicketReport'


let routes = {
    path: '/tickets',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/tickets/report/',
            name: 'Tickets',
            component: TicketReport,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/tickets/',
            name: 'Tickets',
            component: Crud,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/tickets/view/:id?',
            name: 'Tickets/View',
            component: View,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/tickets/edit/:id?',
            name: 'Tickets/Edit',
            component: Edit,
            meta: {
                permission: 'is_admin'
            }
        }

    ]
};


export default routes
