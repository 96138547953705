<template>
  <div>
    <div class="row justify-content-center align-items-center login-top">
      <div class="col-lg-3 p-0 d-none d-lg-block">
        <div class="log-pic">
          <img src="../assets/images/login.jpg" alt />
        </div>
      </div>
      <div class="col-lg-3 p-lg-0">
        <div class="login">
          <img
            class="log-logo"
            src="../assets/images/logo-innovare-dark.png"
            alt
          />
          <div class="log-text">
            Seja bem-vindo ao IPP (Innovare Project Planing). Acesse para ver as
            funcionalidades.
          </div>
          <form action>
            <div class="form-group">
              <label for="inputEmail">Email</label>
              <input
                type="email"
                class="form-control"
                id="inputEmail"
                placeholder
                value="admin@innovareti.com.br"
                v-model="email"
              />
            </div>
            <div class="form-group">
              <label for="inputSenha">Senha</label>
              <input
                @keyup="capsOn"
                type="password"
                class="form-control"
                id="inputSenha"
                placeholder
                value="innovareti"
                v-model="password"
                @keyup.enter="login"
              />
              <div v-if="activatedCaps" class="left">
                <label>
                  <span class="caps"
                    >CapsLock Ativo
                    <i class="fa fa-arrow-circle-up" aria-hidden="true"></i
                  ></span>
                </label>
              </div>
              <small id="ajudaSenha" class="form-text text-muted float-right">
                <a href data-toggle="modal" data-target="#modalSenha"
                  >Esqueci minha senha</a
                >
              </small>
            </div>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="switchLembrar"
              />
              <label class="custom-control-label" for="switchLembrar"
                >Lembrar Senha</label
              >
            </div>
            <button
              @click="login"
              type="button"
              class="btn btn-block btn-login"
            >
              Entrar
            </button>
          </form>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="modalSenha"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalSenhaTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalSenhaTitle">
              Esqueci minha senha
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="log-text">
              Insira seu email cadastrado e em instantes você receberá uma nova
              senha.
            </div>
            <form action>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  v-model="forgot.email"
                  id="inputEmailPass"
                  placeholder="Email"
                />
              </div>
              <button
                type="button"
                class="btn btn-block btn-login"
                @click="forgotPassword"
              >
                Solicitar Recuperação
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activatedCaps: false,
      email: "",
      password: "",
      forgot: {
        email: "",
      },
    };
  },
  methods: {
    login() {
      const self = this;
      const api = this.$store.state.api + "login";

      axios
        .post(api, {
          email: self.email,
          password: self.password,
        })
        .then((response) => {
          self.$store.commit("auth", response.data.access_token);
          self.$router.push({ path: "/" });
        })
        .catch((error) => {
          self.$message("Erro", error.response.data, "error");
        });
    },
    capsOn() {
      if (event.getModifierState && event.getModifierState("CapsLock")) {
        this.activatedCaps = true;
      } else {
        this.activatedCaps = false;
      }
    },
    forgotPassword() {
      const self = this;
      const api = this.$store.state.api + "user/recovery";

      axios
        .post(api, {
          email: self.forgot.email,
        })
        .then((response) => {
          self.$message(
            "Sucesso",
            "Solicitação realizada com sucesso",
            "success"
          );
        })
        .catch((error) => {
          self.$message("Erro", error.response.data, "error");
        });
    },
  },
};
</script>

<style scoped>
body {
  font-family: "Roboto", sans-serif;
}
form .form-input {
  width: 90% !important;
  font-size: 15px !important;
  padding: 10px !important;
  border: 1px solid #c0c0c0;
}
form .form-label {
  width: 90% !important;
  display: block;
  text-align: left;
  font-size: 16px !important;
  margin-left: auto;
  margin-right: auto;
}
form .form-button {
  width: 90%;
  padding: 10px;
  font-size: 18px;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #f0f0f0;
  background: #1197d6 !important;
  color: white;
}

#myModal {
  text-align: left;
}

@media (max-width: 768px) {
  .ibox {
    margin: 0px 0px 0px 0px;
  }
}
.caps {
  color: grey;
}
</style>
