<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">Novo Registro</div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <label for="title">Titulo</label>
          <input type="text" class="form-control" id="title" v-model="wikiclients.title" disabled="disabled"><br>
        </div>

        <div class="col-md-6">
          <label for="title">Cliente</label>
          <v-select
            :options="customers"
            label="customer_name"
            id="customers"
            :reduce="customers => customers.id"
            v-model="wikiclients.customer_id"
            disabled
          >
            <div slot="no-options">Carregando...</div>
          </v-select>
        </div>
        <div class="col-md-6">
          <label for="title">Tipos de Registro</label>
            <input type="text" class="form-control" id="typeOfRegister" v-model="wikiclients.typeOfRegister" disabled />
          <br />
        </div>

      </div><br>

      <div class="row">
        <div class="col-md-12">
          <label for="">Detalhes</label>
          <ckeditor :editor="editor" v-model="wikiclients.description" :config="editorConfig" disabled="disabled"></ckeditor>
        </div>
      </div><br>

      <div class="row">
        <div class="col-md-12">
          <label for="">Tags</label>
            <v-select label="title" id="title" taggable multiple push-tag :options="wikiclients.get_wiki_clients_tags" v-model="wikiclients.get_wiki_clients_tags" disabled="disabled">
            </v-select>
        </div>
      </div>

    </div>

    <div class="card-body">
      <hr />
      <div class="block text-right">
          <router-link to="/wikiclients" class="btn btn-info">
            <i class="fa fa-arrow-left"></i> Voltar
          </router-link>
      </div>

    </div>
  </div>
</template>

<script>

import vSelect from 'vue-select'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  components: {
   vSelect
  },
          data() {
            return {
                editor: ClassicEditor,
                editorData: '<p>Hello World ...</p>',
                editorConfig: {
                },
                wikiclients:{
                  title: '',
                  customer_id: '',
                  typeOfRegister: '',
                  description: '',
                  tags: ''
                },
                clients: [],
                typeOfRegister: [],
                customers: []
            };
        },
  computed: {
    url() {
      return (
        this.$store.getters.api + "issues/?project_id=" + this.$route.params.id
      );
    }
  },


  mounted() {
    let self = this;

    let id = self.$route.params.id;
    self.getCustomers();

    if(id){
      self.getWikiClients(id);
  }
  },
  methods: {

    getWikiClients: function(id){
        let self = this;
        let api  = self.$store.state.api + 'wikiclients/' + id + '?with[]=GetWikiClientsTags';

        self.$http.get(api)
            .then((response) => {
                self.wikiclients = response.data.data[0];
            })
            .catch((error) => {
                self.errors = error.response.data;
                self.$message(null, self.errors, 'error');
            });
    },
    getCustomers() {
        let self = this;
        let api  = self.$store.state.api + 'customers?paginated=false';

        self.$http.get(api)
            .then((response) => {
                self.customers = response.data.data;
            })
            .catch((error) => {
                self.errors = error.response.data;
                self.$message(null, self.errors, 'error');
            });
    },
  }

};
</script>

<style>
</style>
