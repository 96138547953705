  <template>
    <div>
        <base-crud
            crudName="QA - Quality Assurance"
            newText="Nova Solicitação"
            :table="table"
            :columns="columns"
            :options="options"
            :endPoint='endPoint'
            :enableAdd="false"
            :enableEdit="false"
            :enableDelete="false"
            :enableView="false"
            :dataForm="dataForm"
            :customFilters="customFilters"
            @filtered="filtered"
            :extra="extra"
            @assign="assign"
            @step_qa="step_qa"
            @view="view"

        >
        </base-crud>
    </div>
</template>

<script>
    import BaseCrud from '../Base/BaseCrud'
    import { debug } from 'util';

    export default {
        data: function () {
            return {
              customFilters:[
                {
                  name: "created_at",
                  type: "date",
                  call: "filtered"
                }
              ],
                assigneds: [],
                assignedIds: [],
                table: 'qaTable',
                pages: [],
                url: '',
                columns: ['id', 'user_id', 'qa_user_id', 'project_id', 'priority', 'created_at', 'status', 'actions'],
                tableData: [
                  'id',
                  'description'
                ],
                options: {
                    pagination: {chunk: 10, dropdown: false, nav: 'scroll' },
                    perPage: 10,
                    filterByColumn: true,
                    filterable: ['user_id', 'qa_user_id', 'project_id', 'priority', 'status'],
                    debounce: 800,
                    perPageValues: [10,25,50,100],
                    headings: {
                        'id': 'ID',
                        'user_id' : 'Solicitante',
                        'qa_user_id' : 'Responsável QA',
                        'project_id' : 'Projeto',
                        'priority' : 'Prioridade',
                        'created_at' : 'Data Solicitação',
                        'status' : 'Status',
                        'actions' : 'Ações'
                    },

                    sortable: ['id', 'priority'],
                    texts: {
                    filterBy: "Filtrar por",
                    defaultOption: "Selecione"
                    },
                    listColumns: {

                    priority: [
                        {
                        id: "Baixa Prioridade",
                        text: "Baixa Prioridade"
                        },
                        {
                        id: "Média Prioridade",
                        text: "Média Prioridade"
                        },
                        {
                        id: "Alta Prioridade",
                        text: "Alta Prioridade"
                        },
                    ]
                    },
                     texts: {
                    filterBy: "Filtrar",
                    defaultOption: "Selecione"
                    },
                    listColumns: {

                    status: [
                        {
                        id: 1,
                        text: "Aguardando Análise"
                        },
                        {
                        id: 2,
                        text: "QA Rejeitado"
                        },
                     ]
                    },
                    templates: {
                        user_id: function (h, row, index) {
                            return row.user ? row.user.name : 'Sem Nome';
                        },
                        qa_user_id: function (h, row, index) {
                            return row.user_qa ? row.user_qa.name : 'Não Atribuido';
                        },
                        status: function (h, row, index) {
                            return row.deploy_status ? row.deploy_status.title : 'Sem Nome';
                        },
                        project_id: function (h, row, index) {
                            return row.project ? row.project.name : 'Sem Nome';
                        },
                        priority: function (h, row, index) {
                            if(row.priority == 'Alta Prioridade'){
                                return <h5><span class="badge badge-danger">Alta Prioridade</span></h5>
                            } else if(row.priority == 'Média Prioridade') {
                                return <h5><span class="badge badge-warning">Média Prioridade</span></h5>
                            } else {
                                return <h5><span class="badge badge-success">Baixa Prioridade</span></h5>
                            }
                        },
                    },
                    requestFunction: function (data) {
                      let requestData = {};

                      let query = this.$parent.$parent.query(data.query);

                      requestData.params = data;

                      if (
                        this.$parent.$parent.created_at != null &&
                        this.$parent.$parent.created_at != ""
                      ) {
                        requestData.params.created_at = this.$parent.$parent.created_at;
                      }

                      requestData.params.query = "";
                      requestData.params.with = [
                      'user', 'user_qa', 'deployStatus', 'project'
                      ];

                      return this.$http.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                          this.dispatch("error", e);
                        }.bind(this)
                      );
                    },
                },
                extra: [
                    {
                        id: 1,
                        label: '',
                        class: 'btn btn-crud edit fa fa-user-check',
                        title: 'Atribuir a mim',
                        name: 'assign',
                        exceptIds: [this.parsedobj],
                    },
                    {
                        id: 2,
                        label: '',
                        class: 'btn btn-crud check fa fa-list',
                        title: 'Analisar',
                        name: 'step_qa',
                    },
                    {
                        id: 3,
                        label: '',
                        class: 'btn btn-crud view fa fa-eye',
                        title: 'Visualizar',
                        name: 'view',
                    },

                ],
                endPoint: 'wfdeploys/',
                dataForm: {}
            }
        },
        components: {
            BaseCrud
        },
        methods: {
           filtered(date) {
              // console.log('aa')
              this.created_at = date;
            },
            query: function (query) {
                let columns = {
                    user_id: 'user[name]',
                    qa_user_id: 'user_qa[name]',
                    status: 'deploy_status[title]',
                    project_id: 'project[name]',
                    created_at: 'created_at'
                };
                let filters = 'status_qa=1';
                $.each(query, function (index, value) {
                    filters += columns[index] + '=' + value + '&';
                });
                return filters;
            },
            filtered(date) {
              // console.log('aa')
              this.created_at = date;
            },
            assign: function (props, row, index){
                const self = this;

                if(props.row.user_id == this.user.id){

                    swal('Erro', 'Você não pode realizar um QA solicitado por você.', 'info');

                } else
                if(props.row.status != '1'){

                    swal('Erro', 'Tarefa já atribuída a um usuário', 'info');

                } else {

                let api = self.$store.state.api + 'wfdeploys/' + props.row.id;
                let fd  = new FormData();

                fd.append('_method', 'PATCH');
                fd.append('qa_user_id', this.user.id);
                fd.append('status', '2');

                swal({
                    title: "Deseja assumir esta tarefa?",
                    text: "Essa operação não pode ser desfeita",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Sim",
                    showLoaderOnConfirm: true,
                    buttons: {
                        catch: {
                            text: "Não",
                            value: "cancel",
                            className: "btn-danger"
                        },
                        confirm: {
                            text: "Sim",
                            value: 'confirm'
                        }
                    },
                    })
                    .then((value) => {
                        switch (value) {
                            case 'cancel':
                                swal('Cancelado', 'A operação não foi realizada', 'info');
                                break;

                            case 'confirm':

                                self.$http.post(api, fd)
                                .then((response) => {
                                    this.$router.push({
                                        path: 'step_qa/' + props.row.id
                                    })
                                })
                                break;
                        }
                    })
                    .catch((error) => {
                        self.$message(null, error.response.data, 'error');
                    });
                }
            },
            qa: function (props, row, index){
                const self = this;
            },
            step_qa: function (props, row, index){

                if(props.row.qa_user_id == null){

                    swal('Erro', 'Você precisa se atribuir a esta tarefa primeiro', 'info');

                } else
                if(props.row.qa_user_id != this.user.id){

                    swal('Erro', 'Você não tem permissões para acessar esta tarefa', 'info');

                } else {

                    if(props.row.status > '3'){

                        swal('Erro', 'QA - Quality Assurance já finalizado', 'info');

                    } else {
                        this.$router.push({
                            path: 'step_qa/' + props.row.id
                        })
                    }
                }
            },
            view: function (props, row, index){
                const self = this;
                this.$router.push('view/' + props.row.id);
            },
        },
        mounted: function(){
            this.user = this.$store.getters.user;
        },
    }

</script>

<style scoped>
    .VuePagination {
        text-align: center;
    }
</style>
