<template>
    <div>
        <base-crud
                crudName="Projetos"
                newText="Novo Projeto"
                :table="projectTable"
                :columns="columns"
                :options="options"
                :endPoint='endPoint'
                :enableAdd="true"
                :enableEdit="true"
                :enableDelete="true"
                :enableView="true"
                :dataForm="dataForm"
                :customFilters="customFilters"
                @createdAt="createdAt"
                @initialDate="initialDate"
                @finalDate="finalDate"
                :extra="extra"
                @board="board"
        >

        </base-crud>
    </div>
</template>

<script>
    import BaseCrud from '../Base/BaseCrud'
import { debug } from 'util';

    export default {
        data: function () {
            let self = this
            return {
              customFilters:[
                {
                  name: "created_at",
                  type: "date",
                  call: "createdAt"
                },
                {
                  name: "initial_date",
                  type: "date",
                  call: "initialDate"
                },
                {
                  name: "final_date",
                  type: "date",
                  call: "finalDate"
                },
              ],
                extra: [
                  {
                    id: 1,
                    label: '',
                    class: 'btn btn-crud edit fa fa-clipboard-list',
                    title: 'Board',
                    name: 'board',
                  },
                ],
                projectTable: 'projectTable',
                users: [],
                pages: [],
                url: '',
                columns: ['id', 'name', 'project_status_id', 'customer_id', 'created_at', 'initial_date', 'final_date', 'actions'],
                tableData: ['id', 'name', 'customer_id', 'created_at', 'initial_date', 'final_date'],
                debounce: 800,
                options: {
                  filterByColumn: true,
                  filterable: ['name', 'project_status_id', 'customer_id'],
                  listColumns: {
                   project_status_id: [
                      {
                        id: 'Planejamento',
                        text: 'Planejamento',
                      },
                      {
                        id: 'Desenvolvimento',
                        text: 'Desenvolvimento',
                      },
                      {
                        id: 'Finalizado',
                        text: 'Finalizado',
                      },
                      {
                        id: 'StandBy',
                        text: 'StandBy',
                      }
                    ]
                  },
                    sortable: ['id', 'name', 'project_status_id'],
                    pagination: {chunk: 10, dropdown: false, nav: 'scroll' },
                    perPage: 10,
                    perPageValues: [10,25,50,100],
                    headings: {
                        'id': 'ID',
                        'project_status_id' : 'Status',
                        'name' : 'Nome',
                        'customer_id' : 'Cliente',
                        'created_at' : 'Data de Criação',
                        'initial_date' : 'Data de Inicio',
                        'final_date' : 'Data de Finalização',
                        'actions' : 'Ações'
                    },
                    templates: {

                        project_status_id: function (h, row, index) {
                            return row.status != undefined ? row.status.name : 'Sem Status Atribuído';
                        },
                        customer_id: function (h, row, index) {
                          return row.customers ? row.customers.customer_name : "Sem Cliente Atribuído";
                        },
                         initial_date: function (h, row, index) {
                            if(row.initial_date == ''){
                                return <h6><span>n/a</span></h6>
                            } else {
                              return row.initial_date;
                            }
                        },
                         final_date: function (h, row, index) {
                            if(row.final_date == ''){
                                return <h6><span>n/a</span></h6>
                            } else {
                              return row.final_date;
                            }
                        },
                    },
                    requestFunction: function (data) {
                      let requestData = {};

                      let query = this.$parent.$parent.query(data.query);

                      requestData.params = data;

                      if (
                        this.$parent.$parent.created_at != null &&
                        this.$parent.$parent.created_at != ""
                      ) {
                        requestData.params.created_at = this.$parent.$parent.created_at;
                      }
                      if (
                        this.$parent.$parent.initial_date != null &&
                        this.$parent.$parent.initial_date != ""
                      ) {
                        requestData.params.initial_date = this.$parent.$parent.initial_date;
                      }
                      if (
                        this.$parent.$parent.final_date != null &&
                        this.$parent.$parent.final_date != ""
                      ) {
                        requestData.params.final_date = this.$parent.$parent.final_date;
                      }

                      requestData.params.query = "";
                      requestData.params.with = [
                      "status" , "customer"
                      ];

                      return this.$http.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                          this.dispatch("error", e);
                        }.bind(this)
                      );
                    },
                },
                endPoint: 'projects/',

                dataForm: {
                  title: {
                    add: 'Novo Projeto',
                    edit: 'Editar Projeto',
                    view: 'Visualizar Projeto',
                    icon: 'fa fa-user-circle'
                  },
                  button: {
                    back: '/projects-sprints'
                  },
                  url: {
                    model: 'projects'
                  },
                  messages: {
                    success: {
                      add: 'Projeto cadastrado com sucesso!',
                      edit: 'Projeto alterado com sucesso!'
                    }
                  },
                  fields: [
                    {
                      name: 'name',
                      model: 'name',
                      label: 'Nome',
                      type: 'text',
                      isRequired: {
                        add: true,
                        edit: true,
                      },
                      placeholder: 'Digite o nome do Projeto',
                      class: '',
                      length: "12|6|3",
                      offset: null
                    },
                    {
                      name: 'project_status_id',
                      model: 'project_status_id',
                      label: 'Status',
                      type: 'select',
                      list: [],
                      isRequired: {
                        add: true,
                        edit: true,
                      },
                      placeholder: 'Digite o Status do Projeto',
                      class: '',
                      length: "12|6|3",
                      offset: null
                    },
                    {
                      name: 'customer_id',
                      model: 'customer_id',
                      label: 'Cliente',
                      type: 'select',
                      list: [],
                      isRequired: {
                        add: true,
                        edit: true,
                      },
                      placeholder: 'Digite o Cliente do Projeto',
                      class: '',
                      length: "12|6|3",
                      offset: null
                    },
                    {
                      name: 'initial_date',
                      model: 'initial_date',
                      label: 'Data Inicial',
                      type: 'date',
                      list: [],
                      isRequired: {
                        add: false,
                        edit: false,
                      },
                      placeholder: 'Data inicial',
                      class: '',
                      length: "0|12|3",
                      offset: null
                    },
                    {
                      name: 'final_date',
                      model: 'final_date',
                      label: 'Data Final',
                      type: 'date',
                      list: [],
                      isRequired: {
                        add: false,
                        edit: false,
                      },
                      placeholder: 'Data inicial',
                      class: '',
                      length: "0|6|3",
                      offset: null
                    },
                  ]
                }
            }
        },
        methods: {
            createdAt(date) {
              this.created_at = date;
            },
            initialDate(date) {
              this.initial_date = date;
            },
            finalDate(date) {
              this.final_date = date;
            },
             query: function (query) {
                  let columns = {
                      name: 'name',
                      project_status_id: 'status[name]',
                      customer_id: "customers[customer_name]",
                      created_at: "created_at",
                      initial_date: 'initial_date',
                      final_date: 'final_date'
                  };
                  let filters = '&orderByDesc=id&';
                  $.each(query, function (index, value) {
                      filters += columns[index] + '=' + value + '&';
                  });
                  return filters;
              },
          board(data){
            this.$router.push({ path: "/projects/board/" + data.row.id });
          }

        },
        mounted () {

            let self = this

            this.$http.get( this.$store.getters.api + 'projectstatuses?paginated=false')
            .then( response => {
                self.dataForm.fields[1].list = response.data.data
            })

            this.$http.get( this.$store.getters.api + 'customers?paginated=false')
            .then( response => {
              self.dataForm.fields[2].list = response.data.data
            })
        },
        components: {
            BaseCrud
        },
        computed: {
            statusList () {
                // return this.$http.get('/status')
                return [
                    {id: 'e', label: '123'}
                ]
            }
        }
    }
</script>

<style scoped>
    .VuePagination {
        text-align: center;
    }
</style>
