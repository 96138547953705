import Crud from '../components/Daily/Crud'
import Add from '../components/Daily/Add'
import View from '../components/Daily/View'

let routes = {
    path: '/Daily',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/Daily/',
            name: 'Listar Registros',
            component: Crud,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/dailies/Add',
            name: 'Nova Daily',
            component: Add,
        },
        {
            path: '/dailies/View/:id',
            name: 'Visualizar',
            component: View,
            meta: {
                permission: 'is_admin'
            }
        }

    ]
};

export default routes;
