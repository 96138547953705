<template>
  <div>
    <div class="panel animated fadeInRightBig">
      <div class="panel-heading">
        <div class="card-header">
          <div class="card-title">Novo Tipo Contrato</div>
        </div>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10">
            <form action>
              <div class="row">
                <div class="col-md-11">
                  <div class="form-group">
                    <label for="userName">
                      <span class="required">*</span> Titulo
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="typeofcontracts.title"
                      placeholder
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-11">
                  <div class="form-group">
                    <label for="">Descrição</label>
                    <textarea
                      type="text"
                      class="form-control"
                      v-model="typeofcontracts.description"
                      rows="8"
                      placeholder
                    />
                  </div>
                </div>
              </div>

              <hr />
              <br />
              <div class="row ">
                <div class="col-md-12">
                  <div class="d-flex justify-content-between">
                    <router-link to="/usercustomers" class="btn btn-info">
                    <i class="fa fa-arrow-left"></i> Voltar
                  </router-link>

                  <a class="btn btn-success" @click="save">
                    Salvar
                    <i class="fa fa-save"></i>
                  </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>


export default {
 
  data() {
    return {
      typeofcontracts: {
        title: "",
        description: ""
      }
    };
  },
  methods: {
    save: function() {
      const api = this.$store.state.api + "typeofcontracts";
      const self = this;

      let form = new FormData();

      form.append("title", self.typeofcontracts.title);
      form.append("description", self.typeofcontracts.description);

      self.$http
        .post(api, form)
        .then(response => {
          self.typeofcontracts = response.data.data;
          self.tableData = self.users;

          self.$message("Sucesso", `Cadastrado com sucesso`, "success");

          self.typeofcontracts = [];
          this.$router.push("/typeofcontracts");
        })
        .catch(error => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function(key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
    }
  }
};
</script>
<style scoped>
</style>

