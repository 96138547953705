<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card1">
        <div class="card-body">
          <div class="col-md-12">
            <div class="row">
              <label for="">Somente visualização:</label>
            </div>
          </div>
          <form
            @submit.prevent
            class="form-material"
            :class="isEdit"
            modalMode="edit"
          >
            <div class="row">
              <div class="col-sm-12">
                <div class="row form-group">
                  <div class="col-sm-12">
                    <label for>Título</label>
                    <input
                      disabled
                      type="text"
                      class="form-control"
                      v-model="issue.summary"
                    />
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-4">
                    <label for>Projeto</label>
                    <v-select
                      disabled
                      :options="projects"
                      :reduce="(projects) => projects.id"
                      :filterable="false"
                      v-model="issue.project_id"
                      label="Projeto"
                    >
                      <template slot="no-options"
                        >Digite para pesquisar Projetos</template
                      >
                      <template slot="option" slot-scope="option">
                        <div class="d-center">{{ option.name }}</div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">{{ option.name }}</div>
                      </template>
                    </v-select>
                  </div>
                  <div class="col-sm-4">
                    <label for>Sprint</label>
                    <v-select
                      disabled
                      :options="sprints"
                      label="name"
                      id="sprint_id"
                      :reduce="(sprints) => sprints.id"
                      v-model="issue.sprint_id"
                    >
                      <template slot="no-options"
                        >Selecione um projeto para listar as sprints</template
                      >
                      <template slot="option" slot-scope="option">
                        <div class="d-center">{{ option.name }}</div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">{{ option.name }}</div>
                      </template>
                    </v-select>
                  </div>
                  <div class="col-sm-4">
                    <label for="">Prazo</label>
                    <input
                      disabled
                      type="date"
                      class="form-control"
                      v-model="issue.due_date"
                    />
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-2">
                    <label for>Criticidade</label>
                    <select
                      v-model="issue.criticality"
                      class="form-control"
                      disabled
                    >
                      <option value>Selecione...</option>
                      <option value="Low">Baixa</option>
                      <option value="Normal">Normal</option>
                      <option value="High">Alta</option>
                      <option value="Critical">Crítico</option>
                    </select>
                  </div>
                  <div class="col-sm-2">
                    <label for>Pontos</label>
                    <input
                      disabled
                      type="number"
                      v-model="issue.story_points"
                      class="form-control"
                      min="0"
                    />
                  </div>
                  <div class="col-sm-4">
                    <label for>Criado Por</label>
                    <input
                      disabled
                      type="text"
                      v-model="creator"
                      class="form-control"
                    />
                  </div>
                  <div class="col-sm-4">
                    <label for>Responsável</label>
                    <v-select
                      disabled
                      :options="users"
                      :reduce="(users) => users.id"
                      label="name"
                      :filterable="false"
                      v-model="issue.user_id"
                    >
                      <template slot="no-options"
                        >Digite para pesquisar Usuários</template
                      >
                      <template slot="option" slot-scope="option">
                        <div class="d-center">{{ option.name }}</div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">{{ option.name }}</div>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-sm-12">
                    <label for>Descrição</label>
                    <!-- <textarea name="" id="" rows="3" class="form-control" v-model="issue.description"></textarea> -->
                    <ckeditor
                      disabled
                      :editor="editor"
                      v-model="issue.description"
                      :config="editorConfig"
                    ></ckeditor>
                  </div>
                </div>
                <!-- <div class="row form-group">
                      <div class="col-sm-12">
                        <label for>Upload de Arquivos</label>
                        <vue-dropzone
                        @vdropzone-sending="sendingEvent"
                        @vdropzone-complete="completeUpload"
                        ref="myVueDropzone"
                        id="dropzone"
                        disabled
                        :options="dropzoneOptions"
                      ></vue-dropzone>
                      </div>
                    </div> -->
              </div>
              <div class="col-sm-4" style="border-left: 1px solid black">
                <div
                  class="row"
                  v-for="comment in comments"
                  :key="comment.id + new Date()"
                >
                  <template v-if="comment.user.id == $store.getters.user.id">
                    <div class="col-sm-2">
                      <img
                        :src="comment.user.photo"
                        alt="sunil"
                        class="photoFull"
                      />
                    </div>
                    <div class="col-sm-10">
                      <div class="alert alert-info ipp-alert">
                        {{ comment.comment }}
                      </div>
                      <small style="color: grey" class="pull-right coment-date"
                        >{{ comment.user.name }} -
                        {{ comment.created_at | brDate }}</small
                      >
                    </div>
                  </template>
                  <template v-else>
                    <div class="col-sm-10">
                      <div class="alert alert-info ipp-alert">
                        {{ comment.comment }}
                      </div>
                      <small style="color: grey" class="pull-left coment-date"
                        >{{ comment.user.name }} -
                        {{ comment.created_at | brDate }}</small
                      >
                    </div>
                    <div class="col-sm-2">
                      <img
                        :src="comment.user.photo"
                        alt="sunil"
                        class="photoFull"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
// import _ from "lodash";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import NewBoard from "./NewBoard";

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());
    xhr.open(
      "POST",
      "https://api.projetos.innovareti.com.br/api/v1/images/upload/",
      true
    );
    xhr.responseType = "json";
  }
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }
      resolve({
        default: response.url,
      });
    });
    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }
  _sendRequest(file) {
    const data = new FormData();

    data.append("upload", file);
    this.xhr.send(data);
  }
}

function MyUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
    return new MyUploadAdapter(loader);
  };
}
export default {
  props: {
    modalMode: {
      type: String,
      default: "edit",
    },
    selectedIssue: {
      type: Object,
      default: function () {
        this.selectedIssue;
      },
    },
  },
  components: {
    vSelect,
    NewBoard,
  },
  data() {
    return {
      newcomment: "",
      editor: ClassicEditor,
      editorData: "",
      plugins: ["SimpleUploadAdapter"],
      editorConfig: {
        extraPlugins: [MyUploadAdapterPlugin],
      },
      issue: {
        id: "",
        summary: "",
        project_id: 0,
        sprint_id: 0,
      },
      creator: "",
      projects: [],
      sprints: [],
      users: [],
      comments: [],
    };
  },
  computed: {
    filteredSprints: function () {
      return this.sprints.filter((x) => x.project_id == this.issue.project_id);
    },
  },
  watch: {
    modalStatus: function (newStatus) {
      this.clean();
      if (newStatus == true) {
        this.startModal();
      }
    },
  },
  computed: {
    isEdit() {
      return this.modalMode == "edit";
    },
    isAdd() {
      return this.modalMode == "add";
    },

    modalLabel() {
      if (this.modalMode == "add") {
        return "Adicionar";
      } else if (this.modalMode == "edit") {
        return "Editar";
      } else if (this.modalMode == "view") {
        return "Consultar";
      }
      return "";
    },
  },
  mounted() {
    let self = this;

    self.getIssues();
    self.getProjects();
    self.getSprints();
    self.getUsers();

    $("#issueModal").on("hidden.bs.modal", function (e) {
      self.clean();
    });
    window.addEventListener("keyup", function (id) {
      if (event.altKey && event.keyCode === 46) {
        self.deleteIssue(id);
      }
    });
  },
  methods: {
    cardEdit() {
      this.$emit.selectedIssue;
    },
    sendingEvent(file, xhr, formData) {
      formData.append("issue_id", this.$route.params.id);
    },
    completeUpload(response) {
      this.getIssues();
    },
    getIssues: function () {
      const self = this;
      const api = self.$store.state.api + "viewIssue/" + this.$route.params.id;

      self.$http
        .get(api)
        .then((response) => {
          console.log(response.data);
          self.creator = response.data.creator;
          self.issue = response.data.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProjects: function () {
      const self = this;
      const api = self.$store.state.api + "projects?paginated=false";

      self.$http
        .get(api)
        .then((response) => {
          self.projects = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getSprints: function () {
      const self = this;
      const api = self.$store.state.api + "sprints?paginated=false";

      self.$http
        .get(api)
        .then((response) => {
          self.sprints = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "users?paginated=false";

      self.$http
        .get(api)
        .then((response) => {
          self.users = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getComments(id) {
      let self = this;
      let api =
        this.$store.getters.api + "issues/" + id + "?with[]=comments.user";

      this.$http.get(api).then((response) => {
        this.comments = response.data.data[0].comments;
      });
    },
    saveComment() {
      let self = this;
      let api = this.$store.getters.api + "issue-comments";

      if (self.newcomment != "") {
        self.$http
          .post(api, {
            issue_id: self.issue.id,
            comment: self.newcomment,
          })
          .then((response) => {
            self.$message("Sucesso", "Comentário Adicionado", "success");
          })
          .then(() => {
            this.getComments(self.issue.id);

            self.newcomment = "";
          });
      }
    },
    selectedProject(project) {
      let self = this;
      let api = this.$store.getters.api + "sprints";

      self.$http.get(api + "?project_id=" + project.id).then((response) => {
        self.sprints = response.data.data;
      });
    },
    searchUser: _.debounce(function (search) {
      let self = this;
      let api = this.$store.getters.api + "users";
      if (search.length >= 3) {
        self.$http.get(api + "?name=" + search).then((response) => {
          self.users = response.data.data;
        });
      }
    }, 300),
    searchProject: _.debounce(function (search) {
      let self = this;
      let api = this.$store.getters.api + "projects";
      if (search.length >= 3) {
        self.$http.get(api + "?name=" + search).then((response) => {
          self.projects = response.data.data;
        });
      }
    }, 300),
    startModal() {
      if (this.modalMode == "edit") {
        this.issue.summary = this.issue.summary;
        this.issue.description = this.issue.description;
        this.issue.story_points = this.issue.story_points;
        this.issue.criticality = this.issue.criticality;
        this.issue.id = this.issue.id;
        this.issue.status = this.issue.status;
        this.issue.comments = this.issue.comments;
        this.issue.project_id = this.projects.name;
        this.issue.sprint_id = this.issue.sprint;
        this.issue.user_id = this.issue.user;

        this.comments = [];
        this.getComments(this.issue.id);
      }
      $("#issueModal").modal("toggle");
    },
    clean() {
      this.$emit("changeModal", false);
    },
    deleteIssue() {
      let self = this;
      let api = this.$store.getters.api + "issues/" + self.issue.id;

      if (this.modalMode == "edit") {
        self.$http
          .delete(api)
          .then((response) => {
            self.$message("Sucesso", "Card Excluído com Sucesso", "success");
          })
          .then(() => {
            self.$emit("refresh");
          })
          .catch((response) => {
            self.$errorMessage(response);
          });
      }
    },
    save() {
      let self = this;
      let api = this.$store.getters.api + "issues";

      let newIssue = {};

      newIssue.project_id =
        self.issue.project_id != null ? self.issue.project_id.id : "";
      newIssue.project_name =
        self.issue.project_id != null ? self.issue.project_id.name : "";
      newIssue.sprint_id =
        self.issue.sprint_id != null ? self.issue.sprint_id.id : "";

      newIssue.original_estimate = 0;
      newIssue.summary = self.issue.summary;
      newIssue.description = self.issue.description;
      newIssue.total_spent = 0;
      newIssue.status = "todo";
      newIssue.story_points = self.issue.story_points;
      newIssue.criticality = self.issue.criticality;
      newIssue.due_date = self.issue.due_date;

      if (typeof self.issue.user_id == "object") {
        newIssue.user_id = self.issue.user_id.id;
      } else {
        newIssue.user_id = self.issue.user_id;
      }

      if (this.modalMode == "add") {
        self.$http
          .post(api, newIssue)
          .then((response) => {
            self.$message("Sucesso", "Card cadastrado com sucesso!", "success");
          })
          .then(() => {
            self.$emit("refresh");
          })
          .catch((response) => {
            self.$errorMessage(response);
          });
      } else {
        newIssue.status = self.issue.status;
        newIssue.id = self.issue.id;

        self.$http
          .put(api + "/" + newIssue.id, newIssue)
          .then((response) => {
            self.$message("Sucesso", "Card atualizado com sucesso!", "success");
          })
          .then(() => {
            self.$emit("refresh");
          })
          .catch((response) => {
            self.$errorMessage(response);
          });
      }
    },
  },
};
</script>


<style scoped>
.ck-editor__editable_inline {
  min-height: 400px !important;
  max-height: 500px !important;
}
.photo {
  height: 40px;
  width: 60px;
  object-fit: cover;
  border: 1px solid rgb(127, 227, 245);
  border-radius: 5px;
  border-color: rgb(127, 227, 245);
}
.hidden {
  display: none;
}

#message {
  height: 40px !important;
  min-height: 40px !important ;
}

/* Important part */

.modal-body {
  overflow-y: auto;
  height: 75vh;
}

.ipp-alert {
  padding: 5px !important;
  margin-bottom: 0px !important;
}

.coment-date {
  margin-bottom: 10px;
}
.modal {
  text-align: center;
  padding-right: 0px !important;
  height: auto !important;
}
/* .modal-dialog {
  display: inline-block;
  vertical-align: middle;
  min-width: 80vw;
} */
.modal-dialog {
  height: 100%; /* = 90% of the .modal-backdrop block = %90 of the screen */
}
.modal-content {
  height: 100%; /* = 100% of the .modal-dialog block */
}
</style>
