<template>
  <div class="card">
    <div class="card-header animated fadeInRightBig">
      <div class="card-title">Chamados por Usuário</div>

      <div class="card-body">
        <form @submit.prevent>
          <div class="row">
          
            <div class="col-md-4">
              <label for=""><span class="required">*</span> De:</label>
              <input type="date" class="form-control" v-model="initial_date" />
            </div>

            <div class="col-md-4">
              <label for=""><span class="required">*</span> Até:</label>
              <input type="date" class="form-control" v-model="final_date" />
            </div>

          </div>

          <hr />
          <button class="btn btn-primary" @click="generateReport">Gerar</button>

          <a href="" id="downloadReportUserCustomer" class="hidden" target="blank"></a>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
export default {
  components: { vSelect },
  data() {
    return {
      initial_date: new Date(),
      final_date: new Date(),
    };
  },
  mounted() {
   
  },
  methods: {
    generateReport() {
      $("#downloadReportUserCustomer").attr(
        "href",
        this.$store.getters.api +
          "report/report-usercustomer" +
          "?initial_date=" +
          this.initial_date +
          "&final_date=" +
          this.final_date
      );
      document.getElementById("downloadReportUserCustomer").click();
    },
  },
};
</script>

<style>
</style>