<template>
    <div>
        <base-crud
                crudName="Innovare TI - Usuarios"
                newText="Novo Registro"
                :table="projectTable"
                :columns="columns"
                :options="options"
                :endPoint='endPoint'
                :enableAdd="true"
                :enableEdit="true"
                :enableDelete="true"
                :enableView="true"
        >

        </base-crud>
    </div>
</template>

<script>
    import BaseCrud from '../Base/BaseCrud'
    import { debug } from 'util';

    export default {
        data: function () {
            let self = this
            return {
                projectstatuses: [],
                dataForm: {},
                projectTable: 'projectTable',
                users: [],
                pages: [],
                url: '',
                columns: ['id','name', 'customer_id','email', 'tel', 'cel', 'actions'],
                tableData: ['id',],
                debounce: 800,
                options: {
                    filterByColumn: true,
                    filterable: ['id', 'name', 'customer_id', 'email', 'tel', 'cel'],
                    sortable: ['id', 'name', 'customer_id', 'email', 'tel', 'cel'],
                    pagination: {chunk: 10, dropdown: false, nav: 'scroll' },
                    perPage: 10,
                    perPageValues: [10,25,50,100],
                    headings: {
                        'id': 'ID',
                        'name': 'Nome do Usuario',
                        'customer_id' : 'Nome do Cliente',
                        'email': 'E-mail',
                        'tel': 'Telefone',
                        'cel': 'Celular',
                        'actions': 'Ações'
                    },
                     templates: {
                      customer_id: function (h, row, index) {
                            return row.customers ? row.customers.customer_name : 'Nenhum Registro';
                        },
                    },
                    requestFunction: function (data) {
                        let requestData = {};

                        let query = this.$parent.$parent.query(data.query);
                        requestData.params = data;
                        requestData.params.query = '';
                        requestData.params.with = [
                        'customers'
                        ];

                        return this.$http.get(this.url + '?' + query, requestData)
                            .catch(function (e) {
                                    this.dispatch("error", e);
                                }.bind(this)
                            );
                    }
                },
                endPoint: 'usercustomers/',

            }
        },
        methods: {
            query: function (query) {
                let columns = {
                    id: 'id',
                    name: 'name',
                    customer_id: 'customers[customer_name]',
                    cnpj: 'cnpj',
                    email: 'email',
                    tel: 'tel',
                    cel: 'cel',
                    domain: 'domain'
                };
                let filters = '';
                $.each(query, function (index, value) {
                    filters += columns[index] + '=' + value + '&';
                });
                return filters;
            },
        },
        mounted () {

            let self = this
            this.$http.get( this.$store.getters.api + 'projectstatuses?paginated=false')
            .then( response => {
                self.projectstatuses = response.data.data
            })
        },
        components: {
            BaseCrud
        },
    }
</script>

<style scoped>
    .VuePagination {
        text-align: center;
    }
</style>
