import Crud from '../components/Contracts/Crud'
import Add from '../components/Contracts/Add'
import Edit from '../components/Contracts/Edit'
import View from '../components/Contracts/View'
import Manage from '../components/Contracts/Manage'
import ManageEdit from '../components/Contracts/ManageEdit'
import MonthLaunch from '../components/Contracts/MonthLaunch'
import MonthEnd from '../components/Contracts/MonthEnd'
import NewTicketReport from '../components/NewTickets/NewTicketReport'

let routes = {
    path: '/Contracts',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/contracts',
            name: 'Listar Registros',
            component: Crud,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/contracts/Add',
            name: 'Novo Registro',
            component: Add
        },
        {
            path: '/contracts/View/:id',
            name: 'Vizualizar Registro',
            component: View
        },
        {
            path: '/contracts/Edit/:id',
            name: 'Editar Registro',
            component: Edit
        },
        {
            path: '/contracts/manage/month-launch/:id',
            name: 'Gerenciar Registro',
            component: MonthLaunch
        },
        {
            path: '/contracts/manage/month-end/:id',
            name: 'Gerenciar Registro',
            component: MonthEnd
        },
        {
            path: '/contracts/report',
            name: 'Relatórios',
            component: NewTicketReport
        },
        {
            path: '/contracts/manage/:id',
            name: 'Gerenciar Registro',
            component: Manage
        },
        {
            path: '/contracts/manage/contractmanagers/edit/:id',
            name: 'Gerenciar Registro',
            component: ManageEdit
        },

    ]
};

export default routes;
