<template>
  <div>
    <base-crud
      crudName="Innovare TI - Aplicações"
      newText="Novo Registro"
      :table="projectTable"
      :columns="columns"
      :options="options"
      :endPoint="endPoint"
      :enableAdd="true"
      :enableEdit="true"
      :enableView="true"
      :enableDelete="true"
    ></base-crud>
  </div>
</template>

<script>
import BaseCrud from "../Base/BaseCrud";


export default {
  data: function () {
    let self = this;
    return {
      extra: [],
      projectstatuses: [],
      dataForm: {},
      projectTable: "projectTable",
      users: [],
      customers: [],
      typeOfContracts: [],
      pages: [],
      url: "",
      columns: [
        "id",
        "name",
        "environment",
        "link",
        "backup",
        // "protected_api",
        "api_status",
        "has_monitor",
        "customer_id",
        "actions",
      ],
      tableData: ["id"],
      debounce: 800,
      options: {
        filterByColumn: true,
        filterable: [
          "id",
          "name",
          "environment",
          "link",
          "backup",
          //"protected_api",
          "has_monitor",
          "customer_id",
        ],
        sortable: ["id"],
        listColumns: {
          has_monitor: [
            {
              id: 0,
              text: "Não",
            },
            {
              id: 1,
              text: "Sim",
            },
          ],
          // protected_api: [
            // {
              // id: 0,
              // text: "Não",
            // },
            // {
              // id: 1,
              // text: "Sim",
            // },
          // ],
          backup: [
            {
              id: 0,
              text: "Não",
            },
            {
              id: 1,
              text: "Sim",
            },
          ],
          environment: [
            {
              id: 0,
              text: "Homologação",
            },
            {
              id: 1,
              text: "Produção",
            },
          ],
        },
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          name: "Nome",
          environment: "Prod/Homolog?",
          staus: "link",
          backup: "Backup",
          //protected_api: "Monitorar Proteção API?",
          api_status: "Status Proteção API",
          has_monitor: "Monitorado?",
          customer_id: "Cliente",
          actions: "Ações",
        },
        templates: {
          customer_id: function (h, row, index) {
            return row.customers ? row.customers.customer_name : "Sem Nome";
          },
          backup: function (h, row, index) {
            if (row.backup === 1) {
              return (
                <h5>
                  <span class="badge badge-success">Sim</span>
                </h5>
              );
            } else {
              return (
                <h5>
                  <span class="badge badge-danger">Não</span>
                </h5>
              );
            }
          },
          // protected_api: function (h, row, index) {
            // if (row.protected_api === 1) {
              // return (
                // <h5>
                  /* <span class="badge badge-success">Sim</span> */
                /* </h5> */
              // );
            // } else {
              // return (
                // <h5>
                  /* <span class="badge badge-danger">Não</span> */
                /* </h5> */
              // );
            // }
          // },
          has_monitor: function (h, row, index) {
            if (row.has_monitor === 0) {
              return (
                <h5>
                  <span class="badge badge-danger">Não</span>
                </h5>
              );
            } else {
              return (
                <h5>
                  <span class="badge badge-success">Sim</span>
                </h5>
              );
            }
          },
          api_status: function (h, row, index) {
            if (row.api_status === 401) {
              return (
                <h5>
                  <span class="badge badge-success">Protegida</span>
                </h5>
              );
            } else if (row.api_status === 200) {
              return (
                <h5>
                  <span class="badge badge-danger">Aberta</span>
                </h5>
              );
            } else if (row.api_status === 500) {
              return (
                <h5>
                  <span class="badge badge-danger">Erro 500</span>
                </h5>
              );
            } else {
              return (
                <h5>
                  <span class="badge badge-warning">Não Verificado</span>
                </h5>
              );
            }
          },
          environment: function (h, row, index) {
            if (row.environment === 1) {
              return <span>Produção</span>;
            }
            if (row.environment === 0) {
              return <span>Homologação</span>;
            }
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ["customers"];

          return this.$http.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "innover_applications/",
    };
  },
  methods: {
    query: function (query) {
      let columns = {
        id: "id",
        name: "name",
        link: "link",
        backup: "backup",
        environment: "environment",
        //protected_api: "protected_api",
        has_monitor: "has_monitor",
        customer_id: "customers[customer_name]",
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
  mounted() {
    let self = this;
    this.$http
      .get(this.$store.getters.api + "innover_applications?paginated=false")
      .then((response) => {
        self.innover_applications = response.data.data;
      });
  },
  components: {
    BaseCrud,
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.inative {
  text-decoration: line-through;
}
</style>
