<template>
  <div>
    <base-crud
      crudName="Processos"
      newText="Novo Processo"
      :table="processTable"
      :columns="columns"
      :options="options"
      :endPoint="endPoint"
      :enableAdd="true"
      :enableEdit="true"
      :enableDelete="true"
      :enableView="true"
      :dataForm="dataForm"
      @filtered="filtered"
      :extra="extra"
      @solicitation="solicitation"
      :dataChild="dataChild"
    >
    </base-crud>
  </div>
</template>

<script>
import BaseCrud from "../Base/BaseCrud";
import { debug } from "util";

export default {
  data: function () {
    let self = this;
    return {
      extra: [
        {
          id: 1,
          label: '',
          class: 'btn btn-crud edit fa fa-play',
          title: 'Solicitações',
          name: 'solicitation',
        }
      ],
      processTable: "processTable",
      userList: [],
      pages: [],
      url: "",
      columns: ["id", "title", "created_at", "actions"],
      debounce: 800,
      options: {
        filterByColumn: false,
        filterable: ["id", "title"],
        sortable: ["id", "title"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          title: "Nome",
          description: "Descrição",
          created_at: "Data de Criação",
          actions: "Ações",
        },
        templates: {
          initial_date: function (h, row, index) {
            if (row.initial_date == "") {
              return (
                <h6>
                  <span>n/a</span>
                </h6>
              );
            } else {
              return row.initial_date;
            }
          }
        },
        requestFunction: function(data) {
          let requestData = {};

          let query = this.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ['tasks'];

          return this.$http.get(this.url + "?" + query, requestData).catch(
            function(e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        }
      },
      endPoint: "process/",
      dataChild: {
        name: "taskprocess",
        with: 'tasks',
        label: "Tarefas",
        type: "child",
        length: "12",
        data:[],
        fields: [
          {
            name: 'title',
            label: 'Título',
            type: 'text'
          },
          {
            name: 'description',
            label: 'Descrição',
            type: 'text'
          },
          {
            name: 'deadline',
            label: 'Prazo (Horas)',
            type: 'text'
          },
          {
            name: 'responsible_type',
            label: 'Tipo Responsável',
            type: 'select',
            list:[
              {
                id: 1,
                name: 'Grupo'
              },
              {
                id: 2,
                name: 'Usuário'
              }
            ]
          },
          {
            name: 'responsible_id',
            label: 'Responsável',
            type: 'select',
            list: this.users
          },
          {
            name: 'approval',
            label: 'Tem Aprovação?',
            type: 'checkbox'
          },
          {
            name: 'user_id',
            label: 'Aprovador',
            type: 'select',
            list: this.users
          }
        ]
      },
      dataForm: {
        title: {
          add: "Novo Processo",
          edit: "Editar Processo",
          view: "Visualizar Processo",
          icon: "fa fa-user-circle",
        },
        button: {
          back: "/process",
        },
        url: {
          model: "process",
        },
        messages: {
          success: {
            add: "Processo cadastrado com sucesso!",
            edit: "Processo alterado com sucesso!",
          },
        },
        fields: [
          {
            name: "title",
            model: "title",
            label: "Título",
            type: "text",
            isRequired: {
              add: true,
              edit: true,
            },
            placeholder: "Digite o nome do Processo",
            class: "",
            length: "12|6|3",
            offset: null,
          },
          {
            name: "description",
            model: "description",
            label: "Descrição",
            type: "textarea",
            list: [],
            isRequired: {
              add: false,
              edit: false,
            },
            placeholder: "Descrição do Processo",
            class: "",
            length: "12|6|3",
            offset: null,
          }
        ],
      },
    };
  },
  methods: {
    filtered(date) {
      // console.log('aa')
      this.created_at = date;
    },
    query: function (query) {
      let columns = {
        title: "title",
        created_at: "created_at",
      };
      let filters = "&orderByDesc=id&";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    solicitation(data) {
      this.$router.push({ path: "/process/solicitation/" + data.row.id });
    }
  },
  mounted() {
    let self = this;

    let api = self.$store.getters.api + 'users?paginated=false'

    self.$http.get(api)
    .then(response => {

      self.userList = response.data.data

    })

  },
  computed:{
    users(){
      return this.userList;
    }
  },
  components: {
    BaseCrud,
  }
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
</style>
