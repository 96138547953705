<template>
  <div>
    <div class="panel animated fadeInRightBig">
      <div class="panel-heading">
        <div class="card-header">
          <div class="card-title">Status</div>
        </div>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>
                <span class="required">*</span> Notificar chamados com SLA entre
              </label>
              <div class="row">
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    v-model="ticketNotification.start"
                    placeholder="De"
                  />
                  <small>horas</small>
                </div>
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    v-model="ticketNotification.end"
                    placeholder="Até"
                  />
                  <small>horas</small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group">
              <label>
                <span class="required">*</span> Usuários para Notificar
              </label>
              <v-select
                :options="users"
                label="name"
                :reduce="(users) => users.id"
                multiple
                v-model="ticketNotification.users"
              >
                <div slot="no-options">Carregando...</div>
              </v-select>
              <small>Você pode selecionar mais de um usuário.</small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>
                <span class="required">*</span> Status para Notificar
              </label>
              <v-select
                :options="status"
                label="title"
                :reduce="(status) => status.id"
                v-model="ticketNotification.status"
              >
                <div slot="no-options">Carregando...</div>
              </v-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label> <span class="required"></span> Cliente </label>
              <v-select
                :options="customers"
                label="customer_name"
                :reduce="(customers) => customers.id"
                v-model="ticketNotification.customer_id"
              >
                <div slot="no-options">Carregando...</div>
              </v-select>
              <small
                >Não obrigatório, se estiver em branco considera todos os
                clientes.</small
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <hr />
            <div class="block text-right">
              <a class="btn btn-success" @click="save">
                Salvar
                <i class="fa fa-save"></i>
              </a>
              <router-link to="/ticket_notifications" class="btn btn-info">
                <i class="fa fa-arrow-left"></i> Voltar
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
export default {
  data() {
    return {
      ticketNotification: {},
      users: [],
      status: [],
      customers: [],
    };
  },
  components: { vSelect },
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "ticket_notifications/";

      const id = self.$route.params.id;
      if (id) {
        self.ticketNotification._method = "put";
        api += id;
      }

      self.$http
        .post(api, self.ticketNotification)
        .then((response) => {
          self.$message("Sucesso", `Registro salvo com sucesso`, "success");
          this.$router.push("/ticket_notifications");
        })
        .catch((error) => {
          self.errors = error.response.data;
          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
    },
    getEntity(id) {
      const self = this;
      const api =
        self.$store.state.api + "ticket_notifications/" + id + "/?with[]=users";
      self.$http
        .get(api)
        .then((response) => {
          self.ticketNotification = response.data.data[0];
          var usrs = [];
          self.ticketNotification.users.forEach((element) => {
            usrs.push(element.user_id);
          });

          self.ticketNotification.users = usrs;

          console.log(usrs);
        })
        .catch((error) => {
          self.message("Erro", error.response.data, "error");
        });
    },
    getUsers(id) {
      const self = this;
      const api = self.$store.state.api + "users/?paginated=false";
      self.$http
        .get(api)
        .then((response) => {
          self.users = response.data.data;
        })
        .catch((error) => {
          self.message("Erro", error.response.data, "error");
        });
    },
    getCustomers(id) {
      const self = this;
      const api = self.$store.state.api + "customers/?paginated=false";
      self.$http
        .get(api)
        .then((response) => {
          self.customers = response.data.data;
        })
        .catch((error) => {
          self.message("Erro", error.response.data, "error");
        });
    },
    getStatus(id) {
      const self = this;
      const api = self.$store.state.api + "tickets_status/?paginated=false";
      self.$http
        .get(api)
        .then((response) => {
          self.status = response.data.data;
        })
        .catch((error) => {
          self.message("Erro", error.response.data, "error");
        });
    },
  },
  mounted() {
    this.getUsers();
    this.getStatus();
    this.getCustomers();
    const id = this.$route.params.id;
    if (id) {
      this.getEntity(id);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.uploading-image {
  display: flex;
  border-radius: 20px;
  width: 200px;
  height: 200px;
}
</style>
