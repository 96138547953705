<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">Editar Registro</div>
    </div>

    <div class="card-body">
      <form>
        <div class="form-row">
          <div class="col">
            <label>Origem</label>
            <v-select
              label="name"
              v-model="contractmanagers.origin"
              :options="['Chamado', 'Projetos', 'Contrato']"
            ></v-select>
          </div>
          <div class="col">
            <label>Tipo</label>
            <v-select
              label="name"
              v-model="contractmanagers.operation"
              :options="['Creditar', 'Debitar']"
            ></v-select>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <label>Data Hora Inicio</label>
            <date-time
              v-model="contractmanagers.start_date"
              format="YYYY-MM-DD HH:mm:ss"
              formatted="DD/MM/YYYY HH:mm:ss"
              output-format="YYYY-MM-DD HH:mm:ss"
              :no-button-now="true"
            >
              <input
                type="text"
                class="form-control"
                :value="contractmanagers.start_date"
              />
            </date-time>
          </div>
          <div class="col">
            <label>Data Hora Final</label>
            <date-time
              v-model="contractmanagers.end_date"
              format="YYYY-MM-DD HH:mm:ss"
              formatted="DD/MM/YYYY HH:mm:ss"
              output-format="YYYY-MM-DD HH:mm:ss"
              :no-button-now="true"
            >
              <input
                type="text"
                class="form-control"
                :value="contractmanagers.end_date"
              />
            </date-time>
          </div>
        </div>
        <br />
        <div class="form-row">
          <label>Total de horas</label>
          <input
            type="text"
            v-mask="'###:##:##'"
            class="form-control"
            id="total-hours"
            v-model="contractmanagers.total_hours"
            placeholder="000:00:00 ou Vazio para automatico"
          />
          <span style="color: red; font-size: 10px"
            >Calculo de horas considerando jornada de trabalho esta em
            desenvolvimento.</span
          >
        </div>
        <div class="form-row">
          <label>Descrição</label>
          <textarea
            class="form-control"
            v-model="contractmanagers.description"
            rows="3"
          ></textarea>
        </div>
        <br />
        <div class="form-row">
          <label>N° de Controle</label>
          <input
            class="form-control"
            v-model="contractmanagers.entity_id"
            type="text"
            placeholder="N° de Chamado, Cod. do Projeto, n° do Contrato, etc."
          />
        </div>
      </form>
    </div>

    <div class="card-body">
      <hr />
      <div class="row ">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <template>
              <button @click="$router.go(-1)" class="btn btn-info">
                <i class="fa fa-arrow-left"></i> Voltar
              </button>
            </template>
            <a class="btn btn-success" @click="save">
              Salvar
              <i class="fa fa-save"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      contractmanagers: [],
    };
  },
  computed: {
    url() {
      return this.$store.getters.api + this.$route.params.id;
    },
  },
  mounted() {
    let id = this.$route.params.id;
    if (id) {
      this.getContractManager(id);
    }
  },
  methods: {
    formatDate: function (inputDate) {
      let splitString = inputDate.split(" ");
      let splitDate = splitString[0].split("/");
      let newDate =
        splitDate[2] +
        "-" +
        splitDate[1] +
        "-" +
        splitDate[0] +
        " " +
        splitString[1];

      return newDate;
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "contractmanagers/manage-edit";

      let form = new FormData();

      form.append("contract_id", self.$route.params.id);
      form.append("operation", self.contractmanagers.operation);
      form.append("origin", self.contractmanagers.origin);
      form.append(
        "start_date",
        this.formatDate(self.contractmanagers.start_date)
      );
      form.append("end_date", this.formatDate(self.contractmanagers.end_date));
      form.append("total_hours", self.contractmanagers.total_hours);
      form.append("description", self.contractmanagers.description);
      form.append("entity_id", self.contractmanagers.entity_id);

      if (self.contractmanagers.id) {
        api += "/" + self.contractmanagers.id;
        form.append("_method", "PUT");
      }
      self.$http
        .post(api, form)
        .then((response) => {
          self.$message("Sucesso", `Registro Alterado com Sucesso`, "success");
          $("#ModalCenter").hide();
          // this.$router.push({ path: "/contracts/manage/" + data.row.id });
          location.reload();
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });
            html += "</ul>";
            self.errors = html;
          }
          self.$message(null, self.errors, "error");
        });
    },
    getContractManager: function (id) {
      let self = this;
      let api = self.$store.state.api + "contractmanagers/" + id;

      self.$http
        .get(api)
        .then((response) => {
          self.contractmanagers = response.data.data[0];
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
  },
};
</script>

<style>
.vertical-line {
  border-right: 1px solid #999;
}
</style>