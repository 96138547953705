    <template>
  <div>
    <div class="panel animated fadeInRightBig">
      <div class="panel-heading">
        <div class="card-header">
          <div class="card-title">Novo Usuário</div>
        </div>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10">
            <form action>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="userName">
                      <span class="required">*</span> Nome
                    </label>
                    <input type="text" class="form-control" v-model="usercustomers.name"
                    disabled placeholder />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="userEmail">
                      <span class="required">*</span> E-mail
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="userEmail"
                      v-model="usercustomers.email"
                      disabled
                      placeholder
                      autocomplete="off"
                    />
                  </div>
                </div>
                 <div class="col-md-4">
                  <div class="form-group">
                    <label for="userEmail">Cliente:
                    </label>
                    <v-select
                      :options="customers"
                      label="customer_name"
                      id="customers"
                      :reduce="customers => customers.id"
                      v-model="usercustomers.customer_id"
                      disabled
                    >
                      <div slot="no-options">Carregando...</div>
                    </v-select>
                </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="tel">Telefone:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="tel"
                      v-model="usercustomers.tel"
                      disabled
                      placeholder='(00)0000-0000'
                      autocomplete="false"
                      v-mask="'(##)####-####'"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="cel">Celular:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="cel"
                      v-model="usercustomers.cel"
                      disabled
                      placeholder='(00)0000-0000'
                      autocomplete="false"
                      v-mask="'(##)####-####'"
                    />
                  </div>
                </div>
              </div>
              <hr />
              <br />
              <div class="block text-right">
                <a class="btn btn-success" @click="save">
                  Salvar
                  <i class="fa fa-save"></i>
                </a>
                <router-link to="/usercustomers" class="btn btn-info">
                  <i class="fa fa-arrow-left"></i> Voltar
                </router-link>
              </div>
            </form>
          </div>
          <div class="col-md-2">
            <div v-if="previewImage" class="user-img">
              <img :src="previewImage" class="uploading-image" width="169" height="198" />
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect
  },
  name: "imageUpload",
  data() {
    return {
      usercustomers: {
        name: "",
        email: "",
        password: "",
        photo: "",
        customer_id: "",
      },
      customers: [],
      previewImage: null,
      errors: undefined
    };
  },
  computed: {
    url() {
      return this.$store.getters.api + this.$route.params.id;
    }
  },
  mounted() {
    let self = this;
    let id = self.$route.params.id;

    if (id) {
      self.getUserCustomer(id);
    }
    this.getCustomers(id);
  },

  methods: {
    save: function() {
      let api = this.$store.state.api + "usercustomers";
      const self = this;

      let form = new FormData();

      let photo = document.getElementById("userPic");

      form.append("name", self.usercustomers.name);
      form.append("customer_id", self.usercustomers.customer_id);
      form.append("email", self.usercustomers.email);
      form.append("tel", self.usercustomers.tel);
      form.append("cel", self.usercustomers.cel);
      form.append("password", self.usercustomers.password);
      form.append("password_confirmation", self.usercustomers.password_confirmation);
      form.append("photo", photo.files[0] ? photo.files[0] : "");

      if (self.usercustomers.id) {
        api += "/" + self.usercustomers.id;
        form.append("_method", "PUT");
      }
      self.$http
        .post(api, form)
        .then(response => {
          self.usercustomers = response.data.data;
          self.tableData = self.users;

          self.$message("Sucesso", `Cadastrado com sucesso`, "success");

          self.usercustomers = [];
          this.$router.push("/usercustomers");
        })
        .catch(error => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function(key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.previewImage = e.target.result;
      };
    },
    getUserCustomer: function(id) {
      let self = this;
      let api = self.$store.state.api + "usercustomers/" + id;

      self.$http
        .get(api)
        .then(response => {
          self.usercustomers = response.data.data[0];
        })
        .catch(error => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
    getCustomers: function(id) {
      let self = this;
      let api = self.$store.state.api + "customers?paginated=false/";
      self.$http
        .get(api)
        .then(response => {
          self.customers = response.data.data;
        })
        .catch(error => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    }
  }
};
</script>
<style scoped>
.uploading-image {
  display: flex;
}
</style>
