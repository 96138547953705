import Crud from '../components/WikiClients/Crud'
import Add from '../components/WikiClients/Add'
import View from '../components/WikiClients/View'

let routes = {
    path: '/wikiClients',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/wikiClients/add/',
            name: 'Adicionar Regitros',
            component: Add,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/wikiClients/edit/:id',
            name: 'Editar Regitros',
            component: Add,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/wikiClients/view/:id',
            name: 'Visualizar Regitros',
            component: View,
            meta: {
                permission: 'is_admin'
            }
        },        
        {
            path: '/wikiClients/',
            name: 'Listar Registros',
            component: Crud,
            meta: {
                permission: 'is_admin'
            }
        },
    ]
};


export default routes