<template>
    <div>
        <base-crud
            crudName="Status de Chamados"
            newText="Novo Status"
            :table="table"
            :columns="columns"
            :options="options"
            :endPoint="endPoint"
            :enableAdd="true"
            :enableEdit="true"
            :enableDelete="true"
            :enableView="false"
            :dataForm="dataForm"
            :extra="extra"
            :toadd="toadd"
        ></base-crud>
    </div>
</template>

<script>
import BaseCrud from "../Base/BaseCrud";


export default {
    data: function() {
        return {
            assigneds: [],
            assignedIds: [],
            table: "ticketStatusTable",
            pages: [],
            url: "",
            columns: ["id", "title", "actions"],
            tableData: ["id", "title"],
            options: {
                pagination: { chunk: 10, dropdown: false, nav: "scroll" },
                perPage: 10,
                filterByColumn: true,
                filterable: ["id", "title"],
                debounce: 800,
                perPageValues: [10, 25, 50, 100],
                headings: {
                    id: "ID",
                    title: "Título",
                    actions: "Ações"
                },

                sortable: ["id", "title"],
                templates: {},
                requestFunction: function(data) {
                    let requestData = {};

                    let query = this.$parent.$parent.query(data.query);
                    requestData.params = data;
                    requestData.params.query = "";

                    return this.$http
                        .get(this.url + "?" + query, requestData)
                        .catch(
                            function(e) {
                                this.dispatch("error", e);
                            }.bind(this)
                        );
                }
            },
            extra: [],
            endPoint: "tickets_status/",
            toadd: "ticket_status/",
            dataForm: {}
        };
    },
    components: {
        BaseCrud
    },
    methods: {
        query: function(query) {
            let columns = {
                id: "id",
                title: "title"
            };
            let filters = "";
            $.each(query, function(index, value) {
                filters += columns[index] + "=" + value + "&";
            });
            return filters;
        }
    },
    mounted: function() {}
};
</script>

<style scoped>
.VuePagination {
    text-align: center;
}
</style>
