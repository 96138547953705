import { render, staticRenderFns } from "./TeamBoard.vue?vue&type=template&id=c09d9e34&scoped=true"
import script from "./TeamBoard.vue?vue&type=script&lang=js"
export * from "./TeamBoard.vue?vue&type=script&lang=js"
import style0 from "./TeamBoard.vue?vue&type=style&index=0&id=c09d9e34&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c09d9e34",
  null
  
)

export default component.exports