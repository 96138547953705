<template>
  <div
    class="modal fade"
    id="issueMultipleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document" :class="{ 'modal-xl': isAdd }">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Multiplos Cards</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent class="form-material">
            <div class="row">
              <div :class="{ 'col-md-12': isAdd }">
                <div class="row form-group">
                  <div class="col-md-4">
                    <label for>Projeto</label>
                    <v-select
                      :options="projects"
                      :filterable="false"
                      v-model="issue.project_id"
                      label="Projeto"
                      @search="searchProject"
                      @input="selectedProject"
                    >
                      <template slot="no-options"
                        >Digite para pesquisar Projetos</template
                      >
                      <template slot="option" slot-scope="option">
                        <div class="d-center">{{ option.name }}</div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">{{ option.name }}</div>
                      </template>
                    </v-select>
                  </div>
                  <div class="col-md-4">
                    <label for>Sprint</label>
                    <v-select
                      :options="sprints"
                      v-model="issue.sprint_id"
                      label="Sprint"
                    >
                      <template slot="no-options"
                        >Selecione um projeto para listar as sprints</template
                      >
                      <template slot="option" slot-scope="option">
                        <div class="d-center">{{ option.name }}</div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">{{ option.name }}</div>
                      </template>
                    </v-select>
                  </div>

                  <div class="col-md-4">
                    <label for>Responsável</label>
                    <v-select
                      :options="users"
                      :reduce="(users) => users.id"
                      label="name"
                      :filterable="false"
                      v-model="issue.user_id"
                      @search="searchUser"
                    >
                      <template slot="no-options"
                        >Digite para pesquisar Usuários</template
                      >
                      <template slot="option" slot-scope="option">
                        <div class="d-center">{{ option.name }}</div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">{{ option.name }}</div>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <button @click="addChild" class="btn btn-primary btn-sm">
                  <i class="fa fa-plus"></i>
                </button>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>*</th>
                      <th>Título</th>
                      <th>Pontos</th>
                      <th>Criticidade</th>
                      <th>Prazo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in rows" :key="row.id">
                      <td>
                        <button
                          class="btn btn-danger"
                          @click="deleteRow(index)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="row.summary"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          v-model="row.story_points"
                          class="form-control"
                          min="0"
                        />
                      </td>
                      <td>
                        <select
                          v-model="issue.criticality"
                          class="form-control"
                        >
                          <option value>Selecione...</option>
                          <option value="Low">Baixa</option>
                          <option value="Normal">Normal</option>
                          <option value="High">Alta</option>
                          <option value="Critical">Crítico</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="date"
                          class="form-control"
                          v-model="row.due_date"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary p-1 ml-3 float-right"
            @click="save"
            v-if="modalMode != 'view'"
          >
            <i class="fa fa-floppy-o"></i> Salvar
          </button>
          <button
            type="button"
            class="btn btn-warning p-1 ml-3 float-right"
            data-dismiss="modal"
            @click="clean"
            aria-label="Close"
          >
            <i class="fa fa-times"></i> Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import _ from "lodash";

export default {
  components: { vSelect },
  props: ["modalMode", "modalStatus", "selectedIssue"],
  filters: {
    brDate(text) {
      var data =
        text.substr(8, 2) +
        "/" +
        text.substr(5, 2) +
        "/" +
        text.substr(0, 4) +
        " " +
        text.substr(11, 10);
      return data;
    },
  },
  data() {
    return {
      rows: [],
      multipleCards: [],
      newcomment: "",
      issue: {
        summary: "",
        project_id: 0,
        sprint_id: 0,
        user_id: "",
      },
      projects: [],
      sprints: [],
      users: [],
      comments: [],
    };
  },
  watch: {
    modalStatus: function (newStatus) {
      this.clean();
      if (newStatus == true) {
        this.startModal();
      }
    },
  },
  computed: {
    isAdd() {
      return this.modalMode == "add";
    },
  },
  mounted() {
    let self = this;
    $("#issueMultipleModal").on("hidden.bs.modal", function (e) {
      self.clean();
    });
    window.addEventListener("keyup", function (id) {
      if (event.keyCode === 46) {
        self.deleteIssue(id);
      }
    });
  },
  methods: {
    deleteRow(index) {
      this.rows.splice(index, 1);
    },
    addChild() {
      this.rows.push({});
    },
    getComments(id) {
      let self = this;
      let api =
        this.$store.getters.api + "issues/" + id + "?with[]=comments.user";

      this.$http.get(api).then((response) => {
        this.comments = response.data.data[0].comments;
      });
    },
    saveComment() {
      let self = this;
      let api = this.$store.getters.api + "issue-comments";

      if (self.newcomment != "") {
        self.$http
          .post(api, {
            issue_id: self.issue.id,
            comment: self.newcomment,
          })
          .then((response) => {
            self.$message("Sucesso", "Comentário Adicionado", "success");
          })
          .then(() => {
            this.getComments(self.issue.id);

            self.newcomment = "";
          });
      }
    },
    selectedProject(project) {
      let self = this;
      let api = this.$store.getters.api + "sprints";

      self.$http.get(api + "?project_id=" + project.id).then((response) => {
        self.sprints = response.data.data;
      });
    },
    searchUser: _.debounce(function (search) {
      let self = this;
      let api = this.$store.getters.api + "users";
      if (search.length >= 3) {
        self.$http.get(api + "?name=" + search).then((response) => {
          self.users = response.data.data;
        });
      }
    }, 300),
    searchProject: _.debounce(function (search) {
      let self = this;
      let api = this.$store.getters.api + "projects";
      if (search.length >= 3) {
        self.$http.get(api + "?name=" + search).then((response) => {
          self.projects = response.data.data;
        });
      }
    }, 300),
    startModal() {
      if (this.modalMode == "add") {
        this.issue.summary = "";
        this.issue.description = "";
        this.issue.story_points = 0;
        this.issue.criticality = "";
        this.issue.id = 0;
        this.issue.status = "todo";

        this.issue.project_id = "";
        this.issue.sprint_id = "";
        this.issue.user_id
          ? this.issue.user_id
          : this.$store.getters.user.id || this.issue.user_id != null
          ? this.issue.user_id
          : "";
        this.issue.due_date = moment().format("YYYY-MM-DD");
        this.issue.comments = [];

        this.clean();
      }
      $("#issueMultipleModal").modal("toggle");
    },
    clean() {
      this.$emit("changeModal", false);
    },
    deleteIssue() {
      let self = this;
      let api = this.$store.getters.api + "issues/" + self.issue.id;
    },

    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      self.rows.forEach((row, index) => {
        fd.append(
          "multipleCards[" + index + "][project_id]",
          self.issue.project_id != null ? self.issue.project_id.id : null
        );
        fd.append(
          "multipleCards[" + index + "][project_name]",
          self.issue.project_id != null ? self.issue.project_id.name : ""
        );
        fd.append(
          "multipleCards[" + index + "][sprint_id]",
          self.issue.sprint_id != null ? self.issue.sprint_id.id : null
        );
        fd.append("multipleCards[" + index + "][original_estimate]", 0);
        fd.append("multipleCards[" + index + "][summary]", row.summary);
        fd.append("multipleCards[" + index + "][total_spent]", 0);
        fd.append("multipleCards[" + index + "][status]", "todo");
        fd.append(
          "multipleCards[" + index + "][story_points]",
          row.story_points
        );
        fd.append("multipleCards[" + index + "][criticality]", row.criticality);
        fd.append("multipleCards[" + index + "][due_date]", row.due_date);

        if (typeof self.issue.user_id == "object") {
          fd.append(
            "multipleCards[" + index + "][user_id]",
            self.issue.user_id.id
          );
        } else {
          fd.append(
            "multipleCards[" + index + "][user_id]",
            self.issue.user_id
          );
        }
      });
      return fd;
    },

    save() {
      let self = this;
      let api = this.$store.getters.api + "issues/multiple-cards/";

      let newIssue = {};

      const fd = self.makeFormData();

      if (this.modalMode == "add") {
        self.$http
          .post(api, fd)
          .then((response) => {
            self.$message("Sucesso", "Card cadastrado com sucesso!", "success");
          })
          .then(() => {
            self.$emit("refresh");
          })
          .catch((response) => {
            self.$errorMessage(response);
          });
      } else {
        newIssue.status = self.issue.status;
        newIssue.id = self.issue.id;

        self.$http
          .put(api + "/" + newIssue.id, newIssue)
          .then((response) => {
            self.$message("Sucesso", "Card atualizado com sucesso!", "success");
          })
          .then(() => {
            self.$emit("refresh");
          })
          .catch((response) => {
            self.$errorMessage(response);
          });
      }
    },
  },
};
</script>

<style scoped>
label {
  font-weight: bold;
}

.photo {
  height: 40px;
  width: 60px;
  object-fit: cover;
  border: 1px solid rgb(127, 227, 245);
  border-radius: 5px;
  border-color: rgb(127, 227, 245);
}
.hidden {
  display: none;
}

#message {
  height: 40px !important;
  min-height: 40px !important ;
}

/* Important part */
.modal-dialog {
  overflow-y: initial !important;
}
.modal-body {
  height: 350px;
  overflow-y: auto;
}

.ipp-alert {
  padding: 5px !important;
  margin-bottom: 0px !important;
}

.coment-date {
  margin-bottom: 10px;
}
</style>
