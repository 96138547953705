<template>
  <div>
    <div class="panel animated fadeInRightBig">
      <div class="panel-heading">
        <div class="card-header">
          <div class="card-title">Novo Usuário</div>
        </div>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10">
            <form action="">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="userName"><span class="required">*</span> Nome</label>
                    <input type="text" class="form-control" v-model="users.name" placeholder="" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="userEmail"><span class="required">*</span> E-mail</label>
                    <input type="text" class="form-control" id="userEmail" v-model="users.email" placeholder=""
                      autocomplete="off" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="userPic">Foto do Usuário</label> <br>
                    <input type="file" id="userPic" class="mt-2" accept="image/*" name="userPic" @change="uploadImage"
                      placeholder="" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="userPassword"><span class="required">*</span> Senha</label>
                    <input type="password" class="form-control" id="userPassword" v-model="users.password"
                      placeholder="" autocomplete="false" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="userPasswordConfirmation"><span class="required">*</span> Confirme a Senha</label>
                    <input type="password" class="form-control" id="userPasswordConfirmation"
                      v-model="users.password_confirmation" placeholder="" autocomplete="false" />
                  </div>
                </div>
                <div class="col-md-4 d-flex align-items-end mb-3">
                  <div class="row">
                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="users.switch" />
                      <label class="custom-control-label" for="customSwitch1">Ver todos os registros</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <h6 class="mb-3">Nível de acesso ao controle de Deploy</h6>
                  <div class="d-block my-3">
                    <div class="custom-control custom-checkbox">
                      <input id="is_dev" name="is_dev" type="checkbox" class="custom-control-input"
                        v-model="users.is_dev" checked required />
                      <label class="custom-control-label" for="is_dev">Developer</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input id="is_qa" name="paymentMethod" type="checkbox" class="custom-control-input"
                        v-model="users.is_QA" required />
                      <label class="custom-control-label" for="is_qa">QA - Quality Assurance</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input id="is_deploy" name="paymentMethod" type="checkbox" class="custom-control-input"
                        v-model="users.is_deployer" required />
                      <label class="custom-control-label" for="is_deploy">Deploy</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input id="is_admin" name="paymentMethod" type="checkbox" class="custom-control-input"
                        v-model="users.is_admin" required />
                      <label class="custom-control-label" for="is_admin">Administrador</label>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row ">
                <div class="col-md-12">
                  <div class="d-flex justify-content-between">
                    <router-link to="/usercustomers" class="btn btn-info">
                      <i class="fa fa-arrow-left"></i> Voltar
                    </router-link>

                    <a class="btn btn-success" @click="save">
                      Salvar
                      <i class="fa fa-save"></i>
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div v-if="previewImage" class="col-md-2">
            <div class="user-img">
              <img :src="previewImage" class="uploading-image" />
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "imageUpload",
  data() {
    return {
      users: {
        name: "",
        email: "",
        password: "",
        email: "",
        photo: "",
        is_dev: false,
        is_QA: false,
        is_deployer: false,
        is_admin: false,
        switch: false,
      },
      previewImage: null,
      errors: undefined,
    };
  },
  computed: {},
  methods: {
    comparePassword: function () {
      if (this.users.password != this.users.password_confirmation || null)
        return [];
    },
    save: function () {
      const api = this.$store.state.api + "users";
      const self = this;
      if (this.comparePassword()) {
        swal("Erro", "Senhas diferentes, Moisés!", "info");
      } else if (this.users.password.length < 6) {
        swal(
          swal("Erro", "Não Permitida senha com Menos de 6 Digitos", "info")
        );
      } else if (this.users.password_confirmation.length < 6) {
        swal(
          swal("Erro", "Não Permitida senha com Menos de 6 Digitos", "info")
        );
      } else {
        let photo = document.getElementById("userPic");

        let form = new FormData();

        form.append("name", self.users.name);
        form.append("email", self.users.email);
        form.append("password", self.users.password);
        form.append("password_confirmation", self.users.password_confirmation);
        form.append("photo", photo.files[0] ? photo.files[0] : "");
        form.append("is_dev", self.users.is_dev);
        form.append("is_deployer", self.users.is_deployer);
        form.append("is_QA", self.users.is_QA);
        form.append("is_admin", self.users.is_admin);
        form.append('switch', self.users.switch ? 1 : 0);


        self.$http
          .post(api, form)
          .then((response) => {
            self.users = response.data.data;
            self.tableData = self.users;

            self.$message(
              "Sucesso",
              `Usuário cadastrado com sucesso`,
              "success"
            );

            self.users = {
              name: "",
              email: "",
              password: "",
              photo: "",
            };
            this.$router.push("/users");
          })
          .catch((error) => {
            self.errors = error.response.data;

            if (typeof self.errors === "object") {
              let html = "<ul>";

              $.each(self.errors, function (key, value) {
                html += "<li>" + value[0] + "</li>";
              });

              html += "</ul>";

              self.errors = html;
            }

            self.$message(null, self.errors, "error");
          });
      }
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
    },
  },
  mounted: function () {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.required {
  color: red;
}

.uploading-image {
  display: flex;
  border-radius: 20px;
  width: 200px;
  height: 200px;
}
</style>
