<template>
    <div>
        <base-crud
                crudName="Tickets"
                newText="Novo Ticket"
                :table="projectTable"
                :columns="columns"
                :options="options"
                :endPoint='endPoint'
                :enableAdd="admin"
                :enableEdit="true"
                :enableDelete="true"
                :enableView="true"
                :dataForm="dataForm"
        >

        </base-crud>
    </div>
</template>

<script>
    import BaseCrud from '../Base/BaseCrud'
import { debug } from 'util';

    export default {
        data: function () {
            let self = this
            return {
                admin: true,
                projectTable: 'ticketTable',
                users: [],
                pages: [],
                url: '',
                columns: ['id', 'ticket', 'title', 'customer_name', 'actions'],
                tableData: ['id', 'ticket', 'title', 'customer_name', 'title'],
                options: {
                    filterByColumn: true,
                    debounce: 1000,
                    filterable: ['id', 'ticket', 'title', 'customer_name'],
                    sortable: ['id', 'ticket', 'title', 'customer_name'],
                    pagination: {chunk: 10, dropdown: false, nav: 'scroll' },
                    perPage: 10,
                    perPageValues: [10,25,50,100],
                    headings: {
                        'id': 'ID',
                        'title' : 'Título',
                        'ticket': 'Número do ticket',
                        'customer_name': 'Cliente',
                        'actions' : 'Ações'
                    },
                    templates: {

                    //     project_status_id: function (h, row, index) {
                    //         return row.status != undefined ? row.status.name : '';
                    //     }
                    },
                    requestFunction: function (data) {
                        let requestData = {};

                        let query = this.$parent.$parent.query(data.query);
                        requestData.params = data;
                        requestData.params.query = '';
                        requestData.params.with = [
                        ];

                        return this.$http
                            .get(this.url + '?' + query, requestData)
                            .catch(function (e) {
                                    this.dispatch("error", e);
                                }.bind(this)
                            );
                    }
                },
                endPoint: 'tickets/',

                dataForm: {
                  title: {
                    add: 'Novo Ticket',
                    edit: 'Editar Ticket',
                    view: 'Visualizar Ticket',
                    icon: 'fa fa-user-circle'
                  },
                  button: {
                    back: '/tickets'
                  },
                  url: {
                    model: 'tickets'
                  },
                  messages: {
                    success: {
                      add: 'Ticket cadastrado com sucesso!',
                      edit: 'Ticket alterado com sucesso!'
                    }
                  },
                  fields: [
                    {
                      name: 'ticket',
                      model: 'ticket',
                      label: 'Número do ticket',
                      type: 'text',
                      isRequired: {
                        add: true,
                        edit: false,
                      },
                      placeholder: 'Digite o Número do ticket',
                      class: '',
                      length: "12|6|3",
                      offset: null
                    },
                    {
                      name: 'title',
                      model: 'title',
                      label: 'Título',
                      type: 'text',
                      isRequired: {
                        add: true,
                        edit: false,
                      },
                      placeholder: 'Digite o Título',
                      class: '',
                      length: "12|6|3",
                      offset: null
                    },
                    {
                      name: 'user_name',
                      model: 'user_name',
                      label: 'Pessoa responsável no cliente',
                      type: 'text',
                      isRequired: {
                        add: true,
                        edit: false,
                      },
                      placeholder: 'Digite a Pessoa responsável no cliente',
                      class: '',
                      length: "12|6|3",
                      offset: null
                    },
                    {
                      name: 'customer_name',
                      model: 'customer_name',
                      label: 'Cliente',
                      type: 'text',
                      isRequired: {
                        add: true,
                        edit: false,
                      },
                      placeholder: 'Digite o Cliente',
                      class: '',
                      length: "12|6|3",
                      offset: null
                    },
                    {
                      name: 'status',
                      model: 'status',
                      label: 'Status',
                      type: 'select',
                      list: [
                        {
                            id: 'Sem atendente vinculado',
                            name: 'Sem atendente vinculado'
                        },
                        {
                            id: 'Aguardando interação do atendente',
                            name: 'Aguardando interação do atendente'
                        },
                        {
                            id: 'Respondido, aguardando resposta do cliente',
                            name: 'Respondido, aguardando resposta do cliente'
                        },
                        {
                            id: 'Finalizado',
                            name: 'Finalizado'
                        },
                        {
                            id: 'Respondido pelo cliente, aguardando resposta',
                            name: 'Respondido pelo cliente, aguardando resposta'
                        }
                      ],
                      isRequired: {
                        add: true,
                        edit: false,
                      },
                      placeholder: 'Digite o Status',
                      class: '',
                      length: "12|6|3",
                      offset: null
                    },
                    {
                      name: 'category',
                      model: 'category',
                      label: 'Categoria',
                      type: 'select',
                      list: [
                        {
                            id: 'Solicitação',
                            name: 'Solicitação'
                        },
                        {
                            id: 'Problema',
                            name: 'Problema'
                        },
                        {
                            id: 'Agenda',
                            name: 'Agenda'
                        },
                        {
                            id: 'Dúvidas',
                            name: 'Dúvidas'
                        },
                        {
                            id: 'NULL',
                            name: 'Outro'
                        }
                      ],
                      isRequired: {
                        add: true,
                        edit: false,
                      },
                      placeholder: 'Digite a Categoria',
                      class: '',
                      length: "12|6|3",
                      offset: null
                    },
                    {
                      name: 'last_iteration',
                      model: 'last_iteration',
                      label: 'Data da última interação',
                      type: 'datetime',
                      isRequired: {
                        add: true,
                        edit: false,
                      },
                      placeholder: 'Digite a data de Última interação',
                      class: '',
                      length: "12|6|3",
                      offset: null
                    },
                    {
                      name: 'closed_at',
                      model: 'closed_at',
                      label: 'Data de fechamento',
                      type: 'datetime',
                      isRequired: {
                        add: false,
                        edit: false,
                      },
                      placeholder: 'Digite a data de Fechamento',
                      class: '',
                      length: "12|6|3",
                      offset: null
                    },
                    {
                      name: 'priority',
                      model: 'priority',
                      label: 'Prioridade',
                      type: 'text',
                      isRequired: {
                        add: true,
                        edit: false,
                      },
                      placeholder: 'Digite a Prioridade',
                      class: '',
                      length: "12|6|3",
                      offset: null
                    },
                    {
                      name: 'worked_time',
                      model: 'worked_time',
                      label: 'Tempo trabalhado',
                      type: 'text',
                      isRequired: {
                        add: true,
                        edit: false,
                      },
                      placeholder: 'Digite o Tempo trabalhado',
                      class: '',
                      length: "12|6|3",
                      offset: null
                    }
                  ]
                }
            }
        },
        methods: {
            query: function (query) {
                let columns = {
                    id: 'id',
                    description: 'description',
                    title: 'title',
                    ticket: 'ticket',
                    customer_name: 'customer_name'
                };
                let filters = '&orderByDesc=id&';
                $.each(query, function (index, value) {
                    filters += columns[index] + '=' + value + '&';
                });
                return filters;
            }
        },
        mounted () {

            let self = this;

            this.user = this.$store.getters.user;

            this.$http.get( this.$store.getters.api + 'projectstatuses?paginated=false')
            .then( response => {
                self.dataForm.fields[1].list = response.data.data
            })
        },
        components: {
            BaseCrud
        },
        computed: {
            statusList () {
                // return this.$http.get('/status')
                return [
                    {id: 'e', label: '123'}
                ]
            },
            is_admin () {
             if ($store.state.user.is_admin) {
              this.admin == true;
            }else{
              this.admin == false;
            }
            }
        }
    }
</script>

<style scoped>
    .VuePagination {
        text-align: center;
    }
</style>
