import Crud from '../components/TicketNotifications/Crud'
import Entity from '../components/TicketNotifications/Entity'

let routes = {
    path: '/ticket_notifications',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/ticket_notifications/',
            name: 'Status',
            component: Crud
        },
        {
            path: '/ticket_notifications/add',
            name: 'Status',
            component: Entity
        },
        {
            path: '/ticket_notifications/edit/:id?',
            name: 'Status',
            component: Entity
        },
    ]
}

export default routes