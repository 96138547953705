<template>
  <div>
    <!-- cards content -->
    <div class="card-group">
      <div class="card">
        <div class="card-body">
          <h5 class="header">Fechamento Mensal do Contrato: {{contract.name}} - ID: {{contract.id}}</h5>
          <div class="row">
            <div class="col-md-4">
              <label for>Data Inicial</label>
              <input type="date" class="form-control" v-model="initial_date" />
            </div>
            <div class="col-md-4">
              <label for>Data Inicial</label>
              <input type="date" class="form-control" v-model="final_date" />
            </div>
            <div class="col-md-12">
              <br>
              <button @click="getTickets" class="btn btn-primary">Buscar Chamados</button>

              <button @click="assignContract" class="btn btn-danger">Lançar no Contrato</button>
              <button @click="$router.go(-1)" class="btn btn-warning">Voltar</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loaded">
      <div class="row">
        <div class="col-md-12 text-center">
          <i class="fa fa-spinner fa-spin fa-5x"></i>
        </div>
      </div>
    </div>
    <div class="card" v-if="loaded">
      <div class="card-body">
        <h5 class="header">Chamados do Período</h5>
        <div class="row">
          <div class="col-md-12">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" @click="checkAll">
                  </th>
                  <th>#</th>
                  <th>Cliente</th>
                  <th>Usuário</th>
                  <th>Título</th>
                  <th>Responsável</th>
                  <th>Horas</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ticket in tickets" :key="ticket.id">
                  <td><input type="checkbox" name="" id="" v-model="ticket.selected"></td>
                  <td>{{ticket.code}}</td>
                  <td>{{ticket.sent_by.name}}</td>
                  <td>{{ticket.sent_by.customers.customer_name}}</td>
                  <td>{{ticket.subject}}</td>
                  <td>{{ticket.responsible ? ticket.responsible.name : ''}}</td>
                  <td>{{calcTime(ticket.service_hours)}}</td>
                </tr>
              </tbody>
              <tfoot v-if="tickets.length > 0">
                <tr>
                  <td colspan="6">Total</td>
                  <td>{{calcTotal()}}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    let self = this;
    return {
      contract: {},
      initial_date: "",
      final_date: "",
      tickets: {},
      loaded: true,
    };
  },
  mounted() {
    this.getContract();
  },
  methods: {
    assignContract(){

      var data = [];
      this.tickets.forEach(ticket => {
        if(ticket.selected){

          ticket.service_hours.forEach( hour => {
            
            var totalHours = (hour.total_minutes / 60);

            var minutes = '00';
            if(totalHours % 2 != 0){

              minutes = totalHours - Math.floor(totalHours)

              minutes = Number((minutes * 60).toFixed(2));

              /*
              if(totalHours < 1){
                totalHours = '00';
              } 
              */
             totalHours = Math.trunc(totalHours);
              
            }

            var finalHour = Number(totalHours) + ':' + minutes + ':00'; 

            var item = {
              'contract_id': this.$route.params.id,
              'operation': 'Debitar',
              'origin': 'Chamado',
              'start_date': hour.start,
              'end_date': hour.end,
              'total_hours': finalHour,
              'description': hour.comment,
              'entity_id': hour.id
            }

            console.log(item)
            
            data.push(item)
          })

        }
      })

      let api = this.$store.getters.api + 'contractmanager/month-launch'
      let self = this

      this.$http.post(api,{
        data: data
      })
      .then( response => {
        self.$message('Sucesso', 'Lançamento efetuado com sucesso', "success");
      })
      .then( () => {
        self.getTickets()
      })
      .catch( response => {
        self.$errorMessage(response);
      })

    },
    checkAll(){
      this.tickets.forEach(ticket => {
        ticket.selected = ticket.selected ? false : true
      })
      this.$forceUpdate();
    },
    calcTime(hours){

      let total = 0;
      
      hours.forEach(hour => {
        total += hour.total_minutes
      });      

      return (total / 60).toFixed(2)
    },
    calcTotal(){
      let total = 0;
      let self = this
      
      self.tickets.forEach(ticket => {

        ticket.service_hours.forEach( hour => {
          total += hour.total_minutes
        });      
      })
      
      /*
      let decimal = (total / 60 ) / 100;
      let hour = Number(total);

      decimal = decimal * 60;

      return hour + ':' + decimal
      */

      return (total/60).toFixed(2);
    },
    getContract() {
      const self = this;
      let id = self.$route.params.id;

      const api = self.$store.state.api + "contracts/" + id;

      self.$http
        .get(api)
        .then((response) => {
          self.contract = response.data.data[0];
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
    getTickets() {
      const self = this;
      let id = self.$route.params.id;

      const api = self.$store.state.api + "end-month-tickets";

      self.loaded = false;

      self.$http
        .get(api, {
          params: {
            contract_id: id,
            initial_date: self.initial_date,
            final_date: self.final_date,
          },
        })
        .then((response) => {
          self.tickets = response.data;
          self.loaded = true
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
          self.loaded = true
        });
    },
  },
};
</script>

<style scoped>
</style>