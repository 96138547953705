<template>
    <div>
        <div class="panel animated fadeInRightBig">
            <div class="panel-heading">
                <div class="card-header">
                    <div class="card-title">Status</div>
                </div>
            </div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>
                                <span class="required">*</span> Nome
                            </label>
                            <input type="text" class="form-control" v-model="ticketStatus.title" />
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="form-group">
                            <label>
                                <span class="required">*</span> Descrição
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="ticketStatus.description"
                            />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <input
                                type="checkbox"
                                class="big-chbx"
                                v-model="ticketStatus.notify_client"
                            />
                            <label>
                                <span class="required">*</span>Notifica cliente
                            </label>
                        </div>
                        <div class="form-group">
                            <input
                                type="checkbox"
                                class="big-chbx"
                                v-model="ticketStatus.discounts"
                            />
                            <label>
                                <span class="required">*</span>Desconta
                            </label>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="form-group">
                            <label>
                                <span class="required">*</span>Porcentagem de conclusão
                            </label>
                            <div class="row">
                                <div class="col-md-2">
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="ticketStatus.percentage"
                                    />
                                </div>
                                <div class="col-md-10">
                                    <input
                                        type="range"
                                        class="form-control"
                                        min="0"
                                        max="100"
                                        v-model="ticketStatus.percentage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block text-right">
                    <a class="btn btn-success" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                    </a>
                    <router-link to="/tickets_status" class="btn btn-info">
                        <i class="fa fa-arrow-left"></i> Voltar
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            ticketStatus: {
                title: "",
                description: "",
                notify_client: "",
                discounts: "",
                percentage: ""
            }
        };
    },
    methods: {
        makeFormData() {
            let fd = new FormData();

            fd.append("title", this.ticketStatus.title);
            fd.append("description", this.ticketStatus.description);
            fd.append(
                "notify_client",
                this.ticketStatus.notify_client ? 1 : 0
            );
            fd.append("discounts", this.ticketStatus.discounts ? 1 : 0);
            fd.append("percentage", this.ticketStatus.percentage);

            const id = this.$route.params.id;
            if (id) {
                fd.append("id", id);
                fd.append("_method", "PUT");
            }
            return fd;
        },
        save: function() {
            const self = this;
            let api = self.$store.state.api + "tickets_status/";

            const id = self.$route.params.id
            if(id){
                api += id
            }

            const fd = self.makeFormData();

            self.$http
                .post(api, fd)
                .then(response => {
                    self.users = response.data.data;
                    self.tableData = self.users;

                    self.$message(
                        "Sucesso",
                        `Usuário salvo com sucesso`,
                        "success"
                    );

                    this.$router.push("/tickets_status");
                })
                .catch(error => {
                    self.$message("Erro", error.response.data, "error");
                });
        },
        getTicketStatus(id){
            const self = this;
            const api = self.$store.state.api + 'tickets_status/' + id;
            self.$http.get(api)
                .then((response) => {
                    self.ticketStatus = response.data.data[0]
                }).catch((error) => {
                    self.message('Erro', error.response.data, 'error');
                });
        }
    },
    mounted(){
        const id = this.$route.params.id
        if(id){
            this.getTicketStatus(id)
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.uploading-image {
    display: flex;
    border-radius: 20px;
    width: 200px;
    height: 200px;
}
</style>
