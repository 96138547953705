<template>
  <div>
    <base-crud
      crudName="Nome do Processo"
      newText="Novo Registro"
      :table="table"
      :columns="columns"
      :options="options"
      :endPoint="endPoint"
      :enableAdd="true"
      :enableEdit="true"
      :enableDelete="true"
      :enableView="false"
      :dataForm="dataForm"
    >
    </base-crud>
  </div>
</template>

<script>
import BaseCrud from "../Base/BaseCrud";

export default {
  data: function () {
    return {
      table: "process_namesTable",
      // pages: [],
      // url: '',
      columns: ["id", "process_name", "actions"],
      tableData: ["id", "process_name", "actions"],
      options: {
        sortable: ["id", "process_name", "actions"],
        filterable: ["id", "process_name"],
        filterByColumn: true,
        debounce: 1000,
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          process_name: "Nome do Processo",
          actions: "Ações",
        },
        templates: {},
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = [];

          return this.$http.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "process_names/",

      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    query: function (query) {
      let columns = {
        id: "id",
        process_name: "process_name",
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
</style>
