import { render, staticRenderFns } from "./CrudDevs.vue?vue&type=template&id=572f140e&scoped=true"
import script from "./CrudDevs.vue?vue&type=script&lang=js"
export * from "./CrudDevs.vue?vue&type=script&lang=js"
import style0 from "./CrudDevs.vue?vue&type=style&index=0&id=572f140e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "572f140e",
  null
  
)

export default component.exports