<template>
  <div>
    <div class="col-md-12">
      <issue-modal
        :modalMode="issueModal"
        :modalStatus="issueModalStatus"
        :selectedIssue="selectedIssue"
        @changeModal="changeIssueModal"
        @refresh="refresh"
      ></issue-modal>
      <br />
      <h3>Processo: {{ process.title }}</h3>
      <hr />
    </div>

    <div class="row-kanban row flex-row flex-nowrap mt-4 pb-4">
      <div v-for="column in tasks" :key="column.title" class="col-3">
        <h4 class="text-gray-700 font-semibold font-sans tracking-wide text-sm" :id="'column-' + column.id">
          {{ column.title }}
        </h4>

        <!-- Draggable -->
        <draggable
          :list="column.issues"
          :animation="200"
          ghost-class="ghost-card"
          group="tasks"
          class="dragg-list"
          @end="changed"
          @change="changed"
        >
          <!-- Task -->

          <task
            v-for="(task, index) in column.issues"
            :key="task.id"
            :task="task"
            :column="column"
            :index="index"
            class="mt-3 cursor-move"
            @cardEdit="cardEdit(task)"
            @deleteIssue="deleteIssue"
          ></task>
          <!-- </transition-group> -->
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import IssueModal from "../Board/IssueModal";
import draggable from "vuedraggable";
import Task from "../Board/TaskCard";
import vSelect from "vue-select";
import _ from "lodash";

export default {
  components: {
    Task,
    draggable,
    IssueModal,
    vSelect,
  },
  data() {
    return {
      process: {},
      tasks: [],
      lastElementAdded: null,
      issueModal: "add",
      issueModalStatus: false,
      selectedIssue: {},
    };
  },
  mounted() {
    let self = this;

    this.loadIssues();
  },
  methods: {
    set(value) {
      if (this.$store.commit("updateList", value)) {
        self.changed(event);
      }
    },
    deleteIssue(id, index, column) {
      let self = this;
      let api = this.$store.getters.api + "issues/";

      swal({
        title: "Voce está certo disso?",
        text: "a exclusão do card não pode ser desfeita!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger"
          },
          confirm: {
            text: "Sim",
            value: "confirm"
          }
        }
      }).then(value => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O card não foi excluido!", "info");
            break;

          case "confirm":
            self.$http
              .delete(api + id)
              .then(response => {
                column.tasks.splice(index, 1);
              })
              .then(() => {
                self.$message(
                  "Sucesso",
                  "Card(s) Deletado(s) com sucesso",
                  "success"
                );
                this.refresh();
              });
            break;
        }
      });
    },
    cardEdit(card) {
      console.log("dblclick");
      card.cancel = true;
      this.selectedIssue = card;
      this.issueModal = "edit";
      this.issueModalStatus = true;
    },
    changeIssueModal(status) {
      this.issueModalStatus = status;
      this.refresh();
    },
    changed(event) {
      const self = this;
      if (event.added) {
        self.lastElementAdded = event.added.element;
      }

      if (event instanceof CustomEvent && self.lastElementAdded) {
        let from = $(event.from).siblings("h4").text();
        let fromId = $(event.from).siblings("h4")[0].id;
        let to = $(event.to).siblings("h4").text();
        let toId = $(event.to).siblings("h4")[0].id;


        self.lastElementAdded.process_status = to;
        self.lastElementAdded.taskprocess_id = toId.split('-')[1];

        const api = self.$store.state.api + "move-issue-solicitation";
        self.$http
          .post(api, {
            changed: [self.lastElementAdded],
            action: "moveIssue",
            key: "id",
          })
          .then((response) => {
            self.lastElementAdded = null;
          })
          .catch((error) => {
            self.$message("Erro", error.response.data, "error");
          });
      }
    },

    loadIssues() {
      let self = this;
      let api =
        this.$store.getters.api +
        "process?id=" +
        self.$route.params.id +
        "&with[]=tasks.issues.process&with[]=tasks.issues.taskProcess";
      let issues = this.$http.get(api).then((response) => {
        this.process = response.data.data[0];
        this.tasks = response.data.data[0].tasks;
      });
    },

    refresh() {
      this.loadIssues();
    },
  },
};
</script>

<style scoped>
#row-kanban {
  overflow-x: scroll;
}

.col-4 {
  display: inline-block;
  min-height: 800px;
}

.dragg-list {
  height: 100%;
  min-height: 300px;
  /* padding-bottom:100%; */
  border: 1px solid grey;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f7fafc;
}

.column-width {
  min-width: 320px;
  width: 320px;
}
/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}
.text-gray-700 {
  padding-left: 15px;
}
button:focus,
input:focus,
select:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
}
.column-width {
  /* min-width: 320px; */
  width: 380px;
}
.bg-gray-100 {
  --bg-opacity: 1;
  background-color: rgb(251, 253, 255);
}
.border-white {
  border-color: rgba(53, 53, 53, 0.16) !important;
}
.bg-blue {
  border-radius: 3px;
  background-color: #fd0019c5;
}
.bg-ylw {
  border-radius: 3px;
  background-color: #0099ffb2;
}
.bg-green {
  border-radius: 3px;
  background-color: #18b43abc;
}
.text-title {
  padding-left: 4px;
}
.keyboard {
  text-align: center;
  font-size: 14px;
  font-family: sans-serif;
}
.keyboard__row {
  display: inline-block;
  height: 1em;
  margin: 0.2em;
}
.keyboard__row--h1 {
  height: 1.7em;
  line-height: 1.4em;
}
.keyboard__row--h3 {
  height: 1.6em;
}
.keyboard__row > * {
  position: relative;
  background: rgb(138, 135, 135);
  text-align: center;
  color: #eee;
  float: left;
  border-radius: 0.3em;
  margin: 0.2em;
  padding: 0.2em;
  width: 1.8em;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  border: 1px solid #444;
  box-shadow: 0 0.2em 0 0.05em rgb(75, 75, 75);
  border-bottom-color: #555;
}
.keyboard__row > div[data-pressed],
.keyboard__row > div:active {
  background: #2a2a2a;
  color: #aaa;
  position: relative;
  top: 0.2em;
  box-shadow: 0 0 0 0.05em black;
}
.key--letter {
  line-height: 1.2em;
}
.key--word > * {
  font-size: 0.8em;
}
</style>
