<template>

    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h1>Gestão de Chamados</h1>
                    </div>
                    <loading
                        :active.sync="isLoading"
                        :can-cancel="false"
                        :is-full-page="fullPage"
                    ></loading>
                    <div class="panel-body">
                        Filtros: 
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label for=""><strong>De (Abertura)</strong></label>
                                <input type="date" class="form-control" v-model="from">
                            </div>
                            <div class="col-md-3">
                                <label for=""><strong>Até (Abertura)</strong></label>
                                <input type="date" class="form-control" v-model="to">
                            </div>

                            <div class="col-md-3">
                                <label for=""><strong>Cliente</strong></label>
                                <v-select @search="searchCustomers" :options="customers" :reduce="customer => customer.id" label="customer_name" v-model="customer" >
                                    <div slot="no-options">Carregando...</div>
                                </v-select>    
                            </div>

                            <div class="col-md-3">
                                <label for=""><strong>Contrato</strong></label>
                                <v-select @search="searchContracts" :options="contracts" :reduce="contract => contract.id" label="name" v-model="contract" >
                                    <div slot="no-options">Carregando...</div>
                                </v-select>    
                            </div>

                           
                        </div>
                        <div class="row form-group">
                            <div class="col-md-4">
                                <label for=""><strong>De (Horas)</strong></label>
                                <input type="date" class="form-control" v-model="fromHour">
                            </div>
                            <div class="col-md-4">
                                <label for=""><strong>Até (Horas)</strong></label>
                                <input type="date" class="form-control" v-model="toHour">
                            </div>
                            <div class="col-md-4">
                                <label for=""><strong>Responsável</strong></label>
                                <v-select @search="searchUsers" :options="users" :reduce="user => user.id" label="name" v-model="user" >
                                    <div slot="no-options">Carregando...</div>
                                </v-select>    
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12 ">
                            
                                <div class="form-check form-check-inline">
                                    <input id="without_contract" type="checkbox" class="form-check-input" v-model="without_contract">
                                    <label for="without_contract" class="form-check-label">Sem Contrato</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input id="without_hours" class="form-check-input" type="checkbox" v-model="without_hours">
                                    <label for="without_hours" class="form-check-label">Sem horas</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input id="without_responsible" type="checkbox" class="form-check-input" v-model="without_responsible">
                                    <label for="without_responsible" class="form-check-label">Sem Responsável</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input id="without_responsible" type="checkbox" class="form-check-input" v-model="without_area">
                                    <label for="without_responsible" class="form-check-label">Sem Área</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input id="without_responsible" type="checkbox" class="form-check-input" v-model="without_process_name">
                                    <label for="without_responsible" class="form-check-label">Sem Nome de Processo</label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <button class="btn btn-primary" @click="search">Buscar</button>
                        <hr>
                        <div class="table-responsive">
                            <table class="table table-bordered table-condensed" >
                                <thead>
                                    <tr>
                                        <th width="5px">N° Ticket</th>
                                        <th width="4px">Título</th>
                                        <th width="5px">Responsável</th>
                                        <th width="5px">Solicitante</th>
                                        <th width="5px">Cliente</th>
                                        <th min-width="8">Classificação</th>
                                        <th min-width="8">Contrato</th>
                                        <th min-width="8">Área</th>
                                        <th min-width="10px">Processo</th>
                                        <th min-width="10px">SLA</th>
                                        <th min-width="3px" max-width="5px">Status</th>
                                        <th min-width="3px">*</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(ticket, index) in tickets" :key="ticket.id">
                                        <td>
                                            {{ticket.code}}
                                        </td>
                                        <td data-toogle="tooltip" :title=ticket.subject>
                                            <!-- <input type="text" class="form-control" v-model="ticket.subject"> -->
                                            {{ ticket.subject.substr(0,10)}} ...
                                        </td>
                                        <td>
                                            {{ticket.responsible ? ticket.responsible.name : 'Sem Responsavel'}}
                                        </td>
                                        <td>
                                            <!-- <input type="text" class="form-control" v-model="ticket.sent_by.name"> -->
                                            {{ticket.sent_by.name}}
                                        </td>
                                        <td>
                                            {{ticket.sent && ticket.sent.customers ? ticket.sent.customers.customer_name : 'Sem Cliente'}}
                                        </td>
                                        <td>
                                            <input type="text" v-model="ticket.classification" class="form-control" @keypress="searchClassification(ticket.classification, index)">
                                            <select class="form-control" 
                                                v-if="searching == index" 
                                                @change="selectClassification(this, ticket)"
                                                v-model="ticket.new_classification"
                                                >
                                                <option value="">Selecione</option>
                                                <option :value="classification" v-for="classification in classifications" :key="classification">
                                                    {{classification}}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                class="form-control" v-model="ticket.contract_id"
                                                @change="save(ticket)">
                                                <option value="">Sem Contrato</option>
                                                <option
                                                    v-for="contract in contractList" :key="contract.id" 
                                                    :value="contract.id">{{contract.name}}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                class="form-control" v-model="ticket.process_area_id"
                                                @change="save(ticket)">
                                                <option value="">Sem Área</option>
                                                <option
                                                    v-for="area in areaList" :key="area.id" 
                                                    :value="area.id">{{area.process_areas}}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                class="form-control" v-model="ticket.process_name_id"
                                                @change="save(ticket)">
                                                <option value="">Sem Processo</option>
                                                <option
                                                    v-for="processName in processNameList" :key="processName.id" 
                                                    :value="processName.id">{{processName.process_name}}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            {{ticket.SLA}}
                                        </td>
                                        <td>
                                            {{ticket.status_entity.title}}
                                        </td>
                                        <td>
                                            <button class="btn btn-primary" @click="openTicket(ticket.id)"><i class="fa fa-arrow-up"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import vSelect from 'vue-select'
export default {
    components: {vSelect, Loading},
    data(){
        return {
            fullPage: true,
            isLoading: false,
            tickets: [],
            from: '',
            to: '' ,//(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()).toISOString().substring(0,10),
            fromHour: '',
            toHour: '' ,
            customers: [],
            customer: '',
            contract: '',
            user: '',
            classifications: [],
            searching: null,
            without_hours: false,
            without_contract: false,
            without_responsible: false,
            without_process_name: false,
            without_area: false,
            contracts: [],
            contractList: [],
            areaList: [],
            processNameList: [],
            users: []
        }
    },
    mounted () {

        this.from = new Date()
        this.from.setDate(1);
        this.from = this.from.toISOString().substring(0,10)

        this.to = new Date()
        this.to = this.to.toISOString().substring(0,10)

        this.getLists()
        this.searchCustomers('', true)
        this.searchContracts('', true)
        this.searchUsers('', true)
    },
    methods: {
        openTicket(id){
            open('/new_tickets/view/' + id)
        },
        save: function(ticket) {
            
            const self = this;
            const api = self.$store.state.api + "new_tickets/" + ticket.id;
            self.$http.put(api, {
                category_id: ticket.category_id,
                status: ticket.status,
                responsible_id: ticket.responsible_id,
                contract_id: ticket.contract_id,
                code: ticket.code,
                priority: ticket.priority,
                classification: ticket.classification,
                process_area_id: ticket.process_area_id,
                process_name_id: ticket.process_name_id
            })
            .then((response) => {
                self.$message("Sucesso", "Atualizado com sucesso", "success");
            })
            .catch((error) => {
                self.$message("Erro", error.response.data, "error");
            });
        },
        selectClassification(object, ticket){
            
            ticket.classification = ticket.new_classification
            this.searching = null

            this.save(ticket)
        },
        searchClassification(search, index){
            
            let api = this.$store.getters.api + 'tickets/classifications'
            if(search != ''){
                api = api + '?search=' + search 
            }
            let self = this

            self.searching = index

            self.$http.get(api)
            .then(response => {
                self.classifications = response.data

            })
        },
        searchCustomers(search, loading){
            
            let api = this.$store.getters.api + 'customers'
            if(search != ''){
                api = api + '?customer_name=' + search 
            }
            let self = this

            self.$http.get(api)
            .then(response => {
                self.customers = response.data.data 
                loading = false
            })

        },
        searchContracts(search, loading){

            let api = this.$store.getters.api + 'contracts'
            if(search != ''){
                api = api + '?name=' + search 
            }
            let self = this

            self.$http.get(api)
            .then(response => {
                self.contracts = response.data.data 
                loading = false
            })

        },
        searchUsers(search, loading){

            let api = this.$store.getters.api + 'users'
            if(search != ''){
                api = api + '?name=' + search 
            }
            let self = this

            self.$http.get(api)
            .then(response => {
                self.users = response.data.data 
                loading = false
            })
        },
        search(){
            let self = this
            let api = this.$store.getters.api + 'ticket-management'

            self.isLoading = true

            var params = {
                paginated: false
            }

            if(self.customer != ''){
                params['customer_id'] = self.customer  
            }

            if(self.contract != ''){
                params['contract_id'] = self.contract  
            }

            if(self.user != ''){
                params['responsible_id'] = self.user  
            }

            //params['with'] = ['sent_by', 'sent.customers', 'responsible', 'contract', 'status_entity']
            params['from'] = self.from
            params['to'] = self.to
            params['fromHour'] = self.fromHour
            params['toHour'] = self.toHour
            params['without_hours'] = self.without_hours
            params['without_contract'] = self.without_contract
            params['without_responsible'] = self.without_responsible
            params['without_area'] = self.without_area
            params['without_process_name'] = self.without_process_name            
                
            self.$http.get(api,{
                params: params
            })
            .then( response => {
                self.tickets = response.data
            })
            .then( () => {
                self.isLoading = false 
            })
        },
        getLists(){

            this.getContracts()
            this.getAreas()
            this.getProcessNames()
        },
        getContracts(){
            let self = this
            let api = this.$store.getters.api + 'contracts'

            self.$http.get(api,{
                params: {
                    paginated: false
                }
            })
            .then( response => {
                self.contractList = response.data.data
            })
        },
        getAreas(){
            let self = this
            let api = this.$store.getters.api + 'process_areas'

            self.$http.get(api,{
                params: {
                    paginated: false
                }
            })
            .then( response => {
                self.areaList = response.data.data
            })
        },
        getProcessNames(){
            let self = this
            let api = this.$store.getters.api + 'process_names'

            self.$http.get(api,{
                params: {
                    paginated: false
                }
            })
            .then( response => {
                self.processNameList = response.data.data
            })
        }
    },
}
</script>

<style>

</style>