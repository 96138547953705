<template>
  <div>
    <section>
      <!-- Main Content -->
      <div class="main-content">
        <div class="row">
          <div class="col-md-1">
            <button
              class="btn btn-filters bkd"
              type="button"
              data-toggle="modal"
              data-target="#modalNew"
            >
              <i class="fas fa-plus"></i> Novo Item
            </button>
          </div>
          <div class="col-md-1">
            <button
              class="btn btn-filters"
              type="button"
              data-toggle="collapse"
              data-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <i class="fas fa-filter"></i> Filtros
            </button>
          </div>
          <div class="col-md-12">
            <small style="margin-top: -15px" class="mb-3">Legenda:</small><br />
            <div class="row">
              <div class="col-md-2 mb-3">
                <i class="fas fa-circle blue" style="font-size: 14px"></i>
                &nbsp; Aguardando
              </div>
              <div class="col-md-2 mb-3">
                <i class="fas fa-circle yellow" style="font-size: 14px"></i>
                &nbsp; Em desenvolvimento
              </div>
              <div class="col-md-2 mb-3">
                <i class="fas fa-circle green" style="font-size: 14px"></i>
                &nbsp; Finalizado
              </div>
            </div>
          </div>
        </div>
        <div class="row collapse" id="collapseExample">
          <div class="col-md-4" style="margin-top: 15px">
            <label>Responsável</label>
            <v-select
              :options="users"
              label="name"
              :reduce="(users) => users.id"
              multiple
              class="style-chooser"
              v-model="filters.responsible"
            >
              <div slot="no-options">Nenhum registro encontrado.</div>
            </v-select>
          </div>
          <div class="col-md-4" style="margin-top: 15px">
            <label>Solicitante</label>
            <v-select
              :options="users"
              label="name"
              :reduce="(users) => users.id"
              multiple
              class="style-chooser"
              v-model="filters.created_by"
            >
              <div slot="no-options">Nenhum registro encontrado.</div>
            </v-select>
          </div>
          <div class="col-md-4" style="margin-top: 15px">
            <label>Status</label>
            <v-select
              :options="status"
              label="title"
              :reduce="(status) => status.id"
              multiple
              class="style-chooser"
              v-model="filters.status"
            >
              <div slot="no-options">Nenhum registro encontrado.</div>
            </v-select>
          </div>
          <div class="col-md-6" style="margin-top: 15px">
            <label>Projeto</label>
            <v-select
              :options="projects"
              label="name"
              v-on:input="loadSprint()"
              :reduce="(projects) => projects"
              class="style-chooser"
              v-model="filters.project"
            >
              <div slot="no-options">Nenhum registro encontrado.</div>
            </v-select>
          </div>
          <div
            class="col-md-6"
            style="margin-top: 15px"
            v-if="filters.project && sprintLoaded"
          >
            <label>Sprint</label>
            <v-select
              :options="filters.project.sprints"
              label="name"
              multiple
              :reduce="(sprints) => sprints.id"
              class="style-chooser"
              v-model="filters.sprint_id"
            >
              <div slot="no-options">Nenhum registro encontrado.</div>
            </v-select>
          </div>
          <div class="col-md-6" style="margin-top: 15px">
            <label>Exibir itens finalizados ?</label>
            <br />
            <input type="checkbox" v-model="show_finished" />
          </div>
          <div
            align="center"
            style="margin-top: 15px; margin-bottom: 15px"
            class="col-md-12"
          >
            <button
              class="btn btn-add"
              data-toggle="collapse"
              data-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
              @click="getEntity(), (filtered = true)"
            >
              Filtrar
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div
              class="alert alert-danger"
              role="alert"
              style="text-align: center"
              v-if="filters.project && filtered == true"
            >
              Filtrando os itens do backlog para o projeto:
              <strong>{{ filters.project.name }}</strong>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="card-title">
                  Backlog
                  {{
                    filters.project == undefined && !filtered ? "do IPP" : ""
                  }}
                  em Votação
                </div>
              </div>
              <div class="card-body">
                <table-data
                  :backlogs="backlogs.inVotation"
                  :total="backlogs.inVotation_count"
                  @viewBacklog="viewBacklog"
                  @toggleVote="toggleVote"
                  @openModalAttribution="openModalAttribution"
                  @changeStatusModal="changeStatusModal"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="card-title">
                  Backlog
                  {{
                    filters.project == undefined && !filtered ? "do IPP" : ""
                  }}
                  em Desenvolvimento
                </div>
              </div>
              <div class="card-body">
                <table-data
                  :backlogs="backlogs.outVotation"
                  :total="backlogs.outVotation_count"
                  @viewBacklog="viewBacklog"
                  @toggleVote="toggleVote"
                  @openModalAttribution="openModalAttribution"
                  @changeStatusModal="changeStatusModal"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="show_finished">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="card-title">
                  Backlog
                  {{
                    filters.project == undefined && !filtered ? "do IPP" : ""
                  }}
                  Finalizado
                </div>
              </div>
              <div class="card-body">
                <table-data
                  :backlogs="backlogs.finished"
                  :total="backlogs.finished_count"
                  @viewBacklog="viewBacklog"
                  @toggleVote="toggleVote"
                  @openModalAttribution="openModalAttribution"
                  @changeStatusModal="changeStatusModal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    ></loading>

    <!-- Modal Atributtion -->
    <div
      class="modal fade"
      id="modalStatus"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Alterar Status
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mt-2">
                <label for>Selecione o Status</label>
                <v-select
                  :options="status"
                  label="title"
                  :reduce="(status) => status.id"
                  class="style-chooser"
                  v-model="change_status.status"
                >
                  <div slot="no-options">Nenhum registro encontrado.</div>
                </v-select>
              </div>
              <div class="col-md-12 mt-2">
                <label for>Previsão de Entrega</label>
                <input
                  type="date"
                  v-model="change_status.forecast_date"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button type="button" class="btn btn-primary" @click="saveStatus">
              Salvar Status
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Atributtion -->
    <div
      class="modal fade"
      id="modalAttribution"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Atribuir Colaborador
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mt-2">
                <label for>Selecione o Colaborador</label>
                <v-select
                  :options="users"
                  label="name"
                  :reduce="(users) => users.id"
                  class="style-chooser"
                  v-model="attribution.user_id"
                >
                  <div slot="no-options">Nenhum registro encontrado.</div>
                </v-select>
              </div>
              <div class="col-md-12 mt-2">
                <label for>Previsão de Entrega</label>
                <input
                  type="date"
                  v-model="attribution.forecast_date"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button type="button" class="btn btn-primary" @click="attribute">
              Atribuir
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal New -->
    <div
      class="modal fade"
      id="modalNew"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{ new_backlog.edit ? "Editar Backlog" : "Novo Backlog" }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" style="margin-top: 10px">
              <div class="col-md-9">
                <label for>Título</label>
                <input
                  type="text"
                  v-model="new_backlog.title"
                  class="form-control"
                />
              </div>
              <div class="col-md-3">
                <label for>Tipo</label>
                <v-select
                  :options="types"
                  label="title"
                  :reduce="(types) => types.id"
                  class="style-chooser"
                  v-model="new_backlog.type"
                >
                  <div slot="no-options">Nenhum registro encontrado.</div>
                </v-select>
              </div>
              <div class="col-md-6">
                <label for>Projeto</label>
                <v-select
                  :options="projects"
                  label="name"
                  :reduce="(projects) => projects"
                  class="style-chooser"
                  v-model="new_backlog.project"
                >
                  <div slot="no-options">Nenhum registro encontrado.</div>
                </v-select>
              </div>
              <div class="col-md-6" v-if="new_backlog.project">
                <label for>Sprint</label>
                <v-select
                  :options="new_backlog.project.sprints"
                  label="name"
                  :reduce="(sprints) => sprints.id"
                  class="style-chooser"
                  v-model="new_backlog.sprint_id"
                >
                  <div slot="no-options">Nenhum registro encontrado.</div>
                </v-select>
              </div>
              <div class="col-md-12 mt-2">
                <label for>Descrição</label>
                <ckeditor
                  :editor="editor"
                  v-model="new_backlog.description"
                  :height="20"
                  :rows="2"
                  :config="editorConfig"
                ></ckeditor>
              </div>

              <!-- <template v-if="new_backlog.project_id">
                <div
                  class="col-md-12 mt-2"
                  v-if="new_backlog.project_id.sprints"
                >
                  <label for>Selecione a Sprint</label>
                  <v-select
                    :options="new_backlog.project_id.sprints"
                    label="name"
                    :reduce="(sprints) => sprints.id"
                    class="style-chooser"
                    v-model="new_backlog.sprint_id"
                  >
                    <div slot="no-options">Nenhum registro encontrado.</div>
                  </v-select>
                </div>
              </template> -->
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="!new_backlog.edit"
              @click="newBacklog"
            >
              Criar Backlog
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-else
              @click="saveChanges"
            >
              Editar Backlog
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal View -->
    <div
      class="modal fade"
      id="modalView"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Visualizar</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <small>Título</small>
                <br />
                <span
                  class="mt-3"
                  style="font-weight: bold; color: #000c40; font-size: 16px"
                  >{{ view.title }}</span
                >
              </div>
              <div class="col-md-12 mt-4">
                <small>Descrição</small>
                <div class="mt-3" v-html="view.description"></div>
              </div>
              <div class="col-md-12 mt-4">
                <small>Votos</small>
                <div v-for="user in view.votes" :key="user.id">
                  <div :class="'chat message'">
                    <img
                      class="chat-pic ml-2"
                      :src="user.user.photo"
                      alt="Chat Profile"
                    />
                    <div class="chat-message">
                      <div class="chat-who" style="font-size: 14px">
                        {{ user.user.name }}
                      </div>
                      <div class="chat-text" style="font-size: 11px">
                        Votou em {{ user.created_at }}
                      </div>
                    </div>

                    <div class="clearfix mt-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              v-if="view.admin"
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="deleteBacklog"
            >
              Deletar Backlog
            </button>
            <button
              v-if="view.admin"
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="editBacklog"
            >
              Editar Backlog
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import TableData from "./Table.vue";

export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
      sprintLoaded: true,
      show_finished: false,
      filters: {},
      filtered: false,
      editor: ClassicEditor,
      editorData: "",
      plugins: ["SimpleUploadAdapter"],
      editorConfig: {
        extraPlugins: [MyUploadAdapterPlugin],
        height: "100px",
      },
      backlogs: [],
      new_backlog: {},
      projects: [],
      users: [],
      view: {},
      status: [
        { title: "Aguardando", id: 1 },
        { title: "Em desenvolvimento", id: 2 },
        { title: "Finalizado", id: 3 },
      ],
      types: [
        { title: "Melhoria", id: 1 },
        { title: "BUG", id: 2 },
      ],
      attribution: {},
      change_status: {},
    };
  },
  computed: {},
  methods: {
    loadSprint() {
      const self = this;
      self.sprintLoaded = false;
      delete self.filters.sprint_id;
      setTimeout(function () {
        self.sprintLoaded = true;
      }, 100);
    },
    editBacklog() {
      const self = this;
      self.new_backlog = self.view;
      self.new_backlog.edit = true;
      console.log(self.viewBacklog);
      $("#modalNew").modal("toggle");
    },
    deleteBacklog() {
      const self = this;

      swal({
        title: "Deseja deletar o registro?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O registro não foi excluido!", "info");
            break;

          case "confirm":
            const api = self.$store.state.api + "backlogs/" + self.view.id;
            self.isLoading = true;

            self.$http
              .delete(api)
              .then((response) => {
                self.getEntity();
                self.isLoading = false;
                swal("Sucesso", "Registro excluído com sucesso!", "success");
              })
              .catch((error) => {
                swal("Erro", "O registro não foi excluido!", "error");
                self.isLoading = false;
              });

            self.$http
              .delete(this.url + row.id, {
                id: row.id,
              })
              .then((response) => {
                swal("Sucesso", "Registro excluído com sucesso!", "success");

                $(function () {
                  setTimeout(function () {
                    location.reload();
                  }, 1000);
                });
              });
            break;
        }
      });
    },
    saveChanges() {
      const self = this;
      const api = self.$store.state.api + "edit-backlog";
      self.isLoading = true;

      self.$http
        .post(api, self.new_backlog)
        .then((response) => {
          self.getEntity();
          self.isLoading = false;
          $("#modalNew").modal("hide");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });
            html += "</ul>";
            self.errors = html;
          }
          self.$message(null, self.errors, "error");
          self.isLoading = false;
        });
    },
    changeStatusModal(id) {
      const self = this;
      self.change_status.backlog_id = id;
    },
    openModalAttribution(id) {
      const self = this;
      self.attribution.backlog_id = id;
    },
    backlogStatus(backlog) {
      if (backlog.status == 1) {
        return "Aguardando";
      }
      if (backlog.status == 2) {
        return "Em desenvolvimento";
      }
      if (backlog.status == 3) {
        return "Finalizado";
      }
    },
    viewBacklog(obj) {
      const self = this;
      self.view = obj;
      $("#modalView").modal("toggle");
    },
    attribute: function () {
      const self = this;
      const api = self.$store.state.api + "attribute-backlog";
      self.isLoading = true;

      self.$http
        .post(api, self.attribution)
        .then((response) => {
          self.getEntity();
          self.isLoading = false;
          self.attribution = {};

          $("#modalAttribution").modal("hide");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });
            html += "</ul>";
            self.errors = html;
          }
          self.$message(null, self.errors, "error");
          self.isLoading = false;
        });
    },
    saveStatus: function () {
      const self = this;
      const api = self.$store.state.api + "save-status-backlog";
      self.isLoading = true;

      self.$http
        .post(api, self.change_status)
        .then((response) => {
          self.getEntity();
          self.isLoading = false;
          self.change_status = {};

          $("#modalStatus").modal("toggle");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });
            html += "</ul>";
            self.errors = html;
          }
          self.$message(null, self.errors, "error");
          self.isLoading = false;
        });
    },
    newBacklog: function () {
      const self = this;
      const api = self.$store.state.api + "save-backlog";
      self.isLoading = true;

      self.new_backlog.project_id = self.new_backlog.project.id;

      self.$http
        .post(api, self.new_backlog)
        .then((response) => {
          self.getEntity();
          self.isLoading = false;
          $("#modalNew").modal("hide");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });
            html += "</ul>";
            self.errors = html;
          }
          self.$message(null, self.errors, "error");
          self.isLoading = false;
        });
    },
    toggleVote: function (id) {
      const self = this;
      const api = self.$store.state.api + "toggle-vote";
      self.isLoading = true;

      let obj = { id: id };

      self.$http
        .post(api, obj)
        .then((response) => {
          self.getEntity();
          self.isLoading = false;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.getEntity();
          self.isLoading = false;
        });
    },
    getEntity: function (id) {
      const self = this;
      const api = self.$store.state.api + "get-backlogs";
      self.isLoading = true;

      self.$http
        .post(api, self.filters)
        .then((response) => {
          self.backlogs = response.data;
          self.isLoading = false;
          self.new_backlog = {};
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.isLoading = false;
        });
    },
    getProjects: function (id) {
      const self = this;
      const api =
        self.$store.state.api + "projects?paginated=false&with[]=sprints";

      self.$http
        .get(api)
        .then((response) => {
          self.projects = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUsers: function (id) {
      const self = this;
      const api = self.$store.state.api + "schedule-users?";

      self.$http
        .post(api)
        .then((response) => {
          self.users = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  components: { vSelect, Loading, ClassicEditor, TableData },
  props: {},
  mounted: function () {
    const self = this;
    self.getEntity();
    self.getProjects();
    self.getUsers();
  },
};

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    xhr.open(
      "POST",
      "https://api.projetos.innovareti.com.br/api/v1/images/upload/",
      true
    );
    xhr.responseType = "json";
  }
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }
      resolve({
        default: response.url,
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  _sendRequest(file) {
    const data = new FormData();

    data.append("upload", file);

    this.xhr.send(data);
  }
}

function MyUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
    return new MyUploadAdapter(loader);
  };
}
</script>
<style scoped>
.green {
  color: rgb(57, 172, 0);
}

.red {
  color: rgb(172, 23, 0);
}

.blue {
  color: rgb(0, 113, 199);
}

.yellow {
  color: rgb(254, 211, 38);
}

.link {
  color: rgb(0, 98, 172);
  cursor: pointer;
  text-decoration: none;
}

.link:hover {
  color: rgb(0, 50, 89);
  cursor: pointer;
  text-decoration: underline;
}
</style>