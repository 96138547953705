<template>
  <div>
    <div class="panel animated fadeInRightBig">
      <div class="panel-heading">
        <div class="card-header">
          <div class="card-title">Visualizar Contrato</div>
        </div>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10">
            <form action>
              <div class="row">
                <div class="col-md-11">
                  <div class="form-group">
                    <label for="userName">Titulo</label>
                    <input type="text" class="form-control" v-model="typeofcontracts.title" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-11">
                  <div class="form-group">
                    <label for>Descrição</label>
                    <textarea
                      type="text"
                      class="form-control"
                      v-model="typeofcontracts.description"
                      rows="8"
                    />
                  </div>
                </div>
              </div>

              <hr />
              <br />
              <div class="block text-right">
                <a class="btn btn-success" @click="save">
                  Salvar
                  <i class="fa fa-save"></i>
                </a>
                <router-link to="/typeofcontracts" class="btn btn-info">
                  <i class="fa fa-arrow-left"></i> Voltar
                </router-link>
              </div>
            </form>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeofcontracts: {
        title: "",
        description: ""
      }
    };
  },
  computed: {
    url() {
      return this.$store.getters.api + this.$route.params.id;
    }
  },
  mounted() {
    let self = this;
    let id = self.$route.params.id;

    if (id) {
      self.getTypeOfContracts(id);
    }
  },

  methods: {
    getTypeOfContracts: function(id) {
      let self = this;
      let api = self.$store.state.api + "typeofcontracts/" + id;

      self.$http
        .get(api)
        .then(response => {
          self.typeofcontracts = response.data.data[0];
        })
        .catch(error => {
          self.errors = error.response.data;
          self.$message(null, self.errors, "error");
        });
    },
    save: function() {
      let api = this.$store.state.api + "typeofcontracts";
      const self = this;

      let form = new FormData();

      form.append("title", self.typeofcontracts.title);
      form.append("description", self.typeofcontracts.description);

      if (self.typeofcontracts.id) {
        api += "/" + self.typeofcontracts.id;
        form.append("_method", "PUT");
      }

      self.$http
        .post(api, form)
        .then(response => {
          self.typeofcontracts = response.data.data;
          self.tableData = self.users;

          self.$message("Sucesso", `Cadastrado com sucesso`, "success");

          self.typeofcontracts = [];
          this.$router.push("/typeofcontracts");
        })
        .catch(error => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function(key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
    }
  }
};
</script>
<style scoped>
</style>

