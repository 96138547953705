<template>
  <div>
    <base-crud
      crudName="Chamados Finalizados"
      newText="Nova Solicitação"
      :table="table"
      :columns="columns"
      :options="options"
      :endPoint="endPoint"
      :enableAdd="false"
      :enableEdit="false"
      :enableDelete="true"
      :enableView="true"
      :dataForm="dataForm"
      :extra="extra"
      @open="openTap"
      @squad="squad"
    ></base-crud>
    <div>
      <div
        class="modal fade"
        id="squadModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="attachModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="squadModalLabel">
                Squad Reponsável - {{ idModal.code }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div style="padding-left: 70px" class="col-md-8">
                <div class="form-group">
                  <label for="sprint_id">Squad Atuante:</label>
                  <v-select
                    :options="squads"
                    label="name"
                    id="squads"
                    :reduce="(squads) => squads.name"
                    v-model="ticket.squad"
                  >
                    <div slot="no-options">Carregando...</div>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                @click="assignSquad()"
              >
                Salvar
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                @click="clean()"
              >
                <i class="fa fa-times"></i> Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../Base/BaseCrud";

import vSelect from "vue-select";

export default {
  data: function () {
    return {
      ticket: {
        squad: "",
      },
      idModal: {},
      squads: [
        {
          id: 1,
          name: "Time Fullstack",
        },
        {
          id: 2,
          name: "Time Fluig",
        },
        {
          id: 3,
          name: "Comercial",
        },
      ],
      assigneds: [],
      assignedIds: [],
      table: "closedsTickets",
      pages: [],
      url: "",
      columns: [
        "client_id",
        "sent",
        "code",
        "subject",
        "status_entity",
        "created_at",
        "squad",
        "contract",
        "actions",
      ],
      tableData: ["id", "description"],
      options: {
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        filterByColumn: true,
        filterable: [
          "client_id",
          "sent",
          "code",
          "subject",
          "status_entity",
          "contract",
          "created_at",
          "squad",
        ],
        sortable: [
          "client_id",
          "sent",
          "code",
          "subject",
          "status_entity",
          "created_at",
          "squad",
        ],
        listColumns: {
          status_entity: [
            {
              id: 11,
              text: "Finalizado",
            },
          ],
        },
        debounce: 800,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          client_id: "Cliente",
          sent: "Enviado por",
          code: "Número do Chamado",
          subject: "Título",
          status_entity: "Status",
          created_at: "Aberto em",
          squad: "Time Responsável",
          actions: "Ações",
        },
        sortable: ["id", "priority"],
        templates: {
          created_at: function (h, row, index) {
            return moment(row.created_at).format("DD/MM/YYYY HH:mm:ss");
          },
          status_entity: function (h, row, index) {
            if (row.status == "11") {
              return (
                <h5>
                  <span class="badge badge-success">Finalizado</span>
                </h5>
              );
            }
          },
          client_id: function (h, row, index) {
            return row.sent
              ? row.sent.customers.customer_name
              : "Nenhum Registro";
          },
          sent: function (h, row, index) {
            return row.sent ? row.sent.name : "Nenhum Registro";
          },
          contract: function (h, row, index) {
            return row.contract ? row.contract.name : "";
          },
          squad: function (h, row, index) {
            return row.squad ? row.squad : "Não Atribuido";
          },
          contract: function (h, row, index) {
            return row.contract ? row.contract.name : "Sem Informação";
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = [
            "sent.customers",
            "status_entity",
            "contract",
          ];

          console.log('resultado ' ,this.$store.getters.user.switch);
          console.log('resultado ' ,this.$store.getters.user);
          if (this.$store.getters.user.switch == 0) {
            console.log('entrou');
            requestData.params.responsible_id = this.$store.getters.user.id;
          }

          return this.$http.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      extra: [
        {
          id: 1,
          title: "Abrir em outra aba",
          class: "btn btn-crud check fa fa-arrow-up",
          name: "open",
          label: "",
        },
        {
          id: 2,
          label: "",
          class: "btn btn-crud edit fa fa-users",
          title: "Setar Squad?",
          name: "squad",
          meta: {},
        },
      ],
      endPoint: "new_tickets/",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
    vSelect,
  },
  methods: {
    squad(props, row, index) {
      this.idModal = props.row;
      $("#squadModal").modal("show");
    },
    getTicket() {
      const self = this;
      self.$http
        .get(self.$store.getters.api + "ticket_assign_squad/")
        .then((response) => {
          self.ticket = response.data.data;
          console.log((self.ticket = response.data.data));
        });
    },
    assignSquad: function () {
      const self = this;

      if (this.ticket.squad == "" || this.ticket.squad == null) {
        swal("Erro", "Selecione um Squad Responsável para Salvar!", "info");
      } else {
        let api = self.$store.state.api + "ticket_assign_squad/";

        swal({
          title: "Deseja Alterar o squad reponsável?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Sim",
          showLoaderOnConfirm: true,
          buttons: {
            catch: {
              text: "Não",
              value: "cancel",
              className: "btn-danger",
            },
            confirm: {
              text: "Sim",
              value: "confirm",
            },
          },
        })
          .then((value) => {
            switch (value) {
              case "cancel":
                swal("Cancelado", "A operação não foi realizada", "info");
                break;

              case "confirm":
                this.idModal.squad = this.ticket.squad;

                self.$http.post(api, this.idModal).then((response) => {
                  self.$refs.crudTickets.$refs.table.refresh();
                });
                break;
            }
          })
          .catch((error) => {
            self.$message(null, error.response.data, "error");
          });
      }
      $("#squadModal").modal("hide");
    },
    clean() {
      this.ticket.squad = "";
    },
    openTap(props) {
      open("/new_tickets/view/" + props.row.id);
    },
    query: function (query) {
      let columns = {
        id: "id",
        client_id: "sent.customers[customer_name]",
        sent: "sent.customers[name]",
        code: "code",
        subject: "subject",
        status_entity: "status",
        created_at: "created_at",
        contract: "contract[name]",
      };
      let filters = "&orderByDesc[]=id&closed=1&";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
  mounted: function () {
    let self = this;
    this.user = this.$store.getters.user;

    $("#squadModal").modal("hide", function (e) {
      self.clean();
    });
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
</style>
