<template>
  <div>
    <div id="content" v-on:keyup="keymonitor" class="content animated slideInRight delay-1s">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                Resetar senha de {{ $store.getters.user.name }}
                <hr />
                <span class="required_fields">
                  <span class="required">*</span> Campos obrigatórios
                </span>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <form id="form">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="description">
                            <span class="required">*</span> Nova senha:
                          </label>
                          <input type="password" minlength="6" v-on:keyup="keymonitor" maxlength="12" class="form-control"
                            autocomplete="false" v-model="user.password" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="description">
                            <span class="required">*</span> Corfirme a nova
                            senha:
                          </label>
                          <input type="password" minlength="6" v-on:keyup="keymonitor" maxlength="12" class="form-control"
                            autocomplete="false" v-model="user.password_confirmation" />
                        </div>
                      </div>
                    </div>
                    <div v-if="activatedCaps" class="left">
                      <label>
                        <span class="caps">CapsLock Ativo
                          <i class="fa fa-arrow-circle-up" aria-hidden="true"></i></span>
                      </label>
                    </div>
                    <div v-if="comparePassword()" class="left">
                      <!-- <youtube :video-id="videoId" :player-vars="playerVars" @playing="playing"></youtube> -->
                      <label>
                        <span class="comparePassword">As senhas não podem ser diferentes!</span>
                      </label>
                    </div>
                    <hr />
                    <div class="row ">
                      <div class="col-md-12">
                        <div class="d-flex justify-content-between">
                          <template>
                            <button @click="$router.go(-1)" class="btn btn-info">
                              <i class="fa fa-arrow-left"></i> Voltar
                            </button>
                          </template>
                          <a class="btn btn-success" @click="save">
                            Salvar
                            <i class="fa fa-save"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        name: "",
        password: "",
        password_confirmation: ""
      },
    }
  },

  methods: {
    save() {
      const self = this;

      if (this.comparePassword()) {
        swal("Erro", "Senhas diferentes, Moisés!", "info");
      } else if (this.user.password.length < 6) {
        swal("erro", "A senha deve conter ao menos 6 caracteres!")
      } else if (this.user.password_confirmation.length < 6) {
        swal("erro", "A senha deve conter ao menos 6 caracteres!")
      } else {
        let api = self.$store.state.api + "users/change-password";
        let form = new FormData();

        form.append("_method", "POST");
        form.append("user_id", this.$store.getters.user.id);
        form.append("password", this.user.password);

        swal({
          title: "Deseja Alterar Senha?",
          text: "Essa operação não pode ser desfeita",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Sim",
          showLoaderOnConfirm: true,
          buttons: {
            catch: {
              text: "Não",
              value: "cancel",
              className: "btn-danger"
            },
            confirm: {
              text: "Sim",
              value: "confirm"
            }
          }
        })
          .then(value => {
            switch (value) {
              case "cancel":
                swal("Cancelado", "A operação não foi realizada", "info");
                break;

              case "confirm":
                self.$http.post(api, form).then(response => {
                  this.$router.push({
                    path: "/"
                  });
                });
                break;
            }
          })
          .catch(error => {
            self.$message(null, error.response.data, "error");
          });
      }
    },
    keymonitor() {
      if (event.getModifierState && event.getModifierState("CapsLock")) {
        this.activatedCaps = true;
      } else {
        this.activatedCaps = false;
      }
    },
    comparePassword: function () {
      if (this.user.password != this.user.password_confirmation || null)
        return [];
    },
    getUser: function (id) {
      const self = this;
      const api = self.$store.state.api + "users/" + id;

      self.$http
        .get(api)
        .then(response => {
          self.users = response.data.data[0];
        })
        .catch(error => {
          self.$message(null, error.response.data, "error");
        });
    },
    mounted: function () {
      const self = this;
      let id = self.$store.getters.user.id;
      if (id) {
        self.getUser(id);
      }
    }
  }
};
</script>

<style>
.comparePassword {
  color: rgb(241, 0, 0);
  font: bold;
  font-size: 15px;
}

.caps {
  color: black;
  font: bold;
  font-size: 15px;
}

.required {
  color: red;
}

iframe {
  width: 100%;
  max-width: 300px;
  height: 150px
}
</style>
