import ReportUserCustomer from '../components/ReportUserCustomer/ReportUserCustomer'

let routes = {
    path: '/report',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/report/report-usercustomer',
            name: 'Chamados por Usuário',
            component: ReportUserCustomer,
            meta: {
                permission: ''
            }
        }
    ]
}
export default routes

