<template>
  <div>
    <div class="panel animated fadeInRightBig">
      <div class="panel-heading">
        <div class="panel-title">
          <span>
            <b>Editar Registro</b>
            <i
              class="fa fa-user-circle"
              style="float: right; margin-top: 6px; font-size: 21px"
            ></i>
            <hr />
          </span>
        </div>
      </div>
      <div class="panel-body">
        <form id="form">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <span class="required">*</span>
                <label for="process_name">Nome do Processo:</label>
                <input
                  type="text"
                  class="form-control"
                  id="process_name"
                  v-model="process_names.process_name"
                  autocomplete="false"
                />
              </div>
            </div>
          </div>

          <div class="block text-right">
            <a class="btn btn-success" @click="save">
              Salvar
              <i class="fa fa-save"></i>
            </a>
            <router-link to="/process_names" class="btn btn-info">
              <i class="fa fa-arrow-left"></i> Voltar
            </router-link>
          </div>
          <div class="row">
            <div class="col-md-4">
              <b><span class="required">*</span> Campos Obrigatórios</b>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      process_names: {
        process_name: "",
      },

      errors: undefined,
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;

      self.process_names.id = self.$route.params.id;

      const api =
        this.$store.state.api + "process_names/" + self.process_names.id;

      self.process_names._method = "put";

      self.$http
        .post(api, self.process_names)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Registro atualizado com sucesso!`,
            "success"
          );
          this.$router.push("/process_names");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
    },
    getEntity(id) {
      const api = this.$store.state.api + "process_names/" + id;
      const self = this;

      self.$http
        .get(api)
        .then((response) => {
          self.process_names = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, "Não foi possível encontrar o produto", "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.getEntity(id);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
