import ProjectsSprints from '../components/Project/ProjectsSprints'

let routes = {
    path: '/projects',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/projects-sprints',
            name: 'Projetos-Sprints',
            component: ProjectsSprints,
            meta: {
                permission: 'is_admin'
            }
        },
    ]
};


export default routes
