<template>
    <div>
        <base-crud
                crudName="Innovare TI - Daily"
                newText="Responder Daily"
                :table="projectTable"
                :columns="columns"
                :options="options"
                :endPoint='endPoint'
                :enableAdd="true"
                :enableEdit="false"
                :enableDelete="false"
                :customFilters="customFilters"
                @filtered="filtered"
                :enableView="true"            
        >
        </base-crud>
    </div>
</template>

<script>
    import BaseCrud from '../Base/BaseCrud'
    import { debug } from 'util';
    import vSelect from 'vue-select';
    
    export default {
        data: function () {
            let self = this
            return {
                customFilters:[
                {
                  name: "created_at",
                  type: "date",
                  call: "filtered"
                }
              ],
                projectstatuses: [],
                dataForm: {},
                projectTable: 'projectTable',
                users: [],
                pages: [],
                url: '',
                columns: ['id','user_id', 'created_at', 'yesterday','today', 'impediments', 'actions'],
                tableData: ['id'],
                debounce: 800,
                options: {
                    filterByColumn: true,
                    filterable: ['user_id', 'yesterday','today', 'impediments'],
                    sortable: ['user_id', 'yesterday','today', 'impediments'],
                    pagination: {chunk: 10, dropdown: false, nav: 'scroll' },
                    perPage: 10,
                    perPageValues: [10,25,50,100],
                    headings: {
                        'id': 'ID',
                        'user_id': 'Usuarios',
                        'created_at': 'Data de Criação',
                        'yesterday' : 'O que fez ontem ?',
                        'today' : 'O que fará hoje ?',
                        'impediments': 'Impedimentos ?',
                        'actions': 'Ações'
                    },
                    templates: {
                      user_id: function (h, row, index) {
                            return row.user ? row.user.name : 'Nenhum Registro';
                        },       
                    },
                    requestFunction: function (data) {
                        let requestData = {};

                        let query = this.$parent.$parent.query(data.query);
                        requestData.params = data;

                        if (
                        this.$parent.$parent.created_at != null &&
                        this.$parent.$parent.created_at != ""
                        ) {
                          requestData.params.created_at = this.$parent.$parent.created_at;
                        }
                        
                        requestData.params.query = '';
                        requestData.params.orderByDesc = '';
                        requestData.params.ascending = '0';
                        requestData.params.with = [
                        'user'
                        ];                  
             

                        return this.$http.get(this.url + '?' + query, requestData)
                            .catch(function (e) {
                                    this.dispatch("error", e);
                                }.bind(this)
                            );
                    }
                },
                endPoint: 'dailies/',

            }
        },
        methods: {
            filtered(date) {
              this.created_at = date;
            },
            query: function (query) {
                let columns = {
                    user_id: 'user[name]',
                    created_at: 'created_at', 
                    yesterday: 'yesterday',
                    today: 'today', 
                    impediments: 'impediments', 
                };
                let filters = '';
                $.each(query, function (index, value) {
                    filters += columns[index] + '=' + value + '&';
                });
                return filters;
            },
        },
        mounted () {

            let self = this
            this.$http.get( this.$store.getters.api + 'projectstatuses?paginated=false')
            .then( response => { 
                self.projectstatuses = response.data.data
            })            
        },
        components: {
            BaseCrud
        },
    }
</script>

<style scoped>
    .VuePagination {
        text-align: center;
    }
</style>