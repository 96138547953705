<template>
    <div>
        <base-crud
                crudName="Innovare TI - Tipos de Contrato"
                newText="Novo Registro"
                :table="projectTable"
                :columns="columns"
                :options="options"
                :endPoint='endPoint'
                :enableAdd="true"
                :enableEdit="true"
                :enableDelete="true"
                :enableView="true"            
        >
          
        </base-crud>
    </div>
</template>

<script>
    import BaseCrud from '../Base/BaseCrud'
    import { debug } from 'util';
    
    export default {
        data: function () {
            let self = this
            return {
                projectstatuses: [],
                dataForm: {},
                projectTable: 'projectTable',
                users: [],
                pages: [],
                url: '',
                columns: ['id','title', 'description', 'actions'],
                tableData: ['id',],
                debounce: 800,
                options: {
                    filterByColumn: true,
                    filterable: ['id', 'title', 'description'],
                    sortable: ['id', 'title', 'description'],
                    pagination: {chunk: 10, dropdown: false, nav: 'scroll' },
                    perPage: 10,
                    perPageValues: [10,25,50,100],
                    headings: {
                        'id': 'ID',
                        'title': 'Titulo',
                        'description' : 'Descrição'
                    },
                    requestFunction: function (data) {
                        let requestData = {};

                        let query = this.$parent.$parent.query(data.query);
                        requestData.params = data;
                        requestData.params.query = '';
                        requestData.params.with = [
                        
                        ];

                        return this.$http.get(this.url + '?' + query, requestData)
                            .catch(function (e) {
                                    this.dispatch("error", e);
                                }.bind(this)
                            );
                    }
                },
                endPoint: 'typeofcontracts/',

            }
        },
        methods: {
            query: function (query) {
                let columns = {
                    id: 'id',
                    title: 'name', 
                    description: 'customer_id',
   
                };
                let filters = '';
                $.each(query, function (index, value) {
                    filters += columns[index] + '=' + value + '&';
                });
                return filters;
            },
        },
        mounted () {

            let self = this
            this.$http.get( this.$store.getters.api + 'projectstatuses?paginated=false')
            .then( response => { 
                self.projectstatuses = response.data.data
            })            
        },
        components: {
            BaseCrud
        },
    }
</script>

<style scoped>
    .VuePagination {
        text-align: center;
    }
</style>