import Crud from '../components/TicketCategory/Crud'

let routes = {
    path: '/ticketcategories',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
      {
          path: '/ticketcategories/:operation?/:id?',
          name: 'Categorias',
          component: Crud
      },
    ]
}

export default routes