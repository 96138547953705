<template>
  <div>
    <div class="panel-body">
      <div class="panel-heading">
        <div class="card-header">
          <div class="card-title">Novo Processo</div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
           <div class="col-md-3">
              <div class="form-group">
                <label for="userName">
                  <span class="required">*</span> Requisitante:
                </label>
                 <span class="requester"> {{this.user.name}} </span>
              </div>
            </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="userName">
                <span class="required">*</span> Titulo:
              </label>
              <input type="text" class="form-control" v-model="task .title"  placeholder />
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group">
              <label for="userName">
                <span class="required">*</span> Descrição:
              </label>
              <textarea class="form-control" v-model="task.description" name="story" rows="3" cols="33"></textarea>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="panel-heading">
        <div class="card-header">
          <button @click="addChild" class="btn btn-add pull-left">+</button>
          <div class="card-title">Tarefas</div>
        </div>
      </div>
      <br>
      <div class="col-md-12">
        <form v-for="(row, index) in rows" v-bind:key="row.id">
          <br>
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <label for="userName">
                  <span class="required">*</span> Titulo:
                </label>
                <input type="text" v-model="row.title" class="form-control"  placeholder />
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <label for="userName">
                  <span class="required">*</span> Descrição:
                </label>
                <textarea class="form-control" v-model="row.description" name="story" rows="1" cols="33"></textarea>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="userName">
                  <span class="required">*</span> Prazo:
                </label>
                <input type="date" v-model="row.deadline" class="form-control"/>
              </div>
            </div>
          </div>
            <br>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="userName">
                    <span class="required">*</span> Atribuir:
                  </label>
                  <v-select
                        :options="to_assign_items" :reduce="to_assign_items.id" v-model="row.to_assign" label="name"
                      >
                      <template slot="no-options">Digite para pesquisar Projetos</template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">{{ option.name }}</div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">{{ option.name }}</div>
                      </template>
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="userName">
                    <span class="required">*</span> Aprovação?
                  </label>
                </div>
                  <input style="margin-left: 35px;" type="checkbox" v-model="row.approval">
              </div>
                <div v-if="tasksProcess.to_assign === '1'" class="col-md-3">
                  <div class="form-group">
                    <label for="userName">
                      <span class="required">*</span> Grupo de aprovação:
                    </label>
                    <v-select
                        :filterable="false"
                        label="Projeto"
                      >
                      <template slot="no-options">Digite para pesquisar Projetos</template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">{{ option.name }}</div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">{{ option.name }}</div>
                      </template>
                    </v-select>
                  </div>
                </div>
              <div v-if="tasksProcess.to_assign === '2'" class="col-md-3">
                <div class="form-group">
                  <label for="userName">
                    <span class="required">*</span> Aprovador:
                  </label>
                  <v-select :options="users" :reduce="users => users.id" v-model="row.user_id" label="name">
                      <template slot="no-options">Digite para pesquisar Projetos</template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">{{ option.name }}</div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">{{ option.name }}</div>
                      </template>
                  </v-select>
                </div>
              </div>
            </div>
            <br>
            <button class="btn btn-danger" @click="removeChild(index)"><i class="fa fa-trash"></i></button>
          <br>
          <div class="panel-heading">
            <div class="card-header">
            </div>
          </div>
        </form>

        <div>
          <div>
            <span class="required_fields">
              <span class="required">*</span> Campos obrigatórios
            </span>
          </div>
          <div class="block text-right">
            <a @click="save" class="btn btn-success">
              Salvar
              <i class="fa fa-save"></i>
            </a>
            <router-link to="/budgetings" class="btn btn-info">
              <i class="fa fa-arrow-left"></i> Voltar
            </router-link>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
export default {
  components: { vSelect },
  data() {
   return {
     user: {},
     rows: [],
     task: {
       title: '',
       description: ''
     },
     tasksProcess: {
        title: '',
        description: '',
        deadline: '',
        to_assign: '',
        approval: '',
        approval_group: '',
        user_id:''
     },
      to_assign_items: [
          {
            id : '1',
            name: "Grupo"
          },
          {
            id : '2',
            name: "Usuário"
          }
        ]
   }
 },
 methods: {
   save() {
      const api = this.$store.state.api + "process";
      const self = this;

      let form = new FormData();
      form.append("task_user_id", self.user.id);
      form.append("title", self.task.title);
      form.append("description", self.task.description);


      self.$http
        .post(api, form)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Aplicação registrada com sucesso`,
            "success"
          );
          this.saveTaskProcess();
          this.$router.push("/process");
        })
        .catch((error) => {
          self.errors = error.response.data;

          if (typeof self.errors === "object") {
            let html = "<ul>";

            $.each(self.errors, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            self.errors = html;
          }

          self.$message(null, self.errors, "error");
        });
        this.saveTaskProcess();
    },

    saveTaskProcess () {
    const api = this.$store.state.api + "taskprocesses";
    const self = this;

    let form = new FormData();

    self.rows.forEach((row, index) => {
        form.append("tasksProcess[" + index + "][title]", row.title);
        form.append("tasksProcess[" + index + "][description]", row.description);
        form.append("tasksProcess[" + index + "][deadline]", row.deadline);
        form.append("tasksProcess[" + index + "][approval]", row.approval);
        form.append("tasksProcess[" + index + "][user_id]", row.user_id);
      });


    self.$http
      .post(api, form)
      .then((response) => {
        self.$message(
          "Sucesso",
          `Aplicação registrada com sucesso`,
          "success"
        );
      })
      .catch((error) => {
        self.errors = error.response.data;

        if (typeof self.errors === "object") {
          let html = "<ul>";

          $.each(self.errors, function (key, value) {
            html += "<li>" + value[0] + "</li>";
          });

          html += "</ul>";

          self.errors = html;
        }

        self.$message(null, self.errors, "error");
      });
   },

   addChild() {
      this.rows.push({});
    },
    removeChild(id) {
      this.rows.splice(id, 1);
    },
    getUsers() {
      const self = this;
      self.$http
        .get(self.$store.getters.api + "users/?paginated=false", {
          params: {
            // Adiciona Relacionamento.
            with: [],
          },
        })
        .then((response) => {
          self.users = response.data.data;
        });
    },
 },
 mounted() {
   const self = this;
   this.user = this.$store.state.user;
   this.getUsers();
 },
}
</script>

<style scoped>
 .required {
   color:red;
 }
 .requester {
   font-weight: bold;
 }
</style>>
