<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <div class="user-profile">
          <img v-if="previewImage" :src="previewImage" class="photo" />
          <img v-else :src="user.picture" class="photo" />
        </div>
        <br />
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div>
              <input
                id="file"
                type="file"
                accept="image/*"
                @change="uploadImage"
                name="userPic"
              />
            </div>
          </div>
        </div>
        <hr />
        <div class="user-profile">
          <h3 class="user_name_max">{{ user.name }}</h3>
          <p>{{ user.email }}</p>
        </div>
        <div class="col-md-12">
          <hr />
          <div class="block text-right">
            <a class="btn btn-success" @click="save">
              Salvar
              <i class="fa fa-save"></i>
            </a>
            <router-link to="/" class="btn btn-info">
              <i class="fa fa-arrow-left"></i> Voltar
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        id: 0,
        name: "",
        email: "",
        picture: "",
      },
      file: [],
      previewImage: null,
    };
  },
  mounted: function () {
    this.user = this.$store.state.user;
    
  },
  methods: {
    save() {
      const self = this;
      const api = self.$store.getters.api + "user/image/update/" + self.user.id;
      let data = new FormData();

      data.append("id", self.user.id);
      data.append("photo", document.getElementById("file").files[0]);

      self.$http
        .post(api, data)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };
    },
    makeid() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
  },
};
</script>

<style scoped>
.img-bor {
  width: 150px;
}
.photo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 300px;
  object-fit: cover;
}

.rigth {
  text-align: right;
}
.user-profile {
  text-align: center;
}
hr {
  color: black;
  background-color: #2ea2cd;
  height: 1px;
}
#inp {
  text-align: center;
  margin: auto;
}
</style>
