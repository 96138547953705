import CrudSprint from '../components/Sprint/CrudSprint'
import Entity from '../components/Sprint/Entity'


let routes = {
    path: '/projects',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/sprints/add',
            name: 'Add Sprint',
            component: Entity,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/sprints/edit/:id',
            name: 'Edit Sprint',
            component: Entity,
            meta: {
                permission: 'is_admin'
            }
        },
        {
            path: '/sprints',
            name: 'Projetos',
            component: CrudSprint,
            meta: {
                permission: 'is_admin'
            }
        },
    ]
};


export default routes
